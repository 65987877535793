import React, { useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { Badge, Switch, Button, Form, Input, InputNumber, message, Row, Segmented, Select, Col, Tooltip } from 'antd';
import AsyncEntityAutocomplete from 'components/jarvisly/AsyncEntityAutocomplete';
import IntlMessage from 'components/util-components/IntlMessage';
import {
  InputCep,
  InputCpfCnpj,
  InputEmail,
  InputPhone,
  InputPlate,
  InputWebsite,
  isValidZip,
  isValidCnpj,
  isValidCpf,
  isValidEmail,
  isValidPlate,
  isValidUrl,
  translate,
} from 'utils/react-jarvisly-helper';
import { filterOption, isValidPhoneNumber, msg, onFormFinishFailed } from 'utils/helpers';
import DatePicker from 'components/jarvisly/DatePicker';
import PropTypes from 'prop-types';
import { getByUri, postByUri, putByUri } from 'components/jarvisly-layouts/ModuleComponent/module-api';
import { clone, returnOnlyNumbers } from 'jarvisly-helper';
import moment from 'moment';
import { ArrowLeftOutlined, ArrowRightOutlined, SaveOutlined } from '@ant-design/icons';
import { useRouter } from 'hooks/useRouter';
import { useSelector } from 'react-redux';
import { api } from 'components/jarvisly-layouts/ModuleComponent/module-methods';
import { AsyncAutoComplete } from '../../../../components/jarvisly/AsyncAutoComplete';
import { getEntitiesByField } from '../../_old-modules/entities/entities-api';

const { Option } = Select;

// CORE COMPONENTS
// =============================================================================

/**
 * Wraps a form with Ant Design Form component and provides common props and callbacks.
 *
 * @prop {object} props - The props passed to the component.
 * @prop {object} props.form - The Ant Design Form instance to use.
 * @prop {object} props.elRefs - The refs to the form fields.
 * @prop {object} [props.initialValues] - The initial values for the form fields.
 *
 * @returns {JSX.Element} The rendered Form component wrapping the children.
 */
export const FormWrapper = props => {

  const intl = useIntl();
  const router = useRouter();
  const rdxModule = useSelector(s => s.moduleRedux.rdxModule);

  const {
    name,
    form,
    elRefs,
    initialValues,
    uriApi,
    idField = '_id',
    showToast,
    onBeforeSave,
    onAfterSave,
    onError,
    dataContext,
    setDataContext,
    formContext,
    setFormContext,
  } = props;

  const onValuesChange = values => {
    const options = {
      name,
      values,
      form,
      initialValues,
      setFormContext,
      formContext,
      setDataContext,
      dataContext,
    };

    onFormValuesChange(options);
  };

  const onFinishFailed = errorInfo => onFormFinishFailed(errorInfo, elRefs);

  const onFinish = async values => {

    // if not changed
    if (!dataContext?.formsChanged?.includes(name)) {
      return onAfterSave && onAfterSave();
    }

    const t = {
      saving: `${translate(intl, 'saving_document')}...`,
      updating: `${translate(intl, 'updating_document')}...`,
      savingSuccess: `${translate(intl, 'document_saved_with_success')}!`,
      updatingSuccess: `${translate(intl, 'document_updated_with_success')}!`,
    };

    // treat the data form
    const v = onBeforeSave ? await onBeforeSave(values) : values;

    if (v === false) return;

    if (dataContext && setDataContext) {
      setDataContext({
        ...dataContext,
        fullLoading: true,
      });
    }

    const body = typeof v === 'object' ? v : values;

    if (!body?.[idField]) body[idField] = dataContext?.dataLock?._id;

    showToast && msg('i', t[body?.[idField] ? 'updating' : 'saving'], 'saving');

    try {
      const result = body?.[idField]
        ? await api.put(uriApi, body)
        : await api.post(uriApi, body);

      const data = result?.response?.data;

      message.destroy('saving');
      showToast && msg('s', t[body?.[idField] ? 'updatingSuccess' : 'savingSuccess'], 'add');

      const formsChanged = dataContext?.formsChanged?.filter(f => f !== name) || [];

      if (dataContext && setDataContext) {
        setDataContext({
          ...dataContext,
          data: { ...data },
          dataLock: { ...clone(data) },
          fullLoading: false,
          formsChanged: formsChanged,
        });
      }

      onAfterSave && await onAfterSave(data);

      if (formContext) {

        if (formContext?.mode === 'add') { // reload the page/module
          router.replace({
            pathname: `${rdxModule?.url}/${data._id}/profile`,
            state: { isActive: true }
          });
        }

        // force update the dataContext and refresh all page
        setFormContext({
          ...formContext,
          refreshTime: +new Date(),
          mode: 'view',
          dataMemory: null
        });
      }

    } catch (e) {

      message.destroy('saving');
      const errorMessage = `Erro ${e?.data?.code}: ${e?.data?.errorMessage}`;

      showToast && msg('e', errorMessage, 'error', 5);

      if (dataContext && setDataContext) {
        setDataContext({
          ...dataContext,
          fullLoading: false,
        });
      }

      onError && onError(e);
    }
  };

  return (<Form
    form={form}
    initialValues={initialValues}
    noValidate
    layout="vertical"
    onFinish={onFinish}
    onFinishFailed={onFinishFailed}
    onValuesChange={onValuesChange}
    validateTrigger="onChange"
    labelCol={{ span: 24 }}
    wrapperCol={{ span: 24 }}
    preserve={true}
    scrollToFirstError={true}>
    {props.children}
  </Form>);
};
FormWrapper.propTypes = {
  name: PropTypes.oneOfType([PropTypes.string.isRequired, PropTypes.array.isRequired]),
  form: PropTypes.object.isRequired,
  elRefs: PropTypes.object,
  initialValues: PropTypes.object,
  onBeforeSave: PropTypes.func,
  onAfterSave: PropTypes.func,
  onError: PropTypes.func,
  uriApi: PropTypes.string.isRequired,
  idField: PropTypes.string,
  showToast: PropTypes.bool,
  setDataContext: PropTypes.func,
  dataContext: PropTypes.object,
  setFormContext: PropTypes.func,
  formContext: PropTypes.object,
};

/**
 * FormWrapperModal is a higher order component that provides a form wrapped in an antd Modal.
 * It receives several props, including name, form, elRefs, initialValues, uriApi, idField, showToast,
 * formState, setFormState, onBeforeSave, onAfterSave and onError.
 * It defines the onFormValuesChange, onFinishFailed and onFinish functions that are used by the Form.
 * The onFinish function calls the onBeforeSave function if it exists and then tries to save the data
 * by sending a POST or PUT request to the uriApi endpoint. If successful, it calls the onAfterSave
 * function and updates the formState. Otherwise, it shows an error message and calls the onError function.
 *
 * @prop {object} props - An object containing the component's props.
 * @prop {string} props.name - The name of the form.
 * @prop {object} props.form - The antd Form object.
 * @prop {object} props.elRefs - An object containing references to the form's elements.
 * @prop {object} props.initialValues - An object containing the initial form data.
 * @prop {string} props.uriApi - The uri for the API endpoint.
 * @prop {string} [props.idField='_id'] - The name of the field that contains the document id.
 * @prop {boolean} [props.showToast=true] - Whether to show toast messages.
 * @prop {object} props.formState - An object containing the form's state.
 * @prop {function} props.setFormState - A function to set the form's state.
 * @prop {function} [props.onBeforeSave] - A function to preprocess the data before saving.
 * @prop {function} [props.onAfterSave] - A function to execute after saving the data.
 * @prop {function} [props.onError] - A function to execute when an error occurs while saving.
 * @returns {JSX.Element} - The JSX code for the wrapped form.
 */
export const FormWrapperModal = props => {

  const intl = useIntl();

  const {
    name,
    form,
    elRefs,
    initialValues,
    uriApi,
    idField = '_id',
    showToast,
    formState,
    setFormState,
    onBeforeSave,
    onAfterSave,
    onError,
  } = props;

  const onValuesChange = () => {
    setFormState(s => ({
      ...s,
      isChanged: formDataChanges(form, initialValues)
    }));
  };

  const onFinishFailed = errorInfo => onFormFinishFailed(errorInfo, elRefs);

  const onFinish = async values => {
    if (!formState.isChanged) return;

    const t = {
      saving: `${translate(intl, 'saving_document')}...`,
      updating: `${translate(intl, 'updating_document')}...`,
      savingSuccess: `${translate(intl, 'document_saved_with_success')}!`,
      updatingSuccess: `${translate(intl, 'document_updated_with_success')}!`,
    };

    // treat the data form
    const v = onBeforeSave ? onBeforeSave(values) : values;
    if (v === false) return;

    setFormState(s => ({
      ...s,
      loading: true
    }));

    const body = typeof v === 'object' ? v : values;

    showToast && msg('i', t[body?.[idField] ? 'updating' : 'saving'], 'saving');

    try {
      const doc = body?.[idField]
        ? await putByUri(uriApi, body?.[idField], body)
        : await postByUri(uriApi, body);

      message.destroy('saving');
      showToast && msg('s', t[body?.[idField] ? 'updatingSuccess' : 'savingSuccess'], 'add');

      onAfterSave && onAfterSave(doc);
      setFormState(s => ({
        ...s,
        loading: false
      }));

    } catch (e) {

      message.destroy('saving');
      const errorMessage = `Erro ${e?.data?.code}: ${e?.data?.errorMessage}`;

      showToast && msg('e', errorMessage, 'error', 5);

      onError && onError(e);
      setFormState(s => ({
        ...s,
        loading: false
      }));
    }
  };

  return (<Form name={name}
                form={form}
                initialValues={initialValues}
                noValidate
                layout="vertical"
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                onValuesChange={onValuesChange}
                validateTrigger="onChange"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                preserve={true}
                scrollToFirstError={true}>
    {props.children}
  </Form>);
};
FormWrapperModal.propTypes = {
  name: PropTypes.oneOfType([PropTypes.string.isRequired, PropTypes.array.isRequired]),
  form: PropTypes.object.isRequired,
  elRefs: PropTypes.object,
  initialValues: PropTypes.object,
  uriApi: PropTypes.string.isRequired,
  idField: PropTypes.string,
  showToast: PropTypes.bool,
  formState: PropTypes.object.isRequired,
  setFormState: PropTypes.func.isRequired,

  onBeforeSave: PropTypes.func,
  onAfterSave: PropTypes.func,
  onError: PropTypes.func,
};

/**
 * Renders a save button for a form wrapped in an Ant Design Row component.
 *
 * @prop {object} props - The props passed to the component.
 * @prop {string} [props.label] - The label of the save button. If not provided, it will be generated from props and default to 'save'.
 * @prop {string} [props.type='default'] - The type of the save button.
 * @prop {boolean} [props.disabled] - Whether the save button is disabled.
 * @prop {boolean} [props.loading] - Whether the save button is in a loading state.
 *
 * @returns {JSX.Element} The rendered Row component wrapping the Button component.
 */
export const FormSaveButton = props => {

  const intl = useIntl();

  const {
    label = buildLabel(props, intl, 'save'),
    type,
    disabled,
    loading,
    formName,
    dataContext,
    onlySave,
    setSubmitted,
    form,
  } = props;

  const autoType = dataContext?.formsChanged?.includes(formName) ? 'primary' : 'default';
  const onClick = () => {

    // force all fields touched to do the validation for item with hasFeedBack
    const touchedFields = Object.values(form.getFieldsError()).map(x => x.name).map(field => {
      return {
        name: field,
        touched: true,
      };
    });

    form.setFields(touchedFields);
    setSubmitted && setSubmitted(true);
    form.submit();
  };

  return (<Row align="middle" justify="end">

    <Button type={type || autoType}
      // htmlType="submit"
            loading={loading}
            onClick={onClick}
            style={{ minWidth: 100 }}
            icon={(autoType === 'primary' || onlySave) ? <SaveOutlined/> : <ArrowRightOutlined/>}
            disabled={disabled}>
          <span className="ml-2">
            {translate(intl, label)}
          </span>
    </Button>

  </Row>);
};
FormSaveButton.propTypes = {
  type: PropTypes.string,
  label: PropTypes.string,
  loading: PropTypes.bool,
  disabled: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  formname: PropTypes.oneOfType([PropTypes.string.isRequired, PropTypes.array.isRequired]),
  dataContext: PropTypes.object.isRequired,
  onlySave: PropTypes.bool,
  setSubmitted: PropTypes.func,
  form: PropTypes.object.isRequired,
};

/**
 * Renders a form input element wrapped in an Ant Design Form.Item component.
 *
 * @prop {object} props - The props passed to the component.
 * @prop {string} props.name - The name of the input element.
 * @prop {string} [props.label] - The label of the input element. If not provided, it will be generated from props.
 * @prop {object} [props.elRef] - The ref to the input element.
 * @prop {array} [props.rules] - The validation rules for the input element.
 * @prop {boolean} [props.disabled] - Whether the input element is disabled.
 * @prop {number} [props.maxLength] - The maximum length of the input element.
 *
 * @returns {JSX.Element} The rendered Form.Item component wrapping the Input component.
 */
export const FormInput = props => {

  const intl = useIntl();
  const t = {
    isRequired: translate(intl, 'required'),
  };

  const {
    name,
    value,
    label = buildLabel(props, intl),
    elRef,
    rules = [{
      required: !!props?.required,
      message: t.isRequired
    }],
    disabled,
    maxLength,
    autoFocus = false,
    placeholder,

    onChange,
    style
  } = props;

  if (rules.findIndex(x => x.required) === -1) {
    rules.push({
      required: !!props?.required,
      message: t.isRequired
    });
  }

  return (
    <Form.Item name={name}
               style={style}
               label={translate(intl, label)}
               rules={rules}>

      <Input ref={elRef}
             value={value}
             placeholder={placeholder}
             onChange={onChange}
             maxLength={maxLength}
             disabled={disabled}
             autoFocus={autoFocus}/>
    </Form.Item>);
};
FormInput.propTypes = {
  name: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string)]).isRequired, // name: PropTypes.arrayOf(PropTypes.string).isRequired,
  label: PropTypes.string,
  rules: PropTypes.array,
  elRef: PropTypes.shape(useRef.prototype),
  disabled: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  maxLength: PropTypes.number,
  required: PropTypes.bool,
  onChange: PropTypes.func,
  autoFocus: PropTypes.bool,
};
FormInput.defaultProps = {
  disabled: false,
};

/**
 * Renders an Ant Design Form.Item component wrapping an input number element.
 *
 * @prop {object} props - The props passed to the component.
 * @prop {string | Array<string>} props.name - The name of the input element.
 * @prop {string} [props.label] - The label of the input element. If not provided, it will be generated from props.
 * @prop {object} [props.elRef] - The ref to the input element.
 * @prop {array} [props.rules] - The validation rules for the input element.
 * @prop {boolean} [props.disabled] - Whether the input element is disabled.
 * @prop {number} [props.maxLength] - The maximum length of the input element.
 * @prop {boolean} [props.required] - Whether the input element is required.
 * @prop {object} [props.style] - The style object for the input element.
 * @prop {function} [props.formatter] - The function for formatting the input element value.
 * @prop {number} [props.min] - The minimum value for the input element.
 * @prop {number} [props.max] - The maximum value for the input element.
 * @prop {function} [props.onChange] - The function to be called when the input element value changes.
 *
 * @returns {JSX.Element} The rendered Form.Item component wrapping the InputNumber component.
 */
export const FormInputNumber = props => {

  const intl = useIntl();
  const t = {
    isRequired: translate(intl, 'required'),
  };

  const {
    name,
    label = buildLabel(props, intl),
    elRef,
    rules = [{
      required: !!props?.required,
      message: t.isRequired
    }],
    disabled,
    maxLength,
    style = { width: '100%' },
    formatter,
    min,
    max,

    decimalSeparator,
    required,

    suffixDefaultValue,
    suffixStyle,

    prefixDefaultValue,
    prefixStyle,

    onChange,
    onPrefixChange,
    onSuffixChange,

    field = {},
    noLabel,
    parser,
    placeholder = ''

  } = props;

  let {
    addonBefore,
    addonAfter,
  } = props;

  if (rules.findIndex(x => x.required) === -1) {
    rules.push({
      required: !!props?.required,
      message: t.isRequired
    });
  }

  // PREFIX
  const AddonPrefix = (
    <Select
      defaultValue={prefixDefaultValue}
      disabled={disabled}
      // options={options}
      style={prefixStyle}
      // value={value}
      // showSearch
      // allowClear
      filterOption={filterOption}
      onChange={onPrefixChange}
      // onSearch={onSearch}
      // disabled={disabled}
      required={required}>

      {Array.isArray(addonBefore) && addonBefore.map(item => (
        <Option key={item.value} value={item.value}>
          {item?.tooltipText
            ? <Tooltip title={item.tooltipText}>{item.label}</Tooltip>
            : item.label}
        </Option>
      ))}

    </Select>
  );

  if (addonBefore && Array.isArray(addonBefore)) addonBefore = AddonPrefix;

  // SUFFIX
  const AddonSuffix = (
    <Select
      defaultValue={suffixDefaultValue}
      disabled={disabled}
      // options={options}
      style={suffixStyle}
      // value={value}
      // showSearch
      // allowClear
      filterOption={filterOption}
      onChange={onSuffixChange}
      // onSearch={onSearch}
      // disabled={disabled}
      required={required}>

      {Array.isArray(addonAfter) && addonAfter.map(item => (
        <Option key={item.value} value={item.value}>
          {item?.tooltipText
            ? <Tooltip title={item.tooltipText}>{item.label}</Tooltip>
            : item.label}
        </Option>
      ))}

    </Select>
  );

  if (addonAfter && Array.isArray(addonAfter)) addonAfter = AddonSuffix;

  return (

    <Form.Item {...field}
               name={name}
               label={!noLabel && translate(intl, label)}
               rules={rules}
               required={required}>

      <InputNumber ref={elRef}
                   style={style}
                   formatter={formatter}
                   min={min}
                   max={max}
                   placeholder={placeholder}
                   parser={parser}
                   addonBefore={addonBefore}
                   addonAfter={addonAfter}
                   onChange={onChange}
                   maxLength={maxLength}
                   decimalSeparator={decimalSeparator}
                   disabled={disabled}/>
    </Form.Item>);
};
FormInputNumber.propTypes = {
  label: PropTypes.string,
  // name: PropTypes.oneOfType([PropTypes.string.isRequired, PropTypes.array.isRequired]) ,
  name: PropTypes.oneOfType([PropTypes.string.isRequired, PropTypes.array.isRequired]),
  rules: PropTypes.array,
  elRef: PropTypes.shape(useRef.prototype),
  disabled: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  maxLength: PropTypes.number,
  required: PropTypes.bool,
  style: PropTypes.object,
  formatter: PropTypes.func,
  min: PropTypes.number,
  max: PropTypes.number,
  onChange: PropTypes.func,
};

/**
 * Renders a form select element for gender wrapped in an Ant Design Form.Item component.
 *
 * @prop {object} props - The props passed to the component.
 * @prop {string} [props.name='gender'] - The name of the select element. Default value is 'gender'.
 * @prop {string} [props.label] - The label of the select element. If not provided, it will be generated from props and default to 'gender'.
 * @prop {object} [props.elRef] - The ref to the select element.
 * @prop {array} [props.rules] - The validation rules for the select element.
 * @prop {boolean} [props.disabled] - Whether the select element is disabled.
 *
 * @returns {JSX.Element} The rendered Form.Item component wrapping the Select component with gender options.
 */
export const FormSelectGender = props => {

  const intl = useIntl();

  const t = {
    male: translate(intl, 'male'),
    female: translate(intl, 'female'),
    notInformed: translate(intl, 'prefer_not_inform'),
    isRequired: translate(intl, 'required'),
  };

  const options = buildOptions();

  const {
    name = 'gender',
    label = buildLabel(props, intl, 'gender'),
    elRef,
    rules = [{
      required: !!props?.required,
      message: t.isRequired
    }],
    disabled,
  } = props;

  return <FormSelect elRef={elRef}
                     name={name}
                     label={label}
                     rules={rules}
                     options={options}
                     disabled={disabled}/>;

  function buildOptions () {
    return [
      {
        value: 'female',
        text: t.female,
        label: t.female,
        disabled: false,
      },
      {
        value: 'male',
        text: t.male,
        label: t.male,
        disabled: false,
      },
      {
        value: 'prefer_not_inform',
        text: t.notInformed,
        label: t.notInformed,
        disabled: false,
      },
    ];
  }

};
FormSelectGender.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  elRef: PropTypes.shape(useRef.prototype),
  rules: PropTypes.array,
  disabled: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  required: PropTypes.bool,
};

/**
 * Renders a form select element wrapped in a Ant Design Form.Item component.
 *
 * @prop {object} props - The props passed to the component.
 * @prop {string} props.name - The name of the select element.
 * @prop {string} [props.label] - The label of the select element. If not provided, it will be generated from props.
 * @prop {object} [props.elRef] - The ref to the select element.
 * @prop {array} [props.rules] - The validation rules for the select element.
 * @prop {boolean} [props.disabled] - Whether the select element is disabled.
 * @prop {function} [props.onChange] - The function called when the select value changes.
 * @prop {string|object} [props.value] - The current value of the select element.
 * @prop {array} [props.options] - The options available in the select element.
 * @prop {function} [props.filterOption] - The function used to filter the options based on the search query.
 * @prop {boolean} [props.required] - Whether the select element is required.
 *
 * @returns {JSX.Element} The rendered Form.Item component wrapping the Select component.
 */
export const FormSelect = props => {

  const intl = useIntl();
  const t = {
    isRequired: translate(intl, 'required'),
  };

  const {
    name,
    label = buildLabel(props, intl),
    elRef,
    rules = [{
      required: !!props?.required,
      message: t.isRequired
    }],
    disabled,
    onChange,
    onSearch,
    value,
    options = [],
    required,
  } = props;

  return (<Form.Item name={name}
                     label={translate(intl, label)}
                     rules={rules}>

    <Select ref={elRef}
            options={options}
            value={value}
            showSearch
            allowClear
            filterOption={filterOption}
            onChange={onChange}
            onSearch={onSearch}
            disabled={disabled}
            required={required}/>
  </Form.Item>);
};
FormSelect.propTypes = {
  // name: PropTypes.oneOfType([PropTypes.string.isRequired, PropTypes.array.isRequired]),
  name: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
  label: PropTypes.string,
  elRef: PropTypes.shape(useRef.prototype),
  rules: PropTypes.array,
  disabled: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  options: PropTypes.array.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  onChange: PropTypes.func,
  onSearch: PropTypes.func,
  filterOption: PropTypes.func,
  required: PropTypes.bool,
};
FormSelect.defaultProps = {
  required: false,
  value: '',
};

/**
 * Renders a form input element for CPF wrapped in an Ant Design Form.Item component.
 *
 * @prop {object} props - The props passed to the component.
 * @prop {string} [props.name='cpf'] - The name of the input element. Default value is 'cpf'.
 * @prop {string} [props.label] - The label of the input element. If not provided, it will be generated from props and default to 'person_cpf'.
 * @prop {object} [props.elRef] - The ref to the input element.
 * @prop {array} [props.rules=[{ required: false }]] - The validation rules for the input element. By default, only the required rule is set to false.
 * @prop {boolean} [props.disabled] - Whether the input element is disabled.
 * @prop {string|object} props.value - The current value of the input element.
 * @prop {object} props.form - The Ant Design Form instance used.
 *
 * @returns {JSX.Element} The rendered Form.Item component wrapping the InputCpfCnpj component with CPF validation.
 */
export const FormCpf = props => {

  const intl = useIntl();
  const t = {
    isRequired: translate(intl, 'required'),
    isInvalid: translate(intl, 'invalid_cpf'),
  };

  const {
    name = 'cpf',
    label = buildLabel(props, intl, 'person_cpf'),
    elRef,
    rules = [{
      required: !!props?.required,
      message: t.isRequired
    }],
    disabled,
    value,
    form,
  } = props;

  const [validatorState, setValidatorState] = useState(null);
  const isRequired = rules?.findIndex(x => x.required) > -1;

  if (rules.findIndex(x => x.validator) === -1) rules.push({ validator: $validator });

  return (<Form.Item name={name}
                     label={translate(intl, label)}
                     value={value}
                     hasFeedback
                     validateStatus={validatorState?.status}
                     help={validatorState?.message}
                     rules={rules}>

    <InputCpfCnpj ref={elRef}
                  profile="cpf"
                  disabled={disabled}/>
  </Form.Item>);

  function $validator () {
    if ($handleValidator() === 'error') {
      return Promise.reject();
    } else {
      return Promise.resolve();
    }
  }

  function $handleValidator () {

    let message = '', status = '';
    const v = form?.getFieldValue(name) !== 'undefined' && form?.getFieldValue(name);

    if (!v && !form?.isFieldsTouched()) {
      return setValidatorState({
        status,
        message
      });

    } else if (isRequired && !v) {
      message = t.isRequired;
      status = 'error';

    } else if (v && !isValidCpf(v)) {
      message = t.isInvalid;
      status = 'error';

    } else {
      status = v ? 'success' : '';
    }

    setValidatorState({
      status,
      message
    });
    return status;
  }

};
FormCpf.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  elRef: PropTypes.shape(useRef.prototype),
  rules: PropTypes.array,
  disabled: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  value: PropTypes.string,
  form: PropTypes.object.isRequired,
  required: PropTypes.bool,
};

/**
 * Renders a form input element for CNPJ wrapped in an Ant Design Form.Item component.
 *
 * @prop {object} props - The props passed to the component.
 * @prop {string} [props.name='cnpj'] - The name of the input element. Default value is 'cnpj'.
 * @prop {string} [props.label] - The label of the input element. If not provided, it will be generated from props and default to 'person_cnpj'.
 * @prop {object} [props.elRef] - The ref to the input element.
 * @prop {array} [props.rules=[{ required: false }]] - The validation rules for the input element. By default, only the required rule is set to false.
 * @prop {boolean} [props.disabled] - Whether the input element is disabled.
 * @prop {string|object} props.value - The current value of the input element.
 * @prop {object} props.form - The Ant Design Form instance used.
 *
 * @returns {JSX.Element} The rendered Form.Item component wrapping the InputCnpjCnpj component with CNPJ validation.
 */
export const FormCnpj = props => {

  const intl = useIntl();
  const t = {
    isRequired: translate(intl, 'required'),
    isInvalid: translate(intl, 'invalid_cnpj'),
  };

  const {
    name = 'cnpj',
    label = buildLabel(props, intl, 'company_cnpj'),
    elRef,
    rules = [{
      required: !!props?.required,
      message: t.isRequired
    }],
    disabled,
    value,
    form,
  } = props;

  const [validatorState, setValidatorState] = useState(null);
  const isRequired = rules?.findIndex(x => x.required) > -1;

  if (rules.findIndex(x => x.validator) === -1) rules.push({ validator: $validator });

  return (<Form.Item name={name}
                     label={translate(intl, label)}
                     value={value}
                     hasFeedback
                     validateStatus={validatorState?.status}
                     help={validatorState?.message}
                     rules={rules}>

    <InputCpfCnpj ref={elRef}
                  profile="cnpj"
                  disabled={disabled}/>
  </Form.Item>);

  function $validator () {

    if ($handleValidator() === 'error') {
      return Promise.reject();

    } else {
      return Promise.resolve();
    }
  }

  function $handleValidator () {

    let message = '', status = '';
    const v = form?.getFieldValue(name) !== 'undefined' && form?.getFieldValue(name);

    if (!v && !form?.isFieldsTouched()) {
      return setValidatorState({
        status,
        message
      });

    } else if (isRequired && !v) {
      message = t.isRequired;
      status = 'error';

    } else if (v && !isValidCnpj(v)) {
      message = t.isInvalid;
      status = 'error';

    } else {
      status = v ? 'success' : '';
    }

    setValidatorState({
      status,
      message
    });
    return status;
  }

};
FormCnpj.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  elRef: PropTypes.shape(useRef.prototype),
  rules: PropTypes.array,
  disabled: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  value: PropTypes.string,
  form: PropTypes.object.isRequired,
  required: PropTypes.bool,
};

/**
 * Renders a form date picker element wrapped in an Ant Design Form.Item component.
 *
 * @prop {object} props - The props passed to the component.
 * @prop {string} props.name - The name of the date picker element.
 * @prop {string} [props.label] - The label of the date picker element. If not provided, it will be generated from props.
 * @prop {object} [props.elRef] - The ref to the date picker element.
 * @prop {array} [props.rules=[{ required: false }]] - The validation rules for the date picker element. By default, only the required rule is set to false.
 * @prop {boolean} [props.disabled] - Whether the date picker element is disabled.
 * @prop {moment.Moment} [props.value] - The current value of the date picker element.
 * @prop {string} [props.format='DD/MM/YYYY'] - The format of the date picker element.
 * @prop {string} [props.placeholder] - The placeholder of the date picker element.
 * @prop {function} [props.onChange] - The function to be called when the date picker element value changes.
 * @prop {tooltip} [props.tooltip] = Message to show in mouse over (?) label icon
 *
 * @returns {JSX.Element} The rendered Form.Item component wrapping the DatePicker component.
 */
export const FormDatePicker = props => {

  const intl = useIntl();

  const t = {
    isRequired: translate(intl, 'required'),
  };

  const {
    name,
    label = buildLabel(props, intl),
    elRef,
    rules = [{
      required: !!props?.required,
      message: t.isRequired
    }],
    disabled,
    value,
    format = 'DD/MM/YYYY',
    placeholder,
    onChange,
    tooltip,
  } = props;

  return (<Form.Item
    name={name}
    label={translate(intl, label)}
    value={value}
    tooltip={tooltip}
    rules={rules}>
    <DatePicker className="w-100 no-border-radius"
                format={format}
                disabled={disabled}
                placeholder={placeholder}
                allowClear
                onChange={onChange}
                ref={elRef}/>
  </Form.Item>);
};
FormDatePicker.propTypes = {
  name: PropTypes.oneOfType([PropTypes.string.isRequired, PropTypes.array.isRequired]),
  label: PropTypes.string,
  elRef: PropTypes.shape(useRef.prototype),
  rules: PropTypes.array,
  disabled: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  value: PropTypes.object,
  format: PropTypes.string,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  required: PropTypes.bool,
  tooltip: PropTypes.string,
};

/**
 * Renders a form input element for phone number wrapped in an Ant Design Form.Item component.
 *
 * @prop {object} props - The props passed to the component.
 * @prop {string} [props.name='phone'] - The name of the input element. Default value is 'phone'.
 * @prop {string} [props.label] - The label of the input element. If not provided, it will be generated from props and default to 'phone'.
 * @prop {object} [props.elRef] - The ref to the input element.
 * @prop {array} [props.rules=[{ required: false }]] - The validation rules for the input element. By default, only the required rule is set to false.
 * @prop {boolean} [props.disabled] - Whether the input element is disabled.
 * @prop {string|object} props.value - The current value of the input element.
 * @prop {object} props.form - The Ant Design Form instance used.
 * @prop {string} [props.profile] - The profile of the input element. Can be 'cellphone' or 'phone'.
 *
 * @returns {JSX.Element} The rendered Form.Item component wrapping the InputPhone component with phone number validation.
 */
export const FormPhone = props => {

  const intl = useIntl();
  const t = {
    isRequired: translate(intl, 'required'),
    isInvalid: translate(intl, 'invalid_phone_number'),
  };

  const {
    name = 'phone',
    label = buildLabel(props, intl, 'phone'),
    elRef,
    rules = [{
      required: !!props?.required,
      message: t.isRequired
    }],
    disabled,
    value,
    form,
    profile,
    onBlur
  } = props;

  const [validatorState, setValidatorState] = useState(null);
  const isRequired = rules?.findIndex(x => x.required) > -1;

  if (rules.findIndex(x => x.validator) === -1) rules.push({ validator: $validator });

  return (<Form.Item name={name}
                     label={translate(intl, label)}
                     value={value}
                     hasFeedback
                     validateStatus={validatorState?.status}
                     help={validatorState?.message}
                     rules={rules}>

    <InputPhone ref={elRef}
                type={profile}
                onBlur={onBlur}
                disabled={disabled}/>
  </Form.Item>);

  function $validator () {

    if ($handleValidator() === 'error') {
      return Promise.reject();

    } else {
      return Promise.resolve();
    }
  }

  function $handleValidator () {

    let message = '', status = '';
    const v = form?.getFieldValue(name) !== 'undefined' && form?.getFieldValue(name);

    if (!v && !form?.isFieldsTouched()) {
      return setValidatorState({
        status,
        message
      });

    } else if (isRequired && !v) {
      message = t.isRequired;
      status = 'error';

    } else if (v && !isValidPhoneNumber(v, profile)) {
      message = t.isInvalid;
      status = 'error';

    } else {
      status = v ? 'success' : '';
    }

    setValidatorState({
      status,
      message
    });
    return status;
  }
};
FormPhone.propTypes = {
  // name: PropTypes.string,
  // name: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
  name: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]),
  label: PropTypes.string,
  elRef: PropTypes.shape(useRef.prototype),
  rules: PropTypes.array,
  disabled: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  value: PropTypes.object,
  form: PropTypes.object.isRequired,
  profile: PropTypes.string,
  required: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
  onBlur: PropTypes.func,
};
FormPhone.defaultProps = {
  disabled: false,
};

/**
 * A component that displays a title for a form. Uses react-intl for localization.
 *
 * @prop {Object} props - The props object for the FormTitle component
 * @prop {string} props.title - The title text to display
 * @prop {string} [props.className='mb-2'] - The CSS class to apply to the outer container element
 *
 * @returns {JSX.Element} - A JSX Element representing the FormTitle component
 */
export const FormTitle = props => {

  const intl = useIntl();
  const {
    title,
    className = 'mb-2',
    centered = false,
  } = props;

  return (<Row className={className}>
    <h5 className={`w-100 text-muted ${centered ? 'text-center' : ''}`}>
      {translate(intl, title || 'n_d')}
    </h5>
  </Row>);
};
FormTitle.propTypes = {
  title: PropTypes.string.isRequired,
  className: PropTypes.string,
  centered: PropTypes.bool,
};

/**
 * A component that displays an email input element wrapped in an Ant Design Form.Item component.
 *
 * @prop {Object} props - The props object for the FormEmail component
 * @prop {string} [props.name='email'] - The name of the email input element. Default value is 'email'.
 * @prop {string} [props.label] - The label of the email input element. If not provided, it will be generated from props and default to 'email'.
 * @prop {object} [props.elRef] - The ref to the email input element.
 * @prop {array} [props.rules=[{ required: false, message: 'This field is required' }]] - The validation rules for the email input element. By default, only the required rule is set to false.
 * @prop {boolean} [props.disabled] - Whether the email input element is disabled.
 * @prop {string|object} props.value - The current value of the email input element.
 * @prop {object} props.form - The Ant Design Form instance used.
 // * @prop {boolean} [props.required] - Whether the email input element is required. If set to true, it overrides the required rule in the rules prop.
 *
 * @returns {JSX.Element} The rendered Form.Item component wrapping the InputEmail component with email validation.
 */
export const FormEmail = props => {

  const intl = useIntl();
  const t = {
    isRequired: translate(intl, 'required'),
    isInvalid: translate(intl, 'invalid_email'),
  };

  const {
    name = 'email',
    label = buildLabel(props, intl, 'email'),
    elRef,
    rules = [{
      required: !!props?.required,
      message: t.isRequired
    }],
    disabled,
    value,
    form,

    onBlur,
  } = props;

  const [validatorState, setValidatorState] = useState(null);
  const isRequired = rules?.findIndex(x => x.required) > -1;

  if (rules.findIndex(x => x.validator) === -1) rules.push({ validator: $validator });

  return (<Form.Item name={name}
                     label={translate(intl, label)}
                     value={value}
                     hasFeedback
                     validateStatus={validatorState?.status}
                     help={validatorState?.message}
                     rules={rules}>

    <InputEmail ref={elRef}
                onBlur={onBlur}
                disabled={disabled}/>
  </Form.Item>);

  function $validator () {

    if ($handleValidator() === 'error') {
      return Promise.reject();

    } else {
      return Promise.resolve();
    }
  }

  function $handleValidator () {

    let message = '', status = '';
    const v = form?.getFieldValue(name) !== 'undefined' && form?.getFieldValue(name);

    if (!v && !form?.isFieldsTouched()) {
      return setValidatorState({
        status,
        message
      });

    } else if (isRequired && !v) {
      message = t.isRequired;
      status = 'error';

    } else if (v && !isValidEmail(v)) {
      message = t.isInvalid;
      status = 'error';

    } else {
      status = v ? 'success' : '';
    }

    setValidatorState({
      status,
      message
    });
    return status;
  }

};
FormEmail.propTypes = {
  // name: PropTypes.string,
  name: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
  label: PropTypes.string,
  elRef: PropTypes.shape(useRef.prototype),
  rules: PropTypes.array,
  disabled: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  value: PropTypes.object,
  form: PropTypes.object.isRequired,
  required: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
  onBlur: PropTypes.func,
};
FormEmail.defaultProps = {
  disabled: false,
};

/**
 * A component that displays an website input element wrapped in an Ant Design Form.Item component.
 *
 * @prop {Object} props - The props object for the FormWebsite component
 * @prop {string} [props.name='website'] - The name of the website input element. Default value is 'website'.
 * @prop {string} [props.label] - The label of the website input element. If not provided, it will be generated from props and default to 'website'.
 * @prop {object} [props.elRef] - The ref to the website input element.
 * @prop {array} [props.rules=[{ required: false, message: 'This field is required' }]] - The validation rules for the website input element. By default, only the required rule is set to false.
 * @prop {boolean} [props.disabled] - Whether the website input element is disabled.
 * @prop {string|object} props.value - The current value of the website input element.
 * @prop {object} props.form - The Ant Design Form instance used.
 * @prop {boolean} [props.required] - Whether the website input element is required. If set to true, it overrides the required rule in the rules prop.
 *
 * @returns {JSX.Element} The rendered Form.Item component wrapping the InputWebsite component with website validation.
 */
export const FormWebsite = props => {

  const intl = useIntl();
  const t = {
    isRequired: translate(intl, 'required'),
    isInvalid: translate(intl, 'invalid_website'),
  };

  const {
    name = 'website',
    label = buildLabel(props, intl, 'website'),
    elRef,
    rules = [{
      required: !!props?.required,
      message: t.isRequired
    }],
    disabled,
    value,
    form,
  } = props;

  const [validatorState, setValidatorState] = useState(null);
  const isRequired = rules?.findIndex(x => x.required) > -1;

  if (rules.findIndex(x => x.validator) === -1) rules.push({ validator: $validator });

  return (<Form.Item name={name}
                     label={translate(intl, label)}
                     value={value}
                     hasFeedback
                     validateStatus={validatorState?.status}
                     help={validatorState?.message}
                     rules={rules}>

    <InputWebsite ref={elRef}
                  disabled={disabled}/>
  </Form.Item>);

  function $validator () {

    if ($handleValidator() === 'error') {
      return Promise.reject();

    } else {
      return Promise.resolve();
    }
  }

  function $handleValidator () {

    let message = '', status = '';
    const v = form?.getFieldValue(name) !== 'undefined' && form?.getFieldValue(name);

    if (!v && !form?.isFieldsTouched()) {
      return setValidatorState({
        status,
        message
      });

    } else if (isRequired && !v) {
      message = t.isRequired;
      status = 'error';

    } else if (v && !isValidUrl(v)) {
      message = t.isInvalid;
      status = 'error';

    } else {
      status = v ? 'success' : '';
    }

    setValidatorState({
      status,
      message
    });
    return status;
  }

};
FormWebsite.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  elRef: PropTypes.shape(useRef.prototype),
  rules: PropTypes.array,
  disabled: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  value: PropTypes.object,
  form: PropTypes.object.isRequired,
  required: PropTypes.bool,
};
FormWebsite.defaultProps = {
  disabled: false,
};

/**
 * A component that displays a vehicle plate input element wrapped in an Ant Design Form.Item component.
 *
 * @prop {Object} props - The props object for the FormPlaca component
 * @prop {string} [props.name='plate'] - The name of the vehicle plate input element. Default value is 'plate'.
 * @prop {string} [props.label] - The label of the vehicle plate input element. If not provided, it will be generated from props and default to 'plate'.
 * @prop {object} [props.elRef] - The ref to the vehicle plate input element.
 * @prop {array} [props.rules=[{ required: false, message: 'This field is required' }]] - The validation rules for the vehicle plate input element. By default, only the required rule is set to false.
 * @prop {boolean} [props.disabled] - Whether the vehicle plate input element is disabled.
 * @prop {string|object} props.value - The current value of the vehicle plate input element.
 * @prop {object} props.form - The Ant Design Form instance used.
 * @prop {boolean} [props.required] - Whether the vehicle plate input element is required. If set to true, it overrides the required rule in the rules prop.
 * @prop {function} [props.onValidating] - A callback function to be called while the plate is being validated.
 * @prop {function} [props.onSearched] - A callback function to be called after the plate search is completed.
 *
 * @returns {JSX.Element} The rendered Form.Item component wrapping the InputPlate component with vehicle plate validation.
 */
export const FormPlaca = props => {

  const intl = useIntl();
  const t = {
    isRequired: translate(intl, 'required'),
    isInvalid: translate(intl, 'invalid_plate'),
  };

  const {
    name = 'plate',
    label = buildLabel(props, intl, 'plate'),
    elRef,
    rules = [{
      required: !!props?.required,
      message: t.isRequired
    }],
    disabled,
    form,

    onValidating,
    onSearched,
  } = props;

  const [value, setValue] = useState(props?.value);
  const [validating, setValidating] = useState(false);
  const [validatorState, setValidatorState] = useState(null);
  const isRequired = rules?.findIndex(x => x.required) > -1;

  if (rules.findIndex(x => x.validator) === -1) rules.push({ validator: $validator });

  const onSearchPlaca = async (ev, plate) => {
    setValue(plate);
    if (!isValidPlate(plate)) return;
    const result = await fetchPlate();
    onSearched && onSearched(result);
  };

  const fetchPlate = () => {

    setValidating(true);
    onValidating(true);

    return new Promise(r => {

      setTimeout(() => {
        setValidating(false);
        onValidating(false);
        elRef?.current?.focus({ cursor: 'all' });
        r();
      }, 1000);
    });
  };

  return (<Form.Item name={name}
                     label={translate(intl, label)}
                     value={value}
                     hasFeedback
                     validateStatus={validatorState?.status}
                     help={validatorState?.message}
                     rules={rules}>

    <InputPlate ref={elRef}
                onChange={onSearchPlaca}
                disabled={disabled || validating}/>
  </Form.Item>);

  function $validator () {

    if ($handleValidator() === 'error') {
      return Promise.reject();

    } else {
      return Promise.resolve();
    }
  }

  function $handleValidator () {

    let message = '', status = '';
    const v = form?.getFieldValue(name) !== 'undefined' && form?.getFieldValue(name);

    if (!v && !form?.isFieldsTouched()) {
      return setValidatorState({
        status,
        message
      });

    } else if (isRequired && !v) {
      message = t.isRequired;
      status = 'error';

    } else if (v && !isValidPlate(v)) {
      message = t.isInvalid;
      status = 'error';

    } else {
      status = v ? 'success' : '';
    }

    setValidatorState({
      status,
      message
    });
    return status;
  }

};
FormPlaca.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  elRef: PropTypes.shape(useRef.prototype),
  rules: PropTypes.array,
  disabled: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  form: PropTypes.object.isRequired,
  required: PropTypes.bool,

  onValidating: PropTypes.func,
  onSearched: PropTypes.func,
};

FormPlaca.defaultProps = {
  value: '',
};

/**
 * A component that renders a select input element for selecting a relationship type, wrapped in an Ant Design Form.Item component.
 *
 * @prop {object} props - The props passed to the component.
 * @prop {string} [props.name='relationship'] - The name of the input element. Default value is 'relationship'.
 * @prop {string} [props.label] - The label of the input element. If not provided, it will be generated from props and default to 'relationship'.
 * @prop {object} [props.elRef] - The ref to the input element.
 * @prop {array} [props.rules=[{ required: false }]] - The validation rules for the input element. By default, only the required rule is set to false.
 * @prop {boolean} [props.disabled] - Whether the input element is disabled.
 * @prop {boolean} [props.required] - Whether the input element is required.
 *
 * @returns {JSX.Element} The rendered FormSelect component wrapping the Ant Design Select component with options for selecting a relationship type.
 */
export const FormSelectRelationship = props => {

  const intl = useIntl();

  const t = {
    husband_wife: translate(intl, 'husband_wife'),
    father_mother: translate(intl, 'father_mother'),
    son_daughter: translate(intl, 'son_daughter'),
    brother_sister: translate(intl, 'brother_sister'),
    grandfather_mother: translate(intl, 'grandfather_mother'),
    grandson_daughter: translate(intl, 'grandson_daughter'),
    great_grandson_daughter: translate(intl, 'great_grandson_daughter'),
    great_grandfather_mother: translate(intl, 'great_grandfather_mother'),
    nephew_niece: translate(intl, 'nephew_niece'),
    uncle_aunt: translate(intl, 'uncle_aunt'),
    cousin: translate(intl, 'cousin'),
    brother_sister_in_law: translate(intl, 'brother_sister_in_law'),
    father_mother_in_law: translate(intl, 'father_mother_in_law'),
    son_daughter_in_law: translate(intl, 'son_daughter_in_law'),
    stepfather_mother: translate(intl, 'stepfather_mother'),
    stepson_daughter: translate(intl, 'stepson_daughter'),
    boyfriend_girlfriend: translate(intl, 'boyfriend_girlfriend'),
    fiance_fiancee: translate(intl, 'fiance_fiancee'),
    friend: translate(intl, 'friend'),
    spouse_husband: translate(intl, 'spouse_husband'),
    other: translate(intl, 'other'),
  };

  const options = [
    {
      value: 'husband_wife',
      text: t.husband_wife,
      label: t.husband_wife,
      disabled: false,
    }, {
      value: 'father_mother',
      text: t.father_mother,
      label: t.father_mother,
      disabled: false,
    }, {
      value: 'son_daughter',
      text: t.son_daughter,
      label: t.son_daughter,
      disabled: false,
    }, {
      value: 'brother_sister',
      text: t.brother_sister,
      label: t.brother_sister,
      disabled: false,
    }, {
      value: 'grandfather_mother',
      text: t.grandfather_mother,
      label: t.grandfather_mother,
      disabled: false,
    }, {
      value: 'grandson_daughter',
      text: t.grandson_daughter,
      label: t.grandson_daughter,
      disabled: false,
    }, {
      value: 'great_grandson_daughter',
      text: t.great_grandson_daughter,
      label: t.great_grandson_daughter,
      disabled: false,
    }, {
      value: 'great_grandfather_mother',
      text: t.great_grandfather_mother,
      label: t.great_grandfather_mother,
      disabled: false,
    }, {
      value: 'nephew_niece',
      text: t.nephew_niece,
      label: t.nephew_niece,
      disabled: false,
    }, {
      value: 'uncle_aunt',
      text: t.uncle_aunt,
      label: t.uncle_aunt,
      disabled: false,
    }, {
      value: 'cousin',
      text: t.cousin,
      label: t.cousin,
      disabled: false,
    }, {
      value: 'brother_sister_in_law',
      text: t.brother_sister_in_law,
      label: t.brother_sister_in_law,
      disabled: false,
    }, {
      value: 'father_mother_in_law',
      text: t.father_mother_in_law,
      label: t.father_mother_in_law,
      disabled: false,
    }, {
      value: 'son_daughter_in_law',
      text: t.son_daughter_in_law,
      label: t.son_daughter_in_law,
      disabled: false,
    }, {
      value: 'stepfather_mother',
      text: t.stepfather_mother,
      label: t.stepfather_mother,
      disabled: false,
    }, {
      value: 'stepson_daughter',
      text: t.stepson_daughter,
      label: t.stepson_daughter,
      disabled: false,
    }, {
      value: 'boyfriend_girlfriend',
      text: t.boyfriend_girlfriend,
      label: t.boyfriend_girlfriend,
      disabled: false,
    }, {
      value: 'fiance_fiancee',
      text: t.fiance_fiancee,
      label: t.fiance_fiancee,
      disabled: false,
    }, {
      value: 'friend',
      text: t.friend,
      label: t.friend,
      disabled: false,
    }, {
      value: 'spouse_husband',
      text: t.spouse_husband,
      label: t.spouse_husband,
      disabled: false,
    }, {
      value: 'other',
      text: t.other,
      label: t.other,
      disabled: false,
    }];

  const {
    name = 'relationship',
    label = buildLabel(props, intl, 'relationship'),
    elRef,
    rules = [{
      required: !!props?.required,
      message: t.isRequired
    }],
    disabled,
    required,
  } = props;

  return <FormSelect elRef={elRef}
                     name={name}
                     label={label}
                     rules={rules}
                     options={options}
                     required={required}
                     disabled={disabled}/>;

};
FormSelectRelationship.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  elRef: PropTypes.shape(useRef.prototype),
  rules: PropTypes.array,
  disabled: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  required: PropTypes.bool,
};

/**
 * A component that renders a select input element for selecting a relationship type, wrapped in an Ant Design Form.Item component.
 *
 * @prop {object} props - The props passed to the component.
 * @prop {string} [props.name='relationship'] - The name of the input element. Default value is 'relationship'.
 * @prop {string} [props.label] - The label of the input element. If not provided, it will be generated from props and default to 'relationship'.
 * @prop {object} [props.elRef] - The ref to the input element.
 * @prop {array} [props.rules=[{ required: false }]] - The validation rules for the input element. By default, only the required rule is set to false.
 * @prop {boolean} [props.disabled] - Whether the input element is disabled.
 * @prop {boolean} [props.required] - Whether the input element is required.
 *
 * @returns {JSX.Element} The rendered FormSelect component wrapping the Ant Design Select component with options for selecting a relationship type.
 */
export const FormNextPrevious = props => {

  const setLocale = (isLocaleOn, localeKey) => isLocaleOn ? <IntlMessage id={localeKey}/> : localeKey.toString();

  const {
    saveButton,
    dataContext,
    setStep,
    step,
    _dataRoots,
    onPreviousClick,
    onNextClick
  } = props;

  const onPrevious = () => {
    setStep(step - 1);
    onPreviousClick && onPreviousClick();
    // setDataContext({ ...dataContext, formsChanged: [] })
  };

  const onNext = () => {
    setStep(step + 1);
    onNextClick && onNextClick();
    // setDataContext({ ...dataContext, formsChanged: [] })
  };

  return (

    saveButton ? <FormSaveButton loading={dataContext?.partialLoading}
                                 formName={props?.formName}
                                 dataContext={dataContext}
                                 disabled={!(_dataRoots.currentStep > step ||
                                   dataContext?.formsChanged?.includes(props?.formName))}
                                 label="next"/>

      : <Row justify={{
        xs: 'space-between',
        md: 'end'
      }} align="middle" className="mt-3">

        <Button type="default"
                onClick={onPrevious}
                icon={<ArrowLeftOutlined/>}
                className="mr-3">
          <span className="ml-2">
            {setLocale(true, 'previous')}
          </span>
        </Button>

        <Button type={_dataRoots?.currentStep === step ? 'primary' : 'default'}
                icon={_dataRoots?.currentStep === step ? <SaveOutlined/> : <ArrowRightOutlined/>}
                onClick={onNext}>
          <span className="ml-2">
            {setLocale(true, 'next')}
          </span>
        </Button>

      </Row>);
};
FormNextPrevious.propTypes = {
  saveButton: PropTypes.bool,
  dataContext: PropTypes.object.isRequired,
  setStep: PropTypes.func.isRequired,
  step: PropTypes.number.isRequired,
  _dataRoots: PropTypes.object.isRequired,

  onNextClick: PropTypes.func,
};

/**
 * Renders an Ant Design FormSelect component that contains a list of apartment options.
 *
 * @prop {object} props - The props passed to the component.
 * @prop {string} [props.name="apartmentId"] - The name of the select element.
 * @prop {string} [props.label] - The label of the select element. If not provided, it will be generated from props.
 * @prop {object} [props.elRef] - The ref to the select element.
 * @prop {array} [props.rules] - The validation rules for the select element.
 * @prop {boolean} [props.disabled] - Whether the select element is disabled.
 * @prop {boolean} [props.required] - Whether the select element is required.
 * @prop {function} [props.onChange] - The function to be called when the select element value changes.
 * @prop {object} [props._parameters] - Additional parameters for building the apartment options list.
 *
 * @returns {JSX.Element} The rendered FormSelect component with apartment options.
 */
export const FormSelectApartment = props => {

  const intl = useIntl();
  const t = {
    isRequired: translate(intl, 'required'),
  };
  const options = buildOptions(props?._parameters);

  const {
    name = 'apartmentId',
    label = buildLabel(props, intl, 'apartment'),
    elRef,
    rules = [{
      required: !!props?.required,
      message: t.isRequired
    }],
    disabled,
    required, // value,
    onChange,
  } = props;

  return <FormSelect elRef={elRef}
                     name={name}
                     label={label}
                     rules={rules}
                     options={options}
    // value={value}
                     required={required}
                     onChange={onChange}
                     disabled={disabled}/>;

  function buildOptions (_parameters) {

    return _parameters?.apartments?.map(apartment => {

      return {
        value: apartment._id,
        text: `${apartment.__apartment} ${apartment.roomNumber} ${apartment.__blockLabel} ${apartment?.__block.abbreviation}${apartment?.roomNumber}${apartment?.__block.abbreviation}`,
        label: <>
          <span className="mr-2">{apartment.__apartment}</span>
          <Badge dot
                 style={{
                   marginRight: 8,
                   color: apartment?.__block?.foreColor,
                   backgroundColor: apartment?.__block?.backColor,
                   marginTop: 4,
                 }}/>
          <span>{apartment.__block?.name}</span>
        </>,
        disabled: false,
      };

    }) || [];
  }
};
FormSelectApartment.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  elRef: PropTypes.shape(useRef.prototype),
  rules: PropTypes.array,
  disabled: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  required: PropTypes.bool,
  _parameters: PropTypes.object.isRequired, // value: PropTypes.string
};

/**
 * Renders an Ant Design FormSelect component that contains a list of resident options.
 *
 * @prop {object} props - The props passed to the component.
 * @prop {string} [props.name="residentId"] - The name of the select element.
 * @prop {string} [props.label] - The label of the select element. If not provided, it will be generated from props.
 * @prop {object} [props.elRef] - The ref to the select element.
 * @prop {array} [props.rules] - The validation rules for the select element.
 * @prop {boolean} [props.disabled] - Whether the select element is disabled.
 * @prop {boolean} [props.required] - Whether the select element is required.
 * @prop {function} [props.onChange] - The function to be called when the select element value changes.
 * @prop {object} [props._parameters] - Additional parameters for building the resident options list.
 *
 * @returns {JSX.Element} The rendered FormSelect component with resident options.
 */
export const FormSelectResident = props => {

  const intl = useIntl();
  const t = {
    isRequired: translate(intl, 'required'),
  };

  const {
    name = 'residentId',
    label = buildLabel(props, intl, 'resident'),
    elRef,
    rules = [{
      required: !!props?.required,
      message: t.isRequired
    }],
    disabled,
    required,

    onChange,

    showAge = false,
    showApartment = false,
  } = props;

  const options = buildOptions(props?._parameters);

  return <FormSelect elRef={elRef}
                     name={name}
                     label={label}
                     rules={rules}
                     options={options}
    // value={value}
                     required={required}
                     onChange={onChange}
                     disabled={disabled}/>;

  function buildOptions (_parameters) {

    return _parameters?.residents?.map(r => {

      const age = r?.birthday && moment().diff(moment(r.birthday), 'years');

      let title = r.name;
      if (showAge) title += `, ${age}`;

      return {
        value: r._id,
        text: `${r.name} | ${r.__uniqueKey} | ${r.__apartment}`,
        label: <Row justify="space-between">

          <Col>
            <div className="mr-2">{title}</div>
          </Col>

          <Col>
            {showApartment ? <div>
              <span className="mr-2">{r.__apartment}</span>
              <Badge dot
                     style={{
                       marginRight: 8,
                       color: r?.__block?.foreColor,
                       backgroundColor: r?.__block?.backColor,
                       marginTop: 4,
                     }}/>

              <span>{r.__block?.name}</span>
            </div> : null}
          </Col>

        </Row>,
        disabled: false,
      };

    }) || [];
  }
};
FormSelectResident.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  elRef: PropTypes.shape(useRef.prototype),
  rules: PropTypes.array,
  disabled: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  required: PropTypes.bool,
  _parameters: PropTypes.object.isRequired,
  onChange: PropTypes.func,

  showAge: PropTypes.bool,
  showApartment: PropTypes.bool,
};

export const FormSimpleSwitch = props => {

  const intl = useIntl();
  const t = {
    isRequired: translate(intl, 'required'),
    yes: translate(intl, 'yes'),
    no: translate(intl, 'no'),
  };

  const {
    name,
    noLabel,
    label = buildLabel(props, intl),
    elRef,
    rules = [{
      required: !!props?.required,
      message: t.isRequired
    }],
    disabled,
    onChange,
    style,

    defaultChecked = false,
    showYesNoLabel = false,
    value = false,
  } = props;

  const checkedChildren = showYesNoLabel && t.yes;
  const unCheckedChildren = showYesNoLabel && t.no;

  const [isChecked, setIsChecked] = useState(defaultChecked);

  useEffect(() => {
    setIsChecked(value);
  }, [value]); // eslint-disable-line react-hooks/exhaustive-deps

  const onLocalChange = checked => {
    const value = onChange ? onChange(checked) : checked;
    setIsChecked(value);
  };

  return (<Form.Item name={name}
                     label={!noLabel && translate(intl, label)}
                     rules={rules}>

    <Switch
      style={style}
      defaultChecked={defaultChecked}
      checked={isChecked}
      checkedChildren={checkedChildren}
      unCheckedChildren={unCheckedChildren}
      onChange={onLocalChange}
      ref={elRef}
      disabled={disabled}/>

  </Form.Item>);
};
FormSimpleSwitch.propTypes = {
  name: PropTypes.oneOfType([PropTypes.string.isRequired, PropTypes.array.isRequired]),
  label: PropTypes.string,
  elRef: PropTypes.shape(useRef.prototype),
  rules: PropTypes.array,
  disabled: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),

  value: PropTypes.bool,
  defaultChecked: PropTypes.bool,
  showYesNoLabel: PropTypes.bool,
  onChange: PropTypes.func,
};

/**
 * Renders an Ant Design Form.Item component wrapping a Segmented component.
 *
 * @prop {object} props - The props passed to the component.
 * @prop {string} props.name - The name of the input element.
 * @prop {string} [props.label] - The label of the input element. If not provided, it will be generated from props.
 * @prop {object} [props.elRef] - The ref to the input element.
 * @prop {array} [props.rules] - The validation rules for the input element.
 * @prop {boolean} [props.disabled] - Whether the input element is disabled.
 * @prop {function} [props.onChange] - The function to be called when the input element value changes.
 * @prop {array} [props.options] - The options for the Segmented component.
 *
 * @returns {JSX.Element} The rendered Form.Item component wrapping the Segmented component.
 */
export const FormSegment = props => {

  const intl = useIntl();
  const t = {
    isRequired: translate(intl, 'required'),
  };

  const {
    name,
    label = buildLabel(props, intl),
    elRef,
    rules = [{
      required: !!props?.required,
      message: t.isRequired
    }],
    disabled,
    onChange,
    options = [],
  } = props;

  return (<Form.Item name={name}
                     label={translate(intl, label)}
                     rules={rules}>

    <Segmented ref={elRef}
               options={options}
               block={true}
               disabled={disabled}
               onChange={onChange}/>

  </Form.Item>);
};
FormSegment.propTypes = {
  name: PropTypes.oneOfType([PropTypes.string.isRequired, PropTypes.array.isRequired]),
  label: PropTypes.string,
  elRef: PropTypes.shape(useRef.prototype),
  rules: PropTypes.array,
  disabled: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  options: PropTypes.array.isRequired,
  onChange: PropTypes.func,
};

/**
 * Renders an Ant Design Form.Item component wrapping a TextArea component.
 *
 * @prop {object} props - The props passed to the component.
 * @prop {string} props.name - The name of the input element.
 * @prop {string} [props.label] - The label of the input element. If not provided, it will be generated from props.
 * @prop {object} [props.elRef] - The ref to the input element.
 * @prop {array} [props.rules] - The validation rules for the input element.
 * @prop {boolean} [props.disabled] - Whether the input element is disabled.
 * @prop {number} [props.maxLength] - The maximum length of the input element.
 * @prop {number} [props.rows=4] - The number of rows to display in the TextArea element.
 * @prop {function} [props.onChange] - The function to be called when the input element value changes.
 * @prop {boolean} [props.required] - Whether the input element is required.
 *
 * @returns {JSX.Element} The rendered Form.Item component wrapping the TextArea component.
 */
export const FormTextArea = props => {

  const { TextArea } = Input;
  const intl = useIntl();
  const t = {
    isRequired: translate(intl, 'required'),
  };

  const {
    name,
    label = buildLabel(props, intl),
    elRef,
    rules = [{
      required: !!props?.required,
      message: t.isRequired
    }],
    disabled,
    maxLength,
    rows = 4,

    onChange,
  } = props;

  if (rules.findIndex(x => x.required) === -1) {
    rules.push({
      required: !!props?.required,
      message: t.isRequired
    });
  }

  return (

    <Form.Item name={name}
               label={translate(intl, label)}
               rules={rules}>

      <TextArea ref={elRef}
                onChange={onChange}
                maxLength={maxLength}
                disabled={disabled}
                rows={rows}/>
    </Form.Item>);
};
FormTextArea.propTypes = {
  label: PropTypes.string,
  name: PropTypes.oneOfType([PropTypes.string.isRequired, PropTypes.array.isRequired]),
  rules: PropTypes.array,
  elRef: PropTypes.shape(useRef.prototype),
  disabled: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  maxLength: PropTypes.number,
  rows: PropTypes.number,
  required: PropTypes.bool,

  onChange: PropTypes.func,
};

/**
 * @component
 *
 * @description
 * This is a React component in JavaScript used to render a form input field for Brazilian zip codes (CEP).
 * The component contains a function to validate the entered CEP and an API call to retrieve data related to the entered CEP.
 *
 * @prop {string} [name=cep] - The name of the field used in the form.
 * @prop {string} [label=CEP] - The label to be displayed for the field.
 * @prop {object} elRef - A reference to the input field.
 * @prop {Array} [rules=[{ required: !!props?.required, message: t.isRequired }]] - An array of validation rules.
 * @prop {string|bool} [disabled=false] - Set to true or a string value to disable the input field.
 * @prop {string} [value] - The current value of the input field.
 * @prop {object} form - An object representing the parent form component. Required.
 * @prop {bool} [required] - Set to true if the input field is required.
 * @prop {function} afterSearch - A callback function to be called after an API call is made.
 *
 * @example
 * import { FormZip } from './FormZip';
 *
 * function MyForm() {
 *   return (
 *     <Form>
 *       <FormZip form={form} />
 *     </Form>
 *   );
 * }
 */
export const FormZip = props => {

  const intl = useIntl();
  const t = {
    isRequired: translate(intl, 'required'),
    isInvalid: translate(intl, 'invalid_zip'),
    isNotFound: translate(intl, 'zip_not_found'),
  };

  const {
    name = 'zip',
    label = buildLabel(props, intl, 'zip'),
    elRef,
    rules = [{
      required: !!props?.required,
      message: t.isRequired
    }],
    disabled,
    value,
    form,

    numberFieldName = 'number',
    afterSearch,
  } = props;

  const [validatorState, setValidatorState] = useState(null);
  const [lastZip, setLastZip] = useState({});
  const isRequired = rules?.findIndex(x => x.required) > -1;

  if (rules.findIndex(x => x.validator) === -1) rules.push({ validator: $validator });

  return (<Form.Item name={name}
                     label={translate(intl, label)}
                     value={value}
                     hasFeedback
                     validateStatus={validatorState?.status}
                     help={validatorState?.message}
                     rules={rules}>

    <InputCep ref={elRef}
              minLength={9}
              maxLength={9}
              onChange={consumeZipCode}
              disabled={disabled || validatorState?.status === 'validating'}/>

  </Form.Item>);

  async function consumeZipCode (event, result) {

    let message = '', status = '';

    if (typeof event === 'string' && event?.length !== 9) {
      afterSearch && afterSearch();

    } else if (typeof event === 'string' && event?.length === 9) { // consumir api

      if (event === lastZip?.zip) {
        status = lastZip?.status;
        message = lastZip?.message;
        setValidatorState({
          status,
          message
        });
        $done(lastZip?.doc);

      } else {
        status = 'validating';
        message = '';
        setValidatorState({
          status,
          message
        });
        setLastZip({
          ...lastZip,
          zip: event
        });
      }

    } else if (typeof event !== 'string') { // retorno do consumo da api

      let doc;
      if (result?.erro) {
        await $done();
        status = 'error';
        message = t.isNotFound;

      } else {
        doc = result;
        await $done(doc);
        status = 'success';
        message = '';
      }

      setLastZip({
        ...lastZip,
        status,
        message,
        doc: doc
      });
      setValidatorState({
        status,
        message
      });
    }

    async function $done (doc) {

      setTimeout(() => elRef?.current?.focus({ cursor: doc ? 'end' : 'all' }), 50);
      if (!doc) return afterSearch && afterSearch();

      // decorate address
      const zip = returnOnlyNumbers(doc?.cep);
      const address = `${form?.getFieldValue(numberFieldName)} ${doc?.logradouro}, ${doc?.localidade}, ${doc?.uf}`;
      const geoPoint = await getGpsByAddress(address);

      const result = {
        title: translate(intl, 'main_address'),
        zip: zip,                       // '13034673'
        address1: doc?.logradouro,      // 'Avenida Antônio Carvalho de Miranda'
        address2: '',                   // additional/complemento
        number: '',                     // house number
        neighborhood: doc?.bairro,      // 'Vila São Bento'
        city: doc?.localidade,          // 'Campinas'
        __city: `${doc?.localidade} - ${doc?.uf}`, // 'Campinas - SP'
        province: doc?.uf,              // 'SP'
        addressReference: '',           // reference point
        addressGps: geoPoint,

        addressAdditionalInfo: {
          note: doc?.complemento,     // from api   -> 'de 460/461 ao fim'
          areaCode: doc?.ddd,         // '19'
          giaCode: doc?.gia,          // '2446'     -> Guia de Informação e Apuração do ICMS - GIA
          ibgeCode: doc?.ibge,        // '3509502'  -> Instituto Brasileiro de Geografia e Estatística
          siafiCode: doc?.siafi,      // '6291',    -> Sistema Integrado de Administração Financeira
        },

        addressMetadata: {
          countryCode: 'br',
          foundByApi: true,
          apiUrl: `https://viacep.com.br/ws/${zip}/json/`,
          zipWithMultipleAddress: !!!doc?.logradouro,
        },
      };

      afterSearch && afterSearch(result);
    }
  }

  function $validator () {

    if ($handleValidator() === 'error') {
      return Promise.reject();

    } else {
      return Promise.resolve();
    }
  }

  function $handleValidator () {

    let message = '', status = '';
    const v = form?.getFieldValue(name) !== 'undefined' && form?.getFieldValue(name);

    if (!v && !form?.isFieldsTouched()) {
      return setValidatorState({
        status,
        message
      });

    } else if (isRequired && !v) {
      message = t.isRequired;
      status = 'error';

    } else if (v && !isValidZip(v)) {
      message = t.isInvalid;
      status = 'error';

    } else {
      status = v ? 'success' : '';
    }

    setValidatorState({
      status,
      message
    });
    return status;
  }

};
FormZip.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  elRef: PropTypes.shape(useRef.prototype),
  rules: PropTypes.array,
  disabled: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  value: PropTypes.string,
  form: PropTypes.object.isRequired,
  required: PropTypes.bool,

  numberFieldName: PropTypes.string,
  afterSearch: PropTypes.func,
};

export const FormAsyncAutoCompleteEntity = props => {

  const [isSearching, setIsSearching] = useState(false);
  const [isSearchingFound, setIsSearchingFound] = useState(false);
  const [lastSearch, setLastSearch] = useState(props?.value?.name || null);

  const intl = useIntl();
  const t = {
    isRequired: translate(intl, 'required'),
  };

  const {
    name = 'selectedEntity',
    value,
    label = buildLabel(props, intl),
    elRef,
    rules = [{
      required: !!props?.required,
      message: t.isRequired
    }],
    disabled,
    maxLength,
    autoFocus = false,

    placeholder,
    onChange,
    onSelect,
    style,

    folder = 'documents',
    dataType,
    dataProfile,
    acceptNew

  } = props;

  useEffect(() => {
    if (!value) return;
    setIsSearchingFound(true);
    setLastSearch(value.name);
  }, [value]);

  if (!!props.required) {
    rules.push({
      validator: () => {
        if (!isSearchingFound && !acceptNew) {
          return Promise.reject(t.isRequired);
        }
        return Promise.resolve();
      }
    });
  }

  const onSelectEntity = value => {

    const entity = {
      _id: value?.key,
      name: value?.label
    };

    onSelect && onSelect(entity);
  };

  return (
    <Form.Item name={name}
               style={style}
               label={translate(intl, label)}
               rules={rules}>

      <AsyncAutoComplete ref={elRef}
        // labelInValue={false}
                         value={value}
                         onChange={onChange}
                         maxLength={maxLength}
                         disabled={disabled}
                         autoFocus={autoFocus}
                         placeholder={placeholder}
                         searchFn={handleEntitySearch}
                         onSelect={onSelect && onSelectEntity}
                         style={{ width: '100%' }}
      />

    </Form.Item>);

  async function handleEntitySearch (searchValue) {

    // abort the searching according the following condition
    if (isSearching) return;
    if (searchValue.length < 3) {
      setLastSearch(null);
      setIsSearchingFound(false);
      return null;
    }

    if (!isSearchingFound && searchValue.includes(lastSearch)) return null;

    setIsSearching(true);

    // const data = await getEntitiesByField('name', searchValue, 'documents', 'company', 'operator');
    const data = await getEntitiesByField(folder, dataType, dataProfile, 'name', searchValue);

    setLastSearch(searchValue);
    setIsSearching(false);
    setIsSearchingFound(!!data);

    // const data = await fetchUsers(searchValue);
    return data?.map(i => ({
      label: i.name,
      // title: i.name,
      value: i.name,
      key: i?._id,
      item: i
    }));
  };
};
FormAsyncAutoCompleteEntity.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  rules: PropTypes.array,
  elRef: PropTypes.shape(useRef.prototype),
  disabled: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  maxLength: PropTypes.number,
  required: PropTypes.bool,
  style: PropTypes.object,
  formatter: PropTypes.func,
  min: PropTypes.number,
  max: PropTypes.number,
  onChange: PropTypes.func,
  onSelect: PropTypes.func,
  folder: PropTypes.string,
  dataType: PropTypes.string.isRequired,
  dataProfile: PropTypes.string,
};

export const FormHiddenInput = props => {
  const { name } = props;
  return (<FormInput name={name} label="none" elRef={undefined} style={{
    display: 'none',
    height: 0
  }}/>);
};

export const forceFormChangesWithHiddenInput = (field, value, args) => {

  const {
    form,
    _data,
    dataContext,
    setDataContext,
    formName
  } = args;

  form.setFieldValue(field, value);

  const isChanged = formDataChanges(form, _data);

  let formsChanged = dataContext?.formsChanged || [];

  if (!isChanged) {
    formsChanged = formsChanged.filter(f => f !== formName);
    setDataContext({
      ...dataContext,
      formsChanged: formsChanged,
    });

  } else {

    if (!dataContext?.formsChanged?.includes(formName)) {
      formsChanged.push(formName);
      setDataContext({
        ...dataContext,
        formsChanged: formsChanged,
      });
    }
  }
};

// ================================================

export const FormDataType = props => {

  // const itemValueDefault = (props?.itemName && props?._data && props?._data[props?.itemName]) ||
  //   props?._data?.dataType

  const {
    itemRef,
    itemRules,
    itemLabel = 'entity_type',
    itemName = 'dataType',
    itemValue,
    itemDisabled = props?.formContext?.mode === 'view',
  } = props;

  const intl = useIntl();

  const t = {
    legal: translate(intl, 'legal_person'),
    individual: translate(intl, 'individual_person'),
  };

  const options = [
    {
      value: 'person',
      text: t.individual,
      label: t.individual,
      disabled: false,
    }, {
      value: 'company',
      text: t.legal,
      label: t.legal,
      disabled: false,
    }];

  return (<Form.Item
    label={translate(intl, itemLabel)}
    rules={itemRules}
    name={itemName}>
    <Select ref={itemRef}
            options={options}
            value={itemValue}
            showSearch
            disabled={itemDisabled}
            filterOption={filterOption}/>
  </Form.Item>);
};

// SIMPLE COMPONENTS
// =============================================================================

// CUSTOM COMPONENTS
// =============================================================================
/*
export const FormAsyncEntityNameOLD = props => {

  const intl = useIntl();

  const {

    itemRef,
    itemRules,
    label = 'name',
    name = 'entity',

    form,

    _router,
    _module,
    formContext,
    dataContext,
  } = props;

  const handleOpenDocument = async record => {
    const path = `${_module?.url} /${record._id}/profile`;
    _router.push(path);
  };

  return (<Form.Item
    label={translate(intl, label)}
    name={name}
    valuePropName="value"
    rules={itemRules}>

    <AsyncEntityAutocomplete
      {...props}
      ref={itemRef}
      form={form}
      // onChange={v => saveContext(dataBase, v, props)}
      selectOkFn={handleOpenDocument}
      disabled={formContext?.mode === 'view' || dataContext?.loading}
    />

  </Form.Item>);
};
*/

export const FormAsyncEntityName = props => {

  const intl = useIntl();
  const t = {
    isRequired: translate(intl, 'required'),
  };

  const {
    name,
    label = buildLabel(props, intl),
    elRef,
    rules = [{
      required: !!props?.required,
      message: t.isRequired
    }],
    disabled,
    autoFocus = false,
    form,
    required,
  } = props;

  if (rules.findIndex(x => x.required) === -1) {
    rules.push({
      required: !!props?.required,
      message: t.isRequired
    });
  }

  return (<Form.Item name={name}
                     label={translate(intl, label)}
                     valuePropName="value"
                     rules={rules}>

    <AsyncEntityAutocomplete ref={elRef}
                             {...props}
                             required={required}
                             name={name}
                             form={form}
      // onChange={v => saveContext(dataBase, v, props)}
      //                        selectOkFn={handleOpenDocument}
                             disabled={disabled}
                             autoFocus={autoFocus}/>

  </Form.Item>);
};

// FUNCTIONS FOR COMPONENTS
// =============================================================================

/* export const buildItemValidator = (validatorKey, isRequired, profile) => {

  const setLocale = (isLocaleOn, localeKey) => isLocaleOn ? <IntlMessage id={localeKey}/> : localeKey.toString();

  const t = {
    isRequired: setLocale(true, 'required'),
    isInvalid: setLocale(true, 'invalid_cpf'),
  };

  switch (validatorKey) {

    case 'plate': {
      return (rule, plate) => {
        if (!plate && !isRequired) return Promise.resolve();
        if (!plate && isRequired) return Promise.reject();
        if (plate && !isValidPlate(plate)) return Promise.reject(setLocale(true, 'invalid_plate'));
        return Promise.resolve();
      };
    }

    case 'cpf': {
      return (_, value) => {
        if ((!value || value === 'undefined') && !isRequired) return Promise.resolve();
        if (!value && isRequired) return Promise.reject(t.isRequired);
        if (value && !isValidCpf(value)) return Promise.reject(t.isInvalid);
        return Promise.resolve();
      };
    }

    case 'cnpj': {
      return (rule, cnpj) => {
        if (!cnpj && !isRequired) return Promise.resolve();
        if (cnpj && !isValidCnpj(cnpj)) return Promise.reject(setLocale(true, 'invalid_cnpj'));
        return Promise.resolve();
      };
    }

    case 'email': {
      return (rule, value) => {
        if ((!value || value === 'undefined') && !isRequired) return Promise.resolve();
        if (!value && isRequired) return Promise.reject(t.isRequired);
        if (value && !isValidEmail(value)) return Promise.reject(setLocale(true, 'invalid_email'));
        return Promise.resolve();
      };
    }

    case 'phone': {
      return (rule, value) => {
        if ((!value || value === 'undefined') && !isRequired) return Promise.resolve();
        if (!value && isRequired) return Promise.reject(t.isRequired);
        if (value && !isValidPhoneNumber(value, profile)) return Promise.reject(setLocale(true, 'invalid_phone_number'));
        return Promise.resolve();
      };
    }

    default: {
      console.error(`Not found the element name '${validatorKey}'! FormComponent.jsx:buildItemValidator`);
    }
  }
}; */

const buildLabel = (props, intl, label) => {

  if (!props?.name) return label || 'n_a';

  return typeof props.name === 'string' ? props.name : Array.isArray(props?.name) && props.name.length > 0
    ? props.name[props.name.length - 1]
    : label;
};

export const formDataChanges = (form, initialValues) => {

  const currentValues = form.getFieldsValue();
  const keys = Object.keys(currentValues);
  let isChanged = false;

  for (const key of keys) {

    let a = initialValues?.[key];
    let b = currentValues?.[key];

    // treat the moment object
    if (moment.isMoment(a)) a = moment(a).toISOString();
    if (moment.isMoment(b)) b = moment(b).toISOString();

    // treat the number
    if (!a) a = undefined;
    if (!b) b = undefined;

    if (a || b) {
      if (!isNaN(a)) a = Number(a);
      if (!isNaN(b)) b = Number(b);
    }

    if (Array.isArray(a)) a.sort((x, y) => x.localeCompare(y));
    if (Array.isArray(b)) b.sort((x, y) => x.localeCompare(y));

    if (typeof a === 'object') a = JSON.stringify(a);
    if (typeof b === 'object') b = JSON.stringify(b);

    if (a !== b) isChanged = true;

  }

  return isChanged;
};

export const getGpsByAddress = async address => {

  // address format: number address, city, province
  // ex: 720 Antonio Carvalho de Miranda, Campinas, SP
  let uri = `/tools/get-gps-by-address/${address}`;
  return await getByUri(uri);
};

export const getAddressGPS = async (body, originalData) => {

  // update geoPoint
  const a = body?.address1 || originalData?.address1;
  const c = body?.city || originalData?.city;
  const p = body?.province || originalData?.province;

  if (body?.number !== originalData?.number && a && c && p) {
    const address = `${body.number} ${a}, ${c}, ${p}`;
    return await getGpsByAddress(address);
  }
};

export const onFormValuesChange = options => {

  const {
    name,
    form,
    initialValues,
    setFormContext,
    formContext,
    setDataContext,
    dataContext,
  } = options;

  const isChanged = formDataChanges(form, initialValues);

  // save form context changes
  if (formContext) {

    const sectionName = formContext?.selectedSection || 'n/a';
    const sectionsChanged = isChanged
      ? [...new Set([...formContext?.sectionsChanged, sectionName])]
      : formContext?.sectionsChanged.filter(f => f !== sectionName) || [];

    setFormContext({
      ...formContext,
      sectionsChanged: sectionsChanged,
    });

  }

  // save data context changes
  if (dataContext) {

    const formsChanged = isChanged
      ? [...new Set([...dataContext?.formsChanged, name])]
      : dataContext?.formsChanged.filter(f => f !== name) || [];

    setDataContext({
      ...dataContext,
      formsChanged: formsChanged,
      sectionsInstances: {
        ...dataContext?.sectionsInstances,
        // [name]: currentFormData,
      },
    });
  }

};

