import React from 'react';
import {Tag} from 'antd';

// COMPONENT *******************************************************************
// *****************************************************************************

const TagValue = props => {

  let {record, key, backColor, foreColor, value} = props;

  if (!key) key = record?._id;
  if (!value) value = record?.value;
  if (!foreColor) foreColor = record?.foreColor;
  if (!backColor) backColor = record?.backColor;

  return (
      <Tag key={key} color={backColor}>
        <span className="text-uppercase" style={{color: foreColor}}>
          {value}
        </span>
      </Tag>
  );
};

export default TagValue;
