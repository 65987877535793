import React from 'react';
import IntlMessage from 'components/util-components/IntlMessage';
import ModuleSettingsValues
  from 'components/jarvisly-layouts/ModuleComponent/ModuleSettings/ModuleSettingsValues';

// GLOBAL VARIABLES ************************************************************
// *****************************************************************************

const setLocale = (isLocaleOn, localeKey) => isLocaleOn ? <IntlMessage id={localeKey}/> : localeKey.toString();

// EXPORT TABS *****************************************************************
// *****************************************************************************

export const atualizaSettingsTabs = props => {

  const {_module} = props;

  if (!['providers'].includes(_module.name)) return;

  return [
    {
      key: 'purchaseChannel',
      label: (<span>{setLocale(true, 'purchases_channels')}</span>),
      children: (<div style={{padding: '4px 24px'}}>

        <ModuleSettingsValues {...props}
                              parValueType="purchasesChannels"
                              parTitle="purchase_channel"
                              withColor
                              parPlaceholder="new_channel"
                              valuesTitle="channels"
        />
      </div>),
    },
  ];
};


