import moment from 'moment-timezone'
import { Modal, notification, message, Input, Button, Row } from 'antd'
import { SearchOutlined } from '@ant-design/icons'
import React from 'react'
import { GetColorName } from 'hex-color-to-color-name'
import { THEME_CONFIG } from 'configs/AppConfig'
import store from 'redux/store'
import {
  isValidZip,
  isValidCnpj,
  isValidCpf,
  isValidEmail,
  isValidUrl,
  translate,
} from './react-jarvisly-helper'
import { CONDOMINIUM_SEED, ORGANIZATION_SEED } from 'configs/AppConfig'
import phone from 'phone'
import { v4 as uuidv4 } from 'uuid'
import { isObject, normalize, returnOnlyNumbers } from 'jarvisly-helper'

let timeoutDebounce

export const generateToken = () => uuidv4()

export const TAG_COLORS_TABLE = [
  '#C41D7F',
  '#CF1322',
  '#D4380D',
  '#D46B08',
  '#D48806',
  '#D4B106',
  '#7CB305',
  '#389E0D',
  '#08979C',
  '#096DD9',
  '#1D39C4',
  '#531DAB']

const TAGS_COLORS = [
  'cyan',
  'red',
  'orange',
  'green',
  'volcano',
  'purple',
  'magenta',
  'lime',
  'gold',
  'blue',
  'geekblue',
]

export const TWITTER_PICKER_DEFAULT_COLORS = [
  '#FF6900',
  '#FCB900',
  '#7BDCB5',
  '#00D084',
  '#8ED1FC',
  '#0693E3',
  '#ABB8C3',
  '#EB144C',
  '#F78DA7',
  '#9900EF']

export const generateHexColor = (toHexOppositeColor = null, blackWhite = false) => {

  // font...
  // https://stackoverflow.com/questions/35969656/how-can-i-generate-the-opposite-color-according-to-current-color
  // https://github.com/onury/invert-color

  if (toHexOppositeColor) return $invertColor(toHexOppositeColor, blackWhite)

  return '#' + (Math.random() * 0xFFFFFF << 0).toString(16).padStart(6, '0')

  // invert color for toHexOppositeColor
  function $invertColor (hex, blackWhite = false) {

    if (hex.indexOf('#') === 0) hex = hex.slice(1)

    // convert 3-digit hex to 6-digits.
    if (hex.length === 3) {
      hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] +
        hex[2]
    }

    if (hex.length !== 6) {

      console.error(`Invalid hex color: ${hex}!`)
      return hex

    }

    let r = parseInt(hex.slice(0, 2), 16)
    let g = parseInt(hex.slice(2, 4), 16)
    let b = parseInt(hex.slice(4, 6), 16)

    if (blackWhite) {

      return (r * 0.299 + g * 0.587 + b * 0.114) > 186 ? '#000000' : '#ffffff'

    }

    // invert color components
    r = (255 - r).toString(16)
    g = (255 - g).toString(16)
    b = (255 - b).toString(16)
    // pad each with zeros and return
    return '#' + $$padZero(r) + $$padZero(g) + $$padZero(b)

    function $$padZero (str, len = 2) {

      const zeros = new Array(len).join('0')
      return (zeros + str).slice(-len)

    }

  }

}

export const isMongoObjectId = str => {
  let regex = /^[0-9a-fA-F]{24}$/
  return regex.test(str)
}

export const numberMask = (value, mask) => {

  let result = ''

  const acceptSpace = false
  const rule = acceptSpace ? '[0-9] ' : '[0-9]'
  const regex = new RegExp(rule, 'ig')

  if (value) value = returnOnlyNumbers(value)

  let inc = 0
  Array.from(value).forEach((letter, index) => {

    if (!mask[index + inc]) return result

    if (!mask[index + inc].match(regex)) {

      setMask(index)

    }

    result += letter

  })

  return result

  function setMask (index) {

    result += mask[index + inc]
    inc++

    if (mask[index + inc] && !mask[index + inc].match(regex)) setMask(index)

  }

}

export const getDeviceWidth = (width) => {
  if (width < 576) return 'xs'
  if (width >= 576) return 'sm'
  if (width >= 768) return 'md'
  if (width >= 992) return 'lg'
  if (width >= 1200) return 'xl'
  if (width >= 1600) return 'xxl'
}

export const isMobile = (props) => {
  // import { withBreakpoints } from 'react-breakpoints';
  // need add withBreakpoints to export default component. Ex: 'export default withBreakpoints(<Component>)';

  const {
    breakpoints,
    currentBreakpoint,
  } = props

  return breakpoints[currentBreakpoint] < breakpoints.tablet
}

export const strNotationToObj = (str, value) => {

  let arr = str.split('.')

  let objString = `{"${str.replaceAll('.', '":{"')}":"${value}"${'}'.repeat(
    arr.length - 1)}}`

  return JSON.parse(objString)
}

export const objToStrNotation = obj => {

  if (!obj || !isObject(obj)) return obj

  const result = {}

  sweep(obj)

  return result

  function sweep (obj, stringKey) {

    Object.keys(obj).map(k => {

      if (moment.isMoment(obj[k])) {
        obj[k] = moment(obj[k]).startOf('day').toISOString()
      }

      if (obj[k] && typeof obj[k] === 'object' && obj[k] !== null &&
        !Array.isArray(obj[k])) {

        sweep(obj[k], stringKey ? `${stringKey}.${k}` : k)

      } else {

        if (stringKey) {

          if (Array.isArray(obj[k])) {

            result[`${stringKey}.${k}`] = obj[k]

            // for (let a of obj[k]) {
            //   sweep(obj[k], stringKey ? `${stringKey}.${k}` : k)
            // }

          } else {

            result[`${stringKey}.${k}`] = obj[k]
          }

        } else {
          result[k] = obj[k]
        }
      }

      return k

    })
  }
}

export const msg = (type, text, key, duration) => {

  // destroy message by key (or text key name)
  if (['destroy', 'd'].includes(type)) return message.destroy(text || key)

  if (!type) return console.error('type missing in msg() helper function!')

  // eslint-disable-next-line default-case
  switch (type.toLowerCase()) {

    case 's':
      type = 'success'
      break

    case 'e':
    case 'f':
      type = 'error'
      break

    case 'w':
      type = 'warning'
      break

    case 'i':
      type = 'info'
      break

    case 'l':
      type = 'loading'
      break
  }

  if (!['success', 'error', 'warning', 'info', 'loading'].includes(type)) {
    console.error(`unknowing type '${type}' in msg() helper function!`)
    return
  }

  message[type]({
    duration: duration || 2,
    key: key,
    content: text,
    className: 'message-info',
  }).then(r => r)
}

export const toast = (type, title, message, duration = 4.5, key) => {

  if (!type) return console.error('type missing in toast() helper function!')

  // eslint-disable-next-line default-case
  switch (type.toLowerCase()) {

    case 's':
      type = 'success'
      break

    case 'e':
    case 'f':
      type = 'error'
      break

    case 'w':
      type = 'warning'
      break

    case 'i':
      type = 'info'
      break
  }

  if (!['success', 'error', 'warning', 'info'].includes(type)) {
    console.error(`unknowing type '${type}' in toast() helper function!`)
    return
  }

  notification[type]({
    message: title,
    description: message,
    duration: duration,
    key: key,
  })
}

export const modal = options => {

  if (!options) {
    return console.error(`'options' missing in modal() helper function!`)
  } else if (!options.type) {
    return console.error(`'options.type' missing in modal() helper function!`)
  } else if (!options.title) {
    return console.error(`'options.title' missing in modal() helper function!`)
  } else if (!options.message) {
    return console.error(
      `'options.message' missing in modal() helper function!`)
  }

  let type = options.type

  // eslint-disable-next-line default-case
  switch (type.toLowerCase()) {

    case 's':
      type = 'success'
      break

    case 'e':
    case 'f':
      type = 'error'
      break

    case 'w':
      type = 'warning'
      break

    case 'i':
      type = 'info'
      break

    case 'c':
      type = 'confirm'
      break
  }

  if (!['success', 'error', 'warning', 'info', 'confirm'].includes(type)) {
    console.error(`unknowing type '${type}' in modal() helper function!`)
    return
  }

  Modal[type]({
    title: options.title,
    content: options.message,
    cancelText: options.cancelText || 'Cancel',
    okText: options.okText || 'OK',
    onOk: options.onOk || null,
    onCancel: options.onCancel || null,
    wrapClassName: 'modal-without-title',
  })

}

export const parseQueryString = location => {
  return location?.search ? Object.fromEntries(
    new URLSearchParams(location.search)) : null
}

export const stringifyQueryString = qs => {
  return new URLSearchParams(qs).toString()
}

export const isValidDate = date => {
  return date instanceof Date && !isNaN(date)
}

export const getWeekdaysArr = (intl, acronym) => {

  const weekdays = [
    'sunday',
    'monday',
    'tuesday',
    'wednesday',
    'thursday',
    'friday',
    'saturday',
  ]

  return weekdays.map((x, idx) => {
    return {
      key: idx,
      value: x,
      color: TAGS_COLORS[idx],
      label: !acronym
        ? translate(intl, x)
        : translate(intl, x).substring(0, 3),
    }
  })
}

export const getDaysPeriods = (intl, acronym) => {

  const periods = [
    'punctual',
    'yearly',
    'semester',
    'four_month',
    'quarterly',
    'bimonthly',
    'monthly',
    'fortnightly',
    'weekly',
  ]

  return periods.map((x, idx) => {
    return {
      key: idx,
      value: x,
      color: TAGS_COLORS[idx],
      label: !acronym
        ? translate(intl, x)
        : translate(intl, x).substring(0, 3),
    }
  })
}

export const getDateName = (date, type = 'weekday', acronym) => {

  if (typeof date === 'undefined') return

  // @ts-ignore
  if (!isValidDate(date) && isNaN(date)) {

    return `invalid 'date' or 'weekday'  => '${date}'`

  }

  const weekdays = [
    'sunday',
    'monday',
    'tuesday',
    'wednesday',
    'thursday',
    'friday',
    'saturday',
    'sunday', // 0 or 7
  ]

  const months = [
    'january',
    'february',
    'march',
    'april',
    'may',
    'june',
    'july',
    'august',
    'september',
    'october',
    'november',
    'december']

  date = new Date(date)

  // @ts-ignore
  const weekday = isValidDate(date) ? weekdays[date.getDay()] : weekdays[date]

  // @ts-ignore
  const month = isValidDate(date) ? months[date.getMonth()] : months[date]

  if (type === 'weekday') {

    return acronym ? weekday.substring(0, 3) : weekday

  } else {

    return acronym ? month.substring(0, 3) : month

  }

}

export const getDateList = (dateFrom, months = 3) => {

  const dateList = []

  for (let i = 0; i < months; i++) {

    dateFrom.add(i === 0 ? 0 : 1, 'M', true)

    const year = new Date(dateFrom).getFullYear()
    const month = new Date(dateFrom).getMonth()
    const monthName = getDateName(dateFrom, 'month')

    const idx = dateList.findIndex(x => x.year === year)

    if (idx === -1) {

      dateList.push({
        year: year,
        months: [
          {
            value: month,
            text: monthName,
          }],
      })

    } else {

      dateList[idx].months.push({
        value: month,
        text: monthName,
      })

    }

  }
  return dateList
}

export const hasFormError = form => {
  if (!form || (form && !form.getFieldsError)) return
  const fields = form.getFieldsError()
  return !!fields.find(x => x.errors.length > 0)
}

export const onFormFinishFailed = (errorInfo, elRefs, setUpdating) => {

  const nameField = errorInfo?.errorFields[0].name
  const fieldRef = nameField[nameField.length - 1]

  msg('e', 'Dados incompletos!')

  if (fieldRef) elRefs[fieldRef]?.current?.focus({ cursor: 'all' })
  setUpdating && setUpdating(false)
}

export const sort = (a, b, key) => {

  if (!isNaN(a[key])) a[key] = Number(a[key])
  if (!isNaN(b[key])) b[key] = Number(b[key])

  if (typeof a[key] === 'number' && typeof b[key] === 'number') {

    return a[key] - b[key]

  }

  if (typeof a[key] === 'string' && typeof b[key] === 'string') {

    a = a[key].toLowerCase()
    b = b[key].toLowerCase()

    return a > b ? 1 : b > a ? -1 : 0

  }

}

export const getMyDomain = () => window.location.href

export const onProduction = () => {
  return !getMyDomain().includes('localhost')
}

export const getFocus = (elRefs, errorInfo) => {
  const fieldRef = errorInfo?.errorFields[0].name.join('.')
  if (fieldRef) elRefs[fieldRef]?.current.focus()
}

export const isObjEquals = (objA, objB) => {

  if (!objA || !objB) return

  let isEquals = true

  const obj1 = clone(objA)
  const obj2 = clone(objB)

  // reference: https://stackoverflow.com/questions/286141/remove-blank-attributes-from-an-object-in-javascript

  Object.keys(obj1).forEach((k) => !obj1[k] && delete obj1[k])
  Object.keys(obj2).forEach((k) => !obj2[k] && delete obj2[k])

  Object.keys(obj1).map(k => {

    if ((obj1[k] || obj2[k]) && $pure(obj1[k]) !== $pure(obj2[k])) {
      isEquals = false
    }
    return k
  })

  Object.keys(obj2).map(k => {

    if ((obj1[k] || obj2[k]) && $pure(obj1[k]) !== $pure(obj2[k])) {
      isEquals = false
    }
    return k
  })

  return isEquals

  function $pure (value) {

    if (!value) return

    if (typeof value === 'object') {
      return JSON.stringify(value)

    } else if (typeof value !== 'string') {
      return value.toString()

    } else {
      return value
    }
  }
}

export const buildDataLikeForm = (form, objData) => {

  if (!form) return objData

  const objMock = form.getFieldsValue()
  if (!objMock || !isObject(objMock)) return objMock

  const mock = {}

  Object.keys(objMock).forEach((k) => {
    mock[k] = objMock[k] ? clone(objMock[k]) : objMock[k]
  })

  if (objData && isObject(objData)) {

    Object.keys(mock).map(k => {
      if (objData.hasOwnProperty(k)) {
        mock[k] = objData[k] ? clone(objData[k]) : objData[k]
      }
      return k
    })
  }

  return clone(mock)
}

export const getColorName = hex => GetColorName(hex)

export const getTagColorName = hex => {

  // magenta red volcano orange gold lime green cyan blue geekblue purple yellow

  if (!hex) return 'white'

  hex = hex.toUpperCase()

  switch (hex) {
    case '#C41D7F':
      return 'magenta'
    case '#CF1322':
      return 'red'
    case '#D4380D':
      return 'volcano'
    case '#D46B08':
      return 'orange'
    case '#D48806':
      return 'gold'
    case '#D4B106':
      return 'yellow'
    case '#7CB305':
      return 'lime'
    case '#389E0D':
      return 'green'
    case '#08979C':
      return 'cyan'
    case '#096DD9':
      return 'blue'
    case '#1D39C4':
      return 'geekblue'
    case '#531DAB':
      return 'purple'
    default:
      return 'white'
  }

}

// MUDAR PARA DIFERENÇA DE DIAS (VIROU O DIA) e nao quantidade de horas!
export const daysDiff = (from, to, by24Hours) => {

  if (!from) {
    from = moment()
  } else {
    if (moment(from).isValid()) from = moment(from).toISOString()
  }

  if (!to) {
    to = moment()
  } else {
    if (moment(to).isValid()) to = moment(to).toISOString()
  }

  if (by24Hours) { // by 24 hours
    return moment(to).diff(moment(from), 'days', true)

  } else { // by day number
    return moment(to).startOf('day').diff(moment(from).startOf('day'), 'days', true)
  }
}

// reference: https://stackoverflow.com/questions/28889826/how-to-set-focus-on-an-input-field-after-rendering
// export const useFocus = () => {
//     const htmlElRef = useRef(null);
//     const setFocus = () => {
//         htmlElRef.current && htmlElRef.current.focus();
//     };
//
//     return [htmlElRef, setFocus];
// };

// reference: https://github.com/ant-design/ant-design/issues/22918
export const filterOption = (input, option, field = 'text') => {

  if (!input || !option) return false

  let find = normalize(input.toString(), 'lower')
  let text = normalize(input.toString(), 'lower')

  if (option[field]) { // find without OptGroup
    text = normalize(option[field].toString(), 'lower')

  } else if (option.label) {

    text = normalize(option.label.toString(), 'lower')

  } else if (option.children) {
    text = normalize(option.children.toString(), 'lower')

  }

  return text.indexOf(find) >= 0

}

export const getDataSeed = property => {

  // subscription collection
  switch (property) {

    case 'subscriptions.settings': {

      /*
            const organizationSettingsSeed = {

              'occurrences': {
                'levels': [
                  {
                    'value': 'Nível 1',
                    'backColor': '#FFFFFF',
                    'foreColor': '#000000',
                  }, {
                    'value': 'Nível 2',
                    'backColor': '#FFFFFF',
                    'foreColor': '#000000',
                  }, {
                    'value': 'Nível 3',
                    'backColor': '#FFFFFF',
                    'foreColor': '#000000',
                  }], 'priorities': [
                  {
                    'title': 'Urgente',
                    'deadlineValue': 1,
                    'deadlineUnit': 'days',
                    'backColor': '#cf1322',
                    'foreColor': '#ffffff',
                    'deadlineDays': 1,
                    'deadlineHours': 24,
                  }, {
                    'title': 'Alta',
                    'deadlineValue': 5,
                    'deadlineUnit': 'days',
                    'backColor': '#d46b08',
                    'foreColor': '#ffffff',
                    'deadlineDays': 5,
                    'deadlineHours': 120,
                  }, {
                    'title': 'Moderada',
                    'deadlineValue': 15,
                    'deadlineUnit': 'days',
                    'backColor': '#7cb305',
                    'foreColor': '#ffffff',
                    'deadlineDays': 15,
                    'deadlineHours': 360,
                  }, {
                    'title': 'Baixa',
                    'deadlineValue': 30,
                    'deadlineUnit': 'days',
                    'backColor': '#096dd9',
                    'foreColor': '#ffffff',
                    'deadlineDays': 30,
                    'deadlineHours': 720,
                  }], 'categories': [], 'tags': [
                  {
                    'value': 'Melhoria',
                    'backColor': '#096dd9',
                    'foreColor': '#ffffff',
                  }, {
                    'value': 'Bug', 'backColor': '#cf1322', 'foreColor': '#ffffff',
                  }], 'analysts': [],
              },
            };
            const condominiumSettingsSeed = {
              'occurrences': {
                'levels': [
                  {
                    'value': '1 - Administrativo',
                    'backColor': '#FFFFFF',
                    'foreColor': '#000000',
                  }, {
                    'value': '2 - Síndico',
                    'backColor': '#FFFFFF',
                    'foreColor': '#000000',
                  }, {
                    'value': '3 - Conselho',
                    'backColor': '#FFFFFF',
                    'foreColor': '#000000',
                  }], 'priorities': [
                  {
                    'title': 'Urgente',
                    'deadlineValue': 1,
                    'deadlineUnit': 'days',
                    'backColor': '#cf1322',
                    'foreColor': '#ffffff',
                    'deadlineDays': 1,
                    'deadlineHours': 24,
                  }, {
                    'title': 'Alta',
                    'deadlineValue': 5,
                    'deadlineUnit': 'days',
                    'backColor': '#d46b08',
                    'foreColor': '#ffffff',
                    'deadlineDays': 5,
                    'deadlineHours': 120,
                  }, {
                    'title': 'Moderada',
                    'deadlineValue': 15,
                    'deadlineUnit': 'days',
                    'backColor': '#7cb305',
                    'foreColor': '#ffffff',
                    'deadlineDays': 15,
                    'deadlineHours': 360,
                  }, {
                    'title': 'Baixa',
                    'deadlineValue': 30,
                    'deadlineUnit': 'days',
                    'backColor': '#096dd9',
                    'foreColor': '#ffffff',
                    'deadlineDays': 30,
                    'deadlineHours': 720,
                  }], 'categories': [], 'tags': [], 'analysts': [],
              },
            };
      */

      if (THEME_CONFIG.APP.PROFILE.NAME === 'condominium') {

        // return condominiumSettingsSeed;
        return CONDOMINIUM_SEED.SUBSCRIPTIONS_SETTINGS

      } else {

        // return organizationSettingsSeed;
        return ORGANIZATION_SEED.SUBSCRIPTIONS_SETTINGS

      }

    }

    default:
      return {}
  }

}

export const getDocumentStatusColor = status => {

  switch (status) {

    case 'not_updated':
      return 'red'

    case 'in_analise':
    case 'pending_update':
      return 'orange'

    case 'with_reservation':
      return 'cyan'

    case 'revised':
      return 'blue'

    case 'updated':
      return 'green'

    case 'archive':
    case 'removed':
      return 'gray'

    default:
      return 'red'

  }
}

export const getDocumentStatusDescription = status => {

  switch (status) {

    case 'pending_update':
      return 'waiting_for_update'

    case 'not_updated':
      return 'update_expired'

    case 'updated':
      return 'document_updated'

    case 'in_analise':
      return 'unmoderated_document'

    case 'with_reservation':
      return 'document_with_reservation'

    case 'revised':
      return 'moderated_document'

    default:
      return 'n_a'

  }
}

export const buildQueryStringSearch = options => {

  const pageSize = localStorage.getItem('gridPageSize') || '10'

  const {
    router,
    rdxModule,
    rdxModuleQuery,
    sideNavFilter,
    gridSearch,
    searchReset,
  } = options

  const apiHeaders = { 'x-resultasobject': false }
  const defaultPage = {
    current: '1',
    pageSize: pageSize,
  } // table list
  const defaultSort = {
    sortField: 'name',
    sortOrder: 'ascend',
  }
  const defaultFilter = rdxModule?.initialFilter || {}
  const defaultSearch = rdxModule?.initialSearch || {}

  const defaultQuery = {
    ...defaultPage, ...defaultSort, ...defaultFilter, ...defaultSearch,
  }

  const q = hasObjectProperties(router?.query)
    ? router?.query
    : rdxModuleQuery?.currentQuery

  const routerQuery = !searchReset ? (q || {}) : null

  // ------------------------------------------------------------------------ //
  // check filter from url query string
  // ------------------------------------------------------------------------ //
  const f = sideNavFilter || rdxModuleQuery?.currentFilter || routerQuery

  const filter = {}
  Object.entries(defaultFilter).map(keyValueArr => {
    const [k] = keyValueArr
    const value = f &&
      rdxModule?.methods?.validateFilterFn([k, f[k]], defaultFilter)
    if (value) filter[k] = value
    return keyValueArr
  })
  // ------------------------------------------------------------------------ //

  // ------------------------------------------------------------------------ //
  // check search from url query string
  // ------------------------------------------------------------------------ //
  const s = hasObjectProperties(gridSearch?.search) ||
    hasObjectProperties(rdxModuleQuery?.currentSearch) ||
    hasObjectProperties(routerQuery) || defaultSearch

  const search = {}
  Object.entries(defaultSearch).map(keyValueArr => {
    const [k] = keyValueArr
    const value = rdxModule?.methods?.validateSearchFn([k, s[k]], searchReset)
    if (typeof value !== 'undefined') search[k] = value
    return keyValueArr
  })

  // ------------------------------------------------------------------------ //

  const page = gridSearch?.page ? gridSearch?.page : {}
  const sort = gridSearch?.sort ? gridSearch?.sort : {}
  const _id = router.match?.params?._id
  const query = _id ? clone({}) : {
    ...defaultQuery, ...routerQuery, ...page, ...sort, ...search, ...filter,
  }

  let qsStr

  if (hasObjectProperties(query)) {

    // update API_URL query string
    qsStr = Object.keys(query).length > 0 && new URLSearchParams(query)
    qsStr = `?${qsStr}`
    router.replace({ search: qsStr })

    // window.history.replaceState(undefined, undefined,
    //     `${router.pathname}?${qsStr}`);

    switch (query.folder) {
      case 'recycle': {
        apiHeaders['x-forceremoved'] = '2'
        break
      }
      case 'archive': {
        apiHeaders['x-forcefiled'] = '2'
        break
      }
      default: // 'documents
    }

  } else {
    qsStr = `/${_id}`
    apiHeaders['x-resultasobject'] = true
  }

  return {
    currentQuery: query,
    defaultQuery,
    str: qsStr,
    apiHeaders,
    defaultFilter,
    currentFilter: filter,
    defaultSearch,
    currentSearch: search,
  }

}

export const removePropertiesWithBlankValues = (obj, includeNull) => {

  if (!obj || !isObject(obj)) return obj

  Object.keys(obj).map(k => {
    if (typeof obj[k] === 'undefined') delete obj[k]
    if (includeNull && (obj[k] === null || obj[k] === 'null')) delete obj[k]
    return k
  })

  return obj
}

export const objectPattern = (obj, mock) => {

  if (!obj || !isObject(obj) || !mock || !isObject(mock)) return obj

  Object.entries(obj).map(keyValueArr => {
    const [k] = keyValueArr
    if (!mock[k]) delete obj[k]
    return keyValueArr
  })

  return obj
}

export const hasObjectProperties = obj => {
  if (!obj) return
  if (Object.keys(obj).length > 0) return obj
}

export const substitution = (str, from, to) => {

  if (!str || !from || !to) {
    console.error('abort substitution()')
    return str
  }

  if (!Array.isArray(from)) from = new Array(from)
  if (!Array.isArray(to)) to = new Array(to.toString())

  if (from.length !== to.length) {
    console.error('abort substitution()')
    return str
  }

  for (let i = 0; i < from.length; i++) {
    str = str.replaceAll(from[i], to[i])
  }

  return str
}

export const findInObjectRecursively = (objArr, property, value, children) => {

  for (let item of objArr) {
    if (item[property] === value) return item

    if (item[children]) {
      let result = findInObjectRecursively(item[children], property, value,
        children)
      if (result) return result
    }
  }
  return false

}

// criado no list helpers utilizado por vários modules aqui no helpers
export const buildTableColumnSearch_APAGUE = (dataIndex, searchInputRef) => {

  const onConfirm = (dataIndex, selectedKeys, confirm, clearFilters) => {
    if (!selectedKeys) clearFilters()
    confirm()
  }

  return {

    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {

      return (<div className="m-3">
        <Input
          ref={searchInputRef}
          placeholder={'Localizar'}
          value={selectedKeys}
          onChange={e => {
            const value = e?.target?.value?.toLocaleString()
            setSelectedKeys(value)
          }}
          onPressEnter={() => onConfirm(dataIndex, selectedKeys, confirm,
            clearFilters)}
          allowClear
          style={{
            width: 188,
            marginBottom: 8,
            display: 'block',
          }}
        />

        <Row justify="end">

          <Button
            className="mt-2"
            type="primary"
            onClick={() => onConfirm(dataIndex, selectedKeys, confirm,
              clearFilters)}
            // onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            // icon={<SearchOutlined />}
            size="small"
            // style={{
            //   width: 90,
            // }}
          >
            OK
          </Button>

        </Row>

      </div>)
    },

    filterIcon: filtered => (
      <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }}/>),

    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => searchInputRef.current.focus({
          cursor: 'all',
        }))
      }
    },
  }
}

export const getUrlParams = () => {
  const params = new Proxy(new URLSearchParams(window.location.search), {
    get: (searchParams, prop) => searchParams.get(prop),
  })

  return params?.folder
}

export const getRedux = (reducer, action) => {
  const state = store.getState()
  return state[reducer][action]
}

export const generateTimestamp = max => {
  const ts = +new Date()
  return ts.toString().slice(-max)
}

export const updateFieldsFeedbackStates = (fieldsArr, data, setState) => {

  if (!fieldsArr || !Array.isArray(fieldsArr) || !data || !setState) return

  const fieldStatus = {}

  // cpf validation
  if (fieldsArr.includes('cpf')) {
    fieldStatus.cpfStatus = data?.cpf ? (isValidCpf(data?.cpf)
      ? 'success'
      : 'error') : ''
  }

  // cnpj validation
  if (fieldsArr.includes('cnpj')) {
    fieldStatus.cnpjStatus = data?.cnpj ? (isValidCnpj(data?.cnpj)
      ? 'success'
      : 'error') : ''
  }

  // phone validation
  if (fieldsArr.includes('phone')) {
    fieldStatus.phoneStatus = (data?.phone || data?.mainContact?.__phone)
      ? (isValidPhoneNumber(data?.phone || data?.mainContact?.__phone)
        ? 'success'
        : 'error')
      : ''
  }

  // email validation
  if (fieldsArr.includes('email')) {
    fieldStatus.emailStatus = (data?.email || data?.mainContact?.email)
      ? (isValidEmail(data?.email || data?.mainContact?.email)
        ? 'success'
        : 'error')
      : ''
  }

  // website validation
  if (fieldsArr.includes('website')) {
    fieldStatus.websiteStatus = (data?.website || data?.mainContact?.website)
      ? (isValidUrl(data?.website || data?.mainContact?.website)
        ? 'success'
        : 'error')
      : ''
  }

  // zip validation
  if (fieldsArr.includes('cep')) {

    const found = (data?.mainAddress && data.mainAddress?.found) || data?.zip

    fieldStatus.zipStatus = (data?.zip || data?.mainAddress?.__zip)
      ? (isValidZip(data?.zip || data?.mainAddress?.__zip) && found
        ? 'success'
        : 'error')
      : ''
  }

  setState(s => ({ ...s, ...fieldStatus }))
}

/**
 * Add two string time values (HH:mm:ss) with javascript
 *
 * Usage:
 *  > addTimes('04:20:10', '21:15:10');
 *  > "25:35:20"
 *  > addTimes('04:35:10', '21:35:10');
 *  > "26:10:20"
 *  > addTimes('30:59', '17:10');
 *  > "48:09:00"
 *  > addTimes('19:30:00', '00:30:00');
 *  > "20:00:00"
 *
 * @param {String} startTime  String time format
 * @param {String} endTime  String time format
 * @returns {String}
 */
export const addTimes = (startTime, endTime) => {
  let times = [0, 0, 0]
  let max = times.length

  let a = (startTime || '').split(':')
  let b = (endTime || '').split(':')

  // normalize time values
  for (let i = 0; i < max; i++) {
    a[i] = isNaN(parseInt(a[i])) ? 0 : parseInt(a[i])
    b[i] = isNaN(parseInt(b[i])) ? 0 : parseInt(b[i])
  }

  // store time values
  for (let i = 0; i < max; i++) {
    times[i] = a[i] + b[i]
  }

  let hours = times[0]
  let minutes = times[1]
  let seconds = times[2]

  if (seconds >= 60) {
    let m = (seconds / 60) << 0
    minutes += m
    seconds -= 60 * m
  }

  if (minutes >= 60) {
    let h = (minutes / 60) << 0
    hours += h
    minutes -= 60 * h
  }

  return (
    Number('0' + hours) +
    ':' +
    ('0' + minutes).slice(-2) +
    ':' +
    ('0' + seconds).slice(-2)
  )
}

export const isDecimals = value => (value % 1 !== 0)

export const getInitials = (str, level) => {

  if (!str) return

  switch (level) {
    case 1:
      // John Doe Smith => JS
      return str.match(/(\b\S)?/g).join('').match(/(^\S|\S$)?/g).join('').toUpperCase()

    case 2:
      // John => JO and "John Doe Smith" => "JS"
      return str.match(/(^\S\S?|\b\S)?/g).join('').match(/(^\S|\S$)?/g).join('').toUpperCase()

    case 3:
      // name.match(/(^\S\S?|\s\S)?/g).map(v=>v.trim()).join("").match(/(^\S|\S$)?/g).join("").toLocaleUpperCase()
      return str.match(/(^\S\S?|\s\S)?/g).map(v => v.trim()).join('').match(/(^\S|\S$)?/g).join('').toLocaleUpperCase()

    default:
      // "John Doe Smith" => "JDS"
      return str.match(/(\b\S)?/g).join('').toUpperCase()
  }
}

export const jarvislyDebounce = (fn, delay = 200) => {
  if (timeoutDebounce) clearTimeout(timeoutDebounce)
  timeoutDebounce = setTimeout(() => fn(), delay)
}

export const clone = obj => {
  if (!obj) return
  if (typeof obj !== 'object') return obj
  return JSON.parse(JSON.stringify(obj))
}

export const parseDateToDB = date => {
  if (!date || !moment.isMoment(date)) return
  return moment(date).toISOString()
}

export const parseDateToDisplay = date => {
  if (!date) return
  if (moment.isMoment(date)) return date
  return moment(date)
}

export const parsePhoneToDB = str => {
  if (!str) return
  return buildPhone(str).numberFull
}

export const parseCpfToDB = str => {
  if (!str) return
  return str && returnOnlyNumbers(str)
}

export const parseCpfToDisplay = str => {
  if (!str) return
  return numberMask(returnOnlyNumbers(str), '999.999.999-99')
}

export const parseCnpjToDB = str => {
  if (!str) return
  return returnOnlyNumbers(str)
}

export const parseCnpjToDisplay = str => {
  if (!str) return
  return numberMask(returnOnlyNumbers(str), '99.999.999/9999-99')
}

export const parseZipToDB = str => {
  if (!str) return
  return returnOnlyNumbers(str)
}

export const parseZipToDisplay = str => {
  if (!str) return
  return numberMask(returnOnlyNumbers(str), '99999-999')
}

export const parseCityToDisplay = (city, province) => {
  if (!city && !province) return
  if (!province) return city
  if (!city) return province
  return `${city} - ${province}`
}

export const parsePhoneToDisplay = str => {
  if (!str) return
  return buildPhone(str).phone
}

export const buildPhone = (fullNumber) => {

  if (!fullNumber) return null
  const isFreeCall = (fullNumber?.substring(0, 4) === '0800' || fullNumber?.substring(0, 4) === '0300')

  if (isFreeCall) {
    fullNumber = returnOnlyNumbers(fullNumber)

  } else {
    if (fullNumber.slice(0, 1) !== '+') fullNumber = `+55${returnOnlyNumbers(fullNumber)}`
  }

  const phone = decomposePhoneNumber(fullNumber, isFreeCall)

  return {
    isValid: phone?.isValid || null,
    numberFull: phone?.fullPhoneNumber || null,
    dialCode: phone?.countryCode || null,
    areaCode: phone?.areaCode || null,
    coreNumber: phone?.coreNumber || null,
    core: phone?.core || null,
    number: phone?.phoneNumber || null,
    phone: phone?.phone || null,
    countryCode: phone?.countryIso2?.toLowerCase() || null,
    phoneMask: phone?.phoneMask || null,
  }
}

export const decomposePhoneNumber = (value, isFreeCall) => {

  if (isFreeCall) {
    return {
      phoneNumber: value,
      phoneMask: '9999-999-9999',
      phone: numberMask(value, '9999-999-9999'),
      core: numberMask(value, '9999-999-9999'),
      coreNumber: value,
      areaCode: '',
      fullPhoneNumber: value,
      countryCode: '+55',
      countryIso2: 'BR',
      countryIso3: 'BRA',
    }
  }

  // workaround for wired phone BR
  const wiredPhone = (value?.length === 13)
    ? value.slice(0, 5) + '0' + value.slice(5, 13)
    : undefined

  const data = phone(wiredPhone || value, {
    country: 'BR',
    validateMobilePrefix: false,
  })

  if (data.isValid) {

    if (wiredPhone) data.phoneNumber = value

    data.phoneNumber = data.phoneNumber.substring(data.countryCode.length, data.phoneNumber.length)

    data.phoneMask = returnPhoneMask(data.phoneNumber, data.countryIso2)

    data.phone = numberMask(data.phoneNumber, data.phoneMask)

    let i = data.phone.indexOf('(')
    const f = data.phone.indexOf(')')

    data.areaCode = data.phone.substring(++i, f)
    data.core = data.phone.substring((f + 2), data.phone.length)
    data.coreNumber = returnOnlyNumbers(data.core)
    data.fullPhoneNumber = `${data.countryCode}${data.phoneNumber}`

  } else {

    data.phoneNumber = null
    data.phoneMask = null
    data.phone = null
    data.core = null
    data.coreNumber = null
    data.areaCode = null
    data.fullPhoneNumber = null

  }

  return data

}

export const returnPhoneMask = (str, countryCode = 'br') => {

  if (!str) return

  countryCode = countryCode.toLowerCase()

  if (countryCode === 'br') {

    if (str.length === 11) {

      if (str.substring(0, 1) === '0') { // 0800-PJMONEY

        return '9999 99 9999'

      } else {

        return '(99) 9.9999-9999'

      }

    } else if (str.length === 10) {

      return '(99) 9999-9999'

    } else {

      return str

    }

  } else {

    return str

  }

}

export const toPascalCase = str => {
  return str.replace(/\w+/g, (word) => {
    return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
  }).replace(/\s+/g, '')
}

export const toCapitalCase = (str) => {
  return str.toLowerCase().replace(/(?:^|\s)\w/g, (match) => match.toUpperCase())
}

export const buildCarBrandOptions = intl => {

  const carBrands = [
    'Audi',
    'BMW',
    'Chery',
    'Chevrolet',
    'Chrysler',
    'Citroen',
    'Dodge',
    'Fiat',
    'Ford',
    'GMC',
    'Honda',
    'Hyundai',
    'Infiniti',
    'Jac Motors',
    'Jaguar',
    'Jeep',
    'Kia',
    'Land Rover',
    'Lexus',
    'Lifan',
    'Mercedes Benz',
    'Mini',
    'Mitsubishi',
    'Nissan',
    'Peugeot',
    'Porsche',
    'Ram',
    'Renault',
    'Rolls Royce',
    'Subaru',
    'Suzuki',
    'Troller',
    'Toyota',
    'Volkswagen',
    'Volvo',
  ]

  const cars = carBrands.map((car) => {
    return {
      value: car.toLowerCase().replace(' ', '_'),
      text: car,
      label: car,
      disabled: false,
    }
  })

  cars.push({
    value: 'not_listed',
    text: translate(intl, 'not_listed'),
    label: translate(intl, 'not_listed').toUpperCase(),
    disabled: false,
  })

  return cars
}

export const buildMotorcycleBrandOptions = intl => {

  const motorcycleBrands = [
    'Honda',
    'Yamaha',
    'Suzuki',
    'Dafra',
    'BMW',
    'Harley Davidson',
    'Kawasaki',
  ]

  const motorcycles = motorcycleBrands.map((motorcycle) => {
    return {
      value: motorcycle.toLowerCase().replace(' ', '_'),
      text: motorcycle,
      label: motorcycle,
      disabled: false,
    }
  })

  motorcycles.push({
    value: 'not_listed',
    text: translate(intl, 'not_listed'),
    label: translate(intl, 'not_listed').toUpperCase(),
    disabled: false,
  })

  return motorcycles
}

export const generateRandomNumber = (digits = 1, forceString) => {

  const exp = 1
  const base = Math.pow(10, exp)
  const result = digits * base

  const randomNumber = Math.floor(Math.random() * result).toString().padStart(digits, '0')
  return forceString ? randomNumber : Number(randomNumber)
}

// -------------------------------------------------------------------------- //
// isValidPhoneNumber
// check if the phone number is valid
// -------------------------------------------------------------------------- //
export const isValidPhoneNumber = (pn, profile) => {

  /*
    const BR_TYPES = {
      FREE: '9999-999-9999',      // 99999999999  -> 11
      WIRED: '(99) 9999-9999',    // 9999999999   -> 10
      MOBILE: '(99) 9.9999-9999', // 99999999999  -> 11
    };
  */

  if (!pn) return

  pn = returnOnlyNumbers(pn)

  if (!pn) return false

  if (profile) {

    if (profile.toUpperCase() === 'FREE') {
      return pn.length === 11 &&
        (pn.substring(0, 4) === '0800' || pn.substring(0, 4) === '0300')

    } else if (profile.toUpperCase() === 'MOBILE') {
      return pn.length === 11 && pn.substring(2, 3) === '9'

    } else if (profile.toUpperCase() === 'WIRED') {
      return pn.length === 10

    } else {
      return false
    }

  } else {

    if (returnOnlyNumbers(pn).length === 11) {

      return pn.substring(0, 4) === '0800' || pn.substring(0, 4) === '0300' || pn.substring(2, 3) === '9'

    } else if (returnOnlyNumbers(pn).length === 10) {

      return pn.substring(0, 1) !== '0' && pn.substring(0, 4) !== '0800' && pn.substring(0, 4) !== '0300' &&
        pn.substring(2, 3) !== '9'

    } else {
      return false
    }
  }
}

export const isOdd = (number = 0) => (number % 2 === 0)

export const isArrEquals = (arr1, arr2) => {

  const sortedArr1 = arr1.slice().sort()
  const sortedArr2 = arr2.slice().sort()

  const stringArr1 = JSON.stringify(sortedArr1)
  const stringArr2 = JSON.stringify(sortedArr2)

  return stringArr1 === stringArr2

}

export const formatThousandsSeparator = number => {
  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
}
