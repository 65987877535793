import React, {useState} from 'react';

export const ModalContext = React.createContext({});

export const ModalProvider = ({children}) => {

  const [state, setState] = useState({});

  return (
      <ModalContext.Provider value={{formContext: state, setFormContext: setState}}>
        {children}
      </ModalContext.Provider>
  );
};
