import { isValidDate } from 'utils/helpers';

export const getTypeTagColor = typeId => {

  typeId = Number(typeId);

  switch (typeId) {

    case 1:  // Initial
      return 'blue';

    case 2:    // Revalidation
      return 'green';

    case 3:    // Resource
      return 'purple';

    case 4:    // Post Acident
      return 'magenta';

    default:
      return 'gray';

  }
};

export const getTypeTagName = typeId => {

  typeId = Number(typeId);

  switch (typeId) {

    case 1:  // Initial
      // return 'initial';
      return 'Inicial';

    case 2:    // Revalidation
      // return 'revalidation';
      return 'Revalidação';

    case 3:    // Resource
      // return 'resource';
      return 'Recurso';

    case 4:    // Post Acident
      // return 'post_acident';
      return 'Pós Acidente';

    default:
      return 'n_a';

  }
};

export const getWeekDay = (date, type = 'weekday', acronym) => {

  if (typeof date === 'undefined') return;
  if (!isValidDate(date) &&
    isNaN(date)) {
    return `invalid 'date' or 'weekday'  => '${date}'`;
  }

  const weekdays = [
    'sunday',
    'monday',
    'tuesday',
    'wednesday',
    'thursday',
    'friday',
    'saturday',
  ];

  const months = [
    'january',
    'february',
    'march',
    'april',
    'may',
    'june',
    'july',
    'august',
    'september',
    'october',
    'november',
    'december',
  ];

  const weekday = isValidDate(date) ? weekdays[date.getDay()] : weekdays[date];
  const month = isValidDate(date) ? months[date.getMonth()] : months[date];

  if (type === 'weekday') {
    return acronym ? weekday.substring(0, 3) : weekday;

  } else {
    return acronym ? month.substring(0, 3) : month;
  }

};

// agendas
export const dataSeed = [
  {
    _id: '622f851124f9539383b5b9c5',
    weekday: 1,
    // weekdaysIdx: [1, 3, 5], // seg, qua e sex
    _metadata: {
      subscriptionId: '6224c2f73b70e006a06a9741',
      legalId: '5a1db82168f60e00143156d5',
      origin: 0,
      hidden: false,
      audit: {
        createdByAccountId: '5a15560e789b400014fffc11',
        createdAt: '2022-03-14T18:10:25.314Z',
      },
      i18n: {
        countryCode: 'br',
        timezoneOffset: -180,
        languageCode: 'pt-br',
        localeCode: 'br',
      },
      application: 'appdedalo',
      documentCode: 'b5b9c5',
      counter: 16,
      __documentoCode: 'b5b9c5.16',
    },
    types: [1, 2], // inicial e revalidação
    seats: 20,
    hour: '7:15', // 24h
    timezoneOffset: -180,
    note: 'Não esquecer de informar seu código ANAC',
  },
  {
    _id: '622f851124f9539383b5b9c6',
    weekday: 2,
    // weekdaysIdx: [2, 4], // ter e qui
    _metadata: {
      subscriptionId: '6224c2f73b70e006a06a9741',
      legalId: '5a1db82168f60e00143156d5',
      origin: 0,
      hidden: false,
      audit: {
        createdByAccountId: '5a15560e789b400014fffc11',
        createdAt: '2022-03-14T18:10:25.314Z',
      },
      i18n: {
        countryCode: 'br',
        timezoneOffset: -180,
        languageCode: 'pt-br',
        localeCode: 'br',
      },
      application: 'appdedalo',
      documentCode: 'b5b9c5',
      counter: 16,
      __documentoCode: 'b5b9c5.16',
    },
    types: [1, 2, 3, 4], // inicial, revalidação, recurso e pós-acidente
    seats: 10,
    hour: '10:00', // 24h
    timezoneOffset: -180,
    note: 'Bla bla bla',
  },
  {
    _id: '622f851124f9539383b5b9c7',
    weekday: 2,
    // weekdaysIdx: [2], // seg, qua e sex
    _metadata: {
      subscriptionId: '6224c2f73b70e006a06a9741',
      legalId: '5a1db82168f60e00143156d5',
      origin: 0,
      hidden: false,
      audit: {
        createdByAccountId: '5a15560e789b400014fffc11',
        createdAt: '2022-03-14T18:10:25.314Z',
      },
      i18n: {
        countryCode: 'br',
        timezoneOffset: -180,
        languageCode: 'pt-br',
        localeCode: 'br',
      },
      application: 'appdedalo',
      documentCode: 'b5b9c5',
      counter: 16,
      __documentoCode: 'b5b9c5.16',
    },
    types: [1], // inicial e revalidação
    seats: 15,
    hour: '14:00', // 24h
    timezoneOffset: -180,
    note: 'Programa Meu Primeiro CMA apenas para CMS',
  }];

export const agendasSeedAggregate = [
  {
    weekday: 1,
    __weekdayName: 'Segunda-Feira',
    agendas: [
      {
        _id: '622f851124f9539383b5b9c5',
        _metadata: {
          subscriptionId: '6224c2f73b70e006a06a9741',
          legalId: '5a1db82168f60e00143156d5',
          origin: 0,
          hidden: false,
          audit: {
            createdByAccountId: '5a15560e789b400014fffc11',
            createdAt: '2022-03-14T18:10:25.314Z',
          },
          i18n: {
            countryCode: 'br',
            timezoneOffset: -180,
            languageCode: 'pt-br',
            localeCode: 'br',
          },
          application: 'appdedalo',
          documentCode: 'b5b9c5',
          counter: 16,
          __documentoCode: 'b5b9c5.16',
        },
        types: [1, 2], // inicial e revalidação
        seats: 20,
        hour: '7:15', // 24h
        timezoneOffset: -180,
        note: 'Não esquecer de informar seu código ANAC',
      },
    ],
  },
  {
    weekday: 2,
    __weekdayName: 'Terça-Feira',
    agendas: [
      {
        _id: '622f851124f9539383b5b9c6',
        _metadata: {
          subscriptionId: '6224c2f73b70e006a06a9741',
          legalId: '5a1db82168f60e00143156d5',
          origin: 0,
          hidden: false,
          audit: {
            createdByAccountId: '5a15560e789b400014fffc11',
            createdAt: '2022-03-14T18:10:25.314Z',
          },
          i18n: {
            countryCode: 'br',
            timezoneOffset: -180,
            languageCode: 'pt-br',
            localeCode: 'br',
          },
          application: 'appdedalo',
          documentCode: 'b5b9c5',
          counter: 16,
          __documentoCode: 'b5b9c5.16',
        },
        types: [1, 2, 3, 4], // inicial, revalidação, recurso e pós-acidente
        seats: 10,
        hour: '10:00', // 24h
        timezoneOffset: -180,
        note: 'Bla bla bla',
      },
      {
        _id: '622f851124f9539383b5b9c7',
        _metadata: {
          subscriptionId: '6224c2f73b70e006a06a9741',
          legalId: '5a1db82168f60e00143156d5',
          origin: 0,
          hidden: false,
          audit: {
            createdByAccountId: '5a15560e789b400014fffc11',
            createdAt: '2022-03-14T18:10:25.314Z',
          },
          i18n: {
            countryCode: 'br',
            timezoneOffset: -180,
            languageCode: 'pt-br',
            localeCode: 'br',
          },
          application: 'appdedalo',
          documentCode: 'b5b9c5',
          counter: 16,
          __documentoCode: 'b5b9c5.16',
        },
        types: [1], // inicial e revalidação
        seats: 15,
        hour: '14:00', // 24h
        timezoneOffset: -180,
        note: 'Programa Meu Primeiro CMA apenas para CMS',
      },
    ],
  },
  {
    weekday: 3,
    __weekdayName: 'Quarta-Feira',
    agendas: [
      {
        _id: '622f851124f9539383b5b9c5',
        _metadata: {
          subscriptionId: '6224c2f73b70e006a06a9741',
          legalId: '5a1db82168f60e00143156d5',
          origin: 0,
          hidden: false,
          audit: {
            createdByAccountId: '5a15560e789b400014fffc11',
            createdAt: '2022-03-14T18:10:25.314Z',
          },
          i18n: {
            countryCode: 'br',
            timezoneOffset: -180,
            languageCode: 'pt-br',
            localeCode: 'br',
          },
          application: 'appdedalo',
          documentCode: 'b5b9c5',
          counter: 16,
          __documentoCode: 'b5b9c5.16',
        },
        types: [1, 2], // inicial e revalidação
        seats: 20,
        hour: '7:15', // 24h
        timezoneOffset: -180,
        note: 'Não esquecer de informar seu código ANAC',
      },
    ],
  },
  {
    weekday: 4,
    __weekdayName: 'Quinta-Feira',
    agendas: [
      {
        _id: '622f851124f9539383b5b9c6',
        _metadata: {
          subscriptionId: '6224c2f73b70e006a06a9741',
          legalId: '5a1db82168f60e00143156d5',
          origin: 0,
          hidden: false,
          audit: {
            createdByAccountId: '5a15560e789b400014fffc11',
            createdAt: '2022-03-14T18:10:25.314Z',
          },
          i18n: {
            countryCode: 'br',
            timezoneOffset: -180,
            languageCode: 'pt-br',
            localeCode: 'br',
          },
          application: 'appdedalo',
          documentCode: 'b5b9c5',
          counter: 16,
          __documentoCode: 'b5b9c5.16',
        },
        types: [1, 2, 3, 4], // inicial, revalidação, recurso e pós-acidente
        seats: 10,
        hour: '10:00', // 24h
        timezoneOffset: -180,
        note: 'Bla bla bla',
      },
    ],
  },
  {
    weekday: 5,
    __weekdayName: 'Sexta-Feira',
    agendas: [
      {
        _id: '622f851124f9539383b5b9c5',
        _metadata: {
          subscriptionId: '6224c2f73b70e006a06a9741',
          legalId: '5a1db82168f60e00143156d5',
          origin: 0,
          hidden: false,
          audit: {
            createdByAccountId: '5a15560e789b400014fffc11',
            createdAt: '2022-03-14T18:10:25.314Z',
          },
          i18n: {
            countryCode: 'br',
            timezoneOffset: -180,
            languageCode: 'pt-br',
            localeCode: 'br',
          },
          application: 'appdedalo',
          documentCode: 'b5b9c5',
          counter: 16,
          __documentoCode: 'b5b9c5.16',
        },
        types: [1, 2], // inicial e revalidação
        seats: 20,
        hour: '7:15', // 24h
        timezoneOffset: -180,
        note: 'Não esquecer de informar seu código ANAC',
      },
    ],
  },
  {
    weekday: 6,
    __weekdayName: 'Sábado',
    agendas: [],
  },
  {
    weekday: 7, // 0 + 7
    __weekdayName: 'Domingo',
    agendas: [],
  },
];

export const agendasSeed = [
  {
    weekday: 1,
    weekdayName: 'Segunda-Feira',
    weekdaysIdx: [1, 3, 5], // seg, qua e sex
    seats: 20,
    candidates: [
      {
        pictureUrl: null,
        backColor: 'grey',
        foreColor: 'white',
        name: 'Ricardo Biruel',
        mobile: '(19) 9.8232-0000',
        email: 'biruel@infodesk.com.br',
      },
      {
        pictureUrl: null,
        backColor: 'red',
        foreColor: 'white',
        name: 'Ane Cardinalli',
        mobile: '(19) 9.9606-6006',
        email: 'ane@me.com',
      },
    ],

  },
];

export const moduleSettings = {
  code: 'agendas',
  name: 'agendas',
  hasAccessControl: false,
  hasSettings: false,
};
