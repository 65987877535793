import React, {useState} from 'react';
import {rdxSetDataProvider} from 'redux/actions/module';
import {useDispatch} from 'react-redux';

export const DataContext = React.createContext({});

export const DataProvider = ({_module, children}) => {

    const initialContext = {
        settings: null,
        parameters: null,

        data: null,
        dataLock: null,

        fullLoading: true,
        partialLoading: false,

        formsChanged: []
    }

    const [state, setState] = useState(initialContext);
    const dispatch = useDispatch();

    const setDataContext = context => {

        const nextState = {...state, ...context};

        setState(nextState);
        dispatch(rdxSetDataProvider(nextState));
    };

    return (
        <DataContext.Provider value={{dataContext: state, setDataContext}}>
            {children}
        </DataContext.Provider>
    );
};
