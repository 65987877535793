import { isMongoObjectId } from 'utils/helpers'
import { decorateDataType } from 'components/jarvisly-layouts/ModuleComponent/module-methods'
import { getResidents } from '../_old-modules/entities/entities-api'

export function validateSearchFn (keyValueArr, resetSearch) {

  const [k, v] = keyValueArr

  if (resetSearch || !v) return 'null'

  switch (k) {

    // case 'documentCode':
    //   if (v === 'null') return 'null'
    //   return v

    case 'roomNumber':
      if (v === 'null') return 'null'
      return v

    case 'documentStatus': {

      const vArr = Array.isArray(v) ? v : v.split(',')
      const newV = []

      if (vArr.length === 0) {
        return 'null'

      } else if (vArr[0] === null || vArr[0] === 'null') {
        return 'null'

      } else {
        if (vArr.includes('in_analise')) newV.push('in_analise')
        if (vArr.includes('with_reservation')) newV.push('with_reservation')
        if (vArr.includes('revised')) newV.push('revised')
      }

      if (newV.length === 0) return 'null'

      return newV
    }

    case 'blockId': {
      const vArr = Array.isArray(v) ? v : v.split(',')
      const newV = []

      vArr.map(x => {
        if (isMongoObjectId(x)) newV.push(x)
        return x
      })

      return newV.length > 0 ? newV : 'null'
    }

    default:
      return 'null'
  }
}

export function validateFilterFn (keyValueArr, defaultFilter) {

  const { dataProfile } = defaultFilter

  const [k, v] = keyValueArr

  switch (k) {

    case 'folder':
      return ['documents', 'archive', 'recycle'].includes(v) ? v : 'documents'

    case 'dataProfile':
      return dataProfile

    default:
      return 'null'
  }

}

export function decorateDocument (docs) {

  const accountId = localStorage.getItem('accountId')

  let isObject = false

  if (!Array.isArray(docs)) {
    isObject = true
    docs = [docs]
  }

  docs.map(doc => {

    doc?.discussions?.map(d => {
      d.from = d.authorId === accountId ? 'me' : 'opposite'
      return d
    })

    return doc
  })

  return isObject ? docs[0] : docs
}

export function decorateNewDocument (_settings, _module) {
  let data = {}
  decorateDataType(_settings, data, _module)
  return data

}

export function removeDocumentValidation (_module, data, intl) {

  const _id = data?._id

  if (!isMongoObjectId(_id)) {
    console.error(
      'Invalid _id! vehicles-methods.js: removeDocumentValidation()')
    return 'abort'
  }

  return null
}

export function parseSettings (_module, _settings) {
  const body = {}
  return body
}

export const getVehicleModuleParameters = async (_module, settings) => {

  return {
    residents: await getResidents(),
  }

  // if (!_module?.name || !rdxSubscription?._id) return
  // if (!rdxSubscription?.moduleParameters) rdxSubscription.moduleParameters = {}
  //
  // const result = await getSubscription(rdxSubscription?._id)
  //
  // rdxSubscription.moduleParameters = {
  //     blocks: result?.settings[_module.name]?.blocks || [],       // blocks
  //     profiles: result?.settings[_module.name]?.profiles || []    // vehicle profiles
  // }

  // return rdxSubscription.moduleParameters

  // return null
}

