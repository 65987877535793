import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react'
import { Col, Form, Row } from 'antd'
import { ROW_GUTTER } from 'constants/ThemeConstant'
import { onFormFinishFailed, parseDateToDisplay } from 'utils/helpers'
import moment from 'moment'
import {
  FormCpf,
  formDataChanges,
  FormDatePicker,
  FormEmail,
  FormInput,
  FormPhone,
  FormSelectGender,
  FormSelectRelationship,
  FormTitle,
} from '../../../FormComponets'
import { useIntl } from 'react-intl'
import { translate } from 'utils/react-jarvisly-helper'

const ResidentModalRegistrationTab = forwardRef((props, ref) => {

  // props deconstruction ------------------------------------------------------
  const {
    record, onSave, setIsFormChanged, _dataRoots,
  } = props

  // component states ----------------------------------------------------------
  const [age, setAge] = useState(record?.age || 0)

  // local variables -----------------------------------------------------------
  const [form] = Form.useForm()
  const elRefs = buildElementsRefs(useRef)
  const intl = useIntl()
  const t = buildTranslations()
  const entryAt = _dataRoots?.titular?.entryAt && parseDateToDisplay(_dataRoots.titular.entryAt)

  // hooks ---------------------------------------------------------------------

  useEffect(() => {
    handleFocus()
    if (!record) form.setFieldValue('entryAt', entryAt)
  }, [record, form]) // eslint-disable-line react-hooks/exhaustive-deps

  useImperativeHandle(ref, () => ({
    onSubmit: field => form.submit(),
  }))

  // METHODS -------------------------------------------------------------------

  function handleFocus (field = 'name', cursorPosition = 'start') {
    elRefs[field]?.current?.focus({ cursor: cursorPosition })
  }

  const onChangeBirthday = value => {
    if (!value || !moment(value).isValid()) return setAge(0)
    setAge(moment().diff(value, 'years'))
  }

  const onValuesChanges = () => {
    setIsFormChanged(formDataChanges(form, record))
  }

  const onFinishFailed = errorInfo => onFormFinishFailed(errorInfo, elRefs)

  const onFinish = values => {
    onSave && onSave(values)
  }

  // UI COMPONENT --------------------------------------------------------------

  return (<>

    <Form form={form}
          layout="vertical"
          initialValues={record}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          onValuesChange={onValuesChanges}
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
          preserve={false}>


      {/* BASIC INFO */}
      <FormTitle title={'dependent_registration'}/>

      <Row gutter={ROW_GUTTER}>

        {/* NAME */}
        <Col xs={24} md={11}>
          <FormInput name="name"
                     label="full_name"
                     disabled={_dataRoots?.updateSentAt}
                     required
                     elRef={elRefs.name}/>
        </Col>
        {/* NAME */}

        {/* GENDER */}
        <Col xs={24} sm={6}>
          <FormSelectGender elRef={elRefs.gender}
                            disabled={_dataRoots?.updateSentAt}
                            required/>
        </Col>
        {/* GENDER */}


        {/* RENTER RELATIONSHIP */}
        <Col xs={24} md={7}>
          <FormSelectRelationship name="relationship"
                                  elRef={elRefs.relationship}
                                  disabled={_dataRoots?.updateSentAt}
                                  required/>
        </Col>
        {/* RENTER RELATIONSHIP */}


      </Row>


      <Row gutter={ROW_GUTTER}>

        {/* BIRTHDAY */}
        <Col xs={24} sm={8}>
          <FormDatePicker name="birthday"
                          required
                          onChange={onChangeBirthday}
                          disabled={_dataRoots?.updateSentAt}
                          elRef={elRefs.birthday}/>
        </Col>
        {/* BIRTHDAY */}


        {/* CPF / CNPJ */}
        {age > 17 ? <Col xs={24} sm={8}>
          <FormCpf elRef={elRefs.cpf}
                   disabled={_dataRoots?.updateSentAt}
                   required
                   form={form}/>
        </Col> : null}
        {/* CPF / CNPJ */}

        {/* RG */}
        {age > 17 ? <Col xs={24} sm={8}>
          <FormInput name="rg"
                     disabled={_dataRoots?.updateSentAt}
                     required
                     elRef={elRefs.rg}/>
        </Col> : null}
        {/* RG */}


      </Row>


      {/* CONTACTS */}
      {age > 17 ? <FormTitle title={'contact_correspondence_notice'}/> : null}

      {age > 17 ? <Row gutter={ROW_GUTTER}>

        {/* PHONE */}
        <Col xs={24} sm={8}>
          <FormPhone form={form}
                     disabled={_dataRoots?.updateSentAt}
                     required
                     elRef={elRefs.phone}/>
        </Col>
        {/* PHONE */}

        {/* EMAIL */}
        <Col xs={24} sm={16}>
          <FormEmail form={form}
                     disabled={_dataRoots?.updateSentAt}
                     elRef={elRefs.email}/>
        </Col>
        {/* EMAIL */}


      </Row> : null}
      {/* CONTACTS */}


      {/* ENTRANCE */}
      <FormTitle title={'period'}/>

      <Row gutter={ROW_GUTTER}>

        {/* ENTRY DATE */}
        <Col xs={24} sm={8}>
          <FormDatePicker name="entryAt"
                          label="move_date"
                          disabled={_dataRoots?.updateSentAt}
                          required
                          tooltip={t.approximate_date}
                          elRef={elRefs.entryAt}/>
        </Col>
        {/* ENTRY DATE */}

      </Row>
      {/* ENTRANCE */}




      <Row gutter={ROW_GUTTER}>

        {/* NOTE */}
        <Col xs={24}>
          <FormInput name="note"
                     label="observation"
                     disabled={_dataRoots?.updateSentAt}
                     elRef={elRefs.note}/>
        </Col>
        {/* NOTE */}

      </Row>


    </Form>


  </>)

  // INTERNAL FUNCTIONS ========================================================
  // ===========================================================================

  function buildTranslations () {
    return {
      approximate_date: translate(intl, 'approximate_date'),
    }
  }

  function buildElementsRefs (hookUseRef) {
    return {
      name: hookUseRef(null), gender: hookUseRef(null), relationship: hookUseRef(null),

      birthday: hookUseRef(null), cpf: hookUseRef(null), rg: hookUseRef(null),

      phone: hookUseRef(null), email: hookUseRef(null), entryAt: hookUseRef(null),

      note: hookUseRef(null),
    }
  }

})

// EXPORT **********************************************************************
// *****************************************************************************

export default ResidentModalRegistrationTab


