import React, {useRef, useState} from 'react';
import {Button, Modal, Row} from 'antd';
import {useIntl} from 'react-intl';
import {
  FormOutlined,
  StarOutlined,
} from '@ant-design/icons';
import {translate} from 'utils/react-jarvisly-helper';
import IntlMessage from 'components/util-components/IntlMessage';
import MainMemberTab from './tabs/MainMemberTab';
import {ModalContext, ModalProvider} from './ProceduresMemberModalProvider';
import {Icon} from 'components/util-components/Icon';

// GLOBAL VARIABLES ************************************************************
// *****************************************************************************

const setLocale = (isLocaleOn, localeKey) => isLocaleOn ?
    <IntlMessage id={localeKey}/> : localeKey.toString();

// MAIN COMPONENT **************************************************************
// *****************************************************************************

const ProceduresMemberModal = props => {
  return (
      <ModalProvider>
        <ProceduresMemberModalLayout {...props}/>
      </ModalProvider>
  );
};

const ProceduresMemberModalLayout = props => {

  // props deconstruction ------------------------------------------------------
  const {showModal, setShowModal, onCloseFn, record} = props;

  // component states -----------------------------------------------------------
  const [state] = useState({
    tabIdx: 'main',
  });

  // local variables ------------------------------------------------------------
  const intl = useIntl();
  const mainTabRef = useRef();

  const {formContext, setFormContext} = React.useContext(ModalContext);

  const titleIcon = !record
      ? StarOutlined
      : FormOutlined;

  const title = !record
      ? `${translate(intl, 'new_member')}`
      : `${translate(intl, 'edit_member')}`;

  // hooks ---------------------------------------------------------------------
  /*
    useEffect(() => {
    }, [formContext?.selectedEntity?._id]); // eslint-disable-line react-hooks/exhaustive-deps
  */

  // METHODS -------------------------------------------------------------------

  const onClose = (event, doc) => {
    setFormContext({});
    setShowModal(false);
    if (onCloseFn && doc) onCloseFn(doc);
  };

  const onSave = () => {

    if (state.tabIdx === 'main') {
      return mainTabRef?.current?.submitForm();
    }

  };


  // UI COMPONENT --------------------------------------------------------------


  const isDisable = !formContext?.selectedEntity?._id;

  const modalButtons = [

    <Row justify="end" key="footerButtons">

      <Button key="cancel"
              type="default"
              onClick={onClose}
      >
        <span>{setLocale(true, 'cancel')}</span>
      </Button>

      <Button key="ok"
              type="primary"
          // loading={updating}
              disabled={isDisable}
              onClick={onSave}>
        <span>{setLocale(true, 'confirm')}</span>
      </Button>
      {/*</Row>*/}

    </Row>];

  return (

      <Modal
          title={<>
            <Icon type={titleIcon}></Icon>
            <span className="ml-2">
            {title}
          </span>
          </>}
          destroyOnClose={true}
          open={showModal}
          width={640}
          // cancelText={setLocale(true, 'cancel')}
          // okText={setLocale(true, 'add')}
          onCancel={onClose}
          // onOk={onSave}
          // okButtonProps={{type: 'primary'}} r
          // cancelButtonProps={{type: 'default'}}
          getContainer={false}
          footer={modalButtons}
          className="modal-without-padding">

        {/*<Tabs onChange={onTabClick} items={tabItems} activeKey={state?.tabIdx}/>*/}

        <div style={{padding: '24px 24px 0'}}>

          <MainMemberTab {...props}
                         formContext={formContext}
                         setFormContext={setFormContext}
                         onClose={onClose}
                         ref={mainTabRef}/>

        </div>

      </Modal>

  );

};

// EXPORT **********************************************************************
// *****************************************************************************

export default ProceduresMemberModal;




