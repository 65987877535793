import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import {
  Row,
  Col,
  Tooltip,
  Tag,
  Progress,
  Avatar,
  Card,
  Select,
  Form,
  Button,
  Alert,
  notification,
  message,
} from "antd";
import { TeamOutlined, ClockCircleOutlined } from "@ant-design/icons";
import utils from "utils";
import Flex from "components/shared-components/Flex";
import IntlMessage from "components/util-components/IntlMessage";
import {
  getScheduleByCandidateCPF,
  getScheduleList,
  removeCandidateFromSchedule,
} from "./schedules-api";
import moment from "moment-timezone";
import { getDateList, getDateName, modal, msg } from "utils/helpers";
import AppCover from "components/jarvisly/AppCover";
import {
  getTypeTagColor,
  getTypeTagName,
} from "../../records/agendas/agendas-helper";
import { withRouter } from "react-router-dom";
import OnlineSchedule from "./modal/OnlineSchedule";
import {
  InputCpfCnpj,
  isValidCpf,
  translate,
} from "utils/react-jarvisly-helper";
import { useIntl } from "react-intl";
import CancelAppointment from "./modal/CancelAppointment";
import img from "assets/img/schedule.jpeg";
import Loading from "components/shared-components/Loading";
import { clone } from "jarvisly-helper";
import apiConfig from "configs/ApiConfig";
import { moduleSettings } from "./schedules-helper";
import { connect } from "react-redux";
import { rdxSetModule } from "redux/actions/module";
import { getCandidateByCpf } from "../../records/candidates/candidates-api";

// GLOBAL VARIABLES ************************************************************
// *****************************************************************************

const setLocale = (isLocaleOn, localeKey) =>
  isLocaleOn ? <IntlMessage id={localeKey} /> : localeKey.toString();

const { Option } = Select;

const _years = getDateList(moment(), 4);
let _currentYear =
  localStorage.getItem("year") && Number(localStorage.getItem("year"));
let _currentMonth =
  localStorage.getItem("month") && Number(localStorage.getItem("month"));

if (!_currentYear || isNaN(_currentYear)) {
  const y = new Date().getFullYear();
  _currentYear = y;
  localStorage.setItem("year", y.toString());
}

if (typeof _currentMonth === "undefined" || isNaN(_currentMonth)) {
  const m = new Date().getMonth();
  _currentMonth = m;
  localStorage.setItem("month", m.toString());
}

const ItemAction = ({ enrolled, seats }) => {
  return (
    <div>
      <Tooltip title="Candidatos">
        <TeamOutlined className="text-muted font-size-md" />
        <span className="ml-1 text-muted">
          {enrolled}/{seats}
        </span>
      </Tooltip>
    </div>
  );
};

const ItemHeader = ({ name, category }) => (
  <div>
    <h4 className="mb-0">{name}</h4>
    <span className="text-muted">{category}</span>
  </div>
);

const ItemInfo = ({ types }) => {
  return (
    <Flex alignItems="center">
      <Row>
        {types.map((tag) => (
          <Col key={tag} className="tag-col-container">
            <Tag color={getTypeTagColor(tag)} className="tag">
              {getTypeTagName(tag).toLowerCase()}
            </Tag>
          </Col>
        ))}
      </Row>
    </Flex>
  );
};

const ItemProgress = ({ progression, color }) => (
  <Progress
    className="progress"
    percent={progression}
    strokeColor={color || "green"}
    size="small"
  />
);

const ItemMember = ({ member }) => {
  return (
    <>
      {member.map((elm, i) =>
        i <= 3 ? (
          <Tooltip title={elm.name} key={`avatar-${i}`}>
            {/* <Avatar size="small" className={`ml-1 cursor-pointer ant-avatar-${elm.avatarColor}`} src={elm.img}> */}
            <Avatar
              size="small"
              className={`ml-1 cursor-pointer`}
              style={{
                backgroundColor: elm?.backColor || "cyan",
                color: elm?.foreColor || "white",
              }}
              src={elm.img}
            >
              {elm.img ? (
                ""
              ) : (
                <span className="font-weight-semibold font-size-sm">
                  {elm?.name ? utils.getNameInitial(elm.name) : "ND"}
                </span>
              )}
            </Avatar>
          </Tooltip>
        ) : null,
      )}
      {member.length > 4 ? (
        <Tooltip title={`Mais ${member.length - 4}`}>
          <Avatar
            size={25}
            className="ml-1 cursor-pointer bg-white border font-size-sm"
          >
            <span className="text-gray-light font-weight-semibold">
              +{member.length - 4}
            </span>
          </Avatar>
        </Tooltip>
      ) : null}
    </>
  );
};

const GridItemOnline = ({
  data,
  type,
  candidate,
  appointment,
  fetchData,
  cpf,
  selfSchedule,
}) => {
  const [showModal, setShowModal] = useState(false);

  const history = useHistory();
  const isPast = selfSchedule
    ? false
    : moment(data.date).diff(moment(), "milliseconds", false) < 0;

  const styles =
    data && appointment && data?._id === appointment?._id
      ? {
          backgroundColor: "rgb(230, 255, 241)",
          borderColor: "rgb(124, 247, 188)",
          borderRadius: "0.625rem",
        }
      : {};

  const className =
    !isPast && data.__vacancies > 0 ? "card-clickable-effect" : "";

  const onClick = (value) => {
    if (selfSchedule && value.__vacancies <= 0) {
      const options = {
        type: "info",
        title: "Não há vagas!",
        message: "Sinto muito, esta agenda já se encontra encerrada!",
        okText: "OK",
      };

      modal(options);
      return;
    }

    if (!appointment) {
      value.searchedCpf = cpf;
      value.candidate = candidate && clone(candidate);
    }

    if (selfSchedule) {
      if (appointment) {
        notification["warning"]({
          message: "Você já possui um agendamento",
          description:
            "Para reagendar em outra data, você primeiro deve cancelar o seu agendamento atual.",
          duration: 0,
        });
        return;
      }

      setShowModal(true);
    } else {
      const date = moment(value.date).format("YYYY-MM-DD");
      const query = `date=${date}&type=${type}`;
      const url = `/app/attendance/schedules/${value._id}/${value.agendaId}?${query}`;
      history.push(url, value);
    }
  };

  if (selfSchedule && data.__seats < data.__enrolled) {
    data.__seats = data.__enrolled;
  }

  return (
    <>
      <OnlineSchedule
        showModal={showModal}
        setShowModal={setShowModal}
        type={type}
        schedule={data}
        candidate={candidate}
        fetchData={fetchData}
        cpf={cpf}
      />

      <Card
        className={className}
        style={{
          opacity: data.__vacancies <= 0 || isPast ? 0.4 : 1,
          ...styles,
        }}
        onClick={() => onClick(data)}
      >
        <Flex alignItems="center" justifyContent="between" className="mb-3">
          <div>
            <ItemHeader name={data.__hour} />
            <span className="font-size-xs text-muted">{data.__timezone}</span>
          </div>
          <ItemAction enrolled={data.__enrolled} seats={data.__seats} />
        </Flex>

        <div className="mt-2">
          <ItemInfo
            statusColor={data.__statusColor}
            dayleft={data.__dayleft}
            date={data.date}
            types={data.types}
          />
        </div>

        <div className="mt-3">
          <ItemProgress
            progression={data.__progression}
            color={data?.__statusColor || "green"}
          />
        </div>

        <Row justify={"space-between"} className="mt-2">
          <Col>{!selfSchedule && <ItemMember member={data.candidates} />}</Col>

          <Col>
            <Tag
              className={
                data.__vacancies <= 0 || isPast ? "bg-gray-lightest" : ""
              }
              style={{ marginRight: 0 }}
              color={data.__vacancies <= 0 || isPast ? "" : data.__statusColor}
            >
              <ClockCircleOutlined />
              {data.__vacancies <= 0 || isPast ? (
                <span className="ml-2 font-weight-semibold">ENCERRADO</span>
              ) : (
                <span className="ml-2 font-weight-semibold">
                  {data.__dayleft} dias restantes
                </span>
              )}
            </Tag>
          </Col>
        </Row>
      </Card>
    </>
  );
};

// COMPONENT *******************************************************************
// *****************************************************************************

const SchedulesIndex = (props) => {
  const selfSchedule = props?.location?.pathname === "/online-schedule";

  if (selfSchedule) {
    apiConfig.defaults.headers["x-accountId"] = "62465de00490ee2ade000001"; // System Robot

    // console.log(
    //   "FROM ->",
    //   window.location.href.includes("dedaloribeirao"),
    //   window.location.href,
    // );

    if (window.location.href.includes("dedaloribeirao")) {
      // console.log("RP");
      apiConfig.defaults.headers["x-subscriptionid"] =
        "6282e7831b7d873e20a479a1"; // Instituto Dédalo RP
      // apiConfig.defaults.headers['x-subscriptionid'] = '62824ab2d47e690bfcd1f466'; // Instituto Dédalo SP
    } else {
      // console.log("SP");
      apiConfig.defaults.headers["x-subscriptionid"] =
        "62824ab2d47e690bfcd1f466"; // Instituto Dédalo SP
      // apiConfig.defaults.headers['x-subscriptionid'] = '6282e7831b7d873e20a479a1'; // Instituto Dédalo RP
    }
  }

  // props destruction and high level variables -------------------------------
  const { rdxSetModule } = props;

  // component states -----------------------------------------------------------
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);
  const [candidate, setCandidate] = useState(null);
  const [appointment, setAppointment] = useState(null);
  const [cpfStatus, setCpfStatus] = useState("");
  const [months, setMonths] = useState(
    _years.find((y) => y.year === _currentYear).months,
  );

  // const [cpf, setCpf] = useState(!selfSchedule ? localStorage.getItem('cpf') || '' : '')
  // const [type, setType] = useState(!selfSchedule ? localStorage.getItem('type') || '' : '')
  const [cpf, setCpf] = useState(localStorage.getItem("cpf") || "");
  const [type, setType] = useState(localStorage.getItem("type") || "");

  const [year, setYear] = useState(_currentYear);
  const [month, setMonth] = useState(_currentMonth);

  const [showSchedules, setShowSchedules] = useState(false);

  const [showCancelModal, setShowCancelModal] = useState(false);
  const [run, setRun] = useState(true);

  // local variables ------------------------------------------------------------

  const [form] = Form.useForm();

  // hooks ---------------------------------------------------------------------
  useEffect(() => {
    rdxSetModule(moduleSettings);

    if (type && year >= 0 && month >= 0 && (!selfSchedule || cpf)) {
      (async () => fetchData())();
      setShowSchedules(true);
    } else {
      setShowSchedules(false);
    }

    if (cpf) setCpfStatus("success");
    setTimeout(() => setRun(false), 600);
  }, [cpf, type, year, month]); // eslint-disable-line react-hooks/exhaustive-deps

  // METHODS -------------------------------------------------------------------

  const intl = useIntl();
  const fetchData = async () => {
    setLoading(true);

    const query = {
      type: type,
      year,
      month,
    };

    let resultList = await getScheduleList(query);

    const resultCandidate = await getCandidateByCpf(cpf);
    const resultAppointment = await getScheduleByCandidateCPF(cpf);

    // hide past schedules
    if (selfSchedule) {
      resultList.map((r) => {
        r.events = r.events.filter(
          (e) => moment(e.date).diff(moment(), "milliseconds", false) > 0,
        );

        return r;
      });

      resultList = resultList.filter(
        (x) =>
          moment(x.day).diff(moment(), "milliseconds", false) > 0 &&
          x.events.length > 0,
      );
    }

    setData(resultList);
    setCandidate(resultCandidate);
    setAppointment(resultAppointment);
    setLoading(false);
  };

  // const onChangeFilter = async (field, value) => {
  const onChangeFilter = async (action, value) => {
    if (typeof value === "object") value = value?.target?.value;

    if (action === "cpf") {
      const cpf = isValidCpf(value) ? value : "";
      setCpf(cpf);
      localStorage.setItem("cpf", cpf);
    }

    if (action === "type") {
      setType(value);
      localStorage.setItem("type", value);
    }

    if (action === "year") {
      const now = new Date();

      if (now.getFullYear() !== value) {
        const m = _years.find((y) => y.year === value).months;
        setMonths(m);
        setMonth(m[0].value);
        form.setFieldsValue({ month: m[0].value });
      }

      setYear(value);
      localStorage.setItem("year", value);
    }

    if (action === "month") {
      setMonth(value);
      localStorage.setItem("month", value);
    }
  };

  const cancelAppointment = () => {
    if (selfSchedule) {
      setShowCancelModal(true);
    } else {
      const options = {
        type: "confirm",
        title: "Cancelar Agendamento",
        message: "Tem realmente certeza que deseja cancelar este agendamento?",
        okText: "Sim",
        cancelText: "Não",
        onOk: $onOk,
      };

      modal(options);
    }

    async function $onOk() {
      msg("i", "Cancelando Agendamento...", "cancelling");

      const body = {
        candidateId: appointment.candidate._id,
        name: appointment.candidate.name,
        email: appointment.candidate.email,
      };

      await removeCandidateFromSchedule(appointment._id, body);
      message.destroy("cancelling");
      msg("s", "Agendamento cancelado com sucesso!");
      fetchData().then((x) => x);
    }
  };

  // UI COMPONENT --------------------------------------------------------------

  const render = () => {
    const appointmentType =
      appointment?.type && getTypeTagName(appointment.type);
    const appointmentWeekday =
      appointment?.date &&
      translate(intl, getDateName(moment(appointment.date)));

    const description = `${appointmentType} agendado para ${appointmentWeekday}, ${appointment?.day} às ${appointment?.hour}h.`;

    return (
      <>
        {appointment ? (
          <Alert
            className="mr-2 ml-2"
            message="CPF Agendado!"
            showIcon
            description={description}
            type="success"
            action={
              // <Tooltip title="Cancelar Agendamento!">
              <Button size="small" danger onClick={cancelAppointment}>
                Cancelar
              </Button>
              // </Tooltip>
            }
          />
        ) : null}

        {data?.map((monthDay) => (
          <div key={monthDay.day}>
            <Row className="mt-5 mr-3 mb-0 ml-3" justify="center">
              <h4 className="mb-0">
                {setLocale(true, getDateName(moment(monthDay.day)))},&nbsp;
                {moment(monthDay.day).format("LL")}
              </h4>
            </Row>

            <div className={`my-4 container-fluid`}>
              <Row gutter={16} justify="center">
                {monthDay?.events?.map((elm) => (
                  <Col
                    xs={24}
                    sm={24}
                    lg={8}
                    xl={8}
                    xxl={6}
                    key={elm._id || elm.agendaId}
                  >
                    <GridItemOnline
                      data={elm}
                      type={type}
                      cpf={cpf}
                      candidate={candidate}
                      appointment={appointment}
                      showCancelModal={showCancelModal}
                      setShowCancelModal={setShowCancelModal}
                      fetchData={fetchData}
                      selfSchedule={selfSchedule}
                    />
                  </Col>
                ))}
              </Row>
            </div>
          </div>
        ))}
      </>
    );
  };

  const enterYourCpf = () => {
    return (
      <Row justify="center" align="middle" style={{ height: "40vh" }}>
        <Col className="text-center">
          <img
            src={img}
            alt="up arrow"
            style={{
              width: 100,
              opacity: 0.4,
              marginBottom: 24,
            }}
          />
          <h4 className="text-muted">Informe o seu CPF e o Tipo da Consulta</h4>
        </Col>
      </Row>
    );
  };

  const rules = {
    cpf: [
      {
        required: true,
        message: setLocale(true, "required_field"),
      },
      {
        validator: (rule, cpf) => {
          if (cpf && !isValidCpf(cpf)) {
            setCpfStatus("error");
            return Promise.reject(setLocale(true, "invalid_cpf"));
          } else {
            setCpfStatus(cpf ? "success" : "error");
            return Promise.resolve();
          }
        },
      },
    ],
    type: [
      {
        required: true,
        message: setLocale(true, "required_field"),
      },
    ],
    cpfRequired: [
      {
        validator: (rule, cpf) => {
          if (cpf && !isValidCpf(cpf)) {
            setCpfStatus("error");
            return Promise.reject(setLocale(true, "invalid_cpf"));
          } else {
            setCpfStatus(cpf ? "success" : "error");
            return Promise.resolve();
          }
        },
      },
    ],
  };

  return (
    <>
      {run && selfSchedule && <Loading cover="page" />}

      <AppCover
        isLoading={loading}
        data={data}
        // marginTop={10}
        fullscreen={selfSchedule}
        ignoreNoData={!showSchedules}
      >
        <CancelAppointment
          showCancelModal={showCancelModal}
          setShowCancelModal={setShowCancelModal}
          schedule={appointment}
          fetchData={fetchData}
        />

        {selfSchedule && (
          <Row justify="center" className="m-4">
            <h2>{setLocale(true, "online_scheduling")}</h2>
          </Row>
        )}

        {/* <Row className="container-fluid m-3 text-center" justify="center"> */}
        <Row justify="center">
          <Form
            form={form}
            layout="vertical"
            initialValues={{
              cpf: cpf || "",
              type: type,
              year: _currentYear,
              month: _currentMonth,
            }}
          >
            <Row gutter={16} justify="center">
              {selfSchedule && (
                <Col className="gutter-row">
                  <Form.Item
                    label={setLocale(true, "person_cpf")}
                    name="cpf"
                    hasFeedback
                    validateStatus={cpfStatus}
                    rules={rules.cpf}
                  >
                    <InputCpfCnpj
                      style={{ width: 150 }}
                      autoFocus
                      disabled={cpfStatus === "validating"}
                      profile="cpf"
                      name="cpf"
                      onChange={(value) => onChangeFilter("cpf", value)}
                    />
                  </Form.Item>
                </Col>
              )}

              <Col className="gutter-row">
                <Form.Item
                  label={setLocale(true, "consult_type")}
                  name="type"
                  rules={[
                    {
                      required: true,
                      message: setLocale(true, "required_field"),
                    },
                  ]}
                >
                  <Select
                    className="text-left"
                    name="type"
                    autoFocus={!selfSchedule}
                    style={{ width: 150 }}
                    onChange={(value) => onChangeFilter("type", value)}
                  >
                    <Option key={1} value={"1"} text="Inicial">
                      Inicial
                    </Option>
                    <Option key={2} value={"2"} text="Revalidação">
                      Revalidação
                    </Option>
                    <Option key={3} value={"3"} text="Recurso">
                      Recurso
                    </Option>
                    <Option key={4} value={"4"} text="Pós Acidente">
                      Pós Acidente
                    </Option>
                  </Select>
                </Form.Item>
              </Col>

              <Col className="gutter-row">
                <Form.Item
                  label={setLocale(true, "year")}
                  name="year"
                  rules={[
                    {
                      required: true,
                      message: setLocale(true, "required_field"),
                    },
                  ]}
                >
                  <Select
                    className="text-left"
                    style={{ width: 150 }}
                    onChange={(value) => onChangeFilter("year", value)}
                  >
                    {_years.map((y) => (
                      <Option key={y.year} value={y.year}>
                        {y.year}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>

              <Col className="gutter-row">
                <Form.Item
                  label={setLocale(true, "month")}
                  name="month"
                  rules={[
                    {
                      required: true,
                      message: setLocale(true, "required_field"),
                    },
                  ]}
                >
                  <Select
                    className="text-left"
                    style={{ width: 150 }}
                    onChange={(value) => onChangeFilter("month", value)}
                    value={month}
                  >
                    {months.map((m) => (
                      <Option key={m.value} value={m.value}>
                        {setLocale(true, m.text)}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>

              {!selfSchedule && (
                <Col className="gutter-row">
                  <Form.Item
                    label={setLocale(true, "person_cpf")}
                    name="cpf"
                    hasFeedback
                    validateStatus={cpfStatus}
                    rules={rules.cpfRequired}
                  >
                    <InputCpfCnpj
                      style={{ width: 150 }}
                      disabled={cpfStatus === "validating"}
                      profile="cpf"
                      name="cpf"
                      onChange={(value) => onChangeFilter("cpf", value)}
                    />
                  </Form.Item>
                </Col>
              )}
            </Row>
          </Form>
        </Row>

        <div style={{ width: "100%" }}>
          {/*<AppCover isLoading={loading} data={data}>*/}

          {showSchedules ? render() : enterYourCpf()}

          {/*</AppCover>*/}
        </div>
      </AppCover>
    </>
  );
};

export default withRouter(connect(null, { rdxSetModule })(SchedulesIndex));
