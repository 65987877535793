import React from 'react';
import {
  decorateDocument,
  decorateNewDocument,
  decorateSettings,
  dataProfileValidation,
  parseSettings,
  removeDocumentValidation,
  validateFilterFn,
  validateSearchFn,
} from './occurrences-methods';
import OccurrencesMainList from './OccurrencesMainList';
import {occurrencesSettingsTabs} from './occurrences-settings';
import {
  FileProtectOutlined,
  MessageOutlined,
} from '@ant-design/icons';
import {Route} from 'react-router-dom';
import NoSection
  from 'components/jarvisly-layouts/ModuleComponent/Components/NoSection';
import OccurrencesProfile from './sections/OccurrencesProfile';

// ************************************************************************** //
// CONSTANTS
// ************************************************************************** //

const MODULE_OCCURRENCES_URL = '/app/operational/occurrences';
export const OCCURRENCES_API_URL = '/occurrences';

const INITIAL_FILTER = {
  folder: 'documents',
  dataProfile: 'plan',
};

const INITIAL_SORT = {
  sortField: '_metadata.audit.updatedAt',
  sortOrder: 'descend',
};

const INITIAL_SEARCH = {
  documentCode: 'null',
  subject: 'null',
};

const INITIAL_CONTEXT = {
  mode: 'add',
  data: null,
  docLock: null,
  selectedSection: null,
  sectionsChanged: [],
  dataMemory: false,
  hasPicture: false,
};

const METHODS = {
  parseSettings: parseSettings,
  decorateSettings: decorateSettings,

  decorateDocument: decorateDocument,
  decorateNewDocument: decorateNewDocument,

  removeDocumentValidation: removeDocumentValidation,
  postDocumentValidation: dataProfileValidation,
  putDocumentValidation: dataProfileValidation,

  validateSearchFn: validateSearchFn,
  validateFilterFn: validateFilterFn,
};

const COMPONENTS = {
  list: props => <OccurrencesMainList {...props}/>,

  // listPreviewHeader: props => <OccurrencesBodyPreviewHeader {...props}/>,
  // listPreviewBody: props => <OccurrencesBodyPreviewData {...props}/>,
  // listPreviewActions: props => <OccurrencesBodyPreviewActions {...props}/>,

  documentSubtitle: props => <div>{props?.record?.description}</div>,
  // documentHeaderColumn2: props => <OccurrencesDocumentHeaderColumn2 {...props}/>,
  // documentHeaderColumn3: props => <OccurrencesDocumentHeaderColumn3 {...props}/>,

  moduleSettings: occurrencesSettingsTabs,
};

const DATA_TYPES = [
  {
    key: 'document',  // database value
    label: 'document',
    description: 'document_description',
    disabled: true,
    checked: true,
  },
];

const DOCUMENT_SECTIONS = [
  {
    key: 'profile',
    icon: <FileProtectOutlined/>,
    label: 'occurrence',
    route: (props, ref, _id) => <Route key="profile"
                                       path={`${MODULE_OCCURRENCES_URL}/${_id}/profile`}
                                       render={() =>
                                           <OccurrencesProfile ref={ref} {...props} />}/>,
  },
  {
    key: 'followUp',
    icon: <MessageOutlined/>,
    label: 'follow_up',
    route: (props, ref, _id) => <Route key="follow_up"
                                       path={`${MODULE_OCCURRENCES_URL}/${_id}/follow-up`}
                                       render={() =>
                                           <NoSection ref={ref} {...props} />}/>,
  },
];

// requiredFields: ['name', 'subject', 'unit', 'occurrenceDate', 'description', 'levelId'],
const REQUIRED_FIELDS = [
  {
    key: 'name',
    label: 'name',
    checked: true,
    disabled: true,
    forDataType: ['document'],
  },
  {
    key: 'subject',
    label: 'subject',
    checked: true,
    disabled: true,
    forDataType: ['document'],
  },

  {
    key: 'unit',
    label: 'unit',
    checked: true,
    disabled: true,
    forDataType: ['document'],
  },
  {
    key: 'occurrenceDate',
    label: 'occurrence_date',
    checked: true,
    disabled: true,
    forDataType: ['document'],
  },
  {
    key: 'description',
    label: 'description',
    checked: true,
    disabled: false,
    forDataType: ['document'],
  },
  {
    key: 'level',
    label: 'level',
    checked: true,
    disabled: false,
    forDataType: ['document'],
  },

];

// ************************************************************************** //
// MODULES PARAMETERS
// ************************************************************************** //

export const MODULE_OCCURRENCES = {
  root: 'occurrences',
  name: 'occurrences',
  singular: 'occurrence',
  titleField: 'subject',
  url: MODULE_OCCURRENCES_URL,
  api: OCCURRENCES_API_URL,

  hasFilter: true,
  hasSettings: false,
  hasAccessControl: true,
  hasConfigurations: true,
  hasDataMemory: false,
  hasRequiredFields: true,

  methods: METHODS,
  components: COMPONENTS,
  dataTypes: DATA_TYPES,
  requiredFields: REQUIRED_FIELDS,

  initialSearch: INITIAL_SEARCH,
  initialContext: INITIAL_CONTEXT,
  initialFilter: INITIAL_FILTER,
  initialSort: INITIAL_SORT,

  labels: {
    new_document_title: 'new_occurrence',
  },

  documentSections: DOCUMENT_SECTIONS,
};

// ************************************************************************** //
// SEEDS
// ************************************************************************** //

export const SETTINGS_SEED = {

  configurations: {
    dataTypes: ['document'],
    dataMemory: false,
  },

  requiredFields: [
    'name',
    'subject',
    'unit',
    'occurrenceDate',
    'description',
    'level'
  ],

};

