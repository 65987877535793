import React from 'react';
import ChartWidget from 'components/shared-components/ChartWidget';
import { Badge, Col, Row } from 'antd';
import { useSelector } from 'react-redux';
import { OnOffStatusTag } from '../../Form/Components';
import moment from 'moment';
import NoData from 'components/layout-components/NoData';
import Loading from 'components/jarvisly/Loading';
import StatisticWidget from 'components/shared-components/StatisticWidget';
import { ROW_GUTTER } from 'constants/ThemeConstant';
import { useIntl } from 'react-intl';
import { translate } from 'utils/react-jarvisly-helper';

const IotDashboardTemperatures = props => {

  // props deconstruction ------------------------------------------------------
  const {
    rdxModuleList,
    devices,
    monitoring24h,
  } = props;

  // local variables -----------------------------------------------------------
  const { direction } = useSelector(state => state.theme);
  const intl = useIntl();
  const t = buildTranslations();

  // component states ----------------------------------------------------------

  // hooks ---------------------------------------------------------------------

  // methods -------------------------------------------------------------------

  // UI COMPONENT --------------------------------------------------------------
  return (<>
    <Loading isFetching={rdxModuleList?.loading} data={monitoring24h?.series}/>

    {monitoring24h?.series?.length === 0 && devices?.length === 0 &&
      <NoData/>
    }

    {monitoring24h?.series?.length > 0
      ? <Row gutter={ROW_GUTTER}>
        <Col span={24}>
          <ChartWidget
            title={t.temperature_last_24h}
            series={monitoring24h?.series}
            xAxis={monitoring24h?.categories}
            height={'300px'}
            direction={direction}
            customOptions={buildLast24hChartOptions()}
          />
        </Col>
      </Row>
      : null}


    {devices?.length > 0

      ? <Row gutter={ROW_GUTTER}>

        {devices && devices?.map(i => <Col xs={24} sm={8} lg={6} xl={4} key={i?._id}>

          <StatisticWidget
            record={i}
            suffix="°"
            infoTitle={i?.installed ? t.additional_info : t.device_not_installed}
            infoContent={
              !i?.installed
                ? <div>
                  <div>{t.channel}: <strong>{i?.channel}</strong></div>
                </div>
                : <div>
                  <div>{t.channel}: <strong>{i?.channel}</strong></div>
                  <div>{t.last_reading}: <strong>{moment(i?.lastReadingAt).format('L LT')}</strong></div>
                  <div>{t.last_value}: <strong>{`${i?.lastValue}°`}</strong></div>
                  <div>{t.last_try}: <strong>{moment(i?.lastReadingAttemptAt).format('L LT')}</strong></div>

                  {/*<div>Última Leitura: <strong>{moment(i?.lastReadingAt).fromNow()}</strong></div>*/}
                  {/* <div>Distância do Sensor: <strong>{i?.distance}cm</strong></div> */}
                  {/* <div>{t.quantity}: <strong>3.234L de 4.232L</strong></div> */}
                </div>
            }
            notFoundTitle={!i.installed ? t.not_installed.toUpperCase() : ''}

            title={
              <Badge className="badge-size-12" color={i?.backColor} text={i?.title}/>
            }

            subtitle={
              <>
                <div><span>{i?.subtitle}</span></div>
                <div><OnOffStatusTag record={i} className="mt-3"/></div>
              </>}

            value={i?.value}
          />

        </Col>)}

      </Row>
      : null}


  </>);

  // INTERNAL FUNCTIONS ========================================================
  // ===========================================================================

  function buildTranslations () {

    return {
      additional_info: translate(intl, 'additional_info'),
      device_not_installed: translate(intl, 'device_not_installed'),
      not_installed: translate(intl, 'n_a'),
      channel: translate(intl, 'channel'),
      last_reading: translate(intl, 'last_reading'),
      last_try: translate(intl, 'last_try'),
      quantity: translate(intl, 'quantity'),
      last_value: translate(intl, 'last_value'),
      temperature_last_24h: translate(intl, 'temperature_last_24h'),
    };
  }

  function buildLast24hChartOptions () {

    return {
      title: {
        margin: 16, // valor da margem inferior em pixels
      },
      colors: monitoring24h?.colors,
      legend: {
        show: false,
      },
      yaxis: {
        min: -10,
        max: 40,
        labels: {
          formatter: function (value) {
            return value + '°';
          },
        },
      },
      markers: {
        size: 6,
      },
      fill: {
        // type: 'gradient', // 'solid' / 'pattern' / 'image'
        // gradient: {
        //   enabled: true,
        //   opacityFrom: 0.55,
        //   opacityTo: 0
        // }
      }
    };
  }
};

export default IotDashboardTemperatures;
