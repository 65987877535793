import {SETTINGS_SEED} from './products-constants';
import {isMongoObjectId} from 'utils/helpers';
import {
  decorateDataType,
} from 'components/jarvisly-layouts/ModuleComponent/module-methods';
import moment from 'moment';


export function validateSearchFn(keyValueArr, resetSearch) {

  const [k, v] = keyValueArr;

  if (resetSearch || !v) return 'null';

  switch (k) {

    case 'documentCode':
      if (v === 'null') return 'null';
      return v;

    case 'name':
      if (v === 'null') return 'null';
      return v;

    case 'documentStatus': {

      const vArr = Array.isArray(v) ? v : v.split(',');
      const newV = [];

      if (vArr.length === 0) {
        return 'null';

      } else if (vArr[0] === null || vArr[0] === 'null') {
        return 'null';

      } else {
        if (vArr.includes('in_analise')) newV.push('in_analise');
        if (vArr.includes('with_reservation')) newV.push('with_reservation');
        if (vArr.includes('revised')) newV.push('revised');
      }

      if (newV.length === 0) return 'null';

      return newV;
    }

    case 'family':
    case 'ambience': {
      const vArr = Array.isArray(v) ? v : v.split(',');
      const newV = [];

      vArr.map(x => {
        if (isMongoObjectId(x)) newV.push(x);
        return x;
      });

      return newV.length > 0 ? newV : 'null';
    }

    default:
      return 'null';
  }
};

export function validateFilterFn(keyValueArr, defaultFilter) {

  const {dataProfile} = defaultFilter;

  const [k, v] = keyValueArr;

  switch (k) {

    case 'folder':
      return ['documents', 'archive', 'recycle'].includes(v) ? v : 'documents';

    case 'dataProfile':
      return dataProfile;

    default:
      return 'null';
  }

};

export function parseSettings(_module, _settings) {

  const body = {};

  // configuration
  // if (!_settings?.configurations) {
  //   body[`settings.${_module.name}.configurations`] = SETTINGS_SEED.configurations;
  // }

  // configuration dataType
  if (!_settings?.configurations?.dataTypes) {
    body[`settings.${_module.name}.configurations.dataTypes`] = SETTINGS_SEED.configurations.dataTypes;
  }

  // families
  if (!_settings?.families || _settings?.families.length === 0) {
    body[`settings.${_module.name}.families`] = SETTINGS_SEED.families;
  }

  // products
  if (!_settings?.products || _settings?.products.length === 0) {
    body[`settings.${_module.name}.products`] = SETTINGS_SEED.products;
  }

  // brands
  if (!_settings?.brands || _settings?.brands.length === 0) {
    body[`settings.${_module.name}.brands`] = SETTINGS_SEED.brands;
  }

  // units
  if (!_settings?.units || _settings?.units.length === 0) {
    body[`settings.${_module.name}.units`] = SETTINGS_SEED.units;
  }

  return body;
}

export function decorateSettings(options) {
  const {result} = options;
  return result;
}

export function decorateDocument(docs) {

  const accountId = localStorage.getItem('accountId');

  let isObject = false;

  if (!Array.isArray(docs)) {
    isObject = true;
    docs = [docs];
  }

  docs.map(doc => {

    doc?.discussions?.map(d => {
      d.from = d.authorId === accountId ? 'me' : 'opposite';
      return d;
    });

    if (doc?.lastInventory) doc.lastInventory = moment(doc.lastInventory);

    return doc;
  });

  return isObject ? docs[0] : docs;
}

export function decorateNewDocument(_settings, intl, _module) {
  let data = {};
  decorateDataType(_settings, data, _module);
  data.lastInventory = moment();
  return data;
}

export function removeDocumentValidation(_module, data, intl) {

  const _id = data?._id;

  if (!isMongoObjectId(_id)) {
    console.error(
        'Invalid _id! product-methods.js: removeDocumentValidation()');
    return 'abort';
  }

  return null;
}

export function dataProfileValidation(_module, body) {

  if (!body || !_module || !_module?.name) return;

  const dataProfile = _module?.initialFilter?.dataProfile;

  if (!body.dataProfile) body.dataProfile = [];

  if (!body.dataProfile.includes(dataProfile)) {
    body.dataProfile.push(dataProfile);
  }
};
