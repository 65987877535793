import React, { useRef, useState } from 'react'
import { Col, Form, Input, InputNumber, Modal, Row } from 'antd'
import { SlidersOutlined } from '@ant-design/icons'
import { onFormFinishFailed } from 'utils/helpers'
import IntlMessage from 'components/util-components/IntlMessage'
import { ROW_GUTTER } from 'constants/ThemeConstant'
import {
  postValueToSettings,
  putValuesInSettings,
} from 'components/jarvisly-layouts/ModuleComponent/module-api'
import { useFocus, useRefreshRecord } from 'components/jarvisly-layouts/ModuleComponent/module-hooks'

// GLOBAL VARIABLES ************************************************************
// *****************************************************************************

const setLocale = (isLocaleOn, localeKey) => isLocaleOn ?
  <IntlMessage id={localeKey}/> : localeKey.toString()

// MAIN COMPONENT **************************************************************
// *****************************************************************************

const ProfilesAdd = React.forwardRef((props, ref) => {

  // props deconstruction ------------------------------------------------------'
  const { showModal, setShowModal, record, _module, settingsId, onRefresh } = props

  // component states ----------------------------------------------------------
  const [loading, setLoading] = useState(false)

  // local variables ------------------------------------------------------------

  const [form] = Form.useForm()

  const elRefs = {
    name: useRef(null),
    m2: useRef(null),
    apartmentDigits: useRef(null),
  }

  const rules = {
    name: [
      {
        required: true, message: setLocale(true, 'required_field'),
      }],
    m2: [
      {
        required: true, message: setLocale(true, 'required'),
      }],
    apartmentDigits: [
      {
        required: true, message: setLocale(true, 'required'),
      }],
  }

  // hooks ---------------------------------------------------------------------
  useRefreshRecord(form, record)
  useFocus(showModal, elRefs?.name)

  // METHODS -------------------------------------------------------------------

  const onClose = () => {
    setShowModal(false)
    form.resetFields()
    setLoading(false)
  }

  const onFinishFailed = errorInfo => onFormFinishFailed(errorInfo, elRefs, setLoading)

  const onFinish = async values => {

    setLoading(true)

    let body = values

    body._id = record?._id

    const options = {
      settingsId,
      _module,

      body,
      valueId: record?._id,
      valueType: 'profiles',
    }

    try {

      let doc

      if (!record?._id) {
        doc = await postValueToSettings(options)

      } else {
        doc = await putValuesInSettings(options)
      }

      onRefresh(doc)
      onClose()

    } catch (error) {
      setLoading(false)
      console.error(error)
    }
  }

  // UI COMPONENT --------------------------------------------------------------

  return (<>


    <Modal
      title={<>
        <SlidersOutlined/>
        <span className="ml-2">
                    {setLocale(true, !record?._id ? 'new_profile' : 'edit_profile')}
                </span>
      </>}
      destroyOnClose={true}
      open={showModal}
      okText={setLocale(true, 'save')}
      onCancel={onClose}
      onOk={form.submit}
      getContainer={false}
      confirmLoading={loading}
    >

      <div className="p-4">


        <Form form={form}
              name="mainForm"
              className="vertical-form-min"
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              layout="vertical"
              disabled={loading}
              autoComplete="off">


          <Row gutter={ROW_GUTTER}>


            {/* NAME */}
            <Col span={12}>
              <Form.Item name="name" rules={rules.name}
                         label={setLocale(true, 'name')}>
                <Input ref={elRefs.name}/>
              </Form.Item>
            </Col>
            {/* NAME */}


            {/* M2 */}
            <Col span={6}>
              <Form.Item name="m2" rules={rules.m2}
                         label={setLocale(true, 'm2')}>
                <InputNumber ref={elRefs.m2}
                             formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                             parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                             style={{ width: '100%' }}
                             min={1}/>
              </Form.Item>
            </Col>
            {/* M2 */}


            {/* APARTMENT DIGITS */}
            <Col span={6}>
              <Form.Item name="apartmentDigits" rules={rules.apartmentDigits}
                         label={setLocale(true, 'format')}
                         tooltip={setLocale(true, 'apartment_digits_info')}>
                <InputNumber ref={elRefs.apartmentDigits} style={{ width: '100%' }} min={1}/>
              </Form.Item>
            </Col>
            {/* APARTMENT DIGITS */}

          </Row>


        </Form>

      </div>
    </Modal>

  </>)

})

// EXPORT **********************************************************************
// *****************************************************************************

export default ProfilesAdd



