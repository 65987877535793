import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react'
import { Col, Form, Row } from 'antd'
import { ROW_GUTTER } from 'constants/ThemeConstant'
import { buildPhone, msg, parseCityToDisplay, parseCnpjToDB } from 'utils/helpers'
import { returnOnlyNumbers } from 'jarvisly-helper'
import { useIntl } from 'react-intl'
import {
  FormZip,
  FormCnpj,
  FormEmail,
  FormInput,
  FormPhone,
  FormSaveButton,
  FormTitle,
  FormWebsite,
  FormWrapper, getAddressGPS,
} from 'views/app-views/portals/autaliza-info/FormComponets'
import { useRouter } from 'hooks/useRouter'
import { translate } from 'utils/react-jarvisly-helper'

// COMPONENT *******************************************************************
// *****************************************************************************

const OrdersPlansProfile = forwardRef((props, ref) => {

  // props deconstruction ------------------------------------------------------
  const {
    _id,
    _module,
    _data,
    dataContext,
    setDataContext,
    formContext,
    setFormContext,
  } = props

  // component states ----------------------------------------------------------
  const [, setSubmitted] = useState(false)
  const [address, setAddress] = useState(null)

  // local variables -----------------------------------------------------------

  const isDisabled = formContext.mode === 'view'
  const formName = 'profileForm'
  const [form] = Form.useForm()
  const intl = useIntl()
  const router = useRouter()
  const elRefs = buildElementsRefs(useRef)
  const fieldsToValidate = ['cnpj', 'phone', 'email', 'zip']
  // ant dataPicker workaround to fix issue about date
  // if (_data?.cnpj) _data.cnpj1 = parseCnpjToDisplay(_data.cnpj)
  // if (_data?.phone) _data.phone1 = parsePhoneToDisplay(_data.phone)
  // if (_data?.zip) _data.zip = parseZipToDisplay(_data.zip)
  if (_data?.city) _data.__city = parseCityToDisplay(_data?.city, _data?.province)

  // hooks ---------------------------------------------------------------------

  useEffect(() => {

    if (_data?._id) {
      form.resetFields()
      form?.setFieldsValue(_data)

      // save address state
      const addr = {
        title: _data?.title,
        zip: _data?.zip,
        address1: _data?.address1,
        address2: _data?.address2,
        number: _data?.number,
        neighborhood: _data?.neighborhood,
        city: _data?.city,
        __city: _data?.__city,
        province: _data?.province,
        addressReference: _data?.addressReference,
        addressGps: _data?.addressGps,
        addressAdditionalInfo: _data?.addressAdditionalInfo,
        addressMetadata: _data?.addressMetadata,
      }

      setAddress(addr)

    } else {

      form.resetFields()
      setAddress(null)

      // workaround because after reset form the tag refs be lost
      setTimeout(() => handleFocus(undefined, 'all'))
    }

    form.validateFields(fieldsToValidate).then(x => x).catch(e => e)
    setDataContext({ ...dataContext, formsChanged: [] })

  }, [_data, form, formContext?.refreshTime]) // eslint-disable-line react-hooks/exhaustive-deps

  // methods -------------------------------------------------------------------

  useImperativeHandle(ref, () => ({
    handleFocus: field => handleFocus(field),
  }))

  function handleFocus (field = 'cnpj', cursorPosition = 'all') {
    elRefs[field]?.current?.focus({ cursor: cursorPosition })
  }

  const onBeforeSave = async values => {

    setSubmitted(true)

    const body = { ...address, ...values }
    const phone = buildPhone(body?.phone)

    body.phoneDialCode = phone?.dialCode
    body.phone = phone?.number

    body._id = _data?._id
    body.cnpj = parseCnpjToDB(body?.cnpj)
    body.addressGps = await getAddressGPS(body, _data) || body?.addressGps

    body.dataProfile = 'plan'
    body.dataType = 'plan'
    body.uniqueKey = returnOnlyNumbers(values.cnpj)

    return body
  }

  const onAfterSave = async doc => {

    if (!doc) return

    if (_id === 'add') { // reload the page/module
      router.replace({ pathname: `${_module?.url}/${doc._id}/profile`, state: { isActive: true } })
      setFormContext({ ...formContext, mode: 'view' })

    } else {
      // force update the dataContext and refresh all page
      setFormContext({ ...formContext, refreshTime: +new Date(), mode: 'view' })
    }
  }

  const onError = error => {
    if (error?.status === 409) {
      msg('e', translate(intl, 'plan_already_exists'), 'error')
      handleFocus('room')
    }
  }

  const onAfterZipSearch = address => {

    setAddress(address)

    if (address) {

      form.setFieldsValue({
        address1: address.address1,
        neighborhood: address.neighborhood,
        __city: address.__city,
      })

      setTimeout(() => {

        if (address?.addressMetadata?.zipWithMultipleAddress) {
          elRefs?.address1?.current?.focus()

        } else {
          elRefs?.number?.current?.focus()
        }

      }, 50)

    } else {
      form.resetFields(['address1', 'neighborhood', '__city'])
      // setIsFormChanged(formDataChanges(form, record))
    }

  }

  // UI COMPONENT --------------------------------------------------------------

  return (

    <FormWrapper
      name={formName}
      form={form}
      elRefs={elRefs}
      uriApi={_module.api}
      initialValues={_data}
      dataContext={dataContext}
      setDataContext={setDataContext}
      formContext={formContext}
      setFormContext={setFormContext}
      onBeforeSave={onBeforeSave}
      onAfterSave={onAfterSave}
      onError={onError}
      showToast>


      {/* ************************************************************
      IDENTIFICATION
      ************************************************************ */}
      <FormTitle title={'identification'}/>


      <Row gutter={ROW_GUTTER}>

        {/* CNPJ */}
        <Col xs={24} sm={7}>
          <FormCnpj elRef={elRefs.cnpj}
                    disabled={isDisabled}
                    required
                    form={form}/>
        </Col>
        {/* CNPJ */}


        {/* PLAN NAME */}
        <Col xs={24} sm={10}>
          <FormInput name="fullName"
                     label="plan_name"
                     disabled={isDisabled}
                     required
                     elRef={elRefs.fullName}/>
        </Col>
        {/* PLAN NAME */}


        {/* TRADE NAME */}
        <Col xs={24} sm={7}>
          <FormInput name="name"
                     label="trade_name"
                     disabled={isDisabled}
                     required
                     elRef={elRefs.name}/>
        </Col>
        {/* TRADE NAME */}

      </Row>


      {/* ************************************************************
      CONTACTS
      ************************************************************ */}
      <FormTitle title={'contact'}/>

      <Row gutter={ROW_GUTTER}>


        {/* PHONE */}
        <Col xs={24} sm={8}>
          <FormPhone form={form}
                     disabled={isDisabled}
                     required
                     elRef={elRefs.phone}/>
        </Col>
        {/* PHONE */}


        {/* EMAIL */}
        <Col xs={24} sm={8}>
          <FormEmail
            form={form}
            disabled={isDisabled}
            required
            elRef={elRefs.email}/>
        </Col>
        {/* EMAIL */}


        {/* WEBSITE */}
        <Col xs={24} sm={8}>
          <FormWebsite
            form={form}
            disabled={isDisabled}
            elRef={elRefs.website}/>
        </Col>
        {/* WEBSITE */}


      </Row>


      {/* ************************************************************
      ADDRESS
      ************************************************************ */}
      <FormTitle title="address"/>
      <Row gutter={ROW_GUTTER}>

        <Col xs={6}>
          <FormZip form={form}
                   elRef={elRefs.zip}
                   afterSearch={onAfterZipSearch}
                   disabled={isDisabled}
                   required/>
        </Col>


        <Col xs={24} sm={13}>
          <FormInput name="address1"
                     label="address"
                     disabled={!address?.addressMetadata?.zipWithMultipleAddress || isDisabled}
                     elRef={elRefs.address1}
                     required={address?.addressMetadata?.zipWithMultipleAddress}/>
        </Col>


        <Col xs={24} sm={5}>
          <FormInput name="number"
                     elRef={elRefs.number}
                     disabled={isDisabled}
                     required/>
        </Col>

      </Row>


      <Row gutter={ROW_GUTTER}>

        <Col xs={24} sm={8}>
          <FormInput name="address2"
                     disabled={isDisabled}
                     elRef={elRefs.address2}/>
        </Col>


        <Col xs={24} sm={8}>
          <FormInput name="neighborhood"
                     disabled={!address?.addressMetadata?.zipWithMultipleAddress || isDisabled}
                     required={address?.addressMetadata?.zipWithMultipleAddress}
                     elRef={elRefs.neighborhood}/>
        </Col>

        <Col xs={24} sm={8}>
          <FormInput name="__city"
                     label="city"
                     disabled={true}
                     elRef={elRefs.city}/>
        </Col>

      </Row>

      <Row gutter={ROW_GUTTER}>

        <Col xs={24}>
          <FormInput name="addressReference"
                     label="reference_point"
                     disabled={isDisabled}
                     elRef={elRefs.addressReference}/>
        </Col>

      </Row>

      {/*
      <Row gutter={ROW_GUTTER}>

        <Col xs={24} style={{ height: 200, width: 200 }}>
          <SimpleMap lat={-22.9110238}
                     lng={-47.09600229999999}
                     height={200}
                     width={'100%'}
                     zoom={16}/>
        </Col>

      </Row>
*/}

      {/* ADDRESS */}


      {/* SAVE BUTTON */}
      <FormSaveButton loading={dataContext?.partialLoading}
                      form={form}
                      formName={formName}
                      dataContext={dataContext}
                      onlySave
                      setSubmitted={setSubmitted}
                      disabled={isDisabled}/>
      {/* SAVE BUTTON */}


    </FormWrapper>

  )

  // INTERNAL FUNCTIONS ========================================================
  // ===========================================================================

  function buildElementsRefs (hookUseRef) {
    return {

      // identification
      cnpj: hookUseRef(null),
      fullName: hookUseRef(null),
      name: hookUseRef(null),

      // contacts
      phone: hookUseRef(null),
      email: hookUseRef(null),
      website: hookUseRef(null),

      // address
      zip: hookUseRef(null),
      address1: hookUseRef(null),
      number: hookUseRef(null),
      address2: hookUseRef(null),
      neighborhood: hookUseRef(null),
      city: hookUseRef(null),
    }
  }

})

// EXPORT **********************************************************************
// *****************************************************************************

export default OrdersPlansProfile
