import React from 'react';
import IotList from './List/List';
import { Route } from 'react-router-dom';
import { defaultDecorateSettings } from 'components/jarvisly-layouts/ModuleComponent/module-methods';
import { IotDocumentHeaderColumn2, IotDocumentHeaderColumn3, IotDocumentSubtitle } from './Form/Header';
import { AlertOutlined, SisternodeOutlined } from '@ant-design/icons';
import IotProfile from './Form/sections/Profile';
import IotDashboard, { segmentedOptionsExported } from './List/Dashboard';
import IotAlarms from './Form/sections/Alarms';
import { THEME_CONFIG } from 'configs/AppConfig';

// GLOBAL VARIABLES ************************************************************
// *****************************************************************************

// ************************************************************************** //
// CONSTANTS
// ************************************************************************** //

const IOT_URL = '/app/records/iot';

const app = {
  sindicoerp: '/app/records/condominium/iot'
};

export const IOT_API_URL = '/v2/iot';

const INITIAL_LIST_STATE = {
  // view: 'dashboard',
  view: 'list',
  showNumberOfLine: localStorage.getItem('showNumberOfLine') === 'true',
  showCheckBox: localStorage.getItem('showCheckBox') === 'true',
};
const INITIAL_REQUEST = {

  // textSearch: {
  //   default: 'mONTE aLto',
  //   enum: ['a', 'b'],
  // },

  folder: {
    default: 'documents',
    enum: ['documents', 'recycle', 'filed'],
  },

  // filter: {
  //   default: {
  //     color: { $in: ['black', 'white'] },
  //     city: { $in: ['Campinas'] },
  //   },
  //   enum: {
  //     color: ['branco', 'azul', 'amarelo'],
  //     city: ['Campinas', 'Itu'],
  //   },
  // },

  search: {},
  //   default: {
  //     age: 43,
  //     roomNumber: { $in: [11, 12, 43] },
  //     name: 'Ane Cardinalli',
  //   },
  //   enum: {
  //     age: [10, 30, 40],
  //     roomNumber: [22, 33, 54, 82],
  //   },
  // },

  sorter: {
    // roomNumber: 1,
    // blockId:-1
  },

  // project: {
  //   room: 1,
  //   blockId: 1,
  // },

  pagination: {
    pageNumber: 1,
    // pageSize: 10
    pageSize: ['10', '20', '50', '100'].includes(localStorage.getItem('pageSize'))
      ? Number(localStorage.getItem('pageSize'))
      : 10,
  },
};

const INITIAL_CONTEXT = {
  hasPicture: true,
  disablePicture: true,
  mode: 'nope',

  selectedSection: null,
  sectionsChanged: [],
  sectionSettingsComponent: null,
};

const METHODS = {
  // parseSettings: parseSettings,
  decorateSettings: defaultDecorateSettings,

  // decorateDocument: decorateDocument,
  // decorateNewDocument: decorateNewDocument,

  // removeDocumentValidation: removeDocumentValidation,
  // postDocumentValidation: defaultDataProfileValidation,
  // putDocumentValidation: defaultDataProfileValidation,
  // refreshParameters: getDeviceModuleParameters,
};

const COMPONENTS = {
  list: props => <IotList {...props}/>,
  dashboard: props => <IotDashboard {...props}/>,
  // listPreviewHeader: props => <IotBodyPreviewHeader {...props}/>,
  // listPreviewBody: props => <IotBodyPreviewData {...props}/>,
  // listPreviewActions: props => <IotBodyPreviewActions {...props}/>,

  documentSubtitle: props => <IotDocumentSubtitle {...props}/>,
  documentHeaderColumn2: props => <IotDocumentHeaderColumn2 {...props}/>,
  documentHeaderColumn3: props => <IotDocumentHeaderColumn3 {...props}/>,
};

const DOCUMENT_SECTIONS = [
  {
    key: 'profile',
    icon: <SisternodeOutlined/>,
    label: 'device',
    route: (props, ref, _id) => <Route key="profile"
                                       path={`${IOT_URL}/${_id}/profile`}
                                       render={() =>
                                         <IotProfile ref={ref} {...props}
                                                     _data={props._dataRoots}
                                                     _dataLock={props._dataRootsLock}/>}/>,
  },
  {
    key: 'alarms',
    icon: <AlertOutlined/>,
    label: 'alarms',
    route: (props, ref, _id) => <Route key="alarms"
                                       path={`${IOT_URL}/${_id}/alarms`}
                                       render={() =>
                                         <IotAlarms ref={ref} {...props}
                                                    _data={props._dataRoots}
                                                    _dataLock={props._dataRootsLock}/>}/>,
  },
];

const REQUIRED_FIELDS = [

  {
    key: 'uniqueKey',
    label: 'unique_key',
    checked: true,
    disabled: false,
  },
];

// ************************************************************************** //
// SEEDS
// ************************************************************************** //

const SETTINGS_SEED = {
  requiredFields: [
    'uniqueKey',
  ],
};

const DATA_AUTO_FILL = {
  // _id: '64545ad53705897234575b25',
  _metadata: {
    subscriptionId: '6438468d8a3d250f91cbb1fa',
    documentStatus: 0,
    hidden: false,
    system: false,
    audit: {
      createdByAccountId: '64187cb6acdb6850dac00457',
      createdOnClient: {
        userAgent: 'Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/112.0.0.0 Safari/537.36',
        acceptLanguage: 'en-US,en;q=0.9,pt;q=0.8',
      },
      updatedAt: '2023-05-05T01:24:37.572Z',
    },
    i18n: {
      countryCode: 'br',
      timezoneOffset: -180,
      languageCode: 'pt-br',
      localeCode: 'br',
    },
    documentCode: '575b25',
    counter: 999,
    origin: 0,
    __documentCode: '999.575b25',
    __origin: 'manual',
    __documentStatus: 'in_analise',
  },
  name: 'Caixa D\'água CV',
  description: 'Localizado no Topo da Torre Azul',
  backColor: '#cf1322',
  foreColor: '#ffffff',
  channel: '121290',
  service: 'thingspeak',
  device: 'water_level_sensor',
  width: 3,
  height: 6,
  depth: 4,
  feeds: [],
  __v: 0,
};

// ************************************************************************** //
// MODULES PARAMETERS
// ************************************************************************** //

export const MODULE_IOT_PARAMETERS = {

  root: 'iot',
  name: 'iot',
  singular: 'device',
  titleField: 'title',
  url: app?.[THEME_CONFIG.APP.ID] || IOT_URL,
  api: IOT_API_URL,
  initialRequest: INITIAL_REQUEST,
  initialListState: INITIAL_LIST_STATE,

  hasDashboard: true,
  hasFilter: true,
  hasSettings: true,
  hasAccessControl: true,
  hasConfigurations: true,
  hasDataMemory: true,
  hasRequiredFields: false,

  dashboardViewsSegmentsOptions: segmentedOptionsExported,

  documentActions: {
    archive: false,
    duplicate: false,
  },

  methods: METHODS,
  components: COMPONENTS,

  // initialSearch: INITIAL_SEARCH,
  requiredFields: REQUIRED_FIELDS,
  initialContext: INITIAL_CONTEXT,
  // initialFilter: INITIAL_FILTER,
  // initialSort: INITIAL_SORT,

  labels: {
    new_document_title: 'new_device',
  },

  documentSections: DOCUMENT_SECTIONS,

  seeds: {
    settings: SETTINGS_SEED,
    data: DATA_AUTO_FILL,
  },

};


