import React, { useEffect, useState } from "react";
import { getIotDevices } from "../api";
import IotDashboardTemperatures from "./dashboards/Temperature";
import { buildDevicesOptions } from "../methods";
import { clone } from "jarvisly-helper";
import { connect } from "react-redux";
import IotDashboardWaterLevel from "./dashboards/WaterLevel";
import { onProduction } from "utils/helpers";
import { notification } from "antd";

const oneMinute = 60000;
const intervalRefresh = 5000;
// const intervalRefresh = !onProduction() ? 5000 : oneMinute
// const intervalRefresh = !onProduction() ? 1000000 : oneMinute

const criticalAudio = new Audio();
criticalAudio.src = "https://www.soundjay.com/clock/alarm-clock-01.mp3";

const alertAudio = new Audio();
alertAudio.src = "https://www.soundjay.com/bathroom/bathroom-faucet-1.mp3";

const IotDashboard = (props) => {
  // props deconstruction ------------------------------------------------------
  const { rdxModuleList, rdxSetModuleList, rdxModuleDashboard } = props;

  // local variables -----------------------------------------------------------

  // component states ----------------------------------------------------------
  const [devices, setDevices] = useState(null);
  const [monitoring24h, setMonitoring24h] = useState(null);
  const [working, setWorking] = useState(false);

  // let w = false;
  // hooks ---------------------------------------------------------------------
  useEffect(() => {
    if (!devices || !monitoring24h) {
      (async () => await refreshData(true))();
      return;
    }

    const intervalId = setInterval(async () => {
      await refreshData();
    }, intervalRefresh);

    return () => intervalId && clearInterval(intervalId);
  }, [rdxModuleList?.loading]); // eslint-disable-line react-hooks/exhaustive-deps

  // methods -------------------------------------------------------------------
  const refreshData = (force) => {
    if (working) return;

    return new Promise(async (resolve) => {
      setWorking(true);

      if (force) return $refresh(force);
      if (working) return resolve();

      setTimeout(async () => {
        await $refresh();
        resolve();
      }, intervalRefresh * 2);

      async function $refresh(showLoading) {
        if (showLoading) {
          rdxSetModuleList({
            ...rdxModuleList,
            loading: true,
          });
        }

        const iot = await getIotDevices();

        let devicesState = {
          water_level_sensor:
            (iot?.water_level_sensor?.devices &&
              clone(iot?.water_level_sensor?.devices)) ||
            [],
          temperature_sensor:
            (iot?.temperature_sensor?.devices &&
              clone(iot?.temperature_sensor?.devices)) ||
            [],
          humidity_sensor:
            (iot?.humidity_sensor?.devices &&
              clone(iot?.humidity_sensor?.devices)) ||
            [],
          distance_sensor:
            (iot?.distance_sensor?.devices &&
              clone(iot?.distance_sensor?.devices)) ||
            [],
          switch_device:
            (iot?.switch_device?.devices &&
              clone(iot?.switch_device?.devices)) ||
            [],
        };

        let monitoring24hState = {
          water_level_sensor:
            (iot?.water_level_sensor?.last24h &&
              clone(iot?.water_level_sensor?.last24h)) ||
            [],
          temperature_sensor:
            (iot?.temperature_sensor?.last24h &&
              clone(iot?.temperature_sensor?.last24h)) ||
            [],
          humidity_sensor:
            (iot?.humidity_sensor?.last24h &&
              clone(iot?.humidity_sensor?.last24h)) ||
            [],
          distance_sensor:
            (iot?.distance_sensor?.last24h &&
              clone(iot?.distance_sensor?.last24h)) ||
            [],
          switch_device:
            (iot?.switch_device?.last24h &&
              clone(iot?.switch_device?.last24h)) ||
            [],
        };

        validateAlarm(devicesState);

        if (devicesState) setWorking(false);
        setDevices({ ...devicesState });
        setMonitoring24h({ ...monitoring24hState });

        if (showLoading) {
          rdxSetModuleList({
            ...rdxModuleList,
            loading: false,
          });
        }

        resolve();
      }
    });
  };

  // UI COMPONENT --------------------------------------------------------------
  return (
    <>
      {/* ALL SENSORS */}
      {rdxModuleDashboard?.panel === "all_sensors" && <div>TODOS SENSORES</div>}
      {/* ALL SENSORS */}

      {/* WATER LEVEL SENSOR */}
      {(!rdxModuleDashboard?.panel ||
        rdxModuleDashboard?.panel === "water_level_sensor") && (
        <IotDashboardWaterLevel
          rdxModuleList={rdxModuleList}
          devices={devices?.water_level_sensor}
          monitoring24h={monitoring24h?.water_level_sensor}
        />
      )}
      {/* WATER LEVEL SENSOR */}

      {/* TEMPERATURE */}
      {rdxModuleDashboard?.panel === "temperature_sensor" && (
        <IotDashboardTemperatures
          rdxModuleList={rdxModuleList}
          devices={devices?.temperature_sensor}
          monitoring24h={monitoring24h?.temperature_sensor}
        />
      )}
      {/* TEMPERATURE */}

      {/* HUMIDITY */}
      {rdxModuleDashboard?.panel === "humidity_sensor" && <div>UMIDADE</div>}
      {/* HUMIDITY */}

      {/* DISTANCE */}
      {rdxModuleDashboard?.panel === "distance_sensor" && <div>DISTÂNCIA</div>}
      {/* DISTANCE */}

      {/* POWER */}
      {rdxModuleDashboard?.panel === "power_sensor" && (
        <div>CONSUMO DE ENERGIA</div>
      )}
      {/* POWER */}

      {/* WATER FLOW */}
      {rdxModuleDashboard?.panel === "water_flow_sensor" && (
        <div>CONSUMO DE ÁGUA</div>
      )}
      {/* WATER FLOW */}

      {/* SWITCH */}
      {rdxModuleDashboard?.panel === "switch_device" && <div>INTERRUPTOR</div>}
      {/* SWITCH */}
    </>
  );

  // INTERNAL FUNCTIONS ========================================================
  // ===========================================================================

  function validateAlarm(devices) {
    // water level alarms
    devices?.water_level_sensor?.map((i, idx) => {
      // CRITICAL SHORTAGE
      if (i?.criticalShortage && i.criticalShortage >= i.value) {
        // criticalAudio.play();

        notification.error({
          key: idx,
          message: "Estado Crítico!",
          description: `Sua caixa '${i.title}' (${i.subtitle}) esta abaixo dos ${i.criticalShortage}%. Em breve você ficará sem água.`,
          duration: 0,
          onClose: () => {
            criticalAudio.pause();
            alertAudio.pause();
          },
        });
      } else if (i?.shortageAlert && i.shortageAlert >= i.value) {
        alertAudio.play();

        notification.warn({
          key: idx,
          message: "Alerta de Escassez!",
          description: `Sua caixa '${i.title}' (${i.subtitle}) esta abaixo dos ${i.criticalShortage}%. Necessita da sua atenção urgentemente.`,
          duration: 0,
          onClose: () => {
            criticalAudio.pause();
            alertAudio.pause();
          },
        });
      }

      // CRITICAL SHORTAGE
      if (i?.criticalEvasion && i.criticalEvasion <= i.value) {
        // criticalAudio.play();

        notification.error({
          key: idx,
          message: "Estado Crítico!",
          description: `Sua caixa '${i.title}' (${i.subtitle}) esta acima dos ${i.criticalEvasion}% e começará a perder água pelo 'ladrão'.`,
          duration: 0,
          onClose: () => {
            criticalAudio.pause();
            alertAudio.pause();
          },
        });
      } else if (i?.evasionAlert && i.evasionAlert <= i.value) {
        alertAudio.play();

        notification.warn({
          key: idx,
          message: "Alerta de Evasão!",
          description: `Sua caixa '${i.title}' (${i.subtitle}) esta acima dos ${i.evasionAlert}%. Necessita da sua atenção urgentemente.`,
          duration: 0,
          onClose: () => {
            criticalAudio.pause();
            alertAudio.pause();
          },
        });
      }
    });
  }
};

export const segmentedOptionsExported = buildDevicesOptions(true);

const mapStateToProps = ({ moduleRedux }) => {
  const { rdxModuleDashboard } = moduleRedux;
  return { rdxModuleDashboard };
};

export default connect(mapStateToProps)(IotDashboard);
