import moment from 'moment';
import {clone, isMongoObjectId, modal, substitution} from 'utils/helpers';
import {translate} from 'utils/react-jarvisly-helper';
import {
    decorateDataType,
} from 'components/jarvisly-layouts/ModuleComponent/module-methods';
import {capitalize} from 'jarvisly-helper';
import {getApartments} from "../../apartments/api";

export function validateSearchFn(keyValueArr, resetSearch) {

    const [k, v] = keyValueArr;

    if (resetSearch || !v) return 'null';

    switch (k) {

        case 'documentCode':
            if (v === 'null') return 'null';
            return v;

        case 'name':
            if (v === 'null') return 'null';
            return v;

        case 'documentStatus':

            const vArr = Array.isArray(v) ? v : v.split(',');
            const newV = [];

            if (vArr.length === 0) {
                return 'null';

            } else if (vArr[0] === null || vArr[0] === 'null') {
                return 'null';
                // newV.push('in_analise', 'with_reservation', 'revised');

            } else {
                if (vArr.includes('in_analise')) newV.push('in_analise');
                if (vArr.includes('with_reservation')) newV.push('with_reservation');
                if (vArr.includes('revised')) newV.push('revised');
            }

            if (newV.length === 0) return 'null';
            /*
                  if (newV.length === 0) {
                    newV.push('in_analise', 'with_reservation', 'revised');
                  }
            */

            return newV;

        default:
            return 'null';
    }
};

export function validateFilterFn(keyValueArr, defaultFilter) {

    const {dataProfile} = defaultFilter;

    const [k, v] = keyValueArr;

    switch (k) {

        case 'folder':
            return ['documents', 'archive', 'recycle'].includes(v) ? v : 'documents';

        case 'dataProfile':
            return dataProfile;

        default:
            return 'null';
    }

}

/*export function parseSettings(_module, _settings) {

  const body = {};

  // dataType
  if (!_settings?.configurations?.dataTypes) {
    body[`settings.${_module.name}.configurations.dataTypes`] = SETTINGS_SEED.configurations.dataTypes;
  }

  // dataMemory
  if (_settings?.configurations?.dataMemory === 'undefined') {
    body[`settings.${_module.name}.configurations.dataMemory`] = SETTINGS_SEED.configurations.dataMemory;
  }

  // required fields
  if (!_settings?.requiredFields || _settings?.requiredFields.length === 0) {
    body[`settings.${_module.name}.requiredFields`] = SETTINGS_SEED.requiredFields;
  }

  return body;
}*/

export function decorateDocument(docs) {

    const accountId = localStorage.getItem('accountId');

    let isObject = false;

    if (!Array.isArray(docs)) {
        isObject = true;
        docs = [docs];
    }

    docs = clone(docs)

    docs.map(doc => {
        if (doc?.name) doc.name = capitalize(doc.name);

        doc?.discussions?.map(d => {
            d.from = d.authorId === accountId ? 'me' : 'opposite';
            return d;
        });

        if (doc?.birthday) doc.birthday = moment(doc.birthday);

        return doc;
    });

    return isObject ? docs[0] : docs;
}

export function decorateNewDocument(_settings, _module) {
    let data = {};
    decorateDataType(_settings, data, _module);
    return data;
}

export function removeDocumentValidation(_module, data, intl) {

    const _id = data?._id;
    const dataProfile = data?.dataProfile;
    const profile = _module?.initialFilter?.dataProfile;

    if (!dataProfile) {
        console.error('Invalid dataProfile! removeAtualiza()');
        return 'abort';

    } else if (!profile) {
        console.error('Invalid profile module! removeAtualiza()');
        return 'abort';

    } else if (!isMongoObjectId(_id)) {
        console.error('Invalid _id! removeAtualiza()');
        return 'abort';
    }

    if (dataProfile.length > 1) {

        const idx = dataProfile.findIndex(x => x === profile);
        dataProfile.splice(idx, 1);

        const profile1 = translate(intl, profile);
        const profile2 = translate(intl, dataProfile[0]);
        const description = translate(intl, 'remove_atualiza_description');
        const recycle = `${translate(intl, 'recycle')}!`;

        const descriptionTranslated = substitution(description,
            ['%PROFILE1%', '%PROFILE2%'], [profile1, profile2]);

        const options = {
            type: 'info',
            title: recycle,
            message: descriptionTranslated,
            onOk: async () => dataProfile,
        };

        modal(options);

        return {dataProfile: dataProfile};

    } else {
        return null;
    }
}

export function dataProfileValidation(_module, body) {

    if (!body || !_module || !_module?.name) return;

    const dataProfile = _module?.initialFilter?.dataProfile;

    if (!body.uniqueKey) body.uniquekey = body.name;

    if (!body.dataProfile) body.dataProfile = [];

    if (!body.dataProfile.includes(dataProfile)) {
        body.dataProfile.push(dataProfile);
    }
};

export const getModuleParameters = async (_module, rdxSubscription) => {

    if (!_module?.name || !rdxSubscription?._id) return
    if (!rdxSubscription?.parameters) rdxSubscription.parameters = {}

    rdxSubscription.parameters['apartments'] = await getApartments()
    return rdxSubscription.parameters
}


