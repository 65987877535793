import React, { useEffect, useRef, useState } from "react";
import { Button, Table, Tooltip } from "antd";
import IntlMessage from "components/util-components/IntlMessage";
import { EditOutlined, EyeOutlined } from "@ant-design/icons";
import { buildTableColumnSearch } from "components/jarvisly-layouts/ModuleComponent/module-methods";
import { useSelector } from "react-redux";
import RoundedAvatar from "../../../components/jarvisly/RoundedAvatar";
import DocumentStatusTag from "../../../components/jarvisly-layouts/ModuleComponent/Components/DocumentStatusTag";
import { MdOutlineStorefront, MdPersonOutline } from "react-icons/md";
import { FaDatabase, FaHdd, FaKeyboard } from "react-icons/fa";

// GLOBAL VARIABLES ************************************************************
// *****************************************************************************

const setLocale = (isLocaleOn, localeKey) =>
  isLocaleOn ? <IntlMessage id={localeKey} /> : localeKey.toString();

// COMPONENT *******************************************************************
// *****************************************************************************

const ResidentsMainList = (props) => {
  // props deconstruction ------------------------------------------------------

  const {
    search,

    _module,
    _data,
    _summary,
    _loading,

    rdxModuleQuery,

    onChange,
    onClickOpen,
    onClickPreview,
  } = props;

  // component states (p.s. need by before 'local variables') -------------------
  const [state, setState] = useState({});

  // local variables -----------------------------------------------------------

  const rdxModuleSettings = useSelector((s) => s.moduleRedux.rdxModuleSettings);
  // const searchInputRef = useRef(null)
  // const { useBreakpoint } = Grid
  // const sm = utils.getBreakPoint(useBreakpoint()).includes('sm')
  // const md = utils.getBreakPoint(useBreakpoint()).includes('md')
  const documentCodeRef = useRef();
  const nameRef = useRef();
  const tableColumns = buildTableColumns();

  // hooks ---------------------------------------------------------------------

  useEffect(() => {
    setState({
      ...state,
      blocks: rdxModuleSettings?.residents?.blocks || [],
    });
  }, [rdxModuleSettings]); // eslint-disable-line react-hooks/exhaustive-deps

  // METHODS -------------------------------------------------------------------

  // UI COMPONENT --------------------------------------------------------------

  return (
    <>
      {/*<Input ref={searchRef}/>*/}

      <Table
        rowKey="_id"
        pagination={_summary?.pagination}
        columns={tableColumns.filter((x) => !x.hidden)}
        dataSource={_data}
        onChange={onChange}
        loading={_loading}
      />
    </>
  );

  // INTERNAL FUNCTIONS ========================================================
  // ===========================================================================

  function buildTableColumns() {
    return [
      {
        hidden: !rdxModuleSettings?.showNumberOfLine,
        title: setLocale(true, "line_#"),
        width: 80,
        render: (item, record, index) => (
          <div className="text-left">
            {_summary?.pagination?.current * _summary?.pagination?.pageSize -
              _summary?.pagination?.pageSize +
              index +
              1}
          </div>
        ),
      },

      {
        title: setLocale(true, "document"),
        dataIndex: "documentCode", // dataIndex: '_metadata.documentCode',
        filteredValue:
          search?.documentCode && search.documentCode !== "null"
            ? search?.documentCode
            : null,
        ...buildTableColumnSearch("documentCode", documentCodeRef),
        width: 140,
        render: (_, record) => (
          <span className="text-uppercase text-muted">
            {record?._metadata?.__documentCode}
          </span>
        ),
        sorter: true, // desabilitado temporariamente até consertarem o bug: 4.20.1 (https://github.com/ant-design/ant-design/issues/38392)
        sortDirections: ["ascend", "descend", "ascend"],
        sortOrder:
          rdxModuleQuery?.currentQuery?.sortField === "documentCode" &&
          rdxModuleQuery?.currentQuery?.sortOrder,
      },

      {
        title: setLocale(true, _module?.singular || "na"), // title: FilterByNameInput,
        dataIndex: "name",
        filteredValue:
          search?.name && search.name !== "null" ? search?.name : null,
        ...buildTableColumnSearch("name", nameRef),
        shouldCellUpdate: () => false,
        className: "text-truncate", // ellipsis: true,
        render: (_, record) => (
          <div className="d-flex" style={{ width: 200 }}>
            <RoundedAvatar
              user={record}
              showName
              showCpfCnpj
              showAge
              upperCase
            />
          </div>
        ),
        sorter: true, // desabilitado temporariamente até consertarem o bug: 4.20.1 (https://github.com/ant-design/ant-design/issues/38392)
        sortDirections: ["ascend", "descend", "ascend"],
        sortOrder:
          rdxModuleQuery?.currentQuery?.sortField === "name" &&
          rdxModuleQuery?.currentQuery?.sortOrder,
      },

      // {
      //   title: setLocale(true, 'locale'),
      //   dataIndex: 'city',
      //   render: (_, record) => (<>
      //     {record?.mainAddress?.city && <span>
      //         {record?.mainAddress?.city}, {record?.mainAddress?.province}
      //       </span>}
      //   </>),
      // },

      {
        title: setLocale(true, "status"),
        dataIndex: "documentStatus",
        filteredValue:
          search?.documentStatus && search.documentStatus !== "null"
            ? search?.documentStatus
            : null, // onFilter: (value, record) => record.record?._metadata?.__documentStatus?.includes(value),
        // filteredValue: documentStatusSearch,
        // filteredValue: ['with_reservation', 'revised'],
        width: 100,
        render: (_, record) => <DocumentStatusTag record={record} />,
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
        sortOrder:
          rdxModuleQuery?.currentQuery?.sortField === "documentStatus" &&
          rdxModuleQuery?.currentQuery?.sortOrder,
        filters: [
          {
            text: setLocale(true, "in_analise"),
            value: "in_analise",
          },
          {
            text: setLocale(true, "with_reservation"),
            value: "with_reservation",
          },
          {
            text: setLocale(true, "revised"),
            value: "revised",
          },
        ],
      },

      {
        width: 50,
        render: (_, record) => (
          <div className="text-right">
            <div className="pt-2">
              {record?.dataType === "company" ? (
                <div>
                  <Tooltip
                    placement="left"
                    title={setLocale(true, "legal_person")}
                  >
                    <MdOutlineStorefront />
                  </Tooltip>
                </div>
              ) : (
                <div>
                  <Tooltip
                    placement="left"
                    title={setLocale(true, "individual_person")}
                  >
                    <MdPersonOutline />
                  </Tooltip>
                </div>
              )}
            </div>

            <div>
              {record?._metadata?.origin === "2" ? (
                <div>
                  <Tooltip
                    placement="left"
                    title={setLocale(true, "automatic_registration")}
                  >
                    <FaHdd />
                  </Tooltip>
                </div>
              ) : record?._metadata?.origin === "1" ? (
                <div>
                  <Tooltip
                    placement="left"
                    title={setLocale(true, "imported_registration")}
                  >
                    <FaDatabase />
                  </Tooltip>
                </div>
              ) : (
                <div>
                  <Tooltip
                    placement="left"
                    title={setLocale(true, "manual_registration")}
                  >
                    <FaKeyboard />
                  </Tooltip>
                </div>
              )}
            </div>
          </div>
        ),
      },

      {
        title: setLocale(true, "actions"),
        dataIndex: "actions",
        width: 50,
        render: (_, record) => (
          <div className="text-right d-flex justify-content-end">
            <Tooltip title={setLocale(true, "edit")}>
              <Button
                className="mr-2"
                icon={<EditOutlined />}
                onClick={() => onClickOpen(record)}
                size="small"
              />
            </Tooltip>

            <Tooltip title={setLocale(true, "view")}>
              <Button
                className="mr-2"
                icon={<EyeOutlined />}
                onClick={() => onClickPreview(record)}
                size="small"
              />
            </Tooltip>
          </div>
        ),
      },
    ];
  }
};

// EXPORT **********************************************************************
// *****************************************************************************

export default React.memo(ResidentsMainList);
