import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react'
import { Col, Form, Row } from 'antd'
import { useIntl } from 'react-intl'
import { translate } from 'utils/react-jarvisly-helper'
import { ROW_GUTTER } from 'constants/ThemeConstant'
import IntlMessage from 'components/util-components/IntlMessage'
import { onFormFinishFailed } from 'utils/helpers'
import { formDataChanges, FormInput, FormPlaca, FormSelect, FormTitle } from '../../../FormComponets'

const VehicleModalRegistrationTab = forwardRef((props, ref) => {

  // props deconstruction ------------------------------------------------------

  const {
    record,
    onSave,
    setIsFormChanged,
    _dataRoots,
  } = props

  // component states ----------------------------------------------------------
  const [validating, setValidating] = useState(false)
  const [plateFound, setPlateFound] = useState()
  const [brandOptions, setBrandOptions] = useState([])

  // local variables -----------------------------------------------------------

  const [form] = Form.useForm()
  const intl = useIntl()
  const elRefs = buildElementsRefs(useRef)
  const elRules = buildElementsRules()
  const typeOptions = buildTypeOptions()
  const colorOptions = buildColorOptions()

  // hooks ---------------------------------------------------------------------

  useEffect(() => {
    refreshBrands()
    handleFocus()
  }, [record]) // eslint-disable-line react-hooks/exhaustive-deps

  // METHODS -------------------------------------------------------------------

  function handleFocus (field = 'plate', cursorPosition = 'start') {
    elRefs[field]?.current?.focus({ cursor: cursorPosition })
  }

  useImperativeHandle(ref, () => ({
    onSubmit: field => form.submit(),
  }))

  const onPlateValidating = value => setValidating(value)

  const onPlateSearched = result => {
    if (result) setPlateFound(result)
  }

  function refreshBrands (value = record?.type) {
    const brands = value === 'motorcycle'
      ? buildMotorcycleBrandOptions()
      : buildCarBrandOptions()

    if (brands.findIndex(b => b.value === form.getFieldValue('brand')) === -1) {
      form.setFieldValue('brand', '')
    }

    setBrandOptions(brands)
  }

  const onValuesChanges = () => {
    setIsFormChanged(formDataChanges(form, record))
  }

  const onFinishFailed = errorInfo => onFormFinishFailed(errorInfo, elRefs)

  const onFinish = values => {
    values.plateFromApi = plateFound
    onSave && onSave(values)
  }

  // UI COMPONENT --------------------------------------------------------------

  return (<>


    <Form form={form}
          layout="vertical"
          initialValues={record}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          onValuesChange={onValuesChanges}
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
          preserve={false}>


      {/* BASIC INFO */}
      <FormTitle title={'vehicle_data'}/>


      <Row gutter={ROW_GUTTER}>

        {/* PLATE */}
        <Col xs={24} md={8}>
          <FormPlaca form={form}
                     elRef={elRefs.plate}
                     onValidating={onPlateValidating}
                     onSearched={onPlateSearched}
                     rules={elRules.plate}
                     disabled={_dataRoots?.updateSentAt}
                     required/>
        </Col>
        {/* PLATE */}

        {/* TYPE */}
        <Col xs={24} md={8}>
          <FormSelect name="type"
                      elRef={elRefs.type}
                      options={typeOptions}
                      onChange={refreshBrands}
                      disabled={validating || plateFound || _dataRoots?.updateSentAt}
                      required/>
        </Col>
        {/* TYPE */}

        {/* YEAR */}
        <Col xs={24} md={8}>
          <FormInput name="year"
                     elRef={elRefs.year}
                     rules={elRules.year}
                     maxLength={4}
                     disabled={validating || plateFound || _dataRoots?.updateSentAt}
                     required/>
        </Col>
        {/* YEAR */}


      </Row>


      <Row gutter={ROW_GUTTER}>

        {/* BRAND */}
        <Col xs={24} md={8}>
          <FormSelect name="brand"
                      elRef={elRefs.brand}
                      options={brandOptions}
                      disabled={validating || plateFound || _dataRoots?.updateSentAt}
                      required/>
        </Col>
        {/* BRAND */}

        {/* MODEL */}
        <Col xs={24} md={8}>
          <FormInput name="model"
                     elRef={elRefs.model}
                     disabled={validating || plateFound || _dataRoots?.updateSentAt}
                     required/>
        </Col>
        {/* MODEL */}


        {/* COLOR */}
        <Col xs={24} md={8}>
          <FormSelect name="color"
                      elRef={elRefs.color}
                      options={colorOptions}
                      disabled={validating || plateFound || _dataRoots?.updateSentAt}
                      required/>
        </Col>
        {/* COLOR */}


      </Row>



      <Row gutter={ROW_GUTTER}>

        {/* NOTE */}
        <Col xs={24}>
          <FormInput name="note"
                     label="observation"
                     disabled={_dataRoots?.updateSentAt}
                     elRef={elRefs.note}/>
        </Col>
        {/* NOTE */}

      </Row>



    </Form>


  </>)

  // INTERNAL FUNCTIONS ========================================================
  // ===========================================================================

  function buildColorOptions () {

    const colorsList = [
      'beige',
      'black',
      'blue',
      'bronze',
      'brown',
      'burgundy',
      'caramel',
      'champagne',
      'gray',
      'green',
      'golden',
      'orange',
      'pink',
      'purple',
      'red',
      'silver',
      'turquoise',
      'white',
      'wine',
      'yellow',
    ]

    const colors = colorsList.map((color) => {
      return {
        value: color,
        text: translate(intl, color),
        label: translate(intl, color),
        disabled: false,
      }
    })

    colors.push({
      value: 'not_listed',
      text: translate(intl, 'not_listed'),
      label: translate(intl, 'not_listed').toUpperCase(),
      disabled: false,
    })

    return colors
  }

  function buildMotorcycleBrandOptions () {

    const motorcycleBrands = [
      'Honda',
      'Yamaha',
      'Suzuki',
      'Dafra',
      'BMW',
      'Harley Davidson',
      'Kawasaki',
    ]

    const motorcycles = motorcycleBrands.map((motorcycle) => {
      return {
        value: motorcycle.toLowerCase().replace(' ', '_'),
        text: motorcycle,
        label: motorcycle,
        disabled: false,
      }
    })

    motorcycles.push({
      value: 'not_listed',
      text: translate(intl, 'not_listed'),
      label: translate(intl, 'not_listed').toUpperCase(),
      disabled: false,
    })

    return motorcycles
  }

  function buildCarBrandOptions () {

    const carBrands = [
      'Audi',
      'BMW',
      'Chery',
      'Chevrolet',
      'Chrysler',
      'Citroen',
      'Dodge',
      'Fiat',
      'Ford',
      'GMC',
      'Honda',
      'Hyundai',
      'Infiniti',
      'Jac Motors',
      'Jaguar',
      'Jeep',
      'Kia',
      'Land Rover',
      'Lexus',
      'Lifan',
      'Mercedes Benz',
      'Mini',
      'Mitsubishi',
      'Nissan',
      'Peugeot',
      'Porsche',
      'Ram',
      'Renault',
      'Rolls Royce',
      'Subaru',
      'Suzuki',
      'Troller',
      'Toyota',
      'Volkswagen',
      'Volvo',
    ]

    const cars = carBrands.map((car) => {
      return {
        value: car.toLowerCase().replace(' ', '_'),
        text: car,
        label: car,
        disabled: false,
      }
    })

    cars.push({
      value: 'not_listed',
      text: translate(intl, 'not_listed'),
      label: translate(intl, 'not_listed').toUpperCase(),
      disabled: false,
    })

    return cars
  }

  function buildTypeOptions () {

    const t = {
      car: translate(intl, 'car'),
      motorcycle: translate(intl, 'motorcycle'),
    }

    return [
      {
        value: 'car', text: t.car, label: t.car, disabled: false,
      }, {
        value: 'motorcycle', text: t.motorcycle, label: t.motorcycle, disabled: false,
      },
    ]
  }

  function buildElementsRules () {

    const setLocale = (isLocaleOn, localeKey) => isLocaleOn ? <IntlMessage id={localeKey}/> : localeKey.toString()

    return {

      year: [
        {
          validator: (rule, value) => {

            const currentYear = new Date().getFullYear()

            if (value && (isNaN(Number(value)) || value < 1900 || value > (currentYear + 1))) {
              return Promise.reject(setLocale(true, 'invalid_year'))

            } else {
              return Promise.resolve()
            }
          },
        },
      ],
    }
  }

  function buildElementsRefs (hookUseRef) {
    return {
      plate: hookUseRef(null),
      type: hookUseRef(null),
      year: hookUseRef(null),

      brand: hookUseRef(null),
      model: hookUseRef(null),
      color: hookUseRef(null),

      note: hookUseRef(null),

    }
  }

})

// EXPORT **********************************************************************
// *****************************************************************************

export default VehicleModalRegistrationTab


