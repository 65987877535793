import React, { useEffect, useRef, useState } from 'react'
import { Card, Col, Descriptions, Form, Modal, Row } from 'antd'
import IntlMessage from 'components/util-components/IntlMessage'
import { translate } from 'utils/react-jarvisly-helper'
import { useIntl } from 'react-intl'
import { substitution, onFormFinishFailed, parseDateToDisplay } from 'utils/helpers'
import {
  MailOutlined,
  MobileOutlined,
  PrinterOutlined,
  SendOutlined,
  WhatsAppOutlined,
} from '@ant-design/icons'
import moment from 'moment/moment'
import { useSelector } from 'react-redux'
import { capitalize } from 'jarvisly-helper'
import {
  FormDatePicker,
  FormSegment, FormSelect,
  FormTextArea,
} from 'views/app-views/portals/autaliza-info/FormComponets'
import { ROW_GUTTER } from 'constants/ThemeConstant'
import { postByUriV2, putByUriV2 } from 'configs/ApiConfig'

// GLOBAL VARIABLES ************************************************************
// *****************************************************************************

const setLocale = (isLocaleOn, localeKey) => isLocaleOn ? <IntlMessage id={localeKey}/> : localeKey.toString()
const defaultExpireAt = moment().add(1, 'week')

// COMPONENTS ******************************************************************
// *****************************************************************************

const AskForUpdate = props => {

  // props deconstruction ------------------------------------------------------
  const {
    showModal,
    setShowModal,
    _data,
    _module,
    setFormContext,
    formContext,
  } = props

  // local variables -----------------------------------------------------------
  const rdxSubscription = useSelector(r => r.auth.rdxSubscription)
  const [form] = Form.useForm()
  const intl = useIntl()
  const t = buildTranslations()
  // const initialRecord = { channel: 'whatsapp', message: t.message }
  const campaignOptions = buildCampaignOptions()
  const elRefs = buildElementsRefs(useRef)
  const channelOptions = buildChannelOptions()

  // ant dataPicker workaround to fix issue about date
  if (_data?._updateLastCampaign?.expireAt) {
    _data._updateLastCampaign.expireAt = parseDateToDisplay(_data._updateLastCampaign.expireAt)
  } else if (_data?._updateLastCampaign) {
    _data._updateLastCampaign.expireAt = defaultExpireAt
  }

  // component states ----------------------------------------------------------
  const [loading, setLoading] = useState(false)
  const [channel, setChannel] = useState('whatsapp')
  const [record, setRecord] = useState({})
  const [selectedValue,] = useState('')

  // hooks ---------------------------------------------------------------------
  useEffect(() => {

    if (_data?._id) {

      if (!_data?._update) {

        _data._update = {
          campaign: 'none',
          expireAt: moment().add(1, 'week'),
          channel: 'whatsapp',
          message: t.message,
        }

      } else {

        _data._update = {
          campaign: _data?._updateLastCampaign?.campaign || 'none',
          expireAt: _data?._updateLastCampaign?.expireAt || defaultExpireAt,
          channel: _data?._updateLastCampaign?.channel || 'whatsapp',
          message: _data._update.message || t.message,
        }

      }

      setRecord(_data._update)


      // form && form?.setFieldsValue(_data._update)

      // console.log('data._update ->', _data._update)
    }

    handleFocus()

    // if (!_data?.atualizaInfoId) return

    // fetch from atualizaInfo collection
    // (async () => {
    //   const doc = await getByUri(atualizaApi, _data.atualizaInfoId)
    //   if (!doc) return
    //   setChannel(doc?.channel || initialRecord.channel)
    //   setRecord({ ...doc })
    // })()

  }, [_data, form, showModal]) // eslint-disable-line react-hooks/exhaustive-deps

  /*
    useEffect(() => {
      if (showModal) {

        // console.log('initialRecord', initialRecord)
        console.log('record', record)

        form?.setFieldsValue({ ...initialRecord, ...record })
        handleFocus()
      }
    }, [record, showModal, form]) // eslint-disable-line react-hooks/exhaustive-deps
  */

  // METHODS -------------------------------------------------------------------

  function handleFocus (field = 'campaign', cursorPosition = 'all') {
    elRefs[field]?.current?.focus({ cursor: cursorPosition })
  }

  // close button click or escape
  const onClose = () => {
    setShowModal(false)
  }

  const onOk = () => form.submit()

  /*
    const onBeforeSave = values => {

      const body = { ...values }
      body._id = record?._id
      body.requiredAt = moment()
      body.updateSentAt = null
      body.updateSentBy = null
      body.apartmentId = _data.apartmentId
      body.description = `${_data.__apartment.apartment} | ${_data.__apartment.block.name}`
      body.name = _data.name
      body.phone = parsePhoneToDB(_data.phone)
      body.email = _data.email

      return body
    }
  */

  /*
    const onAfterSave = async doc => {

      if (!doc) return

      if (!record?._id) {
        await putByUri('/moves', _data._id, { atualizaInfoId: doc._id })
      }

      setFormContext({ ...formContext, refreshTime: +new Date() })

      let text
      switch (channel) {

        case 'whatsapp':
          text = t.sending_whatsapp
          break

        case 'sms':
          text = t.sending_sms
          break

        case 'email':
          text = t.sending_email
          break

        case 'form':
          text = t.printing
          break

        default:
          text = ''

      }

      msg('i', text, 'sending')
      await postSendMessage('info@info.com', {})
      msg('s', t.done, 'sending')

      onClose()
    }
  */

  // form submitted successfully
  const onFinish = async values => {

    setLoading(true)

    console.log('values ->', values)

    const bodyCampaign = {
      // _id: record?.campaignId,
      profile: 'apartments', // todo change to dynamic name
      campaign: values.campaign,
      channel: values.channel,
      expireAt: values.expireAt,
    }

    let campaignResult

    // CAMPAIGN
    const campaignUrl = '/v2/update-campaigns'
    if (record?.campaignId) { // put
      campaignResult = await putByUriV2(campaignUrl, record.campaignId, bodyCampaign)

    } else {  // post
      campaignResult = await postByUriV2(campaignUrl, bodyCampaign)
    }

    const campaignId = record?.campaignId || campaignResult?.response?.data?._id

    const body = {
      _metadata: {
        documentStatus: 0
      },
      _update: {
        campaignId,
        message: values.message,
        doneAt: null
      },
    }

    await putByUriV2(_module.api, _data._id, body)
    // const docResult = await putByUriV2(_module.api, _data._id, body)

    // console.log('RESULT 2 ->', docResult)

    setLoading(false)
    setFormContext({ ...formContext, refreshTime: +new Date() })

    onClose()
  }

  // form submitted failure
  const onFinishFailed = errorInfo => onFormFinishFailed(errorInfo, elRefs)

  // UI COMPONENT --------------------------------------------------------------

  return (<>

    <Modal
      title={<>
        <SendOutlined className="mr-2"/>
        {translate(intl, 'request_update')}
      </>}
      open={showModal}
      getContainer={false}
      onCancel={onClose}
      onOk={onOk}
      okText={translate(intl, channel === 'form' ? 'print' : 'send')}
      okButtonProps={{ icon: channel === 'form' ? <PrinterOutlined/> : <SendOutlined/> }}
      confirmLoading={loading}>

      <Card>

        <Descriptions title={setLocale(true, 'responsible')} size="small" column={1}>

          <Descriptions.Item label={setLocale(true, 'name')} labelStyle={{ width: 140 }}>
            <span className="text-muted">{_data?.dataResponsible?.name}</span>
          </Descriptions.Item>

          <Descriptions.Item label={setLocale(true, 'mobile')} labelStyle={{ width: 140 }}>
            <span className="text-muted">{_data?.dataResponsible?.phone}</span>
          </Descriptions.Item>

          <Descriptions.Item label={setLocale(true, 'email')} labelStyle={{ width: 140 }}>
            <span className="text-muted">{_data?.dataResponsible?.email}</span>
          </Descriptions.Item>

          <Descriptions.Item label={setLocale(true, 'access_code')} labelStyle={{ width: 140 }}>
            <span className="text-muted">{_data?.dataResponsible?.accessCode || 'n/a'}</span>
          </Descriptions.Item>

        </Descriptions>

      </Card>

      <Form form={form}
            layout="vertical"
            initialValues={_data?._update}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            scrollToFirstError={true}>


        {/*
      <FormWrapperModal form={form}
                        name={formName}
                        initialValues={_data?._update}
                        uriApi={'/atualiza-info'}
                        onBeforeSave={onBeforeSave}
                        onAfterSave={onAfterSave}
                        setFormState={setFormState}
                        formState={formState}
                        elRefs={elRefs}>
*/}

        <Row gutter={ROW_GUTTER}>

          <Col xs={12}>
            <FormSelect name="campaign"
                        value={selectedValue}
                        options={campaignOptions}
                        disabled={loading}
                        required
                        elRef={elRefs.campaign}/>
          </Col>


          <Col xs={12}>
            <FormDatePicker name="expireAt"
                            label="expire_at"
                            disabled={loading}
                            required
                            elRef={elRefs.expireAt}/>
          </Col>
        </Row>


        <Row gutter={ROW_GUTTER}>

          <Col xs={24}>
            <FormSegment name="channel"
                         onChange={value => setChannel(value)}
                         options={channelOptions}
                         disabled={loading}
                         elRef={elRefs.channel}/>
          </Col>

        </Row>

        {channel !== 'form' && <Row gutter={16}>

          <Col xs={24}>
            <FormTextArea name="message"
                          disabled={loading}
                          rows={5}
                          elRef={elRefs.message}/>
          </Col>

        </Row>}


      </Form>

    </Modal>

  </>)

  // INTERNAL FUNCTIONS ========================================================
  // ===========================================================================

  function buildTranslations () {

    const name = _data?.dataResponsible?.name || translate(intl, 'resident')
    const apartment = _data?.__apartment
    const condominium = capitalize(rdxSubscription?.organization)
    // const accessCode = _data?.dataResponsible?.accessCode || _data?._id?.toString()?.slice(-4)?.toUpperCase()
    // const access_code = translate(intl, 'access_code')

    const request_update_default_message = translate(intl, 'request_update_default_message')
    const t_request_update_default_message = substitution(request_update_default_message,
      ['%NAME%', '%APARTMENT%', '%CONDOMINIUM%'],
      [name, apartment, condominium])

    /*
        const m = `${t_request_update_default_message}

    Link: https://atualiza.info
    ${access_code}: ${accessCode}`
    */

    return {
      // message: m,
      message: t_request_update_default_message,
      sending_whatsapp: translate(intl, 'sending_whatsapp'),
      sending_sms: translate(intl, 'sending_sms'),
      sending_email: translate(intl, 'sending_email'),
      printing: translate(intl, 'printing'),
      done: translate(intl, 'done'),
    }
  }

  function buildCampaignOptions () {

    return [
      {
        value: 'none',
        label: translate(intl, 'none'),
        disabled: false,
      }]
  }

  function buildChannelOptions () {

    return [
      {
        value: 'whatsapp',
        label: translate(intl, 'whatsapp'),
        icon: <WhatsAppOutlined/>,
      },
      {
        value: 'sms',
        label: translate(intl, 'sms'),
        icon: <MobileOutlined/>,
        // disabled: true,
      },
      {
        value: 'email',
        label: translate(intl, 'email'),
        icon: <MailOutlined/>,
        // disabled: true,
      },
      {
        value: 'form',
        label: translate(intl, 'form'),
        icon: <PrinterOutlined/>,
        // disabled: true,
      },
    ]
  }

  function buildElementsRefs (hookUseRef) {
    return {
      campaign: hookUseRef(null),
      channel: hookUseRef(null),
      expireAt: hookUseRef(null),
      message: hookUseRef(null),
    }
  }

}

// EXPORT **********************************************************************
// *****************************************************************************

export default AskForUpdate
