import React, { useImperativeHandle, useState } from 'react'
import SendAtualizaInfo from 'views/app-views/apartments/Form/AskForUpdate'
import PropTypes from 'prop-types'

// COMPONENT *******************************************************************
// *****************************************************************************

const SendAtualizaInfoTrigger = props => {

  // props deconstruction ------------------------------------------------------
  const {
    _data,
    formContext,
    setFormContext,
    innerRef,
  } = props

  // local variables -----------------------------------------------------------

  // component states ----------------------------------------------------------
  const [showAtualizaInfoRequest, setShowAtualizaInfoRequest] = useState(false)

  // hooks ---------------------------------------------------------------------

  // methods -------------------------------------------------------------------
  useImperativeHandle(innerRef, () => ({
    handleMenuClick: handleMenuClick,
  }))

  const handleMenuClick = key => {

    if (key === 'moves_sendAtualizaInfo') {
      setShowAtualizaInfoRequest(true)

    } else {
      console.error('Error: unknown handleMenuClick() key!')
    }
  }

  // UI COMPONENT --------------------------------------------------------------

  return (<SendAtualizaInfo {...props}
                            showModal={showAtualizaInfoRequest}
                            setShowModal={setShowAtualizaInfoRequest}
                            _data={_data}
                            setFormContext={setFormContext}
                            formContext={formContext}/>)
}

SendAtualizaInfoTrigger.propTypes = {
  _data: PropTypes.object,
}

export default SendAtualizaInfoTrigger
