import React, { forwardRef, useEffect, useRef, useState } from 'react';
import { Button, Col, Form, Input, message, Row } from 'antd';
import {
  PlusOutlined,
  MinusCircleOutlined, SaveOutlined,
} from '@ant-design/icons';
import IntlMessage from 'components/util-components/IntlMessage';
import { useIntl } from 'react-intl';
import { ROW_GUTTER } from 'constants/ThemeConstant';
import { translate } from 'utils/react-jarvisly-helper';
import { isObjEquals, msg } from 'utils/helpers';
import { api } from 'components/jarvisly-layouts/ModuleComponent/module-methods';
import { clone } from 'jarvisly-helper';
import { FormInputNumber, FormTitle } from '../../../../../portals/autaliza-info/FormComponets';

// GLOBAL VARIABLES ************************************************************
// *****************************************************************************

const setLocale = (isLocaleOn, localeKey) => isLocaleOn ? <IntlMessage id={localeKey}/> : localeKey.toString();

// COMPONENT *******************************************************************
// *****************************************************************************

const MultiPlansCoverages = forwardRef((props, ref) => {

  // props deconstruction ------------------------------------------------------
  const {
    _id,
    _module,
    _data,
    dataContext,
    setDataContext,
    formContext,
    setFormContext,
  } = props;

  // component states ----------------------------------------------------------
  const [, setComparativeIndexOptions] = useState(buildComparativeIndexOptions());
  const [isFormChanged, setIsFormChanged] = useState(false);
  const [cache, setCache] = useState([]);

  // local variables -----------------------------------------------------------
  const intl = useIntl();
  const t = buildTranslations();
  const exchangeSelectOptions = buildExchangeSuffixOptions();

  const isDisabled = formContext.mode === 'view';
  const formName = 'coveragesForm';
  const [form] = Form.useForm();
  const formRef = useRef();
  const elRefs = {
    alert: useRef(null)
  };

  // hooks ---------------------------------------------------------------------

  useEffect(() => {

    form?.setFieldsValue(_data || {});
    onChangeComparativeIndex();

    setIsFormChanged(false);
  }, [_data, form, formContext?.refreshTime]); // eslint-disable-line react-hooks/exhaustive-deps

  // METHODS -------------------------------------------------------------------

  const onFormChanges = () => {
    const a = form.getFieldValue('coverages');
    const b = _data?.coverages;

    setIsFormChanged(!isObjEquals(a, b));
  };

  const onChangeComparativeIndex = value => {

    const coverages = form?.getFieldValue(['coverages']) || [];
    const defaultOptions = buildComparativeIndexOptions();
    const newOptions = defaultOptions.filter(f => f.value !== value).filter(d => !coverages?.some(s => s?.comparativeIndex === d?.value));

    setComparativeIndexOptions(newOptions);
  };

  const onCurrencyChange = (index, value) => {
    cache[index].currency = value;
    setCache([...cache]);
  };

  const onFinish = async values => {

    values.coverages.map((c, idx) => {
      c.currency = cache[idx].currency;
      return c;
    });

    if (!isFormChanged) return;

    if (!_data.coverages) _data.coverages = [];
    _data.coverages = [...values.coverages];

    setDataContext({
      ...dataContext,
      fullLoading: true,
    });
    msg('i', t.updating, 'saving');

    const body = {
      _id,
      ...values,
    };

    try {
      await api.put(_module.api, body);

      setDataContext({
        ...dataContext,
        data: { ..._data },
        dataLock: { ...clone(_data) },
        fullLoading: false,
        // formsChanged: formsChanged,
      });
      setFormContext({
        ...formContext,
        refreshTime: +new Date(),
        mode: 'view',
        dataMemory: null
      });

      message.destroy('saving');
      msg('s', t.updatingSuccess);

    } catch (e) {

      message.destroy('saving');
      const errorMessage = `Erro ${e?.data?.code}: ${e?.data?.errorMessage}`;
      msg('e', errorMessage, 'error', 5);

      setDataContext({
        ...dataContext,
        fullLoading: false,
      });

    }

  };

  // UI COMPONENT --------------------------------------------------------------

  return (<>

    <FormTitle title="coverages"/>


    <Form ref={formRef}
          name={formName}
          form={form}
          onValuesChange={onFormChanges}
          initialValues={_data}
          onFinish={onFinish}
          autoComplete="off">


      <Form.List name="coverages">
        {(fields, {
          add,
          remove
        }) => (
          <>
            {fields.map((field, index) => (

              <Row gutter={ROW_GUTTER} key={field.key}>

                <Col xs={24} sm={14}>

                  <Form.Item {...field}
                             name={[field.name, 'description']}
                             rules={[
                               {
                                 required: true,
                                 message: t.required_field,
                               },
                             ]}>

                    <Input placeholder={t.coverage}
                           disabled={isDisabled}/>

                  </Form.Item>

                </Col>


                <Col xs={20} sm={8}>

                  <FormInputNumber field={field}
                                   name={[field.name, 'value']}
                                   noLabel
                                   placeholder={t.assured_value}
                                   onSuffixChange={value => onCurrencyChange(index, value)}
                                   elRef={elRefs.alert}
                                   disabled={isDisabled}
                                   decimalSeparator=","
                                   suffixStyle={{ width: 60 }}
                                   addonAfter={exchangeSelectOptions}
                                   suffixDefaultValue={_data?.coverages?.[index]?.currency || _data?.quotation}
                                   min={0}
                                   required/>


                </Col>

                <Col xs={4} sm={2} className="text-right">

                  <Button icon={<MinusCircleOutlined/>}
                          disabled={isDisabled}
                          onClick={() => {
                            cache.splice(index, 1);
                            setCache([...cache]);
                            remove(field.name);
                          }}/>

                </Col>

              </Row>

            ))}

            <Form.Item>

              <Button type="dashed"
                      disabled={isDisabled}
                      onClick={() => {
                        setCache([...cache, { currency: _data.quotation || 'rbl' }]);
                        add();
                      }}
                      block icon={<PlusOutlined/>}>
                &nbsp;{setLocale(true, 'add_service')}
              </Button>

            </Form.Item>

          </>
        )}
      </Form.List>

      <Row justify="end" align="middle">
        <Form.Item>
          <Button type={isFormChanged ? 'primary' : 'default'}
                  icon={<SaveOutlined/>}
                  disabled={isDisabled}
                  htmlType="submit">
            <span className="ml-2">
              {setLocale(true, 'save')}
            </span>
          </Button>
        </Form.Item>
      </Row>


    </Form>

  </>);

  // INTERNAL FUNCTIONS ========================================================
  // ===========================================================================

  function buildTranslations () {

    return {
      service: translate(intl, 'service'),
      comparative_index: translate(intl, 'comparative_index'),
      updating: `${translate(intl, 'updating_document')}...`,
      updatingSuccess: `${translate(intl, 'document_updated_with_success')}!`,
      required_field: `${translate(intl, 'required_field')}!`,
      dollar: `${translate(intl, 'dollar')}`,
      euro: `${translate(intl, 'euro')}`,
      real: `${translate(intl, 'real')}`,
      coverage: `${translate(intl, 'coverage')}`,
      assured_value: `${translate(intl, 'assured_value')}`,
    };
  }

  function buildExchangeSuffixOptions () {

    return [
      {
        value: 'usd',
        text: t.dollar,
        label: '$',
        disabled: false,
        tooltipText: t.dollar,
      },
      {
        value: 'eur',
        text: t.euro,
        label: '€',
        disabled: false,
        tooltipText: t.euro,
      },
      {
        value: 'brl',
        text: t.real,
        label: 'R$',
        disabled: false,
        tooltipText: t.real,
      },
    ];
  }

  function buildComparativeIndexOptions () {

    return [
      {
        value: 'despesas_medicas',
        text: 'despesas_medicas',
        label: 'despesas_medicas',
        disabled: false,
      },
      {
        value: 'despesas_medicas_covid',
        text: 'despesas_medicas_covid',
        label: 'despesas_medicas_covid',
        disabled: false,
      },
      {
        value: 'despesas_odontologicas',
        text: 'despesas_odontologicas',
        label: 'despesas_odontologicas',
        disabled: false,
      },
      {
        value: 'despesas_farmaceutiacs',
        text: 'despesas_farmaceutiacs',
        label: 'despesas_farmaceutiacs',
        disabled: false,
      },
      {
        value: 'despesas_juridicas',
        text: 'despesas_juridicas',
        label: 'despesas_juridicas',
        disabled: false,
      },
      {
        value: 'despesas_fiancas',
        text: 'despesas_fiancas',
        label: 'despesas_fiancas',
        disabled: false,
      },
      {
        value: 'interrupcao_viagem',
        text: 'interrupcao_viagem',
        label: 'interrupcao_viagem',
        disabled: false,
      },
      {
        value: 'danos_mala',
        text: 'danos_mala',
        label: 'danos_mala',
        disabled: false,
      },
      {
        value: 'atraso_bagagem',
        text: 'atraso_bagagem',
        label: 'atraso_bagagem',
        disabled: false,
      },
      {
        value: 'perda_bagagem',
        text: 'perda_bagagem',
        label: 'perda_bagagem',
        disabled: false,
      },
      {
        value: 'cancelamento_viagem',
        text: 'cancelamento_viagem',
        label: 'cancelamento_viagem',
        disabled: false,
      },
      {
        value: 'regresso_sanitario',
        text: 'regresso_sanitario',
        label: 'regresso_sanitario',
        disabled: false,
      },
      {
        value: 'translado_corpo',
        text: 'translado_corpo',
        label: 'translado_corpo',
        disabled: false,
      },
      {
        value: 'retorno_acompanhante',
        text: 'retorno_acompanhante',
        label: 'retorno_acompanhante',
        disabled: false,
      },
      {
        value: 'acompanhantes_hospitalar',
        text: 'acompanhantes_hospitalar',
        label: 'acompanhantes_hospitalar',
        disabled: false,
      },
      {
        value: 'prorrogacao',
        text: 'prorrogacao',
        label: 'prorrogacao',
        disabled: false,
      },
      {
        value: 'retorno_antecipado',
        text: 'retorno_antecipado',
        label: 'retorno_antecipado',
        disabled: false,
      },
      {
        value: 'atraso_voo',
        text: 'atraso_voo',
        label: 'atraso_voo',
        disabled: false,
      },
      {
        value: 'morte_acidental',
        text: 'morte_acidental',
        label: 'morte_acidental',
        disabled: false,
      },
      {
        value: 'invalidez',
        text: 'invalidez',
        label: 'invalidez',
        disabled: false,
      },
    ];
  }

});

export default MultiPlansCoverages;
