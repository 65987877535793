// ************************************************************************** //
// MODULE CUSTOM APIS
// ************************************************************************** //

import { IOT_API_URL } from './constants';
import { getV2 } from 'configs/ApiConfig';

export const getIotDevices = async () => {

  let url = `${IOT_API_URL}/get-iot-dashboard-device`;
  const config = { headers: { 'x-resultasobject': false } };

  try {
    const result = await getV2(url, config);
    const devices = result?.response?.data || [];

    return typeof devices === 'object' && Object.keys(devices).length > 0
      ? devices
      : [];

  } catch (error) {
    throw error;
  }
};

export const getIotCollectorDeviceByChannel = async (service, channel) => {

  if (!channel) {
    return console.error('Invalid channel! getIotCollectorDeviceByChannel()');

  } else if (!service) {
    return console.error('Invalid service! getIotCollectorDeviceByChannel()');
  }

  let url = `${IOT_API_URL}/get-iot-collector/${service}/${channel}`;
  const config = { headers: { 'x-resultasobject': true } };

  try {
    const result = await getV2(url, config);
    return result?.response?.data;

  } catch (error) {
    throw error;
  }
};

export const getDeviceByServiceChannel = async (_id, service, channel) => {

  if (!service) {
    return console.error('Invalid service! getDeviceByChannel()');

  } else if (!channel) {
    return console.error('Invalid channel! getDeviceByChannel()');
  }

  let url = `${IOT_API_URL}/get-iot-by-service-channel/${_id}/${service}/${channel}`;
  const config = { headers: { 'x-resultasobject': true } };

  try {
    const result = await getV2(url, config);
    const data = result?.response?.data || null;
    return Array.isArray(data) && data?.length === 0
      ? null
      : data;

  } catch (error) {
    throw error;
  }
};

/*
export const getIot24hByService = async serviceName => {

  if (!serviceName) {
    return console.error('Invalid serviceName! getIot24hByService()');
  }

  let url = `${IOT_API_URL}/get-iot-dashboard-24h/${serviceName}`;
  const config = { headers: { 'x-resultasobject': false } };

  try {
    return await getV2(url, config);

  } catch (error) {
    throw error;
  }
};

export const getIot24hByService = async serviceName => {

  if (!serviceName) {
    return console.error('Invalid serviceName! getIot24hByService()');
  }

  let url = `${IOT_API_URL}/get-iot-dashboard-24h/${serviceName}`;
  const config = { headers: { 'x-resultasobject': false } };

  try {
    return await getV2(url, config);

  } catch (error) {
    throw error;
  }
};

export const getIotDevices24h = async serviceName => {

  let url = `${IOT_API_URL}/get-iot-dashboard-24h`;
  const config = { headers: { 'x-resultasobject': false } };

  try {
    const result = await getV2(url, config);
    return result?.response?.data || [];

  } catch (error) {
    throw error;
  }
};

*/
