import React from 'react'
import {
  decorateDocument,
  decorateNewDocument,
  removeDocumentValidation,
  validateFilterFn,
  validateSearchFn,
  parseSettings,
  getBikeModuleParameters,
} from './bikes-methods'
import BikesMainList from './BikesMainList'
import { CarOutlined } from '@ant-design/icons'
import { Route } from 'react-router-dom'
import {
  defaultDecorateSettings,
  defaultDataProfileValidation,
} from 'components/jarvisly-layouts/ModuleComponent/module-methods'
import BikesProfile from './sections/BikesProfile'
import {
  BikesDocumentHeaderColumn2,
  BikesDocumentHeaderColumn3,
  BikesDocumentSubtitle,
} from './BikesDocumentHeader'

// GLOBAL VARIABLES ************************************************************
// *****************************************************************************

// ************************************************************************** //
// CONSTANTS
// ************************************************************************** //

const MODULE_BIKES_URL = '/app/records/condominium/bikes'
export const BIKES_API_URL = '/bikes'
export const BIKES_API_URL_V2 = '/v2/bikes'

const INITIAL_REQUEST = {
  filters: {
    colors: ['black', 'white'],
  },
  search: {
    name: 'rick',
    age: 46,
  },
  sort: {
    name: 1,
    age: -1,
  },
  projection: {
    name: 1,
    age: 1,
    city: 1,
  },
  pagination: {
    page: 1,
    size: 10,
  },
}

const INITIAL_FILTER = {
  folder: 'documents',
}

const INITIAL_SORT = {
  sortField: '_metadata.audit.updatedAt',
  sortOrder: 'descend',
}

const INITIAL_SEARCH = {
  name: 'null', // main driver
  roomNumber: 'null',
  blockId: 'null',
  documentStatus: 'null',
}

const INITIAL_CONTEXT = {
  hasPicture: true,
  mode: 'nope',

  selectedSection: null,
  sectionsChanged: [],
  sectionSettingsComponent: null,
}

const METHODS = {
  parseSettings: parseSettings,
  decorateSettings: defaultDecorateSettings,

  decorateDocument: decorateDocument,
  decorateNewDocument: decorateNewDocument,

  removeDocumentValidation: removeDocumentValidation,
  postDocumentValidation: defaultDataProfileValidation,
  putDocumentValidation: defaultDataProfileValidation,

  validateSearchFn: validateSearchFn,
  validateFilterFn: validateFilterFn,

  refreshParameters: getBikeModuleParameters,
}

const COMPONENTS = {
  list: props => <BikesMainList {...props}/>,
  // tableListV2: props => <BikesMainListV2 {...props}/>,
  // listPreviewHeader: props => <BikesBodyPreviewHeader {...props}/>,
  // listPreviewBody: props => <BikesBodyPreviewData {...props}/>,
  // listPreviewActions: props => <BikesBodyPreviewActions {...props}/>,

  documentSubtitle: props => <BikesDocumentSubtitle {...props}/>,
  documentHeaderColumn2: props => <BikesDocumentHeaderColumn2 {...props}/>,
  documentHeaderColumn3: props => <BikesDocumentHeaderColumn3 {...props}/>,

  // moduleSettings: props => <bikesSettingsTabs {...props}/>,
  // moduleSettings: bikesSettingsTabs(),
}

const DATA_TYPES = [
  // {
  //   key: 'document',  // database value
  //   label: 'document',
  //   description: 'document_description',
  //   disabled: true,
  //   checked: true,
  // },
]

const DOCUMENT_SECTIONS = [
  {
    key: 'profile',
    icon: <CarOutlined/>,
    label: 'bike',
    route: (props, ref, _id) => <Route key="profile"
                                       path={`${MODULE_BIKES_URL}/${_id}/profile`}
                                       render={() =>
                                         <BikesProfile ref={ref} {...props}
                                                       _data={props._dataRoots}
                                                       _dataLock={props._dataRootsLock}/>}/>,
  },
]

const REQUIRED_FIELDS = []

// ************************************************************************** //
// SEEDS
// ************************************************************************** //

const SETTINGS_SEED = {

  configurations: {},

  requiredFields: [],
}

const AUTO_FILL_BIKES = {}

// ************************************************************************** //
// MODULES PARAMETERS
// ************************************************************************** //

export const MODULE_BIKES_PARAMETERS = {

  root: 'bikes',
  name: 'bikes',
  singular: 'bike',
  titleField: 'model',
  url: MODULE_BIKES_URL,
  api: BIKES_API_URL,
  apiV2: BIKES_API_URL_V2,
  initialRequest: INITIAL_REQUEST,

  hasFilter: true,
  hasSettings: true,
  hasAccessControl: true,
  hasConfigurations: true,
  hasDataMemory: true,
  hasRequiredFields: false,
  documentActions: {
    archive: false,
    duplicate: false,
  },

  methods: METHODS,
  components: COMPONENTS,
  dataTypes: DATA_TYPES,
  requiredFields: REQUIRED_FIELDS,

  initialSearch: INITIAL_SEARCH,
  initialContext: INITIAL_CONTEXT,
  initialFilter: INITIAL_FILTER,
  initialSort: INITIAL_SORT,

  labels: {
    new_document_title: 'new_bike',
  },

  documentSections: DOCUMENT_SECTIONS,

  seeds: {
    settings: SETTINGS_SEED,
    data: AUTO_FILL_BIKES,
  },

}


