import React, { useEffect, useState } from 'react'
import { Button, Col, Modal, Row, Table, Tooltip } from 'antd'
import IntlMessage from '../../../util-components/IntlMessage'
import { translate } from 'utils/react-jarvisly-helper'
import { useIntl } from 'react-intl'
import {
  DeleteOutlined,
  FileAddOutlined,
  FormOutlined,
  LockOutlined, MoreOutlined,
  QuestionCircleOutlined, SafetyCertificateOutlined,
  SearchOutlined,
} from '@ant-design/icons'
import RoundedAvatar from '../../../jarvisly/RoundedAvatar'
import { connect } from 'react-redux'
import { msg } from 'utils/helpers'
import { clone } from 'jarvisly-helper'
import {
  putInvitationChangeGranted, putSubscriptionChangeGranted,
} from 'views/app-views/account/account-api'
import AppBreadcrumb from '../../../layout-components/AppBreadcrumb'

// GLOBAL VARIABLES ************************************************************
// *****************************************************************************

const setLocale = (isLocaleOn, localeKey) => isLocaleOn ?
  <IntlMessage id={localeKey}/> : localeKey.toString()

// MAIN COMPONENT **************************************************************
// *****************************************************************************

const AccessControl = props => {

  // props deconstruction ------------------------------------------------------
  const {
    showModal, setShowModal, rdxModule, rdxSubscription,
  } = props

  // component states -----------------------------------------------------------
  const [data, setData] = useState([])

  // local variables ------------------------------------------------------------
  const intl = useIntl()

  const title = `${translate(intl, 'access_control')}`
  const modName = rdxModule?.name

  // hooks ---------------------------------------------------------------------
  useEffect(() => {
    const t = clone(rdxSubscription?.team || [])
    setData([...t])
  }, [rdxSubscription?.team])

  // METHODS -------------------------------------------------------------------

  const handleClick = async (option, record) => {

    let type, text
    const granted = record.granted[modName]

    switch (option) {

      case 'access': {
        granted.A = !granted.A
        granted.aColor = granted.A ? '#87d068' : '#f50'

        type = granted.A ? 's' : 'e'
        text = granted.A
          ? 'Concedido acesso ao módulo!'
          : 'Revogado acesso ao módulo!'

        break
      }

      case 'create': {
        granted.C = !granted.C
        granted.cColor = granted.C ? '#87d068' : '#f50'

        type = granted.C ? 's' : 'e'
        text = granted.C
          ? 'Permitido a criação de novos documentos!'
          : 'Revogado permissão para criar documentos!'

        break
      }

      case 'read': {
        granted.R = granted.R === 0 ? granted.R = 1 : granted.R === 1
          ? granted.R = 2
          : granted.R = 0

        granted.rColor = granted.R === 0 ? '#f50' : granted.R === 1
          ? '#f9b128'
          : '#87d068'

        granted.rTitle = granted.R === 0 ? 'read_x' : granted.R === 1
          ? 'read_l1'
          : 'read_l2'

        type = granted.R === 0 ? 'e' : granted.R === 1 ? 'w' : 's'
        text = granted.R === 0
          ? translate(intl, 'read_documents_revoked')
          : granted.R === 1
            ? translate(intl, 'read_documents_l1')
            : translate(intl, 'read_documents_l2')
        break
      }

      case 'update': {
        granted.U = granted.U === 0 ? granted.U = 1 : granted.U === 1
          ? granted.U = 2
          : granted.U = 0

        granted.uColor = granted.U === 0 ? '#f50' : granted.U === 1
          ? '#f9b128'
          : '#87d068'

        granted.uTitle = granted.U === 0 ? 'update_x' : granted.U === 1
          ? 'update_l1'
          : 'update_l2'

        type = granted.U === 0 ? 'e' : granted.U === 1 ? 'w' : 's'
        text = granted.U === 0
          ? translate(intl, 'update_documents_revoked')
          : granted.U === 1
            ? translate(intl, 'update_documents_l1')
            : translate(intl, 'update_documents_l2')
        break
      }

      case 'delete': {
        granted.D = granted.D === 0 ? granted.D = 1 : granted.D === 1
          ? granted.D = 2
          : granted.D = 0

        granted.dColor = granted.D === 0 ? '#f50' : granted.D === 1
          ? '#f9b128'
          : '#87d068'

        granted.dTitle = granted.D === 0 ? 'delete_x' : granted.D === 1
          ? 'delete_l1'
          : 'delete_l2'

        type = granted.D === 0 ? 'e' : granted.D === 1 ? 'w' : 's'
        text = granted.D === 0
          ? translate(intl, 'delete_documents_revoked')
          : granted.D === 1
            ? translate(intl, 'delete_documents_l1')
            : translate(intl, 'delete_documents_l2')
        break
      }

      default:
        break

    }

    const subscriptionId = rdxSubscription?._id
    const invitationId = record?._id
    const accountId = record?.accountId

    if (accountId) {
      await putSubscriptionChangeGranted(accountId, subscriptionId, modName,
        granted)

    } else {
      await putInvitationChangeGranted(invitationId, subscriptionId, modName,
        granted)
    }

    // message.destroy('message');
    msg(type, text, null, 3)

    // setData([...record]);
    setData([...data])
    // forceUpdate();

  }

  const isDisabled = r => {
    return ['owner', 'keyUser'].includes(r?.profile)
  }

  // UI COMPONENT --------------------------------------------------------------

  const onClose = () => setShowModal(false)

  const columns = [
    {
      title: setLocale(true, 'user'),
      dataIndex: 'name',
      render: (_, record) => {

        const member = rdxSubscription?.team?.find(
          t => t?.email === record?.email)

        return (<RoundedAvatar user={record}
                               showEmail
                               showName
                               showIcon
                               profile={member?.profile}/>)
      },
    },

    {
      title: setLocale(true, 'permissions'), // dataIndex: 'name',
      shouldCellUpdate: () => true, render: (_, record, b) => {

        return (<>

          {/* access */}
          <Tooltip title={setLocale(true, 'access_module')}>
            {/*<Button className="mr-2 mb-3 mb-sm-0"*/}
            <Button className="m-1"
                    style={{
                      color: 'white',
                      backgroundColor: record?.granted[modName]?.aColor,
                    }}
                    onClick={() => handleClick('access', record)}
                    disabled={['owner', 'keyUser'].includes(record?.profile)}
                    shape="square"
                    icon={<LockOutlined/>}
                    size="small"/>
          </Tooltip>
          {/* access */}


          {/* create */}
          <Tooltip title={setLocale(true, 'create_documents')}>
            {/*<Button className="mr-2 mb-3 mb-sm-0"*/}
            <Button className="m-1"
                    style={{
                      color: 'white',
                      backgroundColor: record?.granted[modName]?.cColor,
                    }}
                    onClick={() => handleClick('create', record)}
                    disabled={isDisabled(record)}
                    shape="square"
                    icon={<FileAddOutlined/>}
                    size="small"/>
          </Tooltip>
          {/* create */}


          {/* read */}
          <Tooltip title={record?.granted[modName]?.rTitle &&
            setLocale(true, record?.granted[modName]?.rTitle)}>
            {/*<Button className="mr-2 mb-3 mb-sm-0"*/}
            <Button className="m-1"
                    style={{
                      color: 'white',
                      backgroundColor: record?.granted[modName]?.rColor,
                    }}
                    onClick={() => handleClick('read', record)}
                    disabled={isDisabled(record)}
                    shape="square"
                    icon={<SearchOutlined/>}
                    size="small"/>
          </Tooltip>
          {/* read */}


          {/* update */}
          <Tooltip title={record?.granted[modName]?.uTitle &&
            setLocale(true, record?.granted[modName]?.uTitle)}>
            {/*<Button className="mr-2 mb-3 mb-sm-0"*/}
            <Button className="m-1"
                    style={{
                      color: 'white',
                      backgroundColor: record?.granted[modName]?.uColor,
                    }}
                    onClick={() => handleClick('update', record)}
                    disabled={isDisabled(record)}
                    shape="square"
                    icon={<FormOutlined/>}
                    size="small"/>
          </Tooltip>
          {/* update */}


          {/* delete */}
          <Tooltip title={record?.granted[modName]?.dTitle &&
            setLocale(true, record?.granted[modName]?.dTitle)}>
            {/*<Button className="mr-2 mb-3 mb-sm-0"*/}
            <Button className="m-1"
                    style={{
                      color: 'white',
                      backgroundColor: record?.granted[modName]?.dColor,
                    }}
                    onClick={() => handleClick('delete', record)}
                    disabled={isDisabled(record)}
                    shape="square"
                    icon={<DeleteOutlined/>}
                    size="small"/>
          </Tooltip>
          {/* delete */}


          {/* moderator */}
          <Tooltip title={setLocale(true, 'moderator')}>
            {/*<Button className="mr-2 mb-3 mb-sm-0"*/}
            <Button className="m-1"
                    style={{
                      color: 'white',
                      backgroundColor: record?.granted[modName]?.dColor,
                    }}
                    onClick={() => handleClick('moderator', record)}
                    disabled={isDisabled(record)}
                    shape="square"
                    icon={<SafetyCertificateOutlined/>}
                    size="small"/>
          </Tooltip>
          {/* moderator */}


          {/* custom permission */}
          <Tooltip title={setLocale(true, 'custom_permission')}>
            {/*<Button className="mr-2 mb-3 mb-sm-0"*/}
            <Button className="m-1"
                    style={{
                      color: 'white',
                      backgroundColor: record?.granted[modName]?.dColor,
                    }}
                    onClick={() => handleClick('custom_permission', record)}
                    disabled={isDisabled(record)}
                    shape="square"
                    icon={<MoreOutlined/>}
                    size="small"/>
          </Tooltip>
          {/* custom */}

        </>)
      },
    },

  ]

  const showInfo = () => {
    Modal.info({
      title: 'Níveis de Permissões', content: (<div>
        <p className="mt-4">
          <strong>Nível 1</strong>: Acesso apenas á documentos criados pelo próprio usuário.
        </p>
        <p><strong>Nível 2</strong>: Acesso á todos os documentos.</p>
      </div>),

      onOk () {
      },
    })
  }

  const modalButtons = [

    <Row justify="space-between" key="footerButtons">

      {/*<Row align="middle">*/}
      {/* {data?._id ? <span>_id: {data._id}</span> : null} */}
      {/*</Row>*/}

      {/*<Row justify="space-between">*/}


      <Button key="cancel"
              onClick={showInfo}
              icon={<QuestionCircleOutlined/>}
      >
      </Button>

      <Button key="ok"
              type="primary"
        // loading={updating}
        // disabled={showModal && hasFormError(form)}
              onClick={onClose}
      >
        <span>{setLocale(true, 'confirm')}</span>
      </Button>
      {/*</Row>*/}

    </Row>]

  return (<>

    <Modal
      title={<>
        <Row>
          <Col>
            <Row align="middle"><span className="mb-1">{title}</span></Row>
            <Row><span className="text-muted mt-1"><AppBreadcrumb/></span></Row>
          </Col>
        </Row>
      </>}
      open={showModal}
      // width={640}
      onCancel={onClose}
      getContainer={false}
      footer={modalButtons}
      className="modal-without-top-padding">


      <Table rowKey="_id"
             columns={columns}
             pagination={false}
             dataSource={data}
      />


    </Modal>

  </>)

}

// EXPORT **********************************************************************
// *****************************************************************************

const mapStateToProps = ({ auth, moduleRedux }) => {
  const { rdxSubscription } = auth
  const { rdxModule } = moduleRedux
  return { rdxSubscription, rdxModule }
}

export default connect(mapStateToProps)(AccessControl)



