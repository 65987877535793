import React from 'react'
import { Tag, Tooltip } from 'antd'
import { getDocumentStatusColor } from 'utils/helpers'
import { DeleteOutlined, FolderOutlined } from '@ant-design/icons'
import IntlMessage from '../../../util-components/IntlMessage'
import DocumentStatusTag from './DocumentStatusTag'
import moment from 'moment/moment'

const setLocale = (isLocaleOn, localeKey) => isLocaleOn ?
  <IntlMessage id={localeKey}/> : localeKey.toString()

// COMPONENT *******************************************************************
// *****************************************************************************

const DocumentTags = props => {

  const { record } = props

  const atualizaInfo = record?.__atualizaInfo
  const atualizaInfoStatus = atualizaInfo?.updateSentAt
    ? 'updated'
    : atualizaInfo?.updateRequiredAt
      ? 'pending_update'
      : 'not_updated'

  const atualizaInfoWaitingFor = atualizaInfo?.updateRequiredAt && moment(atualizaInfo?.updateRequiredAt).fromNow()
  const atualizaInfoUpdatedFor = atualizaInfo?.updateSentAt && moment(atualizaInfo?.updateSentAt).fromNow()

  return (<div>

    {atualizaInfo &&
      <Tooltip placement="top" title={atualizaInfoUpdatedFor || atualizaInfoWaitingFor}>
        <Tag className="text-capitalize" style={{ margin: 4 }}
             color={getDocumentStatusColor(atualizaInfoStatus)}>
          {setLocale(true, atualizaInfoStatus)}
        </Tag>
      </Tooltip>
    }

    <DocumentStatusTag record={record}/>

    {record?._metadata?.audit?.removedAt && <Tooltip placement="top"
                                                     title={setLocale(true,
                                                       'this_document_removed')}>
      <Tag className="text-capitalize" style={{ margin: 4 }}
           color={getDocumentStatusColor('removed')}>
        <DeleteOutlined className="mr-2"/>
        {setLocale(true, 'removed')}
      </Tag>
    </Tooltip>}

    {record?._metadata?.audit?.filedAt && <Tooltip placement="top"
                                                   title={setLocale(true,
                                                     'this_document_filed')}>
      <Tag className="text-capitalize" style={{ margin: 4 }}
           color={getDocumentStatusColor('archive')}>
        <FolderOutlined className="mr-2"/>
        {setLocale(true, 'filed')}
      </Tag>
    </Tooltip>}

  </div>)
}

export default DocumentTags
