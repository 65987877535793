import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { Col, Form, Row } from 'antd';
import { ROW_GUTTER } from 'constants/ThemeConstant';
import {
  FormInputNumber,
  FormSaveButton,
  FormSimpleSwitch,
  FormTitle,
  FormWrapper,
} from 'views/app-views/portals/autaliza-info/FormComponets';
import { translate } from 'utils/react-jarvisly-helper';
import { useIntl } from 'react-intl';

// COMPONENT *******************************************************************
// *****************************************************************************

const MultiOperatorsParameters = forwardRef((props, ref) => {

  // props deconstruction ------------------------------------------------------
  const {
    _module,
    _dataRoots,
    _data,
    dataContext,
    setDataContext,
    formContext,
    setFormContext,
  } = props;

  // component states ----------------------------------------------------------
  const [, setSubmitted] = useState(false);

  // local variables -----------------------------------------------------------
  const intl = useIntl();
  const [form] = Form.useForm();
  const isDisabled = formContext.mode === 'view';
  const formName = 'parametersForm';
  const elRefs = buildElementsRefs(useRef);
  const t = buildTranslations();
  const exchangeSelectOptions = buildExchangeSuffixOptions();

  // ant dataPicker workaround to fix issue about date
  // if (_data?.cnpj) _data.cnpj1 = parseCnpjToDisplay(_data.cnpj)

  // hooks ---------------------------------------------------------------------

  useEffect(() => {

    if (_data) {
      form.resetFields();

      if (_data?.exchanges && _data?.exchanges?.length > 0) {
        const exchange = _data.exchanges?.find(x => x?.currency === 'usd');
        if (exchange && exchange?.value) _data.exchange = exchange.value;
      }

      form?.setFieldsValue(_data);

    } else {

      form.resetFields();

      // workaround because after reset form the tag refs be lost
      setTimeout(() => handleFocus(undefined, 'all'));
    }

    // setDataContext({ ...dataContext, formsChanged: [] })

  }, [_data, form, formContext?.refreshTime]); // eslint-disable-line react-hooks/exhaustive-deps

  // methods -------------------------------------------------------------------

  useImperativeHandle(ref, () => ({
    handleFocus: field => handleFocus(field),
  }));

  function handleFocus (field = 'exchange', cursorPosition = 'all') {
    elRefs[field]?.current?.focus({ cursor: cursorPosition });
  }

  const onBeforeSave = async values => {

    setSubmitted(true);

    const usdExchange = {
      currency: 'usd',
      value: values?.exchange || 1
    };
    const exchanges = _data?.exchanges || [];
    const exchangeId = exchanges?.findIndex(x => x.currency === 'usd');

    if (exchangeId > -1) {
      exchanges[exchangeId] = usdExchange;

    } else {
      exchanges.push(usdExchange);
    }

    return {
      _id: _dataRoots._id,
      parameters: {
        integrated: values?.integrated || false,
        exchanges,
      },
    };
  };

  // UI COMPONENT --------------------------------------------------------------

  return (

    <FormWrapper name={formName}
                 form={form}
                 elRefs={elRefs}
                 uriApi={_module.api}
                 initialValues={_data}
                 dataContext={dataContext}
                 setDataContext={setDataContext}
                 formContext={formContext}
                 setFormContext={setFormContext}
                 onBeforeSave={onBeforeSave}
                 showToast>


      {/* ************************************************************
      INTEGRATION
      ************************************************************ */}

      {/* API INTEGRATION */}
      <FormTitle title={'integration'}/>

      <Row gutter={ROW_GUTTER}>

        <Col xs={12} md={6}>
          <FormInputNumber name="exchange"
                           elRef={elRefs.exchange}
                           disabled={isDisabled}
                           decimalSeparator=","
                           min={0.01}
                           max={99.99}
            // addonAfter={ExchangeCurrencyTypesAddonSuffix}
                           suffixDefaultValue="usd"
                           suffixStyle={{ width: 60 }}
                           addonAfter={exchangeSelectOptions}
                           required/>
        </Col>

        <Col xs={12} md={6}>
          <FormSimpleSwitch name="integrated"
                            label="api_integrated"
                            form={form}
                            value={_data?.integrated}
                            elRef={elRefs?.integrated}
                            disabled={isDisabled}/>


        </Col>

      </Row>
      {/* API INTEGRATION */}


      {/* SAVE BUTTON */}
      <FormSaveButton loading={dataContext?.partialLoading}
                      form={form}
                      formName={formName}
                      dataContext={dataContext}
                      onlySave
                      setSubmitted={setSubmitted}
                      disabled={isDisabled}/>
      {/* SAVE BUTTON */}


    </FormWrapper>

  );

  // INTERNAL FUNCTIONS ========================================================
  // ===========================================================================

  function buildElementsRefs (hookUseRef) {
    return {
      // identification
      integrated: hookUseRef(null),
      exchange: hookUseRef(null),
    };
  }

  function buildTranslations () {

    return {
      dollar: translate(intl, 'dollar'),
      euro: translate(intl, 'euro'),
      dollarTooltip: translate(intl, 'usd_description'),
    };
  }

  function buildExchangeSuffixOptions () {

    return [
      {
        value: 'usd',
        text: t.dollar,
        label: '$',
        disabled: false,
        tooltipText: t.dollarTooltip,
      },
      // {
      //   value: 'eur',
      //   text: t.euro,
      //   label: '€',
      //   disabled: false,
      //   tooltipText: t.euro,
      // },
    ];
  }

});

// EXPORT **********************************************************************
// *****************************************************************************

export default MultiOperatorsParameters;
