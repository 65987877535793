import { Col, Empty, Row } from 'antd';
import React from 'react';

const NoData = ()=> (
  <Row justify="center" align="middle" style={{ height: '50vh' }}>
    <Col>
      <Empty image={Empty.PRESENTED_IMAGE_SIMPLE}/>
    </Col>
  </Row>
)

export default NoData
