import React from 'react'
import { Tag, Tooltip } from 'antd'
import {
  getDocumentStatusColor, getDocumentStatusDescription,
} from 'utils/helpers'
import IntlMessage from '../../../util-components/IntlMessage'
import { useIntl } from 'react-intl'

const setLocale = (isLocaleOn, localeKey) => isLocaleOn ? <IntlMessage id={localeKey}/> : localeKey.toString()

// COMPONENT *******************************************************************
// *****************************************************************************

const DocumentStatusTag = props => {

  const { record } = props
  const dse = record?._metadata?.__documentStatus // document status in english
  const documentStatus = dse && setLocale(true, dse)
  const reservationNote = record?._metadata?.audit?.reservationNote
  const intl = useIntl()

  const tooltip = reservationNote
    ? reservationNote
    : setLocale(true, getDocumentStatusDescription(dse, record, intl))

  return (<>
    {documentStatus &&

      <Tooltip placement="top" title={tooltip}>

        <Tag className="text-capitalize"
             style={{ margin: 4 }}
             color={getDocumentStatusColor(dse)}>
          {documentStatus}
        </Tag>

      </Tooltip>
    }
  </>)
}

export default DocumentStatusTag
