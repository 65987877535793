import React, { useEffect, useState } from 'react';
import { connect, useSelector } from 'react-redux';
import SideNav from 'components/layout-components/SideNav';
import TopNav from 'components/layout-components/TopNav';
import Loading from 'components/shared-components/Loading';
import MobileNav from 'components/layout-components/MobileNav';
import HeaderNav from 'components/layout-components/HeaderNav';
import PageHeader from 'components/layout-components/PageHeader';
import Footer from 'components/layout-components/Footer';
import AppViews from 'views/app-views/routes';
import { Layout, Grid, Affix, Alert } from 'antd';
import { buildMenu } from 'configs/jarvisly-products';
import {
  SIDE_NAV_WIDTH,
  SIDE_NAV_COLLAPSED_WIDTH,
  NAV_TYPE_SIDE,
  NAV_TYPE_TOP,
  DIR_RTL,
  DIR_LTR,
} from 'constants/ThemeConstant';
import utils from 'utils';
import { useThemeSwitcher } from 'react-css-theme-switcher';
import { useAccount } from 'auth/auth-api';
import { findInObjectRecursively } from 'utils/helpers';
import IntlMessage from 'components/util-components/IntlMessage';
import { isBlockedModule } from 'configs/jarvisly-products';

// GLOBAL VARIABLES ************************************************************
// *****************************************************************************

const setLocale = (isLocaleOn, localeKey) => isLocaleOn ?
  <IntlMessage id={localeKey}/> : localeKey.toString();

const { Content } = Layout;
const { useBreakpoint } = Grid;

export const AppLayout = ({
  navCollapsed,
  navType,
  location,
  direction,
  token,
  rdxSubscription,
}) => {

  const rdxModule = useSelector(r => r.moduleRedux?.rdxModule);
  const navigationConfig = buildMenu(rdxSubscription);
  const currentRouteInfo = findInObjectRecursively(navigationConfig, 'key', location.pathname, 'children');
  const screens = utils.getBreakPoint(useBreakpoint());
  const isMobile = screens.length === 0 ? false : !screens.includes('lg');
  const [isNavSide, setIsNavSide] = useState(false);
  const [isNavTop, setIsNavTop] = useState(false);

  useEffect(() => {
    setIsNavSide(navType === NAV_TYPE_SIDE);
    setIsNavTop(navType === NAV_TYPE_TOP);
    }, [navType]);

  const getLayoutGutter = () => {
    if (isNavTop || isMobile) {
      return 0;
    }
    return navCollapsed ? SIDE_NAV_COLLAPSED_WIDTH : SIDE_NAV_WIDTH;
  };

  const { status } = useThemeSwitcher();
  const [loading] = useAccount(token);

  const getLayoutDirectionGutter = () => {
    if (direction === DIR_LTR) {
      return { paddingLeft: getLayoutGutter() };
    }
    if (direction === DIR_RTL) {
      return { paddingRight: getLayoutGutter() };
    }
    return { paddingLeft: getLayoutGutter() };
  };

  const blockModule = isBlockedModule(rdxModule?.marketplace);

  return ((loading || status === 'loading') ? <Loading cover="page"/> : <Layout>
    <HeaderNav isMobile={isMobile} routeInfo={currentRouteInfo}/>
    {(isNavTop && !isMobile) ? <TopNav routeInfo={currentRouteInfo}/> : null}
    <Layout className="app-container">
      {(isNavSide && !isMobile)
        ? <SideNav routeInfo={currentRouteInfo}/>
        : null}
      <Layout className="app-layout" style={getLayoutDirectionGutter()}>

        <div className={`app-content ${isNavTop
          ? 'layout-top-nav'
          : 'layout-left-nav'}`}
             style={blockModule ? {
               opacity: 0.1,
               pointerEvents: 'none'
             } : {}}
        >
          <PageHeader display={currentRouteInfo?.breadcrumb} title={currentRouteInfo.label}/>
          <Content>
            <AppViews/>
          </Content>


        </div>

        {blockModule
          ? <Affix offsetTop={200} style={{
            position: 'absolute',
            top: 0,
            right: 24,
            zIndex: 0,
          }}>
            <Alert
              className="mt-3"
              message="Demo"
              description={setLocale(true, 'evaluation_period_expired')}
              type="error"
              showIcon
            />
          </Affix>
          : null}

        <Footer/>
      </Layout>
    </Layout>
    {isMobile && <MobileNav/>}
  </Layout>);
};

const mapStateToProps = ({
  theme,
  auth
}) => {
  const {
    navCollapsed,
    navType,
    locale
  } = theme;
  const {
    token,
    rdxSubscription
  } = auth;
  return {
    navCollapsed,
    navType,
    locale,
    token,
    rdxSubscription
  };
};

export default connect(mapStateToProps)(React.memo(AppLayout));
