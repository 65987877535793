import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
} from 'react';
import {
  TimePicker,
  Col,
  Form,
  Input,
  Row,
} from 'antd';
import {onFormFinishFailed,} from 'utils/helpers';
import IntlMessage from 'components/util-components/IntlMessage';
import {ROW_GUTTER} from 'constants/ThemeConstant';
import moment from 'moment-timezone';
import {clone} from 'jarvisly-helper';

// GLOBAL VARIABLES ************************************************************
// *****************************************************************************

const {TextArea} = Input;

const setLocale = (isLocaleOn, localeKey) => isLocaleOn ?
    <IntlMessage id={localeKey}/> : localeKey.toString();

// COMPONENT *******************************************************************
// *****************************************************************************

const MainTaskTab = forwardRef((props, ref) => {

  // props deconstruction ------------------------------------------------------
  const {onClose, task, record} = props;

  let maxOrder = task?.filter(x => !x.__type && !x.isInterval).length;
  if (!record?._id) maxOrder += 1;
  const order = (record?.order && Math.round(record.order)) || maxOrder;
  if (record?.order) record.order = order;

  // component states ----------------------------------------------------------

  // local variables -----------------------------------------------------------

  const isDisabled = false; // formContext.mode === 'view' || _loading;
  const [form] = Form.useForm();

  const elRefs = {
    task: useRef(null),
    time: useRef(null),
    note: useRef(null),
  };

  const rules = {

    task: [
      {
        required: true, message: setLocale(true, 'required_field'),
      }],

    time: [
      {
        required: true, message: setLocale(true, 'required_field'),
      }],

  };

  // hooks ---------------------------------------------------------------------

  useEffect(() => {

    if (record) {
      form.setFieldsValue({...record});

    } else {
      form.resetFields();
    }

    handleFocus();

  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useImperativeHandle(ref, () => ({
    handleFocus: field => handleFocus(field),
    submitForm: () => form.submit(),
  }));

  // METHODS -------------------------------------------------------------------

  function handleFocus(field) {
    let focus = field ? field : 'task';
    setTimeout(() => elRefs[focus]?.current?.focus({cursor: 'all'}), 400);
  }

  const onFinishFailed = errorInfo => onFormFinishFailed(errorInfo, elRefs);

  const onFinish = async values => {
    const body = clone(values);
    body._id = record?._id;
    body.time = moment(body.__time).format('HH:mm');
    onClose(null, body);
  };

  // UI COMPONENT --------------------------------------------------------------

  return (<div>

    <Form
        form={form}
        name="profile"
        layout="vertical"
        initialValues={record || {order: order}}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        // onFormValuesChange={saveContext}
        validateTrigger="onChange"
        labelCol={{span: 24}}
        wrapperCol={{span: 24}}
        noValidate>

      {/* BASIC INFO */}
      {/*<Row className="mb-2">*/}
      {/*  <h5 className="text-muted">*/}
      {/*    {setLocale(true, 'basic_info')}*/}
      {/*  </h5>*/}
      {/*</Row>*/}


      <Row gutter={ROW_GUTTER}>


        {/* NAME */}
        <Col xs={24} sm={18}>

          <Form.Item
              label={setLocale(true, 'task')}
              name="task"
              rules={rules.task}
          >
            <Input ref={elRefs.task}
                   disabled={isDisabled}/>

          </Form.Item>

        </Col>
        {/* NAME */}


        {/* TIME */}
        <Col xs={24} sm={6}>

          <Form.Item
              label={setLocale(true, 'at_time')}
              name="__time"
              valuePropName="value"
              className="form-item-hour"
              rules={rules.time}
          >

            <TimePicker
                ref={elRefs.time}
                style={{width: '100%'}}
                disabled={isDisabled}
                disabledTime={() => [
                  0, 1, 2, 3, 4, 5, 6, 20, 21, 22, 23]}
                format="HH:mm"
                showNow={false}
                minuteStep={5}
            />

          </Form.Item>

        </Col>
        {/* TIME */}


      </Row>


      <Row gutter={ROW_GUTTER}>

        {/* NOTE */}
        <Col xs={24}>

          <Form.Item
              label={setLocale(true, 'note')}
              name="note"
              rules={rules.note}>

            <TextArea ref={elRefs.note}
                      rows={3}
                      disabled={isDisabled}/>

          </Form.Item>

        </Col>
        {/* NOTE */}


      </Row>


    </Form>
  </div>);

});

// EXPORT **********************************************************************
// *****************************************************************************

export default MainTaskTab;
