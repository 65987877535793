import { post, put, del } from 'configs/ApiConfig';
import { isMongoObjectId } from './helpers';

// SENDING SMS CODE (mobile: +55???????????)
export const postSendSmsCode = async (mobile, text, name) => {

  const url = `/tools/send-sms-code/twilio`;
  const config = { headers: { 'x-resultasobject': true } };

  const body = {
    guestName: name || 'n/a',
    phoneNumber: mobile,
    ip: localStorage.getItem('ip') || '0.0.0.0',
    text: text,
  };

  return await post(url, body, config);
};

// VALIDATE SMS CODE
export const postValidateSmsCode = async (smsCodeId, code) => {
  const url = `/tools/match-sms-code`;
  const config = { headers: { 'x-resultasobject': true } };

  const body = {
    smsId: smsCodeId,
    code: code,
  };

  return await post(url, body, config);
};

// UPLOAD FILE TO AWS S3 (FaaS)
export const getS3SignedURLEndpoint = async options => {

  const {
    moduleRoot,
    file,
    operation,
    subscriptionId
  } = options;

  if (!['putObject', 'getObject', 'delObject'].includes(operation)) {
    console.error(
      'The \'operation\' property in \'body\' request, must be \'putObject\', \'getObject\' or \'delObject\'.');
    return false;
  }

  const url = `/tools/upload-file`;
  const config = { headers: { 'x-resultasobject': true } };

  const body = {
    operation: operation,
    uniqueKey: file.uid,
    originFileName: file.name,
    fileSize: file.size,
    fileType: file.type,
    fileModifiedDate: file.lastModifiedDate,
    module: moduleRoot,
    subscriptionId: subscriptionId,
    folder: '',
    fileName: '',
    fileUrl: '',
  };

  return await post(url, body, config);
};

/**
 * @param {string} key - Object path
 * @param {('getObject'|'putObject'|'deleteObject')} operation - HTTP Method
 * @returns {string} - A Temporary API_URL Endpoint
 */
/*
export const getSignedURLEndpoint = (key, operation) => {

  // return await new Promise(async resolve => {
  //
  //   const options = {
  //     Bucket: S3_BUCKET,
  //     Key: key, /!* Filename in the bucket *!/
  //     Expires: 3000, /!* Seconds *!/
  //   };
  //
  //   const url = await s3.getSignedUrlPromise(operation, options);
  //   resolve(url);
  // });

};

*/

export const postSendMessage = async (to, body, service = 'twilio') => {

  // const url = `/tools/send-message-v2/${service}`;
  // const config = {headers: {'x-resultasobject': true}};

  return new Promise(r => {
    setTimeout(() => r('ok'), 1000);
  });

  // const body = {
  //   guestName: name || 'n/a',
  //   phoneNumber: mobile,
  //   ip: localStorage.getItem('ip') || '0.0.0.0',
  //   text: text,
  // };

  // return await post(url, body, config);
};

export const postAttachment = async file => {

  let url = `/attachments`;
  const config = { headers: { 'x-resultasobject': true } };

  const body = {
    ...file,
    name: file?.fileName || 'unknown file',
    service: 'firebase-storage'
  };

  try {
    return await post(url, body, config);

  } catch (error) {
    throw error;
  }

};

export const putAttachment = async (_id, body) => {

  if (!isMongoObjectId(_id)) return console.error('Invalid _id!');

  let url = `/attachments/${_id}`;
  const config = { headers: { 'x-resultasobject': true } };

  try {
    return await put(url, body, config);

  } catch (error) {
    throw error;
  }

};

export const delAttachment = async _id => {

  if (!isMongoObjectId(_id)) return console.error('Invalid _id!');

  let url = `/attachments/${_id}`;
  const config = { headers: { 'x-resultasobject': true } };

  try {
    // del twice to force permanently removal
    setTimeout(async () => await del(url, config), 1000);
    return await del(url, config);

  } catch (error) {
    throw error;
  }

};

