import { del, get, getV2, post, put } from 'configs/ApiConfig';
import { generateHexColor, isMongoObjectId } from 'utils/helpers';
import { clone } from 'jarvisly-helper';
import moment from 'moment';
import { api } from './module-methods';

// ************************************************************************** //
// MODULE APIS
// ************************************************************************** //

// GET LIST
export const getList = async (_module, qsStr, headers = {}) => {

  const url = `${_module.api}${qsStr}`;
  const isObject = headers['x-resultasobject'];
  const key = isObject ? 'doc' : 'docs';

  try {
    const config = { headers: headers };
    const result = await get(url, config, true);

    if (result && result[key]) {
      result[key] = _module?.methods?.decorateDocument && _module.methods.decorateDocument(result[key]);
    }

    return result;

  } catch (error) {
    throw error;
  }
};

// GET DOCUMENT LIST BY FIELD
export const getDocumentListByField = async (_module, field, text) => {

  const url = `${_module.api}/get-list-by-field/${field}/${text}`;
  const config = { headers: { 'x-resultasobject': false } };

  try {
    const result = await get(url, config, true);

    const key = 'docs';

    if (result && result[key]) {
      result[key] = _module.methods.decorateDocument(result[key]);
    }

    return (result && result[key]) || [];

  } catch (error) {
    throw error;
  }
};

// GET DOCUMENT
export const getDocument = async (_module, _id) => {

  if (!isMongoObjectId(_id)) {
    return console.error('Invalid _id! getDocument()');
  }

  const url = `${_module.api}/${_id}`;
  const config = { headers: { 'x-resultasobject': true } };

  try {
    const result = await getV2(url, config);
    const data = result?.response?.data;

    if (data) {
      result.doc = _module?.methods?.decorateDocument
        ? _module.methods.decorateDocument(clone(data))
        : clone(data);
      result.docLock = _module?.methods?.decorateDocument
        ? _module.methods.decorateDocument(clone(data))
        : clone(data);
    }

    return result;

  } catch (error) {
    throw error;
  }
};

// POST
export const postDocument = async (_module, body) => {

  const url = `${_module.api}`;
  const config = { headers: { 'x-resultasobject': true } };

  _module.methods.postDocumentValidation &&
  _module.methods.postDocumentValidation(_module, body);

  try {
    const doc = await post(url, body, config);

    return doc && _module.methods.decorateDocument
      ? _module.methods.decorateDocument(doc)
      : null;

  } catch (error) {
    throw error;
  }

};

// PUT
export const putDocument = async (_module, _id, values) => {

  const body = clone(values);

  if (!isMongoObjectId(_id)) {
    return console.error('Invalid _id! putDocument()');
  }

  const url = `${_module.api}/${_id}`;
  const config = { headers: { 'x-resultasobject': true } };
  // const body = objToStrNotation(v); // for sub documents
  _module.methods?.putDocumentValidation &&
  _module.methods.putDocumentValidation(_module, body);

  try {
    const doc = await put(url, body, config);
    return doc && _module.methods.decorateDocument
      ? _module.methods.decorateDocument(doc)
      : null;

  } catch (error) {
    throw error;
  }
};

// DELETE
export const removeDocument = async (_module, data, intl) => {

  const body = _module?.methods?.removeDocumentValidation
    ? _module.methods.removeDocumentValidation(_module, data, intl)
    : data;

  if (body === 'abort') return;

  try {
    return await api.del(_module.api, body);

  } catch (error) {
    throw error;
  }
};

// ARCHIVE
export const archiveDocument = async (_module, data, rdxUser) => {

  const _id = data?._id;

  if (!isMongoObjectId(_id)) {
    return console.error('Invalid _id! archiveDocument()');

  } else if (!data) {
    return console.error('Invalid data! archiveDocument()');

  } else if (!rdxUser) {
    return console.error('Invalid rdxUser! archiveDocument()');
  }

  const url = `${_module.api}/${_id}`;
  const config = { headers: { 'x-resultasobject': true } };

  let body = {
    '_metadata.audit.filedAt': moment(),
    '_metadata.audit.filedByAccountId': rdxUser._id,
  };

  try {
    const doc = await put(url, body, config);
    return doc ? _module.methods.decorateDocument(doc) : null;

  } catch (error) {
    throw error;
  }
};

// RESTORE
export const restoreDocument = async (_module, data) => {

  const _id = data?._id;
  const isRemoved = data?._metadata?.audit?.removedAt;
  const isFiled = data?._metadata?.audit?.filedAt;

  if (!isMongoObjectId(_id)) {
    return console.error('Invalid _id! restoreEntity()');

  } else if (!isRemoved && !isFiled) {
    return console.error('Invalid isRemoved/isFiled! restoreEntity()');
  }

  const url = `${_module.api}/${_id}`;
  const config = { headers: { 'x-resultasobject': true } };

  const body = isFiled
    ? {
      '_metadata.audit.filedAt': null,
      '_metadata.audit.filedByAccountId': null,
    }
    : {
      '_metadata.audit.removedAt': null,
      '_metadata.audit.removedByAccountId': null,
    };

  try {
    const doc = await put(url, body, config);
    return doc ? _module.methods.decorateDocument(doc) : null;

  } catch (error) {
    throw error;
  }
};

// UPDATE DOCUMENT STATUS
export const updateDocumentStatus = async (_module, record, values, rdxUser) => {

  const _id = record?._id;

  if (!isMongoObjectId(_id)) {
    return console.error('Invalid _id! updateDocumentStatus()');

  } else if (!rdxUser?._id) {
    return console.error('Invalid rdxUser! updateDocumentStatus()');

  } else if (!values?.status) {
    return console.error('Invalid value! updateDocumentStatus()');
  }

  const documentStatus = values?.status === 'with_reservation'
    ? 2
    : values?.status === 'revised'
      ? 1
      : 0;

  const backColor = (!record?.backColor || (record?.backColor === '#b9bcc4'))
    ? generateHexColor()
    : record.backColor;

  const foreColor = (!record?.backColor || (record?.backColor === '#b9bcc4'))
    ? generateHexColor(backColor, true)
    : record.foreColor;

  const body = {
    '_metadata.documentStatus': documentStatus,
    '_metadata.audit.authorizedAt': documentStatus > 0
      ? moment()
      : null,
    '_metadata.audit.authorizedByAccountId': documentStatus > 0
      ? rdxUser?._id
      : null,
    '_metadata.audit.reservationNote': documentStatus === 2
      ? values?.reservation
      : null,
  };

  body.foreColor = values.status === 'in_analise'
    ? '#ffffff'
    : foreColor;

  body.backColor = values.status === 'in_analise'
    ? '#b9bcc4'
    : backColor;

  const url = `${_module.api}/${_id}`;
  const config = { headers: { 'x-resultasobject': true } };

  try {
    const doc = await put(url, body, config);
    return doc ? _module.methods.decorateDocument(doc) : null;

  } catch (error) {
    throw error;
  }
};

// ----

export const getByUri = async (uri, _id) => {

  // if (!isMongoObjectId(_id)) return console.error('Invalid _id! getByUri()')

  const url = _id
    ? `${uri}/${_id}`
    : uri;

  const config = { headers: { 'x-resultasobject': true } };

  try {
    return await get(url, config);

  } catch (error) {
    throw error;
  }
};

export const postByUri = async (uri, body) => {

  const url = `${uri}`;
  const config = { headers: { 'x-resultasobject': true } };

  try {
    return await post(url, body, config);

  } catch (error) {
    throw error;
  }

};

export const putByUri = async (uri, _id, values) => {

  const body = clone(values);

  if (!isMongoObjectId(_id)) return console.error('Invalid _id! putByUri()');

  const url = `${uri}/${_id}`;
  const config = { headers: { 'x-resultasobject': true } };

  try {
    return await put(url, body, config);

  } catch (error) {
    throw error;
  }
};

export const pushSubdocumentByUri = async (uri, _id, field, values) => {

  const url = `${uri}/$push/${field}/${_id}`;
  const config = { headers: { 'x-resultasobject': true } };
  const body = clone(values);

  if (!isMongoObjectId(_id)) return console.error('Invalid _id! pushSubdocumentByUri()');

  try {
    return await put(url, body, config);

  } catch (error) {
    throw error;
  }
};

export const pullSubdocumentByUri = async (uri, _id, field) => {

  const url = `${uri}/$pull/${field}/${_id}`;
  const config = { headers: { 'x-resultasobject': true } };

  if (!isMongoObjectId(_id)) return console.error('Invalid _id! pullSubdocumentByUri()');

  try {
    return await put(url, {}, config);

  } catch (error) {
    throw error;
  }
};

// ************************************************************************** //
// SETTINGS APIS
// ************************************************************************** //

export const getSettingsBySubscriptionId = async subscriptionId => {

  const url = `settings/get-by-subscriptionid/${subscriptionId}`;
  const config = { headers: { 'x-resultasobject': true } };

  try {
    const result = await get(url, config);
    return (result && result['doc']) || null;

  } catch (error) {
    throw error;
  }
};

export const getSettingsById = async _id => {

  const url = _id ? `settings/${_id}` : `settings`;
  const config = { headers: { 'x-resultasobject': true } };

  try {
    let result = await get(url, config);
    if (!result) result = await post(url, {}, config);
    return result;

  } catch (error) {
    throw error;
  }
};

export const putSettings = async (_id, body) => {

  const url = `settings/${_id}`;
  const config = { headers: { 'x-resultasobject': true } };

  try {
    return await put(url, body, config);

  } catch (error) {
    throw error;
  }
};

export const putSettingsConfigurations = async (subscriptionId, body) => {

  const url = `/subscriptions/settings/configurations/${subscriptionId}`;
  const config = { headers: { 'x-resultasobject': true } };

  try {
    return await put(url, body, config);

  } catch (error) {
    throw error;
  }
};

export const putSettingsRequiredFields = async (subscriptionId, body) => {

  const url = `/subscriptions/settings/required-fields/${subscriptionId}`;
  const config = { headers: { 'x-resultasobject': true } };

  try {
    return await put(url, body, config);

  } catch (error) {
    throw error;
  }
};

export const postValueToSettings = async options => {

  const {
    settingsId,
    _module,
    valueType,
    body
  } = options;
  const url = settingsId
    ? `settings/post-value/${settingsId}/${_module.name}/${valueType}`
    : `settings`;
  const config = { headers: { 'x-resultasobject': true } };

  try {
    return settingsId
      ? await put(url, body, config)
      : await post(url, body, config);

  } catch (error) {
    throw error;
  }
};

export const postValueToSettings_OLD = async options => {

  const {
    subscriptionId,
    _module,
    valueType,
    body
  } = options;
  const url = `subscriptions/settings/values/${subscriptionId}/${_module.name}/${valueType}`;
  const config = { headers: { 'x-resultasobject': true } };

  try {
    return await post(url, body, config);

  } catch (error) {
    throw error;
  }
};

export const putValuesInSettings = async options => {

  const {
    settingsId,
    _module,
    valueType,
    body
  } = options;
  const url = `settings/post-value/${settingsId}/${_module.name}/${valueType}`;
  const config = { headers: { 'x-resultasobject': true } };

  try {
    return await put(url, body, config);

  } catch (error) {
    throw error;
  }
};

export const delValueFromSettings = async options => {

  const {
    valueId,
    _module,
    valueType
  } = options;
  const url = `settings/delete-value/${valueId}/${_module.name}/${valueType}`;
  const config = { headers: { 'x-resultasobject': true } };

  try {
    return await del(url, config);

  } catch (error) {
    throw error;
  }
};

