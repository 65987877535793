import React, { forwardRef, useState } from 'react';
import { Button, Popconfirm, Row, Table, Tooltip } from 'antd';
import { DeleteOutlined, EditOutlined, EyeOutlined, PlusOutlined, } from '@ant-design/icons';
import IntlMessage from 'components/util-components/IntlMessage';
import { FormTitle } from '../../../portals/autaliza-info/FormComponets';
import ImageDescription from 'components/jarvisly/ImageDescription';
import EntitiesContactModal from '../Modals/ContactModal';
import { parsePhoneToDisplay, substitution } from 'utils/helpers';
import { translate } from 'utils/react-jarvisly-helper';
import { useIntl } from 'react-intl';

// GLOBAL VARIABLES ************************************************************
// *****************************************************************************

const setLocale = (isLocaleOn, localeKey) => isLocaleOn ? <IntlMessage id={localeKey}/> : localeKey.toString();

// COMPONENT *******************************************************************
// *****************************************************************************

const EntitiesContacts = forwardRef((props, ref) => {

  // props deconstruction ------------------------------------------------------
  const {
    _id,
    _module,
    _data,
    formContext,
    setFormContext,
    dataContext,
    onDeleteContact
  } = props;

  // component states ----------------------------------------------------------
  const [loading, setLoading] = useState(false);
  const [showContactModal, setShowContactModal] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState(null);

  // local variables -----------------------------------------------------------
  const isDisabled = formContext.mode === 'view';
  const intl = useIntl();
  const t = buildTranslations();

  // hooks ---------------------------------------------------------------------

  // METHODS -------------------------------------------------------------------

  const onRefresh = doc => {
    setFormContext({
      ...formContext,
      refreshTime: +new Date()
    });
    setTimeout(() => setLoading(false), 1000);
  };

  const onAdd = () => {
    setSelectedRecord(null);
    setShowContactModal(true);
  };

  const onEdit = (record) => {
    setSelectedRecord(record);
    setShowContactModal(true);
  };

  const onDelete = _id => {
    setLoading(true);
    onDeleteContact(_id);
    onRefresh();
  };

  // UI COMPONENT --------------------------------------------------------------

  return (<>

    <EntitiesContactModal _id={_id}
                          showModal={showContactModal}
                          setShowModal={setShowContactModal}
                          onRefresh={onRefresh}
                          setLoading={setLoading}
                          loading={loading}
                          _module={_module}
                          isDisabled={isDisabled}
                          record={selectedRecord}/>

    <Row justify="space-between" align="middle">

      <FormTitle title="contacts"/>

      <Button type="primary"
              icon={<PlusOutlined/>}
              className="mb-3"
              disabled={isDisabled}
              onClick={onAdd}>
        &nbsp;{setLocale(true, 'contact')}
      </Button>
    </Row>


    <Table rowKey="_id"
           columns={buildColumns()}
           loading={loading || dataContext?.partialLoading}
           pagination={false}
           dataSource={_data?.contacts || []}
    />

  </>);

  // INTERNAL FUNCTIONS ========================================================
  // ===========================================================================

  function buildTranslations () {

    const remove_this_item = translate(intl, 'remove_this_item');
    const item = translate(intl, 'contact');
    const t_remove_this_item = substitution(remove_this_item, ['%ITEM%'], [item]);

    return {
      remove_this_item: t_remove_this_item,
    };
  }

  function buildColumns () {

    return [
      {
        title: setLocale(true, 'name'),
        dataIndex: 'name',
        className: 'text-truncate',
        render: (_, record) => {
          return (<div className="d-flex" style={{ width: 200 }}>
            <ImageDescription
              shape="round"
              showAge={record?.birthday}
              title={record?.name}
              subtitle={record?.note}
              foreColor={record?.foreColor}
              backColor={record?.backColor}
            />
          </div>);
        },
      },

      {
        title: setLocale(true, 'contacts'),
        dataIndex: 'email',
        render: (_, record) => {

          const phone = parsePhoneToDisplay(record?.phone);

          return (<>
            <div>{phone}</div>
            <div>{record?.email}</div>
          </>);
        }

      },

      {
        title: setLocale(true, 'actions'),
        dataIndex: 'actions',
        width: 120, //113,
        className: 'text-center',
        render: (_, record) => {
          return (<>
            <Tooltip title={setLocale(true, !isDisabled ? 'edit' : 'view')}>
              <Button size="small"
                      className="m-2"
                      icon={!isDisabled ? <EditOutlined/> : <EyeOutlined/>}
                      onClick={() => onEdit(record)}/>
            </Tooltip>

            {!isDisabled && <Popconfirm title={t.remove_this_item}
                                        cancelButtonProps={{ className: 'fixed-with-90' }}
                                        okButtonProps={{ className: 'fixed-with-90' }}
                                        onConfirm={() => onDelete(record?._id)}>
              <Button size="small" icon={<DeleteOutlined/>}/>
            </Popconfirm>}
          </>);
        },
      }

    ].filter(x => !x.hidden);

  }

});

export default EntitiesContacts;
