import apiConfig, {del, get, post, put} from 'configs/ApiConfig';
import {useEffect, useState} from 'react';
import {isMongoObjectId, parseQueryString, stringifyQueryString} from 'utils/helpers';
import {useLocation, useHistory} from 'react-router-dom';

const buildQueryString = (location, history, qsObj) => {

  if (!qsObj) qsObj = parseQueryString(location);

  const qs = {
    current: qsObj?.current || 1,
    pageSize: qsObj?.pageSize || 10,
    sortField: qsObj?.sortField || 'name',
    sortOrder: qsObj?.sortOrder || 'ascend',
  };

  if (qsObj?.name) qs.name = qsObj.name;
  if (qsObj?.['anacInfo.code']) qs['anacInfo.code'] = qsObj['anacInfo.code'];

  const qsStr = stringifyQueryString(qs);

  history.replace({
    pathname: location.pathname, search: `?${qsStr}`,
  });

  return qsStr;
};

// FETCH
export const useCandidates = () => {

  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(null);
  const [summary, setSummary] = useState(null);

  const location = useLocation();
  const history = useHistory();

  useEffect(function fetch() {
    (async () => await refreshData(location, history))();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const refreshData = async (location, history, qsObj) => {
    setLoading(true);
    const response = await getCandidates(location, history, qsObj);
    setData(response?.docs);
    setSummary(response?.summary);
    setLoading(false);
  };

  return [loading, data, summary, refreshData];
};

// GET
export const getCandidates = async (location, history, qsObj) => {

  const qsStr = buildQueryString(location, history, qsObj);
  const url = `/appdedalo/candidates?${qsStr}`;
  const config = {headers: {'x-resultasobject': false}};

  try {
    return await get(url, config, true);

  } catch (error) {
    throw error;
  }
};

// GET CANDIDATE BY ID
export const getCandidateById = async _id => {

  const url = `/appdedalo/candidates/${_id}`;
  const config = {headers: {'x-resultasobject': true}};

  try {
    return await get(url, config);

  } catch (error) {
    throw error;
  }
};

// GET CANDIDATE BY CPF
export const getCandidateByCpf = async cpf => {

  if (!cpf) return null;

  const url = `/appdedalo/candidates`;
  const config = {
    headers: {'x-resultasobject': true}, params: {'cpf': cpf},
  };

  try {
    return await get(url, config);

  } catch (error) {
    throw error;
  }
};

// POST
export const postCandidate = async body => {

  const url = `/appdedalo/candidates`;
  const config = {headers: {'x-resultasobject': true}};

  try {
    return await post(url, body, config);

  } catch (error) {
    throw error;
  }

};

// POST RESET CANDIDATE'S PASSWORD
export const postResetPassword = async _id => {

  const callToAction = apiConfig.defaults.headers['x-subscriptionid'] ===
  '6282e7831b7d873e20a479a1'
      ? 'https://sistema.dedaloribeirao.com.br/online-schedule'
      : 'https://sistema.institutodedalo.com.br/online-schedule';

  const url = `/appdedalo/candidates-reset-password`;
  const config = {headers: {'x-resultasobject': true}};
  const body = {
    _id: _id, callToAction: callToAction,
  };

  try {
    return await post(url, body, config);

  } catch (error) {
    throw error;
  }

};

// PUT
export const putCandidate = async (_id, body) => {

  if (!isMongoObjectId(_id)) return console.error('Invalid _id!');

  const url = `/appdedalo/candidates/${_id}`;
  const config = {headers: {'x-resultasobject': true}};

  try {
    return await put(url, body, config);

  } catch (error) {
    throw error;
  }
};

// DELETE
export const removeCandidate = async _id => {

  if (!isMongoObjectId(_id)) return console.error('Invalid _id!');

  const url = `/appdedalo/candidates/${_id}`;
  const config = {headers: {'x-resultasobject': true}};

  try {
    return await del(url, config);

  } catch (error) {
    throw error;
  }
};
