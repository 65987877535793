import { MULTIOPERATORS_API_URL } from './constants';
import { addToSet } from 'configs/ApiConfig';

// ************************************************************************** //
// MODULE CUSTOM METHODS
// ************************************************************************** //

export const onDeleteMultiOperatorsContact = async _id => {
  await addToSet(MULTIOPERATORS_API_URL, _id, '$pull', 'contacts');
};
