import React, { forwardRef, useEffect, useImperativeHandle, useRef } from 'react'
import { Col, Form, Row } from 'antd'
import { useIntl } from 'react-intl'
import { translate } from 'utils/react-jarvisly-helper'
import { ROW_GUTTER } from 'constants/ThemeConstant'
import { onFormFinishFailed } from 'utils/helpers'
import { formDataChanges, FormInput, FormSelect, FormTitle } from '../../../FormComponets'

const BikeModalRegistrationTab = forwardRef((props, ref) => {

  // props deconstruction ------------------------------------------------------
  const {
    record,
    onSave,
    setIsFormChanged,
    _dataRoots,
  } = props

  // component states ----------------------------------------------------------

  // local variables -----------------------------------------------------------

  const [form] = Form.useForm()
  const intl = useIntl()
  const elRefs = buildElementsRefs(useRef)
  const brandOptions = buildBikeBrandOptions()
  const rimOptions = buildRimOptions()
  const colorOptions = buildColorOptions()

  // hooks ---------------------------------------------------------------------

  useEffect(() => {
    handleFocus()
  }, [record]) // eslint-disable-line react-hooks/exhaustive-deps

  // METHODS -------------------------------------------------------------------

  function handleFocus (field = 'brand', cursorPosition = 'start') {
    elRefs[field]?.current?.focus({ cursor: cursorPosition })
  }

  useImperativeHandle(ref, () => ({
    onSubmit: () => form.submit(),
  }))

  const onValuesChanges = () => {
    setIsFormChanged(formDataChanges(form, record))
  }

  const onFinishFailed = errorInfo => onFormFinishFailed(errorInfo, elRefs)

  const onFinish = values => onSave && onSave(values)

  // UI COMPONENT --------------------------------------------------------------

  return (<>


    <Form
      form={form}
      layout="vertical"
      initialValues={record}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      onValuesChange={onValuesChanges}
      labelCol={{ span: 24 }}
      wrapperCol={{ span: 24 }}
      preserve={false}>


      {/* BASIC INFO */}
      <FormTitle title={'bike_data'}/>

      <Row gutter={ROW_GUTTER}>

        {/* BRAND */}
        <Col xs={24} md={6}>
          <FormSelect name="brand"
                      elRef={elRefs.brand}
                      options={brandOptions}
                      disabled={_dataRoots?.updateSentAt}
                      required/>
        </Col>
        {/* BRAND */}

        {/* MODEL */}
        <Col xs={24} md={8}>
          <FormInput name="model"
                     elRef={elRefs.model}
                     disabled={_dataRoots?.updateSentAt}
                     required/>
        </Col>
        {/* MODEL */}


        {/* RIM */}
        <Col xs={24} md={4}>
          <FormSelect name="rim"
                      elRef={elRefs.rim}
                      options={rimOptions}
                      disabled={_dataRoots?.updateSentAt}
                      required/>
        </Col>
        {/* RIM */}


        {/* COLOR */}
        <Col xs={24} md={6}>
          <FormSelect name="color"
                      label="predominant_color"
                      elRef={elRefs.color}
                      options={colorOptions}
                      disabled={_dataRoots?.updateSentAt}
                      required/>
        </Col>
        {/* COLOR */}


      </Row>


      <Row gutter={ROW_GUTTER}>

        {/* NOTE */}
        <Col xs={24}>
          <FormInput name="note"
                     label="observation"
                     disabled={_dataRoots?.updateSentAt}
                     elRef={elRefs.note}/>
        </Col>
        {/* NOTE */}

      </Row>


    </Form>


  </>)

  // INTERNAL FUNCTIONS ========================================================
  // ===========================================================================

  function buildRimOptions () {

    const list = [
      12,
      16,
      20,
      24,
      26,
      27.5,
      29,
    ]

    return list.map(i => {
      return {
        value: i,
        text: i,
        label: i,
        disabled: false,
      }
    })
  }

  function buildColorOptions () {

    const list = [
      'beige',
      'black',
      'blue',
      'bronze',
      'brown',
      'burgundy',
      'caramel',
      'champagne',
      'gray',
      'green',
      'golden',
      'orange',
      'pink',
      'purple',
      'red',
      'silver',
      'turquoise',
      'white',
      'wine',
      'yellow',
    ]

    const arr = list.map(i => {
      return {
        value: i,
        text: translate(intl, i),
        label: translate(intl, i),
        disabled: false,
      }
    })

    arr.push({
      value: 'not_listed',
      text: translate(intl, 'not_listed'),
      label: translate(intl, 'not_listed').toUpperCase(),
      disabled: false,
    })

    return arr
  }

  function buildBikeBrandOptions () {

    const list = [
      'Audax',
      'Bianchi',
      'Cannondale',
      'Caloi',
      'Colnago',
      'Giant',
      'GT Bicycles',
      'Houston',
      'KHS',
      'Lapierre',
      'Merida',
      'Monark',
      'Santa Cruz Bicycles',
      'Scott',
      'Sense',
      'Specialized',
      'Soul Cycles',
      'Sundown',
      'Trek',
      'Yeti Cycles',
    ]

    const arr = list.map(i => {
      return {
        value: i.toLowerCase().replace(' ', '_'),
        text: i,
        label: i,
        disabled: false,
      }
    })

    arr.push({
      value: 'not_listed',
      text: translate(intl, 'not_listed'),
      label: translate(intl, 'not_listed').toUpperCase(),
      disabled: false,
    })

    return arr
  }

  function buildElementsRefs (hookUseRef) {
    return {
      brand: hookUseRef(null),
      model: hookUseRef(null),
      rim: hookUseRef(null),
      color: hookUseRef(null),

      note: hookUseRef(null),
    }
  }

})

// EXPORT **********************************************************************
// *****************************************************************************

export default BikeModalRegistrationTab


