import React, {useRef, useState} from 'react';
import {Col, Form, Input, Modal, Row} from 'antd';
import {useIntl} from 'react-intl';
import {msg, onFormFinishFailed} from 'utils/helpers';
import IntlMessage from '../../../util-components/IntlMessage';
import {translate} from 'utils/react-jarvisly-helper';

// GLOBAL VARIABLES ************************************************************
// *****************************************************************************

const setLocale = (isLocaleOn, localeKey) => isLocaleOn ?
    <IntlMessage id={localeKey}/> : localeKey.toString();

// COMPONENTS ******************************************************************
// *****************************************************************************

const NoteModal = ({showModal, setShowModal}) => {

  // component states -----------------------------------------------------------
  const [updating, setUpdating] = useState(false);

  // local variables and hooks --------------------------------------------------
  const intl = useIntl();
  const [form] = Form.useForm();

  const elRefs = {
    description: useRef(null),
  };

  const rules = {
    description: [
      {
        required: true, message: setLocale(true, 'required_field'),
      }],
  };

  const onClose = () => {
    form.resetFields();
    setUpdating(false);
    setShowModal(false);
  };

  const onOk = () => form.submit();

  // form submitted successfully
  const onFinish = async values => {
    setUpdating(true);
    msg('s', 'Anotação adicionada com sucesso!');
    // onClose();
  };

  // form submitted failure
  const onFinishFailed = errorInfo => onFormFinishFailed(errorInfo, elRefs);

  // UI COMPONENT --------------------------------------------------------------

  return (<>

    <Modal
        title={translate(intl, 'note')}
        // visible={showModal}
        open={showModal}
        // width={400}
        getContainer={false}
        onCancel={onClose}
        onOk={onOk}
        confirmLoading={updating}
        // footer={modalButtons}
    >


      <Form
          style={{width: '100%'}}
          form={form}
          layout="vertical"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          scrollToFirstError={true}
      >

        <Row gutter={16}>

          <Col xs={24}>

            <Form.Item name="description"
                       label={setLocale(true, 'description')}
                       rules={rules.description}>
              <Input ref={elRefs.description}
                     autoFocus
              />
            </Form.Item>

          </Col>

        </Row>

      </Form>

    </Modal>

  </>);

};

export default NoteModal;
