import { get } from 'configs/ApiConfig'
import { ATUALIZA_API_URL } from './atualiza-constants'
import { buildPhone } from 'utils/helpers'

// ************************************************************************** //
// MODULE APIS
// ************************************************************************** //

export const getAtualizaInfoByPhone = async phone => {

  const number = buildPhone(phone)?.numberFull

  let url = `${ATUALIZA_API_URL}/get-by-phone/${number}`
  const config = { headers: { 'x-resultasobject': false } }

  try {
    return await get(url, config)

  } catch (error) {
    throw error
  }
}

// levels:
// 1 = includes no excluded and no archived.
// 2 = includes excluded and no archived,
// 3 = includes no excluded and archived,
// 4 = includes excluded and archived
export const getAtualizaByField = async (field, value, level = 1, dataType, dataProfile) => {

  if (!field || !value) {
    return console.error(
      'missing \'field\' or \'value\' parameters! getAtualizaByField()')
  }

  if (value?.length < 3) return null

  let url = `${ATUALIZA_API_URL}/get-list-by-field/${field}/${value}/${level}`
  const config = { headers: { 'x-resultasobject': false } }

  if (dataType) url += `/${dataType}`
  if (dataProfile) url += `/${dataProfile}`

  try {
    return await get(url, config)

  } catch (error) {
    throw error
  }
}
