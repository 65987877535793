import { useEffect, useState } from "react";
import { rdxSetSubscription, rdxSetUser, signOut } from "redux/actions/auth";
import { get, post, put } from "configs/ApiConfig";
import { useDispatch } from "react-redux";
import { isMongoObjectId } from "utils/helpers";
import {
  onLocaleChange,
  onNavStyleChange,
  onNavTypeChange,
  toggleCollapsedNav,
} from "redux/actions/theme";
import { buildAppModules } from "configs/jarvisly-products";

// HOOK FETCH SELECTED USE BY ACCOUNT
export const useAccount = (token) => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(null);
  const dispatch = useDispatch();

  // let isRendering = false;
  useEffect(
    function fetch() {
      // if (isRendering || !token) return [true];
      if (!token) return [true];

      // isRendering = true;

      (async function () {
        const user = await getAccountByToken(token);

        if (!user || localStorage.getItem("portal") === "active") {
          return dispatch(signOut());
        }

        setAccount(user, dispatch);
        setData(user);
        setLoading(false);
      })();

      // return () => (isRendering = false);
    },
    [token],
  ); // eslint-disable-line react-hooks/exhaustive-deps

  return [loading, data];
};

// GET ACCOUNT FROM DB
export const getAccountByToken = async (token) => {
  const url = `/auth/get-user-by-token-v1/${token}`;
  const config = {
    headers: {
      "x-resultasobject": true,
      "x-subscriptionid": null,
    },
  };

  let user = await get(url, config);

  return buildAppModules(user);
};

// GET ACCOUNT BY EMAIL
export const getAccountByEmail = async (email) => {
  console.log(".....", email);
  const url = `/accounts/get-account-by-email/${email}`;
  const config = { headers: { "x-resultasobject": true } };
  return await get(url, config);
};

// GET FIREBASE USER
export const getFirebaseUser = async (data) => {
  const body = decorateFirebaseUser(data, data.provider);
  const config = { headers: { "x-resultasobject": true } };
  const url = `/auth/signing`;

  return await post(url, body, config);
};

// PUT ACCOUNT
export const putAccount = async (_id, body) => {
  if (!isMongoObjectId(_id)) return console.error("Invalid _id!");

  const url = `/accounts/${_id}`;
  const config = { headers: { "x-resultasobject": true } };

  try {
    return await put(url, body, config);
  } catch (error) {
    throw error;
  }
};

// Google OAuth Decorator
export const decorateFirebaseUser = (data, provider) => {
  const u = data?.user?.user;
  const moreInfo = data?.user?.additionalUserInfo;

  if (!u) return;

  return {
    provider: provider,
    name: u?.displayName || data?.user?.name,
    email: u?.email || "n/a",
    emailConfirmed: false,
    phoneNumber: u?.phoneNumber || data?.user?.phoneNumber,
    phoneNumberConfirmed: !!data?.user?.phoneNumber,
    pictureUrl: u?.photoURL || "n/a",
    token: u?.uid || "n/a",
    locale: moreInfo?.profile?.locale || "n/a",
    newUser:
      typeof moreInfo?.isNewUser === undefined ? "n/a" : moreInfo?.isNewUser,
  };
};

function setAccount(user, dispatch) {
  const availableSubscriptions = user?.subscriptions.filter(
    (x) => x.status === 2,
  );

  const subscriptionId = localStorage.getItem("subscriptionId");

  let subscription = availableSubscriptions?.find(
    (x) => x?._id === subscriptionId,
  );

  if (!subscription && availableSubscriptions?.length > 0) {
    subscription = availableSubscriptions[0];
  } else if (subscription?._id !== subscriptionId) {
    subscription = null;
  }

  dispatch(rdxSetSubscription(subscription));

  // const userSubscription = subscription?.team?.find(
  //     x => x.accountId === user._id);
  //
  // if (userSubscription) {
  //   user.profile = userSubscription?.profile || {};
  //   user.granted = userSubscription?.granted || {};
  // }

  dispatch(rdxSetUser({ ...user }));

  // Theme preferences
  const p = user?.systemPreferences;

  const menuPosition = p?.menuPosition?.toUpperCase() || "SIDE";
  dispatch(onNavTypeChange(menuPosition));

  // const darkMode = p?.darkMode ? 'dark' : 'light';
  // dispatch(onSwitchTheme(darkMode));

  const menuColor =
    p?.menuColor === "dark" ? "SIDE_NAV_DARK" : "SIDE_NAV_LIGHT";
  dispatch(onNavStyleChange(menuColor));

  dispatch(toggleCollapsedNav(!!p?.menuCollapsed));

  dispatch(onLocaleChange(p?.language || "pt"));
}

export const getSubscription = async (subscriptionId) => {
  const url = `subscriptions/${subscriptionId}`;
  const config = {
    headers: {
      "x-subscriptionid": null,
      "x-resultasobject": true,
    },
  };

  try {
    return await get(url, config);
  } catch (error) {
    throw error;
  }
};
