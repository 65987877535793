import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { Card, Col, Form, Row, Switch } from 'antd';
import { ROW_GUTTER } from 'constants/ThemeConstant';
import { isOdd, msg, sort } from 'utils/helpers';
import {
  forceFormChangesWithHiddenInput,
  FormAsyncAutoCompleteEntity,
  FormHiddenInput,
  FormInput,
  FormInputNumber,
  FormSaveButton,
  FormSelect,
  FormTitle,
  FormWrapper,
} from 'views/app-views/portals/autaliza-info/FormComponets';
import { translate } from 'utils/react-jarvisly-helper';
import { useIntl } from 'react-intl';
import { useRouter } from 'hooks/useRouter';
import IntlMessage from 'components/util-components/IntlMessage';
import { clone, normalize } from 'jarvisly-helper';
import UploadFirebaseComponent from 'components/jarvisly/UploadFirebaseComponent';

const setLocale = (isLocaleOn, localeKey) => isLocaleOn ? <IntlMessage id={localeKey}/> : localeKey.toString();

// COMPONENT *******************************************************************
// *****************************************************************************

const MultiPlansProfile = forwardRef((props, ref) => {

  // props deconstruction ------------------------------------------------------
  const {
    _id,
    _module,
    _data,
    dataContext,
    setDataContext,
    formContext,
    setFormContext,
  } = props;

  const destinationsInitialState = _data?.destinations ? [..._data?.destinations] : [];
  const travelProfilesInitialState = _data?.travelProfiles ? [..._data?.travelProfiles] : [];

  // component states ----------------------------------------------------------
  const [, setSubmitted] = useState(false);
  const [grievanceLevel, setGrievanceLevel] = useState('none');
  const [destinations, setDestinations] = useState(destinationsInitialState);
  const [travelProfiles, setTravelProfiles] = useState(travelProfilesInitialState);

  const [removeFileList, setRemoveFileList] = useState([]);
  const [conditionList, setConditionList] = useState([]);
  const [coverageList, setCoverageList] = useState([]);

  // local variables -----------------------------------------------------------

  const intl = useIntl();
  const router = useRouter();
  const t = buildTranslations();
  const isDisabled = formContext.mode === 'view';
  const formName = 'profileForm';
  const [form] = Form.useForm();
  const elRefs = buildElementsRefs(useRef);
  const quotationOptions = buildQuotationOptions();
  const grievanceOptions = buildGrievanceOptions(t);
  const destinationsOptions = buildDestinationsOptions(t);
  const travelProfileOptions = buildTravelProfileOptions(t);

  // ant dataPicker workaround to fix issue about date
  // if (_data?.city) _data.__city = parseCityToDisplay(_data?.city, _data?.province)

  // hooks ---------------------------------------------------------------------

  useEffect(() => {

    form.resetFields();

    if (_data?.__conditionsAttachments) {
      setConditionList([..._data.__conditionsAttachments]);
    }

    if (_data?.__coveragesAttachments) {
      setCoverageList([..._data.__coveragesAttachments]);
    }

    // destinations options update
    destinationsOptions?.map(x => {
      x.checked = _data?.destinations?.includes(x.value) || false;
      return x;
    });
    setDestinations(_data?.destinations ? [..._data.destinations] : []);

    // travel options update
    travelProfileOptions.map(x => {
      x.checked = _data?.travelProfiles?.includes(x.value) || false;
      return x;
    });
    setTravelProfiles(_data?.travelProfiles ? [..._data.travelProfiles] : []);

    if (_data?.grievanceLevel) setGrievanceLevel(_data.grievanceLevel);

    form?.setFieldsValue(_data || {});

    setDataContext({
      ...dataContext,
      formsChanged: []
    });

  }, [_data, form, formContext?.refreshTime]); // eslint-disable-line react-hooks/exhaustive-deps

  // methods -------------------------------------------------------------------

  useImperativeHandle(ref, () => ({
    handleFocus: field => handleFocus(field),
  }));

  function handleFocus (field = 'name', cursorPosition = 'all') {
    elRefs[field]?.current?.focus({ cursor: cursorPosition });
  }

  const onBeforeSave = async values => {

    setSubmitted(true);

    const conditionsFileUrl = conditionList?.[0]?.url;
    const coveragesFileUrl = coverageList?.[0]?.url;

    if (destinations.length === 0) {
      msg('e', t.choose_destination_required);
      return false;
    }

    if (!conditionsFileUrl || !coveragesFileUrl) {
      msg('e', t.attachments_required);
      return false;
    }

    const body = {
      ...values,
      operatorId: values?.selectedOperator?.key,
      destinations,
      travelProfiles,
      conditionsFileUrl,
      coveragesFileUrl
    };

    if (body?.condition) delete body.condition;
    if (body?.coverage) delete body.coverage;
    if (body?.selectedOperator) delete body.selectedOperator;

    body._id = _data?._id;

    return body;
  };

  const onAfterSave = async doc => {

    if (!doc) return;

    if (_id === 'add') { // reload the page/module

      setFormContext({
        ...formContext,
        dataMemory: null,
        mode: 'view'
      });

      setTimeout(() => {
        router.replace({
          pathname: `${_module?.url}/${doc._id}/profile`,
          state: { isActive: true }
        });
      }, 100);

    } else {
      // force update the dataContext and refresh all page
      setFormContext({
        ...formContext,
        dataMemory: null,
        refreshTime: +new Date(),
        mode: 'view'
      });
    }
  };

  const onError = error => {
    if (error?.status === 409) {
      msg('e', translate(intl, 'plan_already_exists'), 'error');
      handleFocus('room');
    }
  };

  const onChangeDestination = (item, checked) => {
    let destinationsArr = clone(destinations);
    if (checked) {
      destinationsArr.push(item.value);
    } else {
      destinationsArr = destinationsArr.filter(x => x !== item.value);
    }
    setDestinations([...destinationsArr]);
    forceFormChangesWithHiddenInput('destinations', destinationsArr, {
      form,
      _data,
      dataContext,
      setDataContext,
      formName
    });
  };

  const onChangeTravelProfile = (item, checked) => {
    let travelProfilesArr = clone(travelProfiles);
    if (checked) {
      travelProfilesArr.push(item.value);
    } else {
      travelProfilesArr = travelProfilesArr.filter(x => x !== item.value);
    }
    setTravelProfiles([...travelProfilesArr]);
    forceFormChangesWithHiddenInput('travelProfiles', travelProfilesArr, {
      form,
      _data,
      dataContext,
      setDataContext,
      formName
    });
  };

  // UI COMPONENT --------------------------------------------------------------

  return (

    <FormWrapper
      name={formName}
      form={form}
      elRefs={elRefs}
      uriApi={_module.api}
      initialValues={_data}
      dataContext={dataContext}
      setDataContext={setDataContext}
      formContext={formContext}
      setFormContext={setFormContext}
      onBeforeSave={onBeforeSave}
      onAfterSave={onAfterSave}
      onError={onError}
      showToast>

      {/* <FormInput name="destinations" */}
      {/*            style={{display:'none', height: 0}} */}
      {/*            // value={destinations} */}
      {/*            elRef={elRefs.destinations}/>      */}

      <FormHiddenInput name="destinations" form={form} _data={_data}/>
      <FormHiddenInput name="travelProfiles" form={form} _data={_data}/>


      {/* ************************************************************
      CHARACTERISTICS
      ************************************************************ */}
      {/* <FormTitle title={'characteristics'}/> */}

      <Card title={setLocale(true, 'plan')}>

        <Row gutter={ROW_GUTTER}>

          {/* NAME */}

          <Col xs={24} sm={12}>
            <FormInput name="name"
                       disabled={isDisabled}
                       required
                       autoFocus
                       elRef={elRefs.name}/>
          </Col>

          {/* NAME */}


          {/* OPERATOR */}

          <Col xs={24} sm={12}>
            <FormAsyncAutoCompleteEntity name="selectedOperator"
                                         label="operator"
                                         dataType="company"
                                         dataProfile="operator"
                                         disabled={isDisabled}
                                         value={_data?.selectedOperator}
                                         required
                                         elRef={elRefs.selectedOperator}/>
          </Col>
          {/* OPERATOR */}

        </Row>


        <Row gutter={ROW_GUTTER}>

          {/* QUOTATION */}
          <Col xs={8}>
            <FormSelect name="quotation"
                        elRef={elRefs.quotation}
                        options={quotationOptions}
                        disabled={isDisabled}
                        required/>
          </Col>
          {/* QUOTATION */}

          {/* MINIMAL DAYS */}
          <Col xs={8}>
            <FormInputNumber name="minDays"
                             label="days_from"
                             elRef={elRefs.minDays}
                             disabled={isDisabled}
                             style={{ width: '100%' }}
                             min={1}
                             max={365}
                             required/>
          </Col>
          {/* MINIMAL DAYS */}


          {/* MAXIMUM DAYS */}
          <Col xs={8}>
            <FormInputNumber name="maxDays"
                             label="days_to"
                             elRef={elRefs.maxDays}
                             disabled={isDisabled}
                             style={{ width: '100%' }}
                             min={1}
                             max={365}
                             required/>
          </Col>
          {/* MAXIMUM DAYS */}

        </Row>
      </Card>

      {/* ************************************************************
      DESTINATIONS
      ************************************************************ */}

      {/* DESTINATION */}
      <Card title={<>
        <span style={{
          color: 'red',
          // fontSize: 12,
          marginRight: 6
        }}>*</span>
        {setLocale(true, 'destinations')}</>}>

        <Row gutter={ROW_GUTTER}>

          {destinationsOptions.map((item, i) => (

            <Col key={i} xs={24} sm={12} style={{ height: 38 }}>

              <Row gutter={ROW_GUTTER} className={!isOdd(i) ? 'ml-sm-4' : ''}>
                <Col span={16}>{item.label}</Col>
                <Col span={8} className="text-right text-sm-left">
                  <Switch checked={destinations.includes(item.value)}
                          disabled={item.disabled || isDisabled}
                          onChange={checked => onChangeDestination(item, checked)}
                  />

                </Col>
              </Row>

            </Col>
          ))}


        </Row>

      </Card>


      {/* ************************************************************
      AGES
      ************************************************************ */}
      {/* <FormTitle title={'age_limits'}/> */}


      <Card title={setLocale(true, 'age_limits')}>

        <Row gutter={ROW_GUTTER}>

          {/* MINIMAL AGE */}
          <Col xs={8}>
            <FormInputNumber name="minAge"
                             label="minimal_age"
                             elRef={elRefs.minAge}
                             disabled={isDisabled}
                             style={{ width: '100%' }}
                             min={0}
                             max={99}
                             required/>
          </Col>
          {/* MINIMAL AGE */}


          {/* MAXIMUM AGE */}
          <Col xs={8}>
            <FormInputNumber name="maxAge"
                             label="maximum_age"
                             elRef={elRefs.maxAge}
                             disabled={isDisabled}
                             style={{ width: '100%' }}
                             min={0}
                             max={99}
                             required/>
          </Col>
          {/* MAXIMUM AGE */}


          {/* BEST AGE */}
          <Col xs={8}>
            <FormSelect name="grievanceLevel"
                        label="grievance_level"
                        elRef={elRefs.grievanceLevel}
                        options={grievanceOptions}
                        disabled={isDisabled}
                        onChange={v => setGrievanceLevel(v)}
                        required/>

            {/* <FormSimpleSwitch name="bestAge" */}
            {/*                   label="best_age" */}
            {/*                   form={form} */}
            {/*                   value={bestAge} */}
            {/*                   elRef={elRefs?.bestAge} */}
            {/*                   onChange={checked => setBestAge(checked)} */}
            {/*                   disabled={isDisabled}/> */}
          </Col>
          {/* BEST AGE */}

        </Row>


        {grievanceLevel === 'level1' || grievanceLevel === 'level2'
          ? <Row gutter={ROW_GUTTER}>

            {/* BEST MINIMAL AGE 1 */}
            <Col xs={8}>
              <FormInputNumber name="grievanceMinAge1"
                               label="minimal_age"
                               elRef={elRefs.grievanceMinAge1}
                               disabled={isDisabled}
                               style={{ width: '100%' }}
                               min={0}
                               max={99}
                               required/>
            </Col>
            {/* BEST MINIMAL AGE 1 */}


            {/* BEST MAXIMUM AGE 1 */}
            <Col xs={8}>
              <FormInputNumber name="grievanceMaxAge1"
                               label="maximum_age"
                               elRef={elRefs.grievanceMaxAge1}
                               disabled={isDisabled}
                               style={{ width: '100%' }}
                               min={0}
                               max={99}
                               required/>
            </Col>
            {/* BEST MAXIMUM AGE 1 */}


            {/* BEST AGE ADDITION 1 */}
            <Col xs={8}>
              <FormInputNumber name="grievanceAdditionAge1"
                               label="addition"
                               elRef={elRefs.grievanceAdditionAge1}
                               disabled={isDisabled}
                               decimalSeparator=","
                               style={{ width: '100%' }}
                               addonAfter="%"
                               suffixStyle={{ width: 60 }}
                               min={1.00}
                               max={999.99}
                               required/>
            </Col>
            {/* BEST AGE ADDITION 1 */}

          </Row>
          : null}

        {grievanceLevel === 'level2'
          ? <Row gutter={ROW_GUTTER}>

            {/* BEST MINIMAL AGE 2 */}
            <Col xs={8}>
              <FormInputNumber name="grievanceMinAge2"
                               label="minimal_age"
                               elRef={elRefs.grievanceMinAge2}
                               disabled={isDisabled}
                               style={{ width: '100%' }}
                               min={0}
                               max={99}
                               required/>
            </Col>
            {/* BEST MINIMAL AGE 2 */}


            {/* BEST MAXIMUM AGE 2 */}
            <Col xs={8}>
              <FormInputNumber name="grievanceMaxAge2"
                               label="maximum_age"
                               elRef={elRefs.grievanceMaxAge2}
                               disabled={isDisabled}
                               style={{ width: '100%' }}
                               min={0}
                               max={99}
                               required/>
            </Col>
            {/* BEST MAXIMUM AGE 2 */}


            {/* BEST AGE ADDITION 2 */}
            <Col xs={8}>
              <FormInputNumber name="grievanceAdditionAge2"
                               label="addition"
                               elRef={elRefs.grievanceAdditionAge2}
                               disabled={isDisabled}
                               decimalSeparator=","
                               style={{ width: '100%' }}
                               addonAfter="%"
                               suffixStyle={{ width: 60 }}
                               min={1.00}
                               max={999.99}
                               required/>
            </Col>
            {/* BEST AGE ADDITION 2 */}

          </Row>
          : null}

      </Card>


      {/* ************************************************************
      TRAVEL PROFILE
      ************************************************************ */}

      {/* DESTINATION */}
      <Card title={<>
        <span style={{
          color: 'red',
          // fontSize: 12,
          marginRight: 6
        }}>*</span>
        {setLocale(true, 'travel_profile')}</>}>

        <Row gutter={ROW_GUTTER}>

          {travelProfileOptions.map((item, i) => (

            <Col key={i} xs={24} sm={12} style={{ height: 38 }}>

              <Row gutter={ROW_GUTTER} className={!isOdd(i) ? 'ml-sm-4' : ''}>
                <Col span={16}>{item.label}</Col>
                <Col span={8} className="text-right text-sm-left">
                  <Switch checked={travelProfiles.includes(item.value)}
                          disabled={item.disabled || isDisabled}
                          onChange={checked => onChangeTravelProfile(item, checked)}
                  />
                </Col>
              </Row>

            </Col>
          ))}


        </Row>

      </Card>


      {/* ************************************************************
      ATTACHMENTS
      ************************************************************ */}
      <Card title={<>
        <span style={{
          color: 'red',
          // fontSize: 12,
          marginRight: 6
        }}>*</span>
        {setLocale(true, 'attachments')}</>}>

        {/* CONDITION */}
        <FormTitle title="general_conditions"/>
        <Row gutter={ROW_GUTTER}>

          <UploadFirebaseComponent record={_data}
                                   hideUploadButton={true}
                                   buttonTitle="Upload"
                                   name="condition"
                                   listType="picture"
                                   fileList={conditionList}
                                   setFileList={setConditionList}
                                   removeFileList={removeFileList}
                                   setRemoveFileList={setRemoveFileList}
                                   isCroppingEnabled={false}
                                   accept={'.pdf'}
                                   fileSize={3}
                                   isDisabled={isDisabled}
                                   fileFolder="plans/conditions"
                                   maxFiles={1}/>

        </Row>
        {/* CONDITION */}


        {/* COVERAGE */}
        <FormTitle title="coverage_terms"/>
        <Row gutter={ROW_GUTTER}>

          <UploadFirebaseComponent record={_data}
                                   hideUploadButton={true}
                                   buttonTitle="Upload"
                                   name="coverage"
                                   listType="picture"
                                   fileList={coverageList}
                                   setFileList={setCoverageList}
                                   removeFileList={removeFileList}
                                   setRemoveFileList={setRemoveFileList}
                                   isCroppingEnabled={false}
                                   accept={'.pdf'}
                                   fileSize={3}
                                   isDisabled={isDisabled}
                                   fileFolder="plans/coverages"
                                   maxFiles={1}/>

        </Row>
        {/* COVERAGE */}

      </Card>


      {/* SAVE BUTTON */}
      <FormSaveButton loading={dataContext?.partialLoading}
                      form={form}
                      formName={formName}
                      dataContext={dataContext}
                      onlySave
                      setSubmitted={setSubmitted}
                      disabled={isDisabled}/>
      {/* SAVE BUTTON */}


    </FormWrapper>

  );

  // INTERNAL FUNCTIONS ========================================================
  // ===========================================================================

  function buildTranslations () {

    return {
      choose_destination_required: translate(intl, 'choose_destination_required'),
      choose_travel_profile_required: translate(intl, 'choose_travel_profile_required'),
      attachments_required: translate(intl, 'attachments_required'),

      north_america: translate(intl, 'north_america'),
      south_america: translate(intl, 'south_america'),
      africa: translate(intl, 'africa'),
      central_america: translate(intl, 'central_america'),
      asia: translate(intl, 'asia'),
      brazil: translate(intl, 'brazil'),
      europe: translate(intl, 'europe'),
      oceania: translate(intl, 'oceania'),
      middle_east: translate(intl, 'middle_east'),

      none: translate(intl, 'none'),
      level_1: translate(intl, 'level_1'),
      level_2: translate(intl, 'level_2'),

      pregnant: translate(intl, 'pregnant'),
      maritime_cruise: translate(intl, 'maritime_cruise'),
      best_seller: translate(intl, 'best_seller'),
      practice_port: translate(intl, 'practice_port'),
      student: translate(intl, 'student'),
    };
  }

  function buildDestinationsOptions (t) {

    return [
      {
        value: 'north_america',
        text: normalize(t.north_america, 'lower'),
        label: t.north_america,
        disabled: false,
      },
      {
        value: 'south_america',
        text: normalize(t.south_america, 'lower'),
        label: t.south_america,
        disabled: false,
      },
      {
        value: 'africa',
        text: normalize(t.africa, 'lower'),
        label: t.africa,
        disabled: false,
      },
      {
        value: 'central_america',
        text: normalize(t.central_america, 'lower'),
        label: t.central_america,
        disabled: false,
      },
      {
        value: 'asia',
        text: normalize(t.asia, 'lower'),
        label: t.asia,
        disabled: false,
      },
      {
        value: 'brazil',
        text: normalize(t.brazil, 'lower'),
        label: t.brazil,
        disabled: false,
      },
      {
        value: 'europe',
        text: normalize(t.europe, 'lower'),
        label: t.europe,
        disabled: false,
      },
      {
        value: 'oceania',
        text: normalize(t.oceania, 'lower'),
        label: t.oceania,
        disabled: false,
      },
      {
        value: 'middle_east',
        text: normalize(t.middle_east, 'lower'),
        label: t.middle_east,
        disabled: false,
      },
    ].sort((a, b) => sort(a, b, 'text'));
  }

  function buildGrievanceOptions (t) {

    // const t = {
    //   none: translate(intl, 'none'),
    //   level_1: translate(intl, 'level_1'),
    //   level_2: translate(intl, 'level_2'),
    //
    // };
    return [
      {
        value: 'none',
        text: normalize(t.none, 'lower'),
        label: t.none,
        disabled: false,
      },
      {
        value: 'level1',
        text: normalize(t.level_1, 'lower'),
        label: t.level_1,
        disabled: false,
      },
      {
        value: 'level2',
        text: normalize(t.level_2, 'lower'),
        label: t.level_2,
        disabled: false,
      },
    ];
  }

  function buildTravelProfileOptions (t) {

    // const t = {
    //   pregnant: translate(intl, 'pregnant'),
    //   best_seller: translate(intl, 'best_seller'),
    //   practice_port: translate(intl, 'practice_port'),
    //   student: translate(intl, 'student'),
    // };

    return [
      {
        value: 'pregnant',
        text: normalize(t.pregnant, 'lower'),
        label: t.pregnant,
        disabled: false,
      },
      {
        value: 'maritime_cruise',
        text: normalize(t.maritime_cruise, 'lower'),
        label: t.maritime_cruise,
        disabled: false,
      },
      {
        value: 'sport',
        text: normalize(t.practice_port, 'lower'),
        label: t.practice_port,
        disabled: false,
      },
      {
        value: 'best_seller',
        text: normalize(t.best_seller, 'lower'),
        label: t.best_seller,
        disabled: false,
      },
      {
        value: 'student',
        text: normalize(t.student, 'lower'),
        label: t.student,
        disabled: false,
      }
    ].sort((a, b) => sort(a, b, 'text'));
  }

  function buildQuotationOptions () {

    return [
      {
        value: 'usd',
        text: 'dolar dollar usd',
        label: '$ (USD)',
        disabled: false,
      },
      {
        value: 'brl',
        text: 'real brl',
        label: 'R$ (BRL)',
        disabled: false,
      },
      {
        value: 'euro',
        text: 'euro',
        label: '€ (EUR)',
        disabled: false,
      }
    ];

  }

  function buildElementsRefs (hookUseRef) {
    return {

      // characteristics
      name: hookUseRef(null),
      selectedOperator: hookUseRef(null),
      quotation: hookUseRef(null),
      minDays: hookUseRef(null),
      maxDays: hookUseRef(null),

      // ages limit
      minAge: hookUseRef(null),
      maxAge: hookUseRef(null),
      bestAge: hookUseRef(null),

      grievanceLevel: hookUseRef(null),
      grievanceMinAge1: hookUseRef(null),
      grievanceMaxAge1: hookUseRef(null),
      grievanceAdditionAge1: hookUseRef(null),

      grievanceMinAge2: hookUseRef(null),
      grievanceMaxAge2: hookUseRef(null),
      grievanceAdditionAge2: hookUseRef(null),
    };
  }

});

// EXPORT **********************************************************************
// *****************************************************************************

export default MultiPlansProfile;
