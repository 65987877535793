import React, { Suspense } from 'react';
import { Col, Row } from 'antd';
import Loading from './Loading';

const AppCover = ({
  children,
  isLoading,
  data,
  fullscreen,
  ignoreNoData,
  marginTop
}) => {
  return (

    <Suspense fallback={<Row justify="center">
      <Col>
        <h1>Fallback Error!</h1>
      </Col>
    </Row>}>


      <Loading isFetching={isLoading}
               data={data}
               marginTop={marginTop}
               fullscreen={fullscreen}
               ignoreNoData={ignoreNoData}/>


      <div className="module-view">


        {children}


      </div>

    </Suspense>

  );
};

export default AppCover;
