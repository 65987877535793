import { APP_PREFIX_PATH, THEME_CONFIG } from "../AppConfig";
import {
  AimOutlined,
  BarsOutlined,
  CalendarOutlined,
  ContactsOutlined,
  ExclamationCircleOutlined,
  FieldTimeOutlined,
  FileProtectOutlined,
  FlagOutlined,
  HomeOutlined,
  InsertRowRightOutlined,
  NotificationOutlined,
  RocketOutlined,
  ScheduleOutlined,
  TagsOutlined,
  TeamOutlined,
  NodeExpandOutlined,
  CarOutlined,
  ShopOutlined,
  BoxPlotOutlined,
  SisternodeOutlined,
} from "@ant-design/icons";
import React from "react";
import IntlMessage from "components/util-components/IntlMessage";
import { onProduction, sort } from "utils/helpers";
import _ from "lodash";
import moment from "moment/moment";
import { clone } from "jarvisly-helper";

// APPLICATIONS
import jarvisly from "./applications/jarvisly.json"; // 0
import sindico from "./applications/sindico.json"; // 2
import lekkus from "./applications/lekkus.json"; // 3
import lekkusIot from "./applications/lekkus-iot.json"; // 3
import dedalo from "./applications/dedalo.json"; // 1
import pubpayer from "./applications/pubpayer.json"; // 4
import multi from "./applications/multi.json"; // 5

// MODULES
//  a) Incluir <module>.json na relação abaixo
//  b) Add Module Icon in getDynamicIcon() function (se for listar no MENU)
//  c) Activate module route in 'components/jarvis-layout/module-methods.js' file
//  d) Add routes in 'views/app-views/routes' according 'module.json' routes (if not use the ModuleList/ModuleForm components)
//  e) Backend - Subscriptions methods: $decorateUserTeam() for GRANTED ACCESS
//  f) <application>.json - Added the module in MODULES array

import foos from "./modules/foos.json";
import residents from "./modules/residents.json";
import vehicles from "./modules/vehicles.json";
import bikes from "./modules/bikes.json";
import pets from "./modules/pets.json";

import companies from "./modules/companies.json";
import providers from "./modules/providers.json";

// multi
import multiOperators from "./modules/_apps/multi/multi-operators.json";
import multiPlans from "./modules/_apps/multi/multi-plans.json";
import multiOrders from "./modules/_apps/multi/multi-orders.json";
import customersPersons from "./modules/customers-persons.json";
import leads from "./modules/leads.json";
import vouchers from "./modules/vouchers.json";
import invoices from "./modules/invoices.json";
import receivable from "./modules/receivable.json";
import payable from "./modules/payable.json";

// sindico
import collaborators from "./modules/collaborators.json";
import assets from "./modules/assets.json";
import procedures from "./modules/procedures.json";
import schedules from "./modules/_appDedaloSchedules.json";
import occurrences from "./modules/occurrences.json";
import apartments from "./modules/apartments.json";
import atualiza from "./modules/atualiza.json";
import moves from "./modules/moves.json";
import iot from "./modules/iot.json";
// import sindicoIot from './modules/sindico-iot.json';
import { FaRegBuilding, FaRegEdit } from "react-icons/fa";
import { BiBuildingHouse } from "react-icons/bi";
import {
  BsBuildings,
  BsCashCoin,
  BsCoin,
  BsDiagram3,
  BsPersonVideo2,
} from "react-icons/bs";
import {
  MdApartment,
  MdDirectionsBike,
  MdOutlineLuggage,
  MdOutlineMapsHomeWork,
  MdOutlinePets,
  MdOutlineWorkspaces,
  MdSupportAgent,
} from "react-icons/md";
import {
  TbDiscount2,
  TbFileInvoice,
  TbMoneybag,
  TbShoppingCart,
} from "react-icons/tb";
import { AiOutlineBulb, AiOutlineDashboard } from "react-icons/ai";
import { TfiHeadphoneAlt } from "react-icons/tfi";
import { FiShoppingBag } from "react-icons/fi";
import { IoPeopleOutline } from "react-icons/io5";
import { SiYourtraveldottv } from "react-icons/si";

// desativa online os aplicativos listados no array
const inMaintenance = []; // 'dedalo', 'sindicoerp', etc...

// ************************************************************************** //
// constants
// ************************************************************************** //

const setLocale = (isLocaleOn, localeKey) =>
  isLocaleOn ? <IntlMessage id={localeKey} /> : localeKey.toString();

const APPLICATIONS = buildApplicationsConstants();
const PROFILES = buildApplicationsProfiles();
const MODULES = buildApplicationsModules();

// ************************************************************************** //
// *************************** APP CONFIGURATION **************************** //
// ************************************************************************** //
const app = getJarvislyApp(dedalo); // <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<

app.IN_MAINTENANCE = inMaintenance.includes(app.ID);

export default app;

// ************************************************************************** //
// internal functions
// ************************************************************************** //

function getJarvislyApp(forcedApp) {
  const url = new URL(window.location.href);
  const domain = url.hostname; // return subdomain + domain

  if (!onProduction() && forcedApp) return $decorateApp(forcedApp);

  for (const app of APPLICATIONS) {
    if (app.DOMAINS.includes(domain)) return $decorateApp(app);
  }

  console.error(`*** NO JARVISLY APPLICATION DEFINED ***`);

  return {
    DEV_MODE: true,
    LOCALE: "en",
    PROFILE: PROFILES.company,
  };

  // decorate the application settings
  function $decorateApp(app) {
    app.DEV_MODE = !onProduction();
    app.IS_PORTAL_ATUALIZA_INFO = domain.includes("atualiza.info");

    // build profile
    if (typeof app.PROFILE === "string") {
      app.PROFILE = PROFILES[app.PROFILE];
    }

    // build modules
    if (!app.MODULES) app.MODULES = [];
    if (typeof app?.MODULES[0] === "string") {
      const modules = [];
      app.MODULES.map((m) => modules.push(MODULES[m]));
      app.MODULES = modules;
    }

    return app;
  }
}

// jarvisly applications
function buildApplicationsConstants() {
  return [jarvisly, dedalo, sindico, lekkus, lekkusIot, pubpayer, multi];
}

// jarvisly applications profiles
function buildApplicationsProfiles() {
  return {
    company: {
      NAME: "organization",
      NAME_PLURAL: "organizations",
      NEW_MESSAGE: "new_organization",
      SELECT_MESSAGE: "select_an_organization",
    },

    clinic: {
      NAME: "clinic",
      NAME_PLURAL: "clinics",
      NEW_MESSAGE: "new_clinic",
      SELECT_MESSAGE: "select_a_clinic",
    },

    condominium: {
      NAME: "condominium",
      NAME_PLURAL: "condominiums",
      NEW_MESSAGE: "new_condominium",
      SELECT_MESSAGE: "select_a_condominium",
    },
  };
}

// jarvisly applications modules
function buildApplicationsModules() {
  return {
    foos,
    apartments,
    residents,
    vehicles,
    bikes,
    pets,
    moves,
    iot,
    // sindicoIot,

    companies,
    providers,
    operators: multiOperators,
    multiPlans,
    customersPersons,

    ordersPlans: multiOrders,
    leads,

    vouchers,
    invoices,
    receivable,
    payable,

    collaborators,
    assets,
    procedures,
    schedules,
    occurrences,
    atualiza,
  };
}

// ************************************************************************** //
// exports functions
// ************************************************************************** //

export const checkUserGranted = (level, moduleName, subscription) => {
  if (!moduleName) return true;

  const granted = subscription?.granted[moduleName];

  return granted;
};

export const isActiveModule = (mpModule, subscription) => {
  // -4 - pagamento vencido (exibe modulo normalmente/info: aguardando pagamento, não perca o acesso! | 10 DIAS, apos 10 dias, status=0)
  // -3 - venceu demo e se encontra no carrinho de compras
  // -2 - demo vencido
  // -----
  //  0 - nao contratado (demo avaliado ou não)
  // -----
  //  1 - Adicionado no carrinho de compras
  //  2 - Em demo (venceu: status = -2)
  //  3 - Em demonstração e adicionado no carrinho de compras (venceu: status = -3)
  //  4 - Ativo (pago, dentro do período vigente)

  const isActiveAccount =
    !mpModule || [-4, -3, -2, 2, 3, 4].includes(mpModule?.status);

  const inDemoPeriod = !mpModule || mpModule?.__leftDemoDays > 0;
  const isPaid = true; // VERIFICAR SE ESTÁ PAGO CASO NAO SEJA MAIS DEMO!

  return isActiveAccount && inDemoPeriod && isPaid;
};

export const isBlockedModule = (mpModule) => {
  // acessa o módulo, mas fica travado com informação de DEMO VENCIDA
  return [-3, -2].includes(mpModule?.status); // demo expired
};

export const getActiveModules = (subscription) => {
  const nativeModules = THEME_CONFIG?.APP?.MODULES?.filter(
    (m) => !m["MARKETPLACE"],
  )?.map((m) => m && m["MODULE_NAME"]);

  const contractedModules = getContractedModules(subscription);

  return [...nativeModules, ...contractedModules];
};

export const getContractedModules = (subscription) => {
  return (
    subscription?.marketplace
      ?.filter((m) => {
        return isActiveModule(m, subscription);
      })
      .map((m) => m.name) || []
  );
};

export const buildMenu = (subscription) => {
  const activeModules = getActiveModules(subscription);

  // ************************************************************************** //
  // DASHBOARD AREA
  // ************************************************************************** //
  const dashboardArea = [
    {
      key: `${APP_PREFIX_PATH}/dashboard`,
      label: setLocale(true, "dashboard"),
      title: "dashboard",
      icon: <AiOutlineDashboard />,
      children: [
        {
          key: "/app/dashboard/home",
          label: setLocale(true, "home"),
          title: setLocale(true, "home"),
          value: "home",
          icon: <AiOutlineDashboard />,
          breadcrumb: "true",
        },
      ],
      _level: 0,
    },
  ];
  // ************************************************************************** //

  // ************************************************************************** //
  // RECORDS AREA
  // ************************************************************************** //
  const recordsArea = [
    {
      key: `${APP_PREFIX_PATH}/records`,
      label: setLocale(true, "records"),
      title: "records",
      icon: <FaRegEdit />,
      children: $decorateMenuGroup("records"),
      _level: 0,
    },
  ];
  // ************************************************************************** //

  // ************************************************************************** //
  // OPERATIONAL AREA
  // ************************************************************************** //
  const operationalArea = [
    {
      key: `${APP_PREFIX_PATH}/operational`,
      label: setLocale(true, "operational"),
      title: "operational",
      icon: <RocketOutlined />,
      children: $decorateMenuGroup("operational"),
      _level: 0,
    },
  ];
  // ************************************************************************** //

  // ************************************************************************** //
  // COMMERCIAL AREA
  // ************************************************************************** //
  const commercialArea = [
    {
      key: `${APP_PREFIX_PATH}/commercial`,
      label: setLocale(true, "commercial"),
      title: "commercial",
      icon: <TfiHeadphoneAlt />,
      children: $decorateMenuGroup("commercial"),
      _level: 0,
    },
  ];
  // ************************************************************************** //

  // ************************************************************************** //
  // FINANCIAL AREA
  // ************************************************************************** //
  const financialArea = [
    {
      key: `${APP_PREFIX_PATH}/financial`,
      label: setLocale(true, "financial"),
      title: "financial",
      icon: <BsCoin />,
      children: $decorateMenuGroup("financial"),
      _level: 0,
    },
  ];
  // ************************************************************************** //

  // ************************************************************************** //
  // FOO EXAMPLE MODULE AREA
  // ************************************************************************** //
  const fooArea = !onProduction()
    ? [
        {
          key: `${APP_PREFIX_PATH}/scaffolding`,
          label: setLocale(true, "scaffolding"),
          title: "scaffolding",
          icon: <AiOutlineBulb />,
          children: $decorateMenuGroup("scaffolding"),
          _level: 0,
        },
      ]
    : [];
  // ************************************************************************** //

  // ************************************************************************** //
  // build navigation menu
  // ************************************************************************** //
  // const navigationConfig = [
  return [
    ...dashboardArea,
    ...recordsArea,
    ...operationalArea,
    ...commercialArea,
    ...financialArea,
    ...fooArea,
  ].filter((x) => x.children.length > 0);

  // default navigationConfig;

  // ************************************************************************** //
  // internal function
  // ************************************************************************** //

  function $decorateMenuGroup(menuGroup) {
    const menu = [];

    if (typeof menuGroup === "string") {
      if (!THEME_CONFIG.APP?.MODULES) return null;

      const modules = clone(THEME_CONFIG.APP.MODULES);

      modules
        .filter((m) => activeModules.includes(m["MODULE_NAME"]))
        .forEach((m) => {
          const menuName = menuGroup?.toUpperCase();
          const selectedMenu =
            m?.["MENUS"]?.[app.ID]?.[menuName] ||
            m?.["MENUS"]?.["default"]?.[menuName] ||
            m?.["MENUS"]?.[menuName];

          if (!selectedMenu) return null;

          selectedMenu?.map((x) => {
            x["MODULE_NAME"] = m["MODULE_NAME"];
            return x;
          });

          const idx = menu
            .filter((x) => activeModules.includes(x["MODULE_NAME"]))
            .findIndex((k) =>
              selectedMenu.find(
                (i) => i["KEY"] === k["KEY"] && i["MODULE_NAME"],
              ),
            );

          if (idx === -1) {
            menu.push(...selectedMenu);
          } else {
            const c1 = menu[idx]["CHILDREN"];
            const c2 =
              selectedMenu.find((k) => k["KEY"] === menu[idx]["KEY"])
                ?.CHILDREN || [];

            let newChildren = _.unionBy(c1, c2, "KEY");

            if (newChildren.length > 0) menu[idx]["CHILDREN"] = newChildren;
          }

          return m;
        });
    } else {
      menu.push(...menuGroup);
    }

    const mm = clone(menu);

    return mm.map((m) => {
      const menu = {};

      menu.index = m["INDEX"];
      menu.key = m["KEY"];
      menu.label = setLocale(true, m["LABEL"]);
      menu.value = m["LABEL"]; // use for internal functions (nav tooltip and module search)
      menu.breadcrumb = m["BREADCRUMB"];

      if (m["ICON"]) menu._icon = getDynamicIcon(m["ICON"]);

      if (typeof menuGroup === "string") {
        menu.icon = getDynamicIcon(m["ICON"]);
      } else {
        if (m["ICON"]) menu._icon = getDynamicIcon(m["ICON"]);
      }

      if (m["CHILDREN"]) {
        menu.children = $decorateMenuGroup(m["CHILDREN"]);
      }

      return menu;
    });

    // ************************************************************************ //
  }
};

export const buildAppModules = (user) => {
  if (!user) return;

  const appModules = THEME_CONFIG.APP?.MODULES || [];

  user.subscriptions?.map((s) => {
    appModules
      ?.filter((m) => m["MARKETPLACE"])
      ?.map((m) => {
        // subscription module index
        const smIdx = s.marketplace.findIndex(
          (sm) => sm?.name === m["MODULE_NAME"],
        );
        const module = smIdx >= 0 ? s.marketplace[smIdx] : {};

        // decorate subscription module
        const marketplace = m["MARKETPLACE"];

        module.name = m["MODULE_NAME"];

        module.icon = marketplace["ICON"];
        module.title = marketplace["TITLE"];
        module.subtitle = marketplace["SUBTITLE"];
        module.demoDays = marketplace["DEMO_DAYS"];
        module.price = marketplace["PRICE_BY_CURRENCY"]["BRL"];

        // stored fields
        if (!module.status) module.status = 0;
        if (!module.demoActivatedAt) module.demoActivatedAt = null;
        if (!module.addedToCartAt) module.addedToCartAt = null;
        if (!module.activatedAt) module.activatedAt = null;
        if (!module.deactivatedAt) module.deactivatedAt = null;
        if (!module.expirationDate) module.expirationDate = null;

        module.__activeDays = 0;
        module.__progression = 0;
        module.__leftDays = 0;
        module.__leftDemoDays = module.demoDays;

        // -------------------------------------------------------------------- //
        // computed fields
        // -------------------------------------------------------------------- //

        const status = module.status;

        if (module?._id) {
          // is persisted in database

          if (!module.activatedAt) {
            // not activated (paid once)

            if (module.demoActivatedAt) {
              // demo available

              const demoDays = module.demoDays;
              const demoActivatedAt = moment(module.demoActivatedAt);
              const demoActiveDays = moment().diff(demoActivatedAt, "days");

              const leftDemoDays =
                demoDays - demoActiveDays > 0 ? demoDays - demoActiveDays : 0;

              const progression =
                demoActiveDays > demoDays
                  ? 100
                  : Math.round((demoActiveDays * 100) / demoDays);

              module.__leftDemoDays = leftDemoDays;
              module.__progression = progression;

              if (leftDemoDays === 0) {
                if (status === 2) {
                  module.status = -2;
                } else if (status === 3) {
                  module.status = -3;
                }
              }
            } else {
            }
          }
        }

        /*

                    const status = module.status;
              const demoDays = module.demoDays;


              if (module.activatedAtAA) {

                const activatedAd = moment(module.activatedAt);
                const activeDays = moment().diff(activatedAd, 'days');

                // set module properties
                module.__activeDays = activeDays;

                // ================================================================== //
                // DEMO MODE (AVAILABLE OR IN DEMO PERIOD)
                // ================================================================== //

                const remainingDays = (demoDays - activeDays) > 0
                    ? (demoDays - activeDays)
                    : 0;

                if (demoDays) {

                  const progression = activeDays > demoDays
                      ? 100
                      : Math.round((activeDays * 100) / demoDays);

                  // status definition
                  if (remainingDays === 0 && module.status === 1) {
                    module.status = -1;

                  } else if (remainingDays > 0 && module.status === -2) {
                    module.status = 0;
                  }

                  // set module properties
                  module.__leftDemoDays = remainingDays;
                  module.__progression = progression;
                  //
                  module.__leftDays = 0;
                }

                // ================================================================== //
                // CONTRACTED MODE (NO DEMO AVAILABLE OR DEMO PERIOD EXPIRED)
                // ================================================================== //

                if (!remainingDays) {

                  const expirationDate = module.expirationDate &&
                      moment(module.expirationDate);

                  const leftDays = expirationDate
                      ? moment(expirationDate).diff(moment(), 'days')
                      : 0;

                  // set module properties
                  module.__leftDemoDays = 0;
                  module.__progression = 0;
                  //
                  module.__leftDays = leftDays;
                }

              }*/

        // __status and __statusColor
        switch (module.status) {
          case 0: {
            module.__status = "not_contracted";
            module.__statusColor = "gold";
            break;
          }

          case 1: {
            module.__status = "waiting_for_payment";
            module.__statusColor = "purple";
            break;
          }

          case 2:
          case 3: {
            module.__status = "evaluation_period";
            module.__statusColor = "cyan";
            break;
          }

          case -2:
          case -3: {
            module.__status = "evaluation_period_expired";
            module.__statusColor = "volcano";
            break;
          }

          // -----

          case -4: {
            if (module.__leftDays > 0) {
              module.__status = "active_until";
              module.__statusColor = "lime";
            } else {
              module.__status = "not_contracted";
              module.__statusColor = "gold";
            }

            break;
          }

          default: {
            module.__status = "not_contracted";
            module.__statusColor = "volcano";
            break;
          }
        }
        // -------------------------------------------------------------------- //

        if (smIdx >= 0) {
          s.marketplace[smIdx] = module;
        } else {
          s.marketplace.push(module);
        }

        return m;
      });

    s.marketplace = s.marketplace.sort((a, b) => sort(a, b, "name"));

    return s;
  });

  return user;
};

export const getDynamicIcon = (IconComponentName, props) => {
  const dynamicIcons = {
    InsertRowRightOutlined: <InsertRowRightOutlined {...props} />,
    RocketOutlined: <RocketOutlined {...props} />,
    FieldTimeOutlined: <FieldTimeOutlined {...props} />,
    FileProtectOutlined: <FileProtectOutlined {...props} />,
    AimOutlined: <AimOutlined {...props} />,
    TagsOutlined: <TagsOutlined {...props} />,
    TeamOutlined: <TeamOutlined {...props} />,
    ScheduleOutlined: <ScheduleOutlined {...props} />,
    CalendarOutlined: <CalendarOutlined {...props} />,
    ContactsOutlined: <ContactsOutlined {...props} />,
    BarsOutlined: <BarsOutlined {...props} />,
    NotificationOutlined: <NotificationOutlined {...props} />,
    FlagOutlined: <FlagOutlined {...props} />,
    HomeOutlined: <HomeOutlined {...props} />,
    NodeExpandOutlined: <NodeExpandOutlined {...props} />,
    CarOutlined: <CarOutlined {...props} />,
    MdDirectionsBike: <MdDirectionsBike {...props} />,
    MdOutlinePets: <MdOutlinePets {...props} />,
    ShopOutlined: <ShopOutlined {...props} />,
    BsPersonVideo2: <BsPersonVideo2 {...props} />,
    TbShoppingCart: <TbShoppingCart {...props} />,
    SiYourtraveldottv: <SiYourtraveldottv {...props} />,
    BsDiagram3: <BsDiagram3 {...props} />,
    MdOutlineLuggage: <MdOutlineLuggage {...props} />,
    IoPeopleOutline: <IoPeopleOutline {...props} />,
    FiShoppingBag: <FiShoppingBag {...props} />,
    MdOutlineWorkspaces: <MdOutlineWorkspaces {...props} />,
    MdSupportAgent: <MdSupportAgent {...props} />,
    TfiHeadphoneAlt: <TfiHeadphoneAlt {...props} />,
    TbDiscount2: <TbDiscount2 {...props} />,
    TbFileInvoice: <TbFileInvoice {...props} />,
    AiOutlineBulb: <AiOutlineBulb {...props} />,
    BsCoin: <BsCoin {...props} />, // financial
    BsCashCoin: <BsCashCoin {...props} />, // payable
    TbMoneybag: <TbMoneybag {...props} />, // receivable
    MdOutlineMapsHomeWork: <MdOutlineMapsHomeWork {...props} />,
    MdApartment: <MdApartment {...props} />,
    BsBuildings: <BsBuildings {...props} />,
    BiBuildingHouse: <BiBuildingHouse {...props} />,
    FaRegBuilding: <FaRegBuilding {...props} />,
    BoxPlotOutlined: <BoxPlotOutlined {...props} />,
    SisternodeOutlined: <SisternodeOutlined {...props} />,
  };

  /*
      const dynamicIconsOnlyName = {
        InsertRowRightOutlined: InsertRowRightOutlined,
        RocketOutlined: RocketOutlined,
        FieldTimeOutlined: FieldTimeOutlined,
        FileProtectOutlined: FileProtectOutlined,
        AimOutlined: AimOutlined,
        TagsOutlined: TagsOutlined,
        TeamOutlined: TeamOutlined,
        ScheduleOutlined: ScheduleOutlined,
        CalendarOutlined: CalendarOutlined,
      };
    */

  const NoIcon = <ExclamationCircleOutlined style={{ color: "red" }} />;

  return dynamicIcons[IconComponentName] || NoIcon;

  // return !onlyName
  //     ? (dynamicIcons[IconComponentName] || NoIcon)
  //     : dynamicIconsOnlyName[IconComponentName] || ExclamationCircleOutlined;
};
