import { Tag } from 'antd';
import React from 'react';
import { buildDevicesOptions, buildOnOffStatusFilter } from '../methods';

// ************************************************************************** //
// MODULE CUSTOM COMPONENTS
// ************************************************************************** //

// -------------------------------------------------------------------------- //
// OnOffStatusTag Component
// -------------------------------------------------------------------------- //
export const OnOffStatusTag = props => {

  const {
    record,
    onOff
  } = props;
  const options = buildOnOffStatusFilter(onOff);

  const selected = record?.status
    ? options.find(d => d.value === record.status) || options[0]
    : options[0];

  return (
    <Tag className={`text-capitalize ${props.className}`}
         style={{ margin: 4 }}
         color={selected.color}>
      <div style={{ marginTop: 2 }}>{selected.text}</div>
    </Tag>
  );
};

// -------------------------------------------------------------------------- //
// DeviceTag Component
// -------------------------------------------------------------------------- //
export const DeviceTag = props => {

  const { record } = props;
  const options = buildDevicesOptions();
  const selected = record?.device
    ? options.find(d => d.value === record.device) || options[0]
    : options[0];

  return (
    <Tag className="text-capitalize" style={{ margin: 4 }} color={selected.color}>
      <span style={{ color: selected?.textColor || 'white' }}>
        {selected.text}
      </span>
    </Tag>
  );
};
