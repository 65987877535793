import React from 'react';
import {
    decorateDocument,
    decorateNewDocument,
    dataProfileValidation,
    removeDocumentValidation,
    validateFilterFn,
    validateSearchFn, getModuleParameters,
} from './atualiza-methods';
import {atualizaSettingsTabs} from './atualiza-settings';
import {
    FormOutlined,
    HomeOutlined,
    UserOutlined,
} from '@ant-design/icons';
import {Route} from 'react-router-dom';
import DocumentNotes
    from 'components/jarvisly-layouts/ModuleComponent/ModuleForm/DocumentNotes';
import {
    defaultDecorateSettings, defaultParseSettings,
} from 'components/jarvisly-layouts/ModuleComponent/module-methods';
import AtualizaProfile from "./tiles/RegistrationTile";
import moment from 'moment'
import NoSection from "components/jarvisly-layouts/ModuleComponent/Components/NoSection";

// ************************************************************************** //
// CONSTANTS
// ************************************************************************** //

export const ATUALIZA_API_URL = '/atualiza-info';
const MODULE_ATUALIZA_URL = '/atualiza-index';


const INITIAL_FILTER = {
    folder: 'documents',
};

const INITIAL_SORT = {
    sortField: '_metadata.audit.updatedAt',
    sortOrder: 'descend',
};

const INITIAL_SEARCH = {
    documentCode: 'null',
    name: 'null',
    documentStatus: 'null',
};

const INITIAL_CONTEXT = {
    // data: null,
    // docLock: null,
    // dataMemory: false,
    mode: 'add',
    selectedSection: 'profile',
    sectionsChanged: [],
    hasPicture: true,
};

const METHODS = {
    parseSettings: defaultParseSettings,
    decorateSettings: defaultDecorateSettings,

    decorateDocument: decorateDocument,
    decorateNewDocument: decorateNewDocument,

    removeDocumentValidation: removeDocumentValidation,
    postDocumentValidation: dataProfileValidation,
    putDocumentValidation: dataProfileValidation,

    validateSearchFn: validateSearchFn,
    validateFilterFn: validateFilterFn,

    refreshParameters : getModuleParameters
};

const COMPONENTS = {
    // list: props => <AtualizaMainList {...props}/>,
    // listPreviewHeader: props => <AtualizaBodyPreviewHeader {...props}/>,
    // listPreviewBody: props => <AtualizaBodyPreviewData {...props}/>,
    // listPreviewActions: props => <AtualizaBodyPreviewActions {...props}/>,

    // documentSubtitle: props => <AtualizaDocumentSubtitle {...props}/>,
    // documentHeaderColumn2: props => <AtualizaDocumentHeaderColumn2 {...props}/>,
    // documentHeaderColumn3: props => <AtualizaDocumentHeaderColumn3 {...props}/>,

    // documentSectionsMenu: (props, ref) => <AtualizaDocumentSectionsMenu {...props} ref={ref}/>,
    // documentSectionsContent: (props, ref) => <AtualizaDocumentSectionsContent {...props} ref={ref}/>,

    // dedalo: {
    //   list: props => <DedaloAtualizaMainList {...props}/>,
    // },

    moduleSettings: atualizaSettingsTabs,
    // modalSettings: props => <AtualizaSettings {...props}/>,
};

const DATA_TYPES = [
    {
        key: 'person',  // database value
        label: 'individual_person',
        description: 'entities_individual_person_description',
        checked: true,
    }
];

const DOCUMENT_SECTIONS_ATUALIZA = [
    {
        key: 'profile',
        icon: <UserOutlined/>,
        label: 'registration_data',
        route: (props, ref, _id) => <Route key="profile"
                                           path={`${MODULE_ATUALIZA_URL}/${_id}/profile`}
                                           render={() =>
                                               <AtualizaProfile ref={ref} {...props} />}/>,
    },
    {
        key: 'habitation',
        icon: <HomeOutlined/>,
        label: 'habitation',
        route: (props, ref, _id) => <Route key="habitation"
                                           path={`${MODULE_ATUALIZA_URL}/${_id}/habitation`}
                                           render={() =>
                                               <NoSection ref={ref} {...props} />}/>,
    },
    {
        key: 'notes',
        icon: <FormOutlined/>,
        label: 'notes',
        route: (props, ref, _id) => <Route key="notes"
                                           path={`${MODULE_ATUALIZA_URL}/${_id}/notes`}
                                           render={() =>
                                               <DocumentNotes ref={ref} {...props} />}/>,
    },
];

const REQUIRED_FIELDS = [
    {
        key: 'name',
        label: 'name',
        checked: true,
        disabled: true,
        forDataType: ['person'],
    },
    {
        key: 'apartment',
        label: 'apartment',
        checked: true,
        disabled: true,
        forDataType: ['person', 'company'],
    },

    {
        key: 'cpf',
        label: 'cpf',
        checked: true,
        disabled: false,
        forDataType: ['person'],
    },
    {
        key: 'rg',
        label: 'rg',
        checked: false,
        disabled: false,
        forDataType: ['person', 'company'],
    },
    {
        key: 'birthday',
        label: 'birthdate',
        checked: false,
        disabled: false,
        forDataType: ['person'],
    },
    {
        key: 'mainPhone',
        label: 'phone',
        checked: true,
        disabled: false,
        dependence: 'mainEmail',
        dependence_msg: '',
        forDataType: ['person', 'company'],
    },
    {
        key: 'mainEmail',
        label: 'email',
        checked: false,
        disabled: false,
        dependence: 'mainPhone',
        forDataType: ['person', 'company'],
    },
];


// ************************************************************************** //
// SEEDS
// ************************************************************************** //


const SETTINGS_SEED_ATUALIZA = {

    configurations: {
        dataTypes: ['person'],
        dataMemory: false,
    },

    requiredFields: [
        'cpf',
        'birthday',
        'name_or_nickname',
        'mainPhone',
    ],

};

export const AUTO_FILL_ATUALIZA = {
    apartmentId: "63e4faa5f2a7e12665c7af03",
    attachmentsIds: undefined,
    birthday: moment('01/01/2000', "DD/MM/YYYY"),
    cpf: "385.995.340-09",
    dataProfile: "atualiza",
    dataType: "person",
    mainContact: {__phone: '(19) 9.8232-0000', email: 'moradorteste@info.com'},
    name: "Morador Teste Seed",
    rg: "123123",
    titular: true,
    uniqueKey: "38599534009"
}


// ************************************************************************** //
// MODULES PARAMETERS
// ************************************************************************** //

export const MODULE_ATUALIZA_PARAMETERS = {

    root: 'atualiza',
    name: 'atualiza',
    singular: 'atualiza',
    titleField: 'name',
    url: MODULE_ATUALIZA_URL,
    api: ATUALIZA_API_URL,

    hasFilter: true,
    hasSettings: true,
    hasAccessControl: true,
    hasConfigurations: true,
    hasDataMemory: true,
    hasRequiredFields: true,

    methods: METHODS,
    components: COMPONENTS,
    dataTypes: DATA_TYPES.filter(x => x.key === 'person'),
    requiredFields: REQUIRED_FIELDS,

    initialSearch: INITIAL_SEARCH,
    initialContext: INITIAL_CONTEXT,
    initialFilter: {...INITIAL_FILTER, dataProfile: 'atualiza'},
    initialSort: INITIAL_SORT,

    labels: {
        new_document_title: 'new_atualiza',
    },

    documentSections: DOCUMENT_SECTIONS_ATUALIZA,

    seeds: {
        settings: SETTINGS_SEED_ATUALIZA
    }

};
