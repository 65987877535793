import React, { useEffect, useState } from 'react';
import { Button, Form, Upload } from 'antd';
import { PlusOutlined, UploadOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';
import { translate } from 'utils/react-jarvisly-helper';
import { generateToken, msg, substitution } from 'utils/helpers';
import { useIntl } from 'react-intl';
import { connect } from 'react-redux';
import { delAttachment, postAttachment } from 'utils/tools-api';
import { firebase, FirebaseConfig } from 'auth/FirebaseAuth';
import ImgCrop from 'antd-img-crop';

/**
 * Upload wrapper files component. Works to firebase storage
 *
 * @param {object} props - The props passed to the component.
 * @param {object} props.form - The Ant Design Form instance to use.
 * @param {object} props.elRefs - The refs to the form fields.
 * @param {object} [props.initialValues] - The initial values for the form fields.
 *
 * @returns {JSX.Element} The rendered Form component wrapping the children.
 */
const UploadFirebaseComponent = props => {

  // props deconstruction ------------------------------------------------------
  const {
    name = 'filesUpload',
    fileSize = 1, // 1mb
    maxFiles,
    accept, // ex: ".jpeg,.png"
    fileFolder,
    record,
    rdxSubscription, // reload,
    listType = 'picture', // "picture" or "picture-card"
    isCroppingEnabled = false,
    fileList,
    setFileList,
    buttonTitle = 'upload',
    removeFileList,
    setRemoveFileList,
    hideUploadButton = false,
    isDisabled
  } = props;

  // local variables -----------------------------------------------------------
  const intl = useIntl();
  const t = buildTranslations();

  // component states ----------------------------------------------------------
  const [disabled, setDisabled] = useState(isDisabled);

  // hooks ---------------------------------------------------------------------

  /*
    useEffect(() => {
      setDisabled(fileList.length >= maxFiles);
    }, [fileList]);// eslint-disable-line react-hooks/exhaustive-deps
  */

  useEffect(() => {
    setDisabled(isDisabled);
  }, [isDisabled]);

  // methods -------------------------------------------------------------------

  const handleUpload = async ({
    onError,
    onSuccess,
    file
  }) => {

    if (maxFiles && fileList?.length > maxFiles) {
      file.status = 'error';
      return onError();
    }

    const isLimitOk = file.size / 1024 / 1024 < fileSize;

    if (!isLimitOk) {
      msg('f', t.file_must_be_smaller_than);
      file.status = 'error';
      return onError();
    }

    const storage = firebase.storage();

    const f = buildFile(file);
    const metadata = { contentType: f.type };
    const storageRef = await storage.ref();

    const filePath = fileFolder ? `${fileFolder}/${f.file}` : f.file;
    const bucket = FirebaseConfig.storageBucket;
    const rootFolder = record?._id || rdxSubscription?._id || 'unknown_folder';
    const imgFile = storageRef.child(`${rootFolder}/${filePath}`);

    try {
      const image = await imgFile.put(file, metadata);

      f.url = await imgFile.getDownloadURL();

      file.bucket = bucket;
      file.rootFolder = rootFolder;
      file.uri = `${rootFolder}/${filePath}`;
      file.url = f.url;
      file.file = f.file;
      file.fileName = f.fileName;
      file.fileExtension = f.fileExtension;
      file.uniqueKey = f.uniqueKey;

      const attach = await postAttachment(file);

      file._id = attach?._id;
      file.persisted = attach?.persisted;

      onSuccess(null, image);
      setTimeout(() => {
        setFileList([...fileList, attach]);
      }, 20);

    } catch (e) {
      onError(e);
    }
  };

  const onRemove = value => {

    const f = fileList.filter(f => f.uid !== value.uid);
    setFileList([...f]);
    setDisabled(false);

    setTimeout(() => {
      setRemoveFileList([...removeFileList, value]);
    }, 20);

    const storage = firebase.storage();
    const storageRef = storage.ref();
    const fileRef = storageRef.child(value?.uri);

    fileRef
      .delete()
      .then(async () => {
        if (value?._id) await delAttachment(value?._id);
      })
      .catch((error) => {
        console.error('delete file error:', error);
      });

    return true;
  };

  const onPreview = async (file) => {
    window.open(file?.url);
  };

  // UI COMPONENT --------------------------------------------------------------

  const UploadButton = () => (listType === 'picture'

    ? <Button icon={<UploadOutlined/>}
              disabled={disabled}>
      &nbsp;{buttonTitle}
    </Button>

    : <div>
      <PlusOutlined/>
      <div style={{ marginTop: 8 }}>
        {buttonTitle}
      </div>
    </div>);

  const CustomUpload = props => (
    <Upload {...props}
            disabled={disabled}
            accept={accept}
            listType={listType}
            className="width-100-percent no-margin"
            customRequest={handleUpload}
            onRemove={onRemove}
            onPreview={onPreview}
            defaultFileList={fileList}
            maxCount={maxFiles}>

      {hideUploadButton
        ? fileList.length < maxFiles && <UploadButton/>
        : <UploadButton/>}

    </Upload>);

  return (

    <Form.Item name={name} className="width-100-percent" valuePropName={fileList}>

      {isCroppingEnabled ? <ImgCrop><CustomUpload/></ImgCrop> : <CustomUpload/>}

    </Form.Item>

  );

  // INTERNAL FUNCTIONS ========================================================
  // ===========================================================================

  function buildTranslations () {
    const file_must_be_smaller_than = translate(intl, 'file_must_be_smaller_than');
    const t_file_must_be_smaller_than = substitution(file_must_be_smaller_than, ['%SIZE%'], [fileSize]);

    return {
      file_must_be_smaller_than: t_file_must_be_smaller_than,
    };
  }

  function buildFile (file) {

    const typeParts = file?.name?.split('.');
    let name, type;

    if (typeParts?.length > 0) {
      type = typeParts?.[typeParts?.length - 1];
      typeParts.pop();
      name = typeParts.join('.');

    } else {
      name = file?.name || +new Date();
      type = 'n_a';
    }

    const uniqueKey = generateToken();
    const fileNameAndExtension = `${uniqueKey}.${type.toLowerCase()}`;

    return {
      uniqueKey,
      fileName: name,
      fileExtension: type.toLowerCase(),
      file: fileNameAndExtension,
      url: null,
      fileBase64: btoa(file),

      name: file?.name,
      type: file.type,
      fileSize: file.size,
    };
  }
};

// EXPORT **********************************************************************
// *****************************************************************************

const mapStateToProps = ({ auth }) => {
  const { rdxSubscription } = auth;
  return { rdxSubscription };
};

export default connect(mapStateToProps)(UploadFirebaseComponent);

UploadFirebaseComponent.propTypes = {
  name: PropTypes.string,
  fileFolder: PropTypes.string,
  fileSize: PropTypes.number,
  accept: PropTypes.string,
  maxFiles: PropTypes.number,
  listType: PropTypes.string,
  isCroppingEnabled: PropTypes.bool,
  buttonTitle: PropTypes.string,

  fileList: PropTypes.array.isRequired,
  setFileList: PropTypes.func.isRequired,
  removeFileList: PropTypes.array.isRequired,
  setRemoveFileList: PropTypes.func.isRequired,
};
