import React from 'react';
import IntlMessage from 'components/util-components/IntlMessage';
import ModuleSettingsValues
  from 'components/jarvisly-layouts/ModuleComponent/ModuleSettings/ModuleSettingsValues';

// GLOBAL VARIABLES ************************************************************
// *****************************************************************************

const setLocale = (isLocaleOn, localeKey) => isLocaleOn ?
    <IntlMessage id={localeKey}/> : localeKey.toString();

// EXPORT TABS *****************************************************************
// *****************************************************************************

export const productsSettingsTabs = (props) => {

  return [

    {
      key: 'families',
      label: (<span>{setLocale(true, 'families')}</span>),
      children: (<div style={{padding: '4px 24px'}}>

        <ModuleSettingsValues {...props}
                              parValueType="families"
                              parTitle="family"
                              withColor
                              parPlaceholder="new_family"
                              valuesTitle="assets_segregation"
        />
      </div>),
    },

    {
      key: 'products',
      label: (<span>{setLocale(true, 'products')}</span>),
      children: (<div style={{padding: '4px 24px'}}>

        <ModuleSettingsValues {...props}
                              parValueType="products"
                              parTitle="product"
                              parPlaceholder="new_product"/>
      </div>),
    },

    {
      key: 'brands',
      label: (<span>{setLocale(true, 'brands')}</span>),
      children: (<div style={{padding: '4px 24px'}}>

        <ModuleSettingsValues {...props}
                              parValueType="brands"
                              parTitle="brand"
                              parPlaceholder="new_brand"/>
      </div>),
    },

  ];
};


