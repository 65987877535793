import React from 'react';
import { Route } from 'react-router-dom';
import {
  defaultDecorateSettings,
  defaultDataProfileValidation,
} from 'components/jarvisly-layouts/ModuleComponent/module-methods';
import {
  MultiPlansDocumentHeaderColumn2,
  MultiPlansDocumentHeaderColumn3,
  MultiPlansDocumentSubtitle,
} from './Form/Header';
import { FileProtectOutlined, SettingOutlined, TagOutlined } from '@ant-design/icons';
import MultiPlansProfile from './Form/sections/Profile';
import PlansList from './Listing/List';
import MultiPlansParameters from './Form/sections/Parameters';
import { decorateNewDocument, getMultiPlanModuleParameters } from './methods';
import { BiDonateHeart } from 'react-icons/bi';
import MultiPlansAssists from './Form/sections/Assists';
import MultiPlansCoverages from './Form/sections/Coverages';

// GLOBAL VARIABLES ************************************************************
// *****************************************************************************

// ************************************************************************** //
// CONSTANTS
// ************************************************************************** //

const PLANS_URL = '/app/records/multi-plans';
export const MULTIPLANS_API_URL = '/v2/multi/plans';

const INITIAL_LIST_STATE = {
  showNumberOfLine: localStorage.getItem('showNumberOfLine') === 'true',
  showCheckBox: localStorage.getItem('showCheckBox') === 'true',
};
const INITIAL_REQUEST = {

  // textSearch: {
  //   field: 'name'
  // },

  // textSearch: {
  //   default: 'mONTE aLto',
  //   enum: ['a', 'b'],
  // },

  folder: {
    default: 'documents',
    enum: ['documents', 'recycle', 'filed'],
  },

  // filter: {
  //   default: {
  //     dataProfile:  ["plan"] ,
  //     dataType:  ["plan"] ,
  //   },
  //   enum: {
  //     dataProfile: ["plan"],
  //     dataType: ["plan"],
  //   },
  // },

  // filter: {
  //   default: {
  //     color: { $in: ['black', 'white'] },
  //     city: { $in: ['Campinas'] },
  //   },
  //   enum: {
  //     color: ['branco', 'azul', 'amarelo'],
  //     city: ['Campinas', 'Itu'],
  //   },
  // },

  search: {},
  //   default: {
  //     age: 43,
  //     roomNumber: { $in: [11, 12, 43] },
  //     name: 'Ane Cardinalli',
  //   },
  //   enum: {
  //     age: [10, 30, 40],
  //     roomNumber: [22, 33, 54, 82],
  //   },
  // },

  sorter: {
    // roomNumber: 1,
    // blockId:-1
  },

  // project: {
  //   room: 1,
  //   blockId: 1,
  // },

  pagination: {
    pageNumber: 1,
    // pageSize: 10
    pageSize: ['10', '20', '50', '100'].includes(localStorage.getItem('pageSize'))
      ? Number(localStorage.getItem('pageSize'))
      : 10,
  },
};
/*

const INITIAL_FILTER = {
  folder: 'documents',
  // dataProfile: 'plan',
}

const INITIAL_SORT = {
  sortField: '_metadata.audit.updatedAt',
  sortOrder: 'descend',
}

const INITIAL_SEARCH = {
  roomNumber: 'null',
  blockId: 'null',
  documentStatus: 'null',
}
*/

const INITIAL_CONTEXT = {
  hasPicture: false,
  mode: 'nope',

  selectedSection: null,
  sectionsChanged: [],
  sectionSettingsComponent: null,
};

const METHODS = {
  // parseSettings: parseSettings,
  decorateSettings: defaultDecorateSettings,

  // decorateDocument: decorateDocument,
  decorateNewDocument: decorateNewDocument,

  // removeDocumentValidation: removeDocumentValidation,
  postDocumentValidation: defaultDataProfileValidation,
  putDocumentValidation: defaultDataProfileValidation,

  refreshParameters: getMultiPlanModuleParameters,
};

const COMPONENTS = {
  list: props => <PlansList {...props}/>,
  // listPreviewHeader: props => <MultiPlansBodyPreviewHeader {...props}/>,
  // listPreviewBody: props => <MultiPlansBodyPreviewData {...props}/>,
  // listPreviewActions: props => <MultiPlansBodyPreviewActions {...props}/>,

  documentSubtitle: (props) => <MultiPlansDocumentSubtitle {...props} />,
  // documentSubtitle: props => <div>{props?.record?.description}</div>,
  documentHeaderColumn2: (props) => <MultiPlansDocumentHeaderColumn2 {...props} />,
  documentHeaderColumn3: (props) => <MultiPlansDocumentHeaderColumn3 {...props} />,

  // moduleSettings: props => <plansSettingsTabs {...props}/>,
  // moduleSettings: plansSettingsTabs(),
};

const DATA_TYPES = [
  {
    key: 'plan',
    label: 'legal_person',
    description: 'plans_legal_person_description',
    checked: true,
  },
];

const DOCUMENT_SECTIONS = [
  {
    key: 'profile',
    icon: <TagOutlined/>,
    label: 'profile',
    route: (props, ref, _id) => (
      <Route
        key="profile"
        path={`${PLANS_URL}/${_id}/profile`}
        render={() => (
          <MultiPlansProfile
            ref={ref}
            {...props}
            _data={props._dataRoots}
            _dataLock={props._dataRootsLock}
          />
        )}
      />
    ),
  },

  {
    key: 'assists',
    // eslint-disable-next-line react/jsx-no-undef
    icon: <BiDonateHeart/>,
    label: 'assist_24h',
    route: (props, ref, _id) => (
      <Route
        key="assists"
        path={`${PLANS_URL}/${_id}/assists`}
        render={() => (
          <MultiPlansAssists
            ref={ref}
            {...props}
            _data={props._dataRoots}
            _dataLock={props._dataRootsLock}
          />
        )}
      />
    ),
  },

  {
    key: 'coverages',
    // eslint-disable-next-line react/jsx-no-undef
    icon: <FileProtectOutlined/>,
    label: 'coverages',
    route: (props, ref, _id) => (
      <Route
        key="coverages"
        path={`${PLANS_URL}/${_id}/coverages`}
        render={() => (
          <MultiPlansCoverages
            ref={ref}
            {...props}
            _data={props._dataRoots}
            _dataLock={props._dataRootsLock}
          />
        )}
      />
    ),
  },

  {
    type: 'divider'
  },

  {
    key: 'parameters',
    // eslint-disable-next-line react/jsx-no-undef
    icon: <SettingOutlined/>,
    label: 'parameters',
    route: (props, ref, _id) => (
      <Route
        key="parameters"
        path={`${PLANS_URL}/${_id}/parameters`}
        render={() => (
          <MultiPlansParameters
            ref={ref}
            {...props}
            _data={props._dataRoots}
            _dataLock={props._dataRootsLock}
          />
        )}
      />
    ),
  },

  /*  {
      key: 'fiscal',
      icon: <FundOutlined/>,
      label: 'fiscal',
      route: (props, ref, _id) => <Route key="fiscal"
                                         path={`${PLANS_URL}/${_id}/fiscal`}
                                         render={() =>
                                           <NoSection ref={ref} {...props}
                                                      _data={props._data?.titular}
                                                      _dataLock={props._dataLock?.titular}/>}/>,
    },*/
  /*  {
      key: 'banks',
      icon: <BankOutlined/>,
      label: 'banks',
      route: (props, ref, _id) => <Route key="banks"
                                         path={`${PLANS_URL}/${_id}/banks`}
                                         render={() =>
                                           <NoSection ref={ref} {...props} />}/>,
    },*/
];

const REQUIRED_FIELDS = [
  {
    key: 'cnpj',
    label: 'cnpj',
    checked: true,
    disabled: false,
    forDataType: ['plan'],
  },

  {
    key: 'foundedAt',
    label: 'foundation_date',
    checked: false,
    disabled: false,
    forDataType: ['plan'],
  },
  {
    key: 'trade_name',
    label: 'trade_name',
    checked: true,
    disabled: true,
    forDataType: ['plan'],
  },

  {
    key: 'phone',
    label: 'phone',
    checked: true,
    disabled: false,
    dependence: 'email',
    dependence_msg: '',
    forDataType: ['plan'],
  },

  {
    key: 'email',
    label: 'email',
    checked: false,
    disabled: false,
    dependence: 'phone',
    forDataType: ['plan'],
  },
];

// ************************************************************************** //
// SEEDS
// ************************************************************************** //

const SETTINGS_SEED = {
  configurations: {
    dataTypes: ['plan'],
  },

  requiredFields: ['cnpj', 'rg', 'trade_name', 'mainPhone'],
};

const DATA_AUTO_FILL = {
  'name': 'Plano X',
  'operatorId': '643ec2151998a2f763a4a08f',
  'quotation': 'usd',
  'minAge': 0,
  'maxAge': 70,

  'grievanceLevel': 'level2',
  'grievanceMinAge1': 50,
  'grievanceMaxAge1': 60,
  'grievanceAdditionAge1': 100,
  'grievanceMinAge2': 61,
  'grievanceMaxAge2': 70,
  'grievanceAdditionAge2': 190.32,

  'destinations': ['africa', 'north_america', 'oceania'],
  'travelProfiles': ['student', 'sport', 'best_seller'],

  'conditionsFileUrl': 'https://firebasestorage.googleapis.com/v0/b/multi-4d8fc.appspot.com/o/643ec14175fdf864855d7183%2Fplans%2Fconditions%2F096cd6c3-3d4e-4c6c-b5b3-17d2b48f89ec.pdf?alt=media&token=821c320c-8873-464a-bb64-7e495f9eadf1',
  'coveragesFileUrl': 'https://firebasestorage.googleapis.com/v0/b/multi-4d8fc.appspot.com/o/643ec14175fdf864855d7183%2Fplans%2Fcoverages%2F8c794b3f-e74e-48b8-8786-097c6c03e408.pdf?alt=media&token=22cceb4d-becf-4d2d-ac98-412247f4254d'
};

// ************************************************************************** //
// MODULES PARAMETERS
// ************************************************************************** //

export const MODULE_MULTIPLANS_PARAMETERS = {
  root: 'plans',
  name: 'plans',
  singular: 'plan',
  titleField: 'name',
  url: PLANS_URL,
  api: MULTIPLANS_API_URL,
  initialRequest: INITIAL_REQUEST,
  initialListState: INITIAL_LIST_STATE,

  hasDashboard: true,
  hasFilter: true,
  hasSettings: true,
  hasAccessControl: true,
  hasConfigurations: true,
  hasDataMemory: true,
  hasRequiredFields: false,

  documentActions: {
    archive: false,
    duplicate: false,
  },

  // showNumberOfLine: true,

  methods: METHODS,
  components: COMPONENTS,
  dataTypes: DATA_TYPES,
  requiredFields: REQUIRED_FIELDS,

  initialContext: INITIAL_CONTEXT,

  // initialSearch: INITIAL_SEARCH,

  // initialFilter: INITIAL_FILTER,
  // initialSort: INITIAL_SORT,

  labels: {
    new_document_title: 'new_plan',
  },

  documentSections: DOCUMENT_SECTIONS,

  seeds: {
    settings: SETTINGS_SEED,
    data: DATA_AUTO_FILL,
  },
};
