// ************************************************************************** //
// MODULE CUSTOM METHODS
// ************************************************************************** //

import { getEntitiesByField } from '../../../_old-modules/entities/entities-api';

export const decorateNewDocument = () => {

  return {
    grievanceLevel: 'none',
    __conditionsAttachments: [],
    __coveragesAttachments: []
  };

};

export const getMultiPlanModuleParameters = async (_module, settings) => {

  return {
    operators: await getEntitiesByField('documents', 'company', 'operator')
  };

};
