import React from 'react';
import IntlMessage from 'components/util-components/IntlMessage';
import {Col, Row, Tooltip} from 'antd';
import {Icon} from 'components/util-components/Icon';
import {
    GlobalOutlined,
    HomeOutlined,
    MailOutlined,
    PhoneOutlined,
} from '@ant-design/icons';

// GLOBAL VARIABLES ************************************************************
// *****************************************************************************

const setLocale = (isLocaleOn, localeKey) => isLocaleOn ?
    <IntlMessage id={localeKey}/> : localeKey.toString();

// COMPONENT *******************************************************************
// *****************************************************************************

export const EntitiesDocumentSubtitle = React.forwardRef((props, ref) => {

    // const {_data, _module} = props;

    return (<>
    </>);

});

// EMAIL AND PHONE
export const EntitiesDocumentHeaderColumn2 = React.forwardRef((props, ref) => {


    const {dataContext} = props

    const _data = dataContext?.docLock

    return (<>
        <Row style={{
            display: _data?.mainContact?.email
                ? 'initial'
                : 'none',
        }}>
            <Col>
                <Row className="p-1" style={{minHeight: 29}}>
                    {_data?.mainContact?.email && <Tooltip placement="right"
                                                           title={setLocale(true,
                                                               'main_email')}>
                        <Icon type={MailOutlined} className="text-primary font-size-md" style={{ flexShrink: 0 }}/>
                        <span className="ml-2 font-weight-semibold text-truncate">
                              {_data?.mainContact?.email}
                            </span>
                    </Tooltip>}
                </Row>
            </Col>
        </Row>

        <Row style={{
            display: _data?.mainContact?.__phone
                ? 'initial'
                : 'none',
        }}>
            <Col>
                <Row className="p-1" style={{minHeight: 29}}>
                    {_data?.mainContact?.__phone &&
                        <Tooltip placement="right"
                                 title={setLocale(
                                     true,
                                     'main_phone')}>
                            <Icon type={PhoneOutlined} className="text-primary font-size-md" style={{ flexShrink: 0 }}/>
                            <span className="ml-2 font-weight-semibold text-truncate">
                              {_data?.mainContact?.__phone}
                            </span>
                        </Tooltip>}
                </Row>
            </Col>
        </Row>
    </>);

});

// ADDRESS AND WEBSITE
export const EntitiesDocumentHeaderColumn3 = React.forwardRef((props, ref) => {

    const {dataContext} = props

    const _data = dataContext?.docLock

    return (<>
        <Row style={{
            display: _data?.mainAddress?.city
                ? 'initial'
                : 'none',
        }}>
            <Col>
                <Row className="p-1" style={{minHeight: 29}}>
                    {_data?.mainAddress?.city &&
                        <Tooltip placement="right"
                                 title={setLocale(
                                     true, 'city')}>
                            <Icon type={HomeOutlined} className="text-primary font-size-md" style={{ flexShrink: 0 }}/>
                            <span className="ml-2 font-weight-semibold text-truncate">
                              {_data?.mainAddress?.city}
                                {_data?.mainAddress?.province && <>
                                    , {_data?.mainAddress?.province}
                                </>}
                            </span>
                        </Tooltip>}
                </Row>
            </Col>
        </Row>

        <Row style={{
            display: _data?.mainContact?.website
                ? 'initial'
                : 'none',
        }}>
            <Col>
                <Row className="p-1" style={{minHeight: 29}}>
                    {_data?.mainContact?.website &&
                        <Tooltip placement="right"
                                 title={setLocale(
                                     true,
                                     'website')}>
                            <Icon type={GlobalOutlined} className="text-primary font-size-md" style={{ flexShrink: 0 }}/>
                            <span className="ml-2 font-weight-semibold text-truncate">
                              {_data?.mainContact?.website}
                            </span>
                        </Tooltip>}
                </Row>
            </Col>
        </Row>
    </>);

});
