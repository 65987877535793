import { del, post, put } from "configs/ApiConfig";
import { clone } from "jarvisly-helper";
import { isMongoObjectId } from "utils/helpers";

// ************************************************************************** //
// Subscriptions
// ************************************************************************** //

// POST SUBSCRIPTION
export const postSubscription = async body => {

  const url = `/subscriptions`;
  const config = { headers: { "x-resultasobject": true } };

  console.log("POST ->", url, body);
  try {
    return await post(url, body, config);

  } catch (error) {
    throw error;
  }
};

// PUT SUBSCRIPTION
export const putSubscription = async (subscriptionId, values) => {

  const body = clone(values);

  if (!isMongoObjectId(subscriptionId)) {
    return console.error("Invalid subscriptionId! putSubscription()");
  }

  const url = `/subscriptions/${subscriptionId}`;
  const config = { headers: { "x-resultasobject": true } };

  try {
    return await put(url, body, config);

  } catch (error) {
    throw error;
  }

};

// PUT SUBSCRIPTION CHANGE TEAM STATUS
export const putSubscriptionChangeTeamStatus = async (
  subscriptionId, accountId, status) => {

  if (!isMongoObjectId(subscriptionId)) {
    return console.error(
      "Invalid subscriptionId! putSubscriptionChangeTeamStatus()");

  } else if (!isMongoObjectId(accountId)) {
    return console.error(
      "Invalid accountId! putSubscriptionChangeTeamStatus()");

  } else if (![-2, -1, 0, 1, 2].includes(status)) {
    return console.error(
      "Invalid status value in putSubscriptionChangeTeamStatus()");
  }

  const body = {
    accountId: accountId,
    status: status
  };

  const url = `/subscriptions/${subscriptionId}/change-team-status`;
  const config = { headers: { "x-resultasobject": true } };

  try {
    return await put(url, body, config);

  } catch (error) {
    throw error;
  }

};

// PUT SUBSCRIPTION CHANGE TEAM GRANTED
export const putSubscriptionChangeGranted = async (
  accountId, subscriptionId, modName, granted) => {

  if (!isMongoObjectId(accountId)) {
    return console.error(
      "Invalid 'accountId' on putSubscriptionChangeGranted()");

  } else if (!isMongoObjectId(subscriptionId)) {
    return console.error(
      "Invalid 'subscriptionId' on putSubscriptionChangeGranted()");

  } else if (!granted) {
    return console.error(
      "Missing 'granted' on putSubscriptionChangeControlAccess()");

  } else if (!modName) {
    return console.error(
      "Missing 'modName' on putSubscriptionChangeControlAccess()");
  }

  const body = {
    accountId: accountId,
    modName: modName,
    granted: clone(granted)
  };

  const url = `/subscriptions/${subscriptionId}/change-granted`;
  const config = { headers: { "x-resultasobject": true } };

  try {
    return await put(url, body, config, false, true);

  } catch (error) {
    throw error;
  }

};

// DEL SUBSCRIPTION
export const delSubscription = async subscriptionId => {

  if (!isMongoObjectId(subscriptionId)) {
    return console.error("Invalid subscriptionId! delSubscription()");
  }

  const url = `/subscriptions/${subscriptionId}`;
  const config = { headers: { "x-resultasobject": true } };

  try {
    return await del(url, config);

  } catch (error) {
    throw error;
  }

};

// PUT SUBSCRIPTION CHANGE TEAM GRANTED
export const putSubscriptionChangeMarketplace = async (
  subscriptionId, status, values) => {

  if (!isMongoObjectId(subscriptionId)) {
    return console.error(
      "Invalid 'subscriptionId' on putSubscriptionChangeMarketplace()");

  } else if (typeof status === "undefined") {
    return console.error(
      "Missing 'status' on putSubscriptionChangeMarketplace()");

  } else if (!values?.name) {
    return console.error(
      "Missing 'status' on putSubscriptionChangeMarketplace()");
  }

  const body = {
    marketplaceId: values?._id,
    status,
    name: values?.name,
    demoActivatedAt: values?.demoActivatedAt
  };

  const url = `/subscriptions/${subscriptionId}/change-marketplace-status`;
  const config = { headers: { "x-resultasobject": true } };

  try {
    return await put(url, body, config, false, true);

  } catch (error) {
    throw error;
  }

};

// ************************************************************************** //
// Invites
// ************************************************************************** //

// PUT REMOVE INVITE
export const putRemoveInviteFromSubscriptions = async (
  subscriptionId, accountId) => {

  if (!isMongoObjectId(subscriptionId)) {
    return console.error(
      "Invalid 'subscriptionId' on putRemoveInviteFromSubscriptions()");

  } else if (!isMongoObjectId(accountId)) {
    return console.error(
      "Invalid 'accountId' on putRemoveInviteFromSubscriptions()");
  }

  const body = { accountId };
  const url = `/subscriptions/${subscriptionId}/cancel-invitation`;
  const config = { headers: { "x-resultasobject": true } };

  try {
    const doc = await put(url, body, config);
    return doc ? doc : null;

  } catch (error) {
    throw error;
  }
};

// POST INVITATION
export const postInvitation = async values => {

  const body = clone(values);
  const url = `/invitations`;
  const config = { headers: { "x-resultasobject": true } };

  try {
    const doc = await post(url, body, config);
    return doc ? doc : null;

  } catch (error) {
    throw error;
  }
};

// PUT INVITATION CHANGE TEAM STATUS
export const putInvitationChangeGranted = async (
  invitationId, subscriptionId, modName, granted) => {

  if (!isMongoObjectId(invitationId)) {
    return console.error(
      "Invalid 'invitationId' on putInvitationChangeGranted()");

  } else if (!isMongoObjectId(subscriptionId)) {
    return console.error(
      "Invalid 'subscriptionId' on putInvitationChangeGranted()");

  } else if (!granted) {
    return console.error(
      "Missing 'granted' on putInvitationChangeControlAccess()");

  } else if (!modName) {
    return console.error(
      "Missing 'modName' on putInvitationChangeControlAccess()");
  }

  const body = {
    subscriptionId: subscriptionId,
    modName: modName,
    granted: clone(granted)
  };

  const url = `/invitations/${invitationId}/change-granted`;
  const config = { headers: { "x-resultasobject": true } };

  try {
    return await put(url, body, config, false, true);

  } catch (error) {
    throw error;
  }

};

// PUT REMOVE INVITE
export const putRemoveInviteFromInvitations = async (
  subscriptionId, invitationId) => {

  if (!isMongoObjectId(subscriptionId)) {
    return console.error(
      "Invalid 'subscriptionId' on putRemoveInviteFromInvitations()");

  } else if (!isMongoObjectId(invitationId)) {
    return console.error(
      "Invalid 'invitationId' on putRemoveInviteFromInvitations()");
  }

  const body = { subscriptionId };
  const url = `/invitations/${invitationId}/cancel-invitation`;
  const config = { headers: { "x-resultasobject": true } };

  try {
    const doc = await put(url, body, config);
    return doc ? doc : null;

  } catch (error) {
    throw error;
  }
};
