import {put} from 'configs/ApiConfig';
import {isMongoObjectId} from 'utils/helpers';
import {clone} from 'jarvisly-helper';
import {PROCEDURES_API_URL} from './procedures-constants';
import {decorateDocument} from './procedures-methods';

// ************************************************************************** //
// MODULE APIS
// ************************************************************************** //

// PUT PROCESS
export const putProcedure = async (procedureId, action, values) => {

  const body = clone(values);

  if (!isMongoObjectId(procedureId)) {
    return console.error('Invalid _id! putProcess()');
  }

  const url = `${PROCEDURES_API_URL}/${procedureId}/${action}`;
  const config = {headers: {'x-resultasobject': true}};

  try {
    const doc = await put(url, body, config);
    return doc ? decorateDocument(doc) : null;

  } catch (error) {
    throw error;
  }
};

