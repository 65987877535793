import { get, put } from 'configs/ApiConfig'
import { APARTMENTS_API_URL } from './constants'

export const getApartments = async _id => {

  let url = `${APARTMENTS_API_URL}/combo-list/${_id}`
  const config = { headers: { 'x-resultasobject': false } }

  try {
    return await get(url, config)

  } catch (error) {
    throw error
  }
}

export const getApartmentsV1 = async _id => {

  let url = _id
    ? `apartments/combo-list/${_id}`
    : `apartments/combo-list`

  const config = { headers: { 'x-resultasobject': false } }

  try {
    return await get(url, config)

  } catch (error) {
    throw error
  }
}

export const getApartmentsCountByBlockId = async blockId => {

  const url = `apartments/get-counter-by-blockid/${blockId}`
  const config = { headers: { 'x-resultasobject': true } }

  try {
    return await get(url, config) || 0

  } catch (error) {
    throw error
  }
}

export const getApartmentsCountByProfile = async profileId => {

  const url = `apartments/get-counter-by-profileid/${profileId}`
  const config = { headers: { 'x-resultasobject': true } }

  try {
    return await get(url, config) || 0

  } catch (error) {
    throw error
  }
}

export const pushApartmentsSubdocument = async (apartmentId, subdocumentBody, subdocumentField) => {

  const url = `apartments/subdocument-action/$push/${subdocumentField}/${apartmentId}`
  const config = { headers: { 'x-resultasobject': true } }

  try {
    return await put(url, subdocumentBody, config)

  } catch (error) {
    throw error
  }
}

export const pullApartmentsSubdocument = async (subdocumentId, subdocumentField) => {

  const url = `apartments/subdocument-action/$pull/${subdocumentField}/${subdocumentId}`
  const config = { headers: { 'x-resultasobject': true } }

  try {
    return await put(url, {}, config)

  } catch (error) {
    throw error
  }
}

