import React, { useRef } from "react";
import { Button, Table, Tag, Tooltip } from "antd";
import IntlMessage from "components/util-components/IntlMessage";
import { EditOutlined, EyeOutlined } from "@ant-design/icons";
import { buildTableColumnSearch } from "components/jarvisly-layouts/ModuleComponent/module-methods";
import moment from "moment";
import { getPriorityColor, getStatusColor } from "./occurrences-methods";
import { useSelector } from "react-redux";

// GLOBAL VARIABLES ************************************************************
// *****************************************************************************

const setLocale = (isLocaleOn, localeKey) =>
  isLocaleOn ? <IntlMessage id={localeKey} /> : localeKey.toString();

// COMPONENT *******************************************************************
// *****************************************************************************

const OccurrencesMainList = (props) => {
  // props deconstruction ------------------------------------------------------

  const {
    search,

    _module,
    _data,
    _summary,
    _loading,

    rdxModuleQuery,

    onChange,
    onClickOpen,
    onClickPreview,
  } = props;

  // local variables -----------------------------------------------------------

  const rdxModuleSettings = useSelector((s) => s.moduleRedux.rdxModuleSettings);
  const documentCodeRef = useRef();
  const subjectRef = useRef();

  // hooks ---------------------------------------------------------------------

  // METHODS -------------------------------------------------------------------

  // UI COMPONENT --------------------------------------------------------------

  const tableColumns = [
    {
      hidden: !rdxModuleSettings?.showNumberOfLine,
      title: setLocale(true, "line_#"),
      width: 80,
      render: (item, record, index) => (
        <div className="text-left">
          {_summary?.pagination?.current * _summary?.pagination?.pageSize -
            _summary?.pagination?.pageSize +
            index +
            1}
        </div>
      ),
    },
    {
      title: setLocale(true, "ticket"),
      dataIndex: ["_metadata", "documentCode"],
      filteredValue:
        search?.documentCode && search.documentCode !== "null"
          ? search?.documentCode
          : null,
      ...buildTableColumnSearch("documentCode", documentCodeRef),
      width: 140,
      render: (_, record) => (
        <>
          <div>
            <span className="text-uppercase text-muted">
              {record?._metadata?.__documentCode}
            </span>
          </div>
        </>
      ),
      sorter: true, // desabilitado temporariamente até consertarem o bug: 4.20.1 (https://github.com/ant-design/ant-design/issues/38392)
      sortDirections: ["ascend", "descend", "ascend"],
      sortOrder:
        rdxModuleQuery?.currentQuery?.sortField === "documentCode" &&
        rdxModuleQuery?.currentQuery?.sortOrder,
    },

    {
      title: setLocale(true, _module?.singular || "na"), // title: FilterByNameInput,
      dataIndex: "subject",
      filteredValue:
        search?.subject && search.subject !== "null" ? search?.subject : null,
      ...buildTableColumnSearch("subject", subjectRef),
      className: "text-truncate", // ellipsis: true,
      render: (_, record) => (
        <div style={{ width: 200 }}>
          <div className="font-weight-semibold">{record.subject}</div>
          <div className="text-muted font-size-sm">
            {record.name}, {record.unit}
          </div>
        </div>
      ),
      sorter: true,
      sortDirections: ["ascend", "descend", "ascend"],
      sortOrder:
        rdxModuleQuery?.currentQuery?.sortField === "subject" &&
        rdxModuleQuery?.currentQuery?.sortOrder,
    },

    {
      title: setLocale(true, "priority"),
      dataIndex: "__priority", // width: 100,
      render: (_, record) => (
        <>
          <Tag
            className="text-capitalize"
            color={getPriorityColor(record?.priority)}
          >
            {setLocale(true, record.__priority || "na")}
          </Tag>
        </>
      ),
    },

    {
      title: setLocale(true, "days"),
      dataIndex: "occurrenceDate",
      className: "text-center",
      render: (_, record) =>
        moment().diff(moment(record.occurrenceDate), "days"), // width: 50,
      sorter: true,
      sortDirections: ["ascend", "descend", "ascend"],
      sortOrder:
        rdxModuleQuery?.currentQuery?.sortField === "occurrenceDate" &&
        rdxModuleQuery?.currentQuery?.sortOrder,
    },

    {
      title: setLocale(true, "status"),
      dataIndex: "__status", // width: 100,
      render: (_, record) => (
        <>
          <Tag
            className="text-capitalize"
            color={getStatusColor(record?.status)}
          >
            {setLocale(true, record.__status || "na")}
          </Tag>
        </>
      ),
    },

    {
      title: setLocale(true, "last_interaction"),
      dataIndex: ["_metadata", "audit", "updatedAt"], // width: 160,
      render: (_, record) => (
        <span>
          {moment(
            record._metadata.audit?.updatedAt ||
              record._metadata.audit.createdAt,
          ).format("L LT")}
        </span>
      ),
      sorter: true,
      sortDirections: ["ascend", "descend", "ascend"],
      sortOrder:
        rdxModuleQuery?.currentQuery?.sortField ===
          "_metadata.audit.updatedAt" &&
        rdxModuleQuery?.currentQuery?.sortOrder,
    },

    /*    {
          title: setLocale(true, 'registration_date'),
          dataIndex: ['_metadata', 'audit', 'createdAt'],
          width: 160,
          hidden: false,
          render: (_, record) => (<>
            {record?._metadata?.audit?.createdAt &&
                moment(record._metadata.audit.createdAt).format('L LT')}
          </>),
          sorter: true,
          sortDirections: ['ascend', 'descend', 'ascend'],
          sortOrder: rdxModuleQuery?.currentQuery?.sortField ===
              '_metadata.audit.createdAt' &&
              rdxModuleQuery?.currentQuery?.sortOrder,
        },*/

    {
      title: setLocale(true, "actions"),
      dataIndex: "actions",
      width: 50,
      render: (_, record) => (
        <div className="text-right d-flex justify-content-end">
          <Tooltip title={setLocale(true, "edit")}>
            <Button
              className="mr-2"
              icon={<EditOutlined />}
              onClick={() => onClickOpen(record)}
              size="small"
            />
          </Tooltip>

          <Tooltip title={setLocale(true, "view")}>
            <Button
              className="mr-2"
              icon={<EyeOutlined />}
              onClick={() => onClickPreview(record)}
              size="small"
            />
          </Tooltip>
        </div>
      ),
    },
  ];

  return (
    <Table
      rowKey="_id"
      pagination={_summary?.pagination}
      columns={tableColumns.filter((x) => !x.hidden)}
      dataSource={_data}
      onChange={onChange}
      loading={_loading}
    />
  );
};

// EXPORT **********************************************************************
// *****************************************************************************

export default React.memo(OccurrencesMainList);
