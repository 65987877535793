import React from 'react';
import {
  decorateDocument, decorateNewDocument,
  decorateSettings, dataProfileValidation,
  parseSettings, removeDocumentValidation,
  validateFilterFn,
  validateSearchFn,
} from './products-methods';
import ProductsMainList from './ProductsMainList';
import {productsSettingsTabs} from './products-settings';
import {ProductsBodyPreviewHeader} from './ProductsPreview';
import {
  AppstoreAddOutlined,
} from '@ant-design/icons';
import {Route} from 'react-router-dom';
import ProductsProfile from './sections/ProductsProfile';

// ************************************************************************** //
// CONSTANTS
// ************************************************************************** //

const MODULE_ASSETS_URL = '/app/records/products/assets';
export const PRODUCTS_API_URL = '/products';

const INITIAL_FILTER = {
  folder: 'documents',
  dataProfile: 'assets',
};

const INITIAL_SORT = {
  sortField: '_metadata.audit.updatedAt',
  sortOrder: 'descend',
};

const INITIAL_SEARCH = {
  documentCode: 'null',
  name: 'null',
  documentStatus: 'null',
  ambience: 'null',
  family: 'null',
};

const INITIAL_CONTEXT = {
  mode: 'add',
  data: null,
  docLock: null,
  selectedSection: null,
  sectionsChanged: [],
  dataMemory: false,
  hasPicture: true,
};

const METHODS = {
  parseSettings: parseSettings,
  decorateSettings: decorateSettings,

  decorateDocument: decorateDocument,
  decorateNewDocument: decorateNewDocument,

  removeDocumentValidation: removeDocumentValidation,
  postDocumentValidation: dataProfileValidation,
  putDocumentValidation: dataProfileValidation,

  validateSearchFn: validateSearchFn,
  validateFilterFn: validateFilterFn,
};

const COMPONENTS = {
  list: props => <ProductsMainList {...props}/>,

  listPreviewHeader: props => <ProductsBodyPreviewHeader {...props}/>,
  // listPreviewBody: props => <ProductsBodyPreviewData {...props}/>,
  // listPreviewActions: props => <ProductsBodyPreviewActions {...props}/>,

  documentSubtitle: props => <div>{props?.record?.description}</div>,
  // documentHeaderColumn2: props => <ProceduresDocumentHeaderColumn2 {...props}/>,
  // documentHeaderColumn3: props => <ProceduresDocumentHeaderColumn3 {...props}/>,

  moduleSettings: productsSettingsTabs,
};

const DATA_TYPES = [
  {
    key: 'assets',  // database value
    label: 'asset',
    description: 'products_assets_description',
    checked: true,
  },
  {
    key: 'consumption',
    label: 'consumption',
    description: 'products_consumption_description',
    checked: false,
  },
  {
    key: 'retail',
    label: 'retail',
    description: 'products_retail_description',
    checked: false,
  },
  {
    key: 'manufacturing',
    label: 'manufacturing',
    description: 'products_manufacturing_description',
    checked: false,
  },
];

const DOCUMENT_SECTIONS = [
  {
    key: 'profile',
    icon: <AppstoreAddOutlined/>,
    label: 'product',
    route: (props, ref, _id) => <Route key="profile"
                                       path={`${MODULE_ASSETS_URL}/${_id}/profile`}
                                       render={() =>
                                           <ProductsProfile ref={ref} {...props} />}/>,
  },
  // {
  //   key: 'origin',
  //   icon: <FlagOutlined/>,
  //   label: 'origin',
  // },
  //   {
  //     key: 'images',
  //     icon: <PictureOutlined/>,
  //     label: 'photos',
  //     route: (props, ref, _id) => <Route key="images"
  //                                        path={`${ASSETS_MODULE_URL}/${_id}/images`}
  //                                        render={() =>
  //                                            <PictureCatalog ref={ref} {...props} />}/>,
  //   },
];

/*
const REQUIRED_FIELDS = [
  {
    key: 'name',
    label: 'name',
    checked: true,
    disabled: true,
    forDataType: ['document'],
  },
];
*/

// ************************************************************************** //
// MODULES PARAMETERS
// ************************************************************************** //

export const MODULE_PRODUCTS_PARAMETERS = {

  ASSETS: {
    root: 'products',
    name: 'assets',
    singular: 'asset',
    titleField: '__productFullName',
    url: MODULE_ASSETS_URL,
    api: PRODUCTS_API_URL,

    hasFilter: true,
    hasSettings: true,
    hasAccessControl: true,
    hasConfigurations: true,
    hasDataMemory: true,
    hasRequiredFields: false,

    methods: METHODS,
    components: COMPONENTS,
    dataTypes: DATA_TYPES.filter(x => x.key === 'assets'),
    requiredFields: [],

    initialSearch: INITIAL_SEARCH,
    initialContext: INITIAL_CONTEXT,
    initialFilter: INITIAL_FILTER,
    initialSort: INITIAL_SORT,

    labels: {
      new_document_title: 'new_assets',
    },

    documentSections: DOCUMENT_SECTIONS,
  },
};

// ************************************************************************** //
// SEEDS
// ************************************************************************** //

export const SETTINGS_SEED = {

  configurations: {
    dataTypes: ['assets', 'consumption', 'retail', 'manufacturing'],
  },

  families: [],

  products: [],

  brands: [],

  units: [
    {
      value: 'UN',
      mensure: 'unit',
    },
    {
      value: 'UNIQUE',
      mensure: 'unique',
    },
    {
      value: 'KG',
      mensure: 'unit',
    },
    {
      value: 'M2',
      mensure: 'bidimensional',
    },
    {
      value: 'MT3',
      mensure: 'tridimensional',
    },
  ],

};

