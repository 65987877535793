import React, { useState } from 'react'
import { Button, Popconfirm, Row, Table, Tag, Tooltip } from 'antd'
import { DeleteOutlined, EditOutlined } from '@ant-design/icons'
import IntlMessage from 'components/util-components/IntlMessage'
import { connect } from 'react-redux'
import BlocksAdd from './BlocksAdd'
import { delValueFromSettings } from 'components/jarvisly-layouts/ModuleComponent/module-api'
import { translate } from 'utils/react-jarvisly-helper'
import { modal, substitution } from 'utils/helpers'
import { useIntl } from 'react-intl'
import { getApartmentsCountByBlockId } from '../api'

// GLOBAL VARIABLES ************************************************************
// *****************************************************************************

const setLocale = (isLocaleOn, localeKey) => isLocaleOn ?
  <IntlMessage id={localeKey}/> : localeKey.toString()

// MAIN COMPONENT **************************************************************
// *****************************************************************************

const Blocks = React.forwardRef((props, ref) => {

  // props deconstruction ------------------------------------------------------'
  let { rdxModuleSettings, rdxModule: _module } = props

  // component states ----------------------------------------------------------
  const [showEditModal, setShowEditModal] = useState(false)
  const [data, setData] = useState(rdxModuleSettings?.apartments?.blocks || [])
  const [selectedRecord, setSelectedRecord] = useState(null)

  // local variables ------------------------------------------------------------

  const intl = useIntl()

  // hooks ---------------------------------------------------------------------

  // METHODS -------------------------------------------------------------------

  const onAdd = () => {
    setSelectedRecord(null)
    setShowEditModal(true)
  }

  const onEdit = (record) => {
    setSelectedRecord(record)
    setShowEditModal(true)
  }

  const onDelete = async _id => {

    // Check if it has occurrences using this tag in some document
    const options = { valueId: _id, _module, valueType: 'blocks' }
    const counter = await getApartmentsCountByBlockId(_id)
    const message = translate(intl, 'exclude_not_allowed_description')
    const translatedMessage = substitution(message, ['%COUNTER%'], [counter])

    if (counter > 0) {
      const options = {
        type: 'warning',
        title: translate(intl, 'deletion_not_allowed'),
        message: translatedMessage,
        okText: 'OK',
      }

      modal(options)
      return
    }

    const doc = await delValueFromSettings(options)
    rdxModuleSettings.apartments.blocks = [...doc?.apartments?.blocks]
    setData([...doc.apartments.blocks])
  }

  const onRefresh = doc => {

    if (!rdxModuleSettings.apartments) rdxModuleSettings.apartments = {}
    rdxModuleSettings.apartments.blocks = [...doc.apartments.blocks]

    setData([...doc.apartments.blocks])
  }

  // UI COMPONENT --------------------------------------------------------------

  const columns = [
    {
      title: '#',
      dataIndex: 'prefixNumber',
      render: (_, record) => record?.prefixNumber,
    },
    {
      title: setLocale(true, 'block_unit'),
      dataIndex: 'name', // width: 168.5,
      render: (_, record) => (
        <Tag key={record._id} color={record?.backColor}>
                  <span className="text-uppercase" style={{ color: record?.foreColor }}>
                    {record?.name}
                  </span>
        </Tag>
      ),
    },
    {
      title: setLocale(true, 'initials'),
      dataIndex: 'abbreviation',
      className: 'text-center',
      render: (_, record) => record?.abbreviation,
    },
    // {
    //   title: setLocale(true, 'reference'),
    //   dataIndex: 'reference',
    //   render: (_, record) => record?.reference,
    // },
    {
      title: setLocale(true, 'floors'),
      dataIndex: 'floors',
      className: 'text-center',
      render: (_, record) => record?.floors,
    },
    {
      title: setLocale(true, 'actions'),
      dataIndex: 'actions',
      width: 120,
      className: 'text-center',
      render: (_, record) => {
        return (<>
          <Tooltip title={setLocale(true, 'edit')}>
            <Button size="small"
                    // className={!isMobile ? 'mr-2' : 'mb-2'}
                    className="m-2"
                    icon={<EditOutlined/>}
                    onClick={() => onEdit(record)}/>
          </Tooltip>

          <Popconfirm title="Excluir este Valor?"
                      cancelButtonProps={{ className: 'fixed-with-90' }}
                      okButtonProps={{ className: 'fixed-with-90' }}
                      onConfirm={() => onDelete(record?._id)}>
            <Button size="small" icon={<DeleteOutlined/>}/>
          </Popconfirm>
        </>)
      },
    }
    ]

  return (<>

    <h5 className="text-muted">
      {setLocale(true, 'condominium_blocks')}
    </h5>


    <BlocksAdd showModal={showEditModal}
               setShowModal={setShowEditModal}
               settingsId={rdxModuleSettings?._id}
               onRefresh={onRefresh}
               _module={_module}
               record={selectedRecord}/>


    {/* ADD BUTTON */}
    <Row justify="center" className="mb-2">
      <Button shape="square"
              type="primary"
              htmlType="submit"
              onClick={onAdd}>
        <span>{setLocale(true, 'add_new_block')}</span>
      </Button>
    </Row>
    {/* ADD BUTTON */}


    <Table
      className="hide-last-line-table"
      rowKey="_id"
      dataSource={data}
      columns={columns || []}
      rowClassName="editable-row"
      pagination={{ pageSize: 5, showSizeChanger: false }}
    />

  </>)
})

// EXPORT **********************************************************************
// *****************************************************************************

const mapStateToProps = ({ moduleRedux }) => {
  const { rdxModuleSettings, rdxModule } = moduleRedux
  return { rdxModuleSettings, rdxModule }
}

export default connect(mapStateToProps)(Blocks)

