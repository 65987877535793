import { get, getV2 } from 'configs/ApiConfig';
import { ENTITIES_API_URL } from './entities-constants';
import moment from 'moment';

// ************************************************************************** //
// MODULE APIS
// ************************************************************************** //

// levels:
// 1 = includes no excluded and no archived.
// 2 = includes excluded and no archived,
// 3 = includes no excluded and archived,
// 4 = includes excluded and archived
export const getEntitiesByField = async (folder, dataType, dataProfile, field, value, resultAsObject = false) => {

  let url = `${ENTITIES_API_URL}/get-entities-name-list`;
  const config = { headers: { 'x-resultasobject': resultAsObject } };

  url += `/${folder || null}`;
  url += `/${dataType || null}`;
  url += `/${dataProfile || null}`;
  url += `/${field || null}`;
  url += `/${value || null}`;

  try {
    const result = await getV2(url, config);
    return result?.response?.data && result?.response?.data.length > 0
      ? result.response.data
      : null;

  } catch (error) {
    throw error;
  }
};

export const getEntitiesByNameAndBirthday = async (
  name, birthday, level = 1, dataType, dataProfile, resultAsObject = false) => {

  if (!name || !birthday || !moment(birthday, 'YYYY-MM-DD').isValid()) {
    return console.error(
      'missing \'name\' or \'birthday\' parameters! getEntitiesByField()');
  }

  if (birthday?.length < 3) return null;

  let url = `${ENTITIES_API_URL}/get-by-name-and-birthday/${name}/${birthday}/${level}`;
  const config = { headers: { 'x-resultasobject': resultAsObject } };

  if (dataType) url += `/${dataType}`;
  if (dataProfile) url += `/${dataProfile}`;

  try {
    return await get(url, config);

  } catch (error) {
    throw error;
  }
};

export const getEntitiesByUniqueKey = async (uniqueKey, level = 1, dataType, dataProfile, resultAsObject = false) => {

  if (!uniqueKey) {
    return console.error(
      'missing \'name\' or \'birthday\' parameters! getEntitiesByField()');
  }

  let url = `${ENTITIES_API_URL}/get-by-unique-key/${uniqueKey}/${level}`;
  const config = { headers: { 'x-resultasobject': resultAsObject } };

  if (dataType) url += `/${dataType}`;
  if (dataProfile) url += `/${dataProfile}`;

  try {
    return await get(url, config);

  } catch (error) {
    throw error;
  }
};

export const getResidents = async (blockId, room, level = 1, resultAsObject = false) => {

  // if (!blockId || !room) {
  //   return console.error('missing \'name\' or \'birthday\' parameters! getEntitiesByField()')
  // }

  let url = `${ENTITIES_API_URL}/get-by-block-room/${level}`;

  if (blockId) url += `/${blockId}`;
  if (room) url += `/${room}`;

  const config = { headers: { 'x-resultasobject': resultAsObject } };

  try {
    return await get(url, config);

  } catch (error) {
    throw error;
  }
};
